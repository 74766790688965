export const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = Browser.CHROME;
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = Browser.FIREFOX;
    } else if (userAgent.match(/safari/i)) {
        browserName = Browser.SAFARI;
    } else if (userAgent.match(/opr\//i)) {
        browserName = Browser.OPERA;
    } else if (userAgent.match(/edg/i)) {
        browserName = Browser.EDGE;
    } else {
        browserName = Browser.UNKNOWN;
    }
    return browserName;
}

export enum Browser {
    CHROME = 'chrome',
    FIREFOX = 'firefox',
    SAFARI = 'safari',
    OPERA = 'opera',
    EDGE = 'edge',
    UNKNOWN = 'unknown',
}