import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { Dispatch, RootState } from '../../../store';

const ArrangementSearchResults = () => {
  const arrangement = useSelector((state: RootState) => state.arrangement);
  const arrangements = useSelector((state: RootState) => state.arrangements);
  const dispatch = useDispatch<Dispatch>();

  if (arrangements.status === 'empty') return <></>;
  if (arrangements.status === 'loading') return <p>Loading arrangements...</p>;
  if (arrangements.status === 'error') return <p>{arrangements.error}</p>;

  return (
    <div className="Flex FlexColumn">
      <p className="text secondary">
        <small>
          Showing {arrangements.items.length} of {arrangements.total}{' '}
          arrangements
        </small>
      </p>
      {arrangements.items.map((item) => (
        <Card key={item.id}>
          <div>
            <p>
              <b>{item.title}</b>
            </p>
            <div className="text secondary">
              <small>
                <pre>{item.id}</pre>
              </small>
            </div>
          </div>
          <div>
            <Button
              disabled={
                arrangement.type === 'arrangement' && arrangement.id === item.id
              }
              onClick={() => {
                dispatch.arrangement.loadArrangement({ id: item.id });
              }}
            >
              Load
            </Button>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ArrangementSearchResults;
