import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import StatusBadge from '../../../components/StatusBadge';
import { Dispatch, RootState } from '../../../store';

const ExistingVersions = () => {
  const dispatch = useDispatch<Dispatch>();
  const arrangement = useSelector((state: RootState) => state.arrangement);
  const currentVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const versions = useSelector((state: RootState) => state.arrangementVersions);
  if (arrangement.type !== 'arrangement') return null;
  return (
    <>
      <h3 style={{ marginTop: '1rem' }}>Arrangement versions</h3>
      {versions.versions.map((version) => {
        return (
          <Card key={version.id} blue={version.id === currentVersion?.id}>
            <div className="Flex FlexColumn">
              <div>
                <StatusBadge status={version.status} />{' '}
                {version.id === currentVersion?.id ? (
                  <small>← The currently loaded version</small>
                ) : (
                  <></>
                )}
              </div>
              <small>
                {new Date(version.createdUtc + 'Z').toLocaleString()}
              </small>
              <pre className="text secondary">
                <small>{version.id}</small>
              </pre>
            </div>
            {version.id !== currentVersion?.id && (
              <Button
                onClick={() => {
                  dispatch.arrangementVersion.setState(version);
                  dispatch.arrangement.loadArrangement({
                    id: arrangement.id,
                    versionId: version.id,
                    preventChangeTab: true,
                  });
                }}
              >
                Load
              </Button>
            )}
          </Card>
        );
      })}
    </>
  );
};

export default ExistingVersions;
