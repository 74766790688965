import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import StatusBadge from '../../../components/StatusBadge';
import Button from '../../../components/Button';
import { Dispatch, RootState } from '../../../store';
import Versions from './Versions';

const Publish = () => {
  const dispatch = useDispatch<Dispatch>();
  const arrangement = useSelector((state: RootState) => state.arrangement);
  const arrangementVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const arrangementVersions = useSelector(
    (state: RootState) => state.arrangementVersions
  );
  const isLatest =
    arrangementVersions.versions[0]?.id === arrangementVersion?.id;

  if (arrangement.type !== 'arrangement') return null;
  return (
    <div className="Flex FlexColumn">
      <p>
        The currently loaded version of the arrangement is{' '}
        <StatusBadge status={arrangementVersion?.status} />.
      </p>
      {!isLatest && (
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          This is NOT the latest version of this arrangement!
        </span>
      )}
      {arrangementVersion && arrangementVersion.status !== 'Published' && (
        <>
          <h2>Publish</h2>
          <p>
            When you're satisfied with the arrangement and all necessary
            information is filled in, go ahead and publish the arrangement to
            prepare it for being publicly visible to users.
          </p>
          <Button
            onClick={() => {
              dispatch.arrangement.publish({
                id: arrangement.id,
                versionId: arrangementVersion.id,
                currentStatus: arrangementVersion.status,
              });
            }}
          >
            Publish
          </Button>
        </>
      )}
      {isLatest && arrangementVersion?.status !== 'Draft' && (
        <>
          <h2>Draft</h2>
          <p>
            If you need to make changes to the arrangement, create a Draft copy
            (i.e. a new version), make the necessary changes and then publish
            the new Draft version.
          </p>
          <Button
            onClick={() => {
              dispatch.arrangement.createDraftCopy({
                id: arrangement.id,
                versionId: arrangementVersion?.id,
              });
            }}
          >
            Create Draft copy
          </Button>
        </>
      )}
      {isLatest && arrangementVersion?.status === 'Published' && (
        <>
          <h2>Unpublish</h2>
          <p>
            Change this arrangement version status to PreviouslyPublished, rendering it no longer publicly visible to users.
          </p>
          <Button
            onClick={() => {
              dispatch.arrangement.unpublish({
                id: arrangement.id,
                versionId: arrangementVersion.id,
              });
            }}
          >
            Unpublish
          </Button>
        </>
      )}
      <h3>After publishing a new arrangement</h3>
      <p>
        Please{' '}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdaCAqh1t4KZMJ4qunknygBENv6-Z94pDGelAVq3IZt51ttbg/viewform"
          target="_blank"
        >
          fill in the form here
        </a>{' '}
        to notify the Streamvoice staff about the new arrangement.
        <br />
        Note that this is only required for completely new arrangements
        (i.e. has not been publicly visible before), the arrangement is
        vetted and then made publicly visible to users by Streamvoice staff.
      </p>
      <Button>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdaCAqh1t4KZMJ4qunknygBENv6-Z94pDGelAVq3IZt51ttbg/viewform"
          target="_blank"
          style={{ textDecoration: 'none', color: 'black' }}
        >
          Fill in the form
        </a>
      </Button>

      <Versions />
    </div>
  );
};

export default Publish;
