import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  greyedOut?: boolean;
  style?: React.CSSProperties;
  yellow?: boolean;
  blue?: boolean;
  red?: boolean;
};

export default function Card({
  children,
  greyedOut,
  style,
  yellow,
  blue,
  red,
}: Props) {
  return (
    <div
      className={classNames(
        'card',
        'text',
        greyedOut && 'secondary',
        yellow && 'NonExisting',
        blue && 'Existing',
        red && 'Important'
      )}
      style={style}
    >
      {children}
    </div>
  );
}
