
import { BarJumpsData, GridExportItem, TimeSyncData } from "../playerTypes";

export const recreateBarJumps = (items: GridExportItem[]): BarJumpsData => {
    const barJumps: BarJumpsData = [];
    let leftIdx: number | null = null;
    let rightIdx: number | null = null;
    for (let i = 0; i < items.length - 1; i++) {
        const leftItem = items[i];
        if (leftIdx === null) leftIdx = leftItem.rootIdx;
        const rightItem = items[i + 1];
        if (leftItem.rootIdx !== rightItem.rootIdx - 1) {
            const item = { from: leftIdx!, to: rightIdx! };
            barJumps.push(item);
            leftIdx = rightItem.rootIdx;
        }
        rightIdx = rightItem.rootIdx;
    }
    const item = { from: leftIdx!, to: rightIdx! };
    barJumps.push(item);
    return barJumps;
}

export const recreateTimeSync = (items: GridExportItem[]): TimeSyncData => {
    const timeSyncData: TimeSyncData = [];
    for (const item of items) {
        if (item.mediaSyncTime) {
            timeSyncData.push({ scorePos: item.scoreStartTime, mediaPos: item.mediaSyncTime });
        }
    }
    return timeSyncData;
}

export const modifyTimeSyncs = (items: GridExportItem[], timeSync: TimeSyncData): GridExportItem[] => {
    console.log('timeSync:', timeSync);
    const timeSyncCopy: TimeSyncData = [];

    timeSync.forEach((ts, tsIdx) => {
        console.log('ts', tsIdx, ts);
        timeSyncCopy.push(ts);
    });
    console.log('timeSyncCopy:', timeSyncCopy);

    const newItems = items.map((item, itemIdx) => {
        item.mediaSyncTime = undefined;
        return item;
    });
    newItems.forEach((item, itemIdx) => {
        console.log('newItem', itemIdx, item.mediaSyncTime);
    })
    const newItems2 = newItems.map((item, itemIdx) => {
        item.mediaSyncTime = undefined;
        if (timeSyncCopy.length > 0 && item.scoreStartTime >= timeSyncCopy[0].scorePos && item.scoreEndTime > timeSyncCopy[0].scorePos) {
            item.mediaSyncTime = timeSyncCopy[0].mediaPos;
            console.log('remove one');
            timeSyncCopy.shift();
        }
        return item;
    });
    newItems2.forEach((item, itemIdx) => {
        console.log('newItem2', itemIdx, item.scoreStartTime, item.mediaSyncTime);
    })
    return newItems2;
    // return items;
}

export const resetGridMediaTimeToScoreTime = (items: GridExportItem[]) => {
    items.forEach((item, itemIdx) => {
        item.mediaSyncTime = undefined;
        item.mediaStartTime = item.scoreStartTime;
        item.mediaEndTime = item.scoreEndTime;
    });
}

export const calculateMediaTimes = (items: GridExportItem[]) => {
    items.forEach((item, itemIdx) => {
        item.mediaStartTime = item.scoreStartTime;
        item.mediaEndTime = item.scoreEndTime;
    });
}