import Signal from '../data/Signal';
import '../wav/WavPublicApi';
import { get } from '../wav/Api';
import { ArrangementData_2_3 } from '../wav/WavPublicApi';
import { PlayerOptions } from '../playerTypes';

export type ArrangementStatus = {
    status: 'arrangementEmpty'
} | {
    status: 'arrangementLoading',
    arrangementId: string
} | {
    status: 'arrangementSuccess',
    arrangementId: string,
    arrangementData: ArrangementData_2_3,
    defaultPlayModeId: string | null
}

export default class ArrangementStore {
    constructor(private readonly options: PlayerOptions, private readonly onError: (error?: unknown) => void) {
    }

    readonly arrangementStatusSignal = new Signal<ArrangementStatus>({ status: 'arrangementEmpty' });

    async loadArrangement(arrangementId: string) {

        //------------------------------------------

        this.arrangementStatusSignal.value = {
            status: 'arrangementLoading',
            arrangementId
        };
        // NOTE: When in preview, arrangementId is actually an arrangementVersionId
        const url = this.options.preview ? `${this.options.apiUrl}/arrangements/preview/${arrangementId}` : `${this.options.apiUrl}/arrangements/${arrangementId}`;
        // console.log('arrangement url', url);
        // console.log('preview', this.options.preview);


        const arrangementData = await get<ArrangementData_2_3>(url, this.options.apiToken, this.options.apiVersion);
        const queryPlayModeId = new URLSearchParams(window.location.search).get('playMode');
        this.arrangementStatusSignal.value = {
            status: 'arrangementSuccess',
            arrangementId,
            arrangementData,
            defaultPlayModeId: queryPlayModeId
        };
    }

    getArrangementData(): ArrangementData_2_3 | null {
        switch (this.arrangementStatusSignal.value.status) {
            case 'arrangementSuccess':
                return this.arrangementStatusSignal.value.arrangementData;
            default:
                return null;
        }
    }

    clear() {
        this.arrangementStatusSignal.value = { status: 'arrangementEmpty' };
    }

}

