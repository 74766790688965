
//

import { SvgPageData, VerovioSvgData } from "../playerTypes";


export const parseSvgPages = (svgsPages: Array<string>): Map<string, VerovioSvgData> => {
    let map: Map<string, VerovioSvgData> = new Map();
    let measureNr = 0;
    let systemNr = 0;
    try {
        svgsPages.forEach((xmlString, pageIdx) => {

            const result = parseSvgPage(xmlString, pageIdx, systemNr, measureNr, map);
            map = result.map;
            measureNr = result.measureNr;
            systemNr = result.systemNr;
        });
    } catch (error) {
        console.log('Error', 'VerovioSvgParser', 'parseSvgPages', error);

    }
    return map;
}

const parseSvgPage = (svgString: string, pageIdx: number, systemNr: number, measureNr: number, map: Map<string, VerovioSvgData>) => {
    const parser = new DOMParser();
    const doc: Document = parser.parseFromString(svgString, "application/xml");
    const svg: Element = doc.firstElementChild as Element;

    const defScaleEl: Element = svg.getElementsByClassName('definition-scale').item(0) as Element;
    // console.log('viewBox', defScaleEl.getAttribute('viewBox'));
    const viewBoxA: Array<string> = defScaleEl.getAttribute('viewBox')?.split(' ') as Array<string>;

    const pageData: SvgPageData = { pageIdx, w: parseInt(viewBoxA[2]), h: parseInt(viewBoxA[3]) };
    map.set('page' + pageIdx, pageData);

    // return { map, measureNr, systemNr };
    const pageMarinEl: Element = defScaleEl.getElementsByClassName('page-margin').item(0) as Element;
    const pageMarginTransform = pageMarinEl.getAttribute('transform');
    const marginValues = pageMarginTransform?.replace('translate(', '').replace(')', '').split(',').map(v => parseInt(v)) as Array<number>;
    // console.log('marginValues', marginValues);

    const systems = coll2Array(pageMarinEl.getElementsByClassName('system'));

    // for (let system of systems) {
    systems.forEach((system: Element, systemIdx: number) => {

        const path = system.firstElementChild as Element;
        const measures = coll2Array(system.getElementsByClassName('measure'));
        const firstMeasure = measures[0];
        const firstMeasureStaffs = coll2Array(firstMeasure.getElementsByClassName('staff'));

        // find first measure first staff top path        
        const firstMeasureFirstStaff = firstMeasureStaffs[0];
        const firstStaffPaths = coll2Array(firstMeasureFirstStaff.getElementsByTagName('path'));
        const firstStaffPathYs: Array<number> = [];

        let pathDA;
        for (const path of firstStaffPaths) {
            const pathD = path.getAttribute('d') as string;
            pathDA = pathD.replace(/M/g, '').replace(/L/g, '').replace(/  /g, ' ').trim().split(' ');
            firstStaffPathYs.push(parseInt(pathDA[1]));
        }
        const firstStaffLastPathY = Math.min(...firstStaffPathYs);


        // const firstStaffFirstPath = firstStaffPaths[0];
        // let firstD = firstStaffFirstPath.getAttribute('d') as string;
        // const firstDA = firstD.replace(/M/g, '').replace(/L/g, '').replace(/  /g, ' ').trim().split(' ');


        // find first measure last staff bottom path
        const firstMeasureLastStaff = firstMeasureStaffs[firstMeasureStaffs.length - 1];
        const lastStaffPaths = coll2Array(firstMeasureLastStaff.getElementsByTagName('path'));
        const lastStaffPathYs: Array<number> = [];
        for (const path of lastStaffPaths) {
            const pathD = path.getAttribute('d') as string;
            const pathDA = pathD.replace(/M/g, '').replace(/L/g, '').replace(/  /g, ' ').trim().split(' ');
            lastStaffPathYs.push(parseInt(pathDA[1]));

        }
        const lastStaffLastPathY = Math.max(...lastStaffPathYs);

        const id = system.getAttribute('id') as string;
        const systemX = marginValues[0] + (pathDA ? parseFloat(pathDA[0]) : 0);
        const systemY = marginValues[1] + firstStaffLastPathY;
        const systemY2 = marginValues[1] + lastStaffLastPathY;

        let lastMeasureX2 = 0;

        // console.log('system', systemIdx, systemY, systemY2, systemY2 - systemY);

        measures.forEach((measure: Element, measureIdx: number) => {
            const staff = measure.getElementsByClassName('staff').item(0) as Element;
            const path = staff.firstElementChild as Element;
            const d = path.getAttribute('d') as string;
            const a = d.replace(/M/g, '').replace(/L/g, '').replace(/  /g, ' ').trim().split(' ');
            const id = measure.getAttribute('id') as string;
            const measureX = marginValues[0] + parseFloat(a[0]);
            const measureY = marginValues[1] + parseFloat(a[1]);
            const measureX2 = marginValues[0] + parseFloat(a[2]);
            map.set(id, { pageIdx, systemIdx, systemNr, measureIdx, measureNr, x: measureX, y: systemY, x2: measureX2, y2: systemY2 });

            const notes = coll2Array(measure.getElementsByClassName('note'));
            for (const note of notes) {
                const notehead = note.getElementsByClassName('notehead').item(0) as Element;
                const use = notehead.firstElementChild as Element;
                const id = note.getAttribute('id') as string;
                const x = marginValues[0] + parseFloat(use.getAttribute('x') as string);
                const y = marginValues[1] + parseFloat(use.getAttribute('y') as string);
                map.set(id, { measureNr, x, y, isPause: false });
            }
            const rests = coll2Array(measure.getElementsByClassName('rest'));
            for (const rest of rests) {
                const use = rest.firstElementChild as Element;
                const id = rest.getAttribute('id') as string;
                const x = marginValues[0] + parseFloat(use.getAttribute('x') as string);
                const y = marginValues[1] + parseFloat(use.getAttribute('y') as string);
                map.set(id, { measureNr, x, y, isPause: true });
            }
            lastMeasureX2 = measureX2;
            measureNr++;
        })
        const systemX2 = lastMeasureX2;
        map.set(id, { pageIdx, systemIdx, systemNr, x: systemX, y: systemY, y2: systemY2, x2: systemX2 });
    });

    return { map, measureNr, systemNr };
}

const coll2Array = (c: HTMLCollectionOf<Element>): Array<Element> => {
    const a = [];
    for (const item of c) a.push(item);
    return a;
}

export const test = async () => {
    const r1 = await window.fetch('/page1.xml');
    const xmlString1 = await r1.text();
    const r2 = await window.fetch('/page2.xml');
    const xmlString2 = await r2.text();
    const xmlStrings = [xmlString1, xmlString2];
    parseSvgPages(xmlStrings);
}


