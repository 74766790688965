
import DocumentStore from "../store/DocumentStore";
import { numberIterator } from "../tools/NumberTools";
import { delay } from "../tools/TimerTools";
import { PlaymodeType_2_3, } from "../wav/WavPublicApi";
import { PageOrientation, XmlSvgInfo } from '../playerTypes';

export const renderXmlSvg = async (pages: Array<XmlSvgInfo>, documentStore: DocumentStore, orientation: PageOrientation, tag = 'SvgMode'): Promise<HTMLElement[]> => {
    const pageDivs: HTMLElement[] = [];
    pages = pages.filter(p => p.layout === orientation);
    // console.log('RenderXmlSvg', pages);
    documentStore.clearPageElements();
    await delay(0);
    documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading pages...' };
    documentStore.documentContentsSignal.value = numberIterator(0, pages.length - 1).map(idx => documentStore.getBlankPage());
    await delay(0);
    pages.forEach((page, pageIdx) => {
        // console.log('RenderXmlSvg', pageIdx);
        const div: HTMLElement = document.createElement('div');
        div.style.position = 'relative';

        const svgModeLabel: HTMLElement = document.createElement('div');
        svgModeLabel.innerText = tag;
        svgModeLabel.style.fontSize = '2rem';
        svgModeLabel.style.position = 'absolute';
        svgModeLabel.style.left = svgModeLabel.style.top = '.5rem';
        svgModeLabel.style.color = 'tomato';
        div.appendChild(svgModeLabel);

        div.style.height = div.style.width = '100%';
        const img: HTMLImageElement = document.createElement('img');
        img.style.height = img.style.width = '100%';
        img.style.userSelect = 'none';
        img.src = page.url;
        img.ondragstart = e => e.preventDefault();
        img.onerror = e => {
            console.log('svg page.url:', page.url);
            console.error('Error loading svg image:', JSON.stringify(e));
        }
        div.appendChild(img);
        documentStore.replaceDocumentContentPage(pageIdx, div);
        pageDivs.push(div);
    });

    documentStore.documentStatusSignal.value = { status: 'documentSuccess', documentType: { type: PlaymodeType_2_3.Xml, svgs: [], timemapData: [] } };
    return pageDivs;
}


