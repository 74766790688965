import { useState, useEffect } from 'react';
// import useTimeout from "./useTimeout"

// export default function useDebounce(callback, delay, dependencies) {
//   const { reset, clear } = useTimeout(callback, delay)
//   useEffect(reset, [...dependencies, reset])
//   useEffect(clear, [])
// }

export default function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

// export default function useDebounce(value, delay, costlyFunction) {
//   const [currentValue, setCurrentValue] = useState(value);
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(
//     () => {
//       // Update debounced value after delay
//       const handler = setTimeout(() => {
//         if (costlyFunction != null) {
//           var calculatedValue = costlyFunction();
//           setDebouncedValue(calculatedValue);
//         } else {
//           setDebouncedValue(currentValue);
//         }
//       }, delay);

//       // Cancel the timeout if value changes (also on delay change or unmount)
//       // This is how we prevent debounced value from updating if value is changed ...
//       // .. within the delay period. Timeout gets cleared and restarted.
//       return () => {
//         clearTimeout(handler);
//       };
//     },
//     [currentValue, delay] // Only re-call effect if value or delay changes
//   );

//   return [debouncedValue, setCurrentValue];
// }
