import gsap, { Expo } from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import Signal from "../data/Signal";
import { DisplayModeState } from "./DisplayStore";
import { PagesLayoutDetailed, Point } from "./DocumentStore";
import { FrameSize } from "./GraphicElementStore";
import { GridTimeDetails, GridVerovioInfo1 } from "../playerTypes";

gsap.registerPlugin(ScrollToPlugin);

// const SCROLL_LOOKAHEAD = 0.800;

const SCROLL_LOOKAHEAD = 0.5;
const SCROLL_DURATION = 1;

// const FRAME_MARGIN = 100;
const FRAME_MARGIN = 100;

export default class AnimationScrollStore {

    private previousScrollTime = 0;

    readonly scrollActiveSignal = new Signal<boolean>(false);
    readonly preventAllScrolling = new Signal<boolean>(false);

    private autostartScrollOnPlay: boolean;

    public constructor(autostartScrollOnPlay = true) {
        this.autostartScrollOnPlay = autostartScrollOnPlay;

        // Just for prototyping, jump to scroll frame by pressing numeric key. 0 = frame 0, 1 = frame 1 etc.

        // document.onkeydown = (e: KeyboardEvent) => {
        //     const keyNum: number = parseInt(e.key);
        //     if (keyNum === NaN) return;
        //     if (this.timeline === null) return;

        //     if (this.frames[keyNum] !== undefined) {
        //         const frame = this.frames[keyNum];
        //         if (frame === null) return;
        //         const pos = frame.pos;
        //         let scrollTime = pos * this.duration + .5;

        //         if (this.previousScrollTime == scrollTime) {
        //             this.timeline.time(scrollTime - 1);
        //             this.timeline.time(scrollTime);
        //         } else {
        //             this.timeline.time(scrollTime);
        //         }
        //         this.previousScrollTime = scrollTime;
        //     }
        // }

        this.scrollActiveSignal.add(active => {
            if (this.timeline === null) return;

            if (active) {
                this.syncToScrollFrameFromTime(this.currentSyncTime);
            } else {
                console.log('stop timeline');
                this.timeline.pause(); // stop timeline if active set to false
            }
        })

        // window.setInterval(() => {
        //     if (!this.timeline) return;
        //     console.log(this.timeline.time());
        // }, 1000);
    }

    public readonly scrollAnimationDataSignal = new Signal<ScrollAnimationData | null>(null);

    private frames: Array<ScrollFrame> = [];
    private timeline: gsap.core.Timeline | null = null;
    private duration = 0;

    public updateScrollAnimation = (gridInfo: GridVerovioInfo1, gridTimeDetails: GridTimeDetails, layoutDetails: PagesLayoutDetailed, duration: number, displayMode: DisplayModeState, currentTime: number) => {

        this.isPlaying = false;
        this.isScrubbing = false;

        this.duration = duration;
        const viewportBounds = layoutDetails.graphicsEl.getBoundingClientRect();
        const areaSize = layoutDetails.areaSize;
        const pagesCoords = layoutDetails.pagesCoords;
        const pageSize = layoutDetails.pageSize;
        const detailedItems = gridTimeDetails.itemDetails;

        let s: { x1: number, y1: number, x2: number, y2: number } | null = null;
        this.frames = [];

        const viewportWidth = viewportBounds.width;
        const viewportHeight = viewportBounds.height;
        const viewportScrollLeft = layoutDetails.graphicsEl.scrollLeft;
        const viewportScrollTop = layoutDetails.graphicsEl.scrollTop;

        detailedItems.forEach((detailedItem, itemIdx) => {

            const itemEl = document.getElementById(gridInfo.items[detailedItem.item.idx].id) as HTMLElement;

            // const itemEl = document.getElementById(detailedItem.itemId) as HTMLElement;

            const itemBounds = itemEl.getBoundingClientRect() as DOMRect;

            const itemX = itemBounds.x + viewportScrollLeft;
            const itemY = itemBounds.y + viewportScrollTop;
            const itemW = itemBounds.width;
            const itemH = itemBounds.height;

            if (s === null) {
                s = { x1: itemX, y1: itemY, x2: itemX + itemW, y2: itemY + itemH };
                this.frames.push({ scrollX: 0, scrollY: 0, x: 0, y: 0, w: 0, h: 0, pos: detailedItem.leftPos });
            } else {
                s.x1 = Math.min(s.x1, itemX);
                s.y1 = Math.min(s.y1, itemY);
                s.x2 = Math.max(s.x2, itemX + itemW);
                s.y2 = Math.max(s.y2, itemY + itemH);
            }
            const sizeX = s.x2 - s.x1;
            const sizeY = s.y2 - s.y1;

            switch (displayMode.state) {
                case 'displayModePortrait':
                    if (sizeX <= (viewportBounds.width - FRAME_MARGIN) && sizeY <= (viewportBounds.height - FRAME_MARGIN)) {
                        const lastFrame = this.frames[this.frames.length - 1];
                        lastFrame.x = s.x1;
                        lastFrame.y = s.y1;
                        lastFrame.w = sizeX;
                        lastFrame.h = sizeY;
                    } else {
                        s = { x1: itemX, y1: itemY, x2: itemX + itemW, y2: itemY + itemH };
                        this.frames.push({ scrollX: 0, scrollY: 0, x: s.x1, y: s.y1, w: sizeX, h: sizeY, pos: detailedItem.leftPos });
                    }
                    break;
                case 'displayModeLandscape':
                    if (sizeX <= (viewportBounds.width - FRAME_MARGIN)) {
                        const lastFrame = this.frames[this.frames.length - 1];
                        lastFrame.x = s.x1;
                        lastFrame.y = s.y1;
                        lastFrame.w = sizeX;
                        lastFrame.h = sizeY;
                    } else {
                        s = { x1: itemX, y1: itemY, x2: itemX + itemW, y2: itemY + itemH };
                        this.frames.push({ scrollX: 0, scrollY: 0, x: s.x1, y: s.y1, w: sizeX, h: sizeY, pos: detailedItem.leftPos });
                    }
                    break;
            }

        });

        // console.log('Scroll frames length ', detailedItems.length, this.frames.length);
        this.frames.forEach(frame => {
            frame.scrollY = frame.y - Math.max(0, ((viewportBounds.height - frame.h) / 2));
            frame.scrollX = frame.x - Math.max(0, ((viewportBounds.width - frame.w) / 2));
        });
        // this.frames.forEach((frame, frameIdx) => {
        //     console.log('frame', frameIdx, frame.x, frame.y, frame.w, frame.h);
        // });

        this.scrollAnimationDataSignal.value = { areaSize, frames: this.frames, pagesCoords, pageSize, viewportBounds };

        if (this.timeline !== null) {
            this.timeline.time(0);
            this.timeline.kill();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({ onComplete: () => null });


        this.frames.forEach((frame, frameIdx) => {
            const scrollTime = Math.max((frame.pos * duration) - SCROLL_LOOKAHEAD, 0);
            const halfFrameMargin = FRAME_MARGIN / 2;
            const scrollX = Math.round(frame.scrollX - halfFrameMargin);
            const scrollY = Math.round(frame.scrollY - halfFrameMargin);
            this.timeline?.to(layoutDetails.graphicsEl, { duration: SCROLL_LOOKAHEAD * 2, ease: Expo.easeOut, scrollTo: { x: scrollX, y: scrollY } }, scrollTime);
        });


        this.timeline.pause(currentTime);

        // // TODO: Extra set time to 0 after creation - should not be needed,
        // // but required after loading a second arr. Strange.
        // window.setTimeout(() => {
        //     if (!this.timeline) return;
        //     this.timeline.pause(0);
        // }, 500);

    }

    private syncToScrollFrameFromTime(time: number) {
        if (this.preventAllScrolling.value) return;
        if (this.timeline === null) return;
        // console.log('syncToScrollFrameFromTime');
        let frame: ScrollFrame | undefined = this.frames.find(frame => (frame.pos * this.duration) > time);
        const frameIdx = frame === undefined ? this.frames.length - 1 : Math.max(0, this.frames.indexOf(frame as ScrollFrame) - 1);
        frame = this.frames[frameIdx];
        if (frame) {
            const syncTime = frame.pos * this.duration;
            this.timeline.time(syncTime - 1);
            this.timeline.time(syncTime);
        }
    }

    private isPlaying = false;
    private isScrubbing = false;
    private currentSyncTime = 0;

    public syncTimeFraction = (timeFraction: number) => {
        if (this.preventAllScrolling.value) return;
        const time = timeFraction * this.duration;
        this.currentSyncTime = time;
        if (!this.timeline) return;
        if (this.isScrubbing) return;
        if (!this.scrollActiveSignal.value) return;
        // console.log('syncTimeFraction', timeFraction, time);
        this.timeline.time(time);
    }

    public syncTime = (time: number) => {
        if (this.preventAllScrolling.value) return;
        this.currentSyncTime = time;
        if (!this.timeline) return;
        if (this.isScrubbing) return;
        if (!this.scrollActiveSignal.value) return;
        // console.log('syncTime', time);
        this.timeline.time(time);
    }

    public play = () => {
        if (this.preventAllScrolling.value) return;
        if (!this.timeline) return;
        if (this.isScrubbing) return;
        if (!this.isPlaying) {
            this.syncToScrollFrameFromTime(this.currentSyncTime);
            if (this.autostartScrollOnPlay) {
                this.scrollActiveSignal.value = true;
            }
        }
        if (!this.scrollActiveSignal.value) return;

        this.isPlaying = true;
        this.timeline.play();
    }

    public stop = () => {
        if (!this.timeline) return;
        this.isPlaying = false;
        this.timeline.pause();
        if (this.preventAllScrolling.value) return;
    }

    public scrub = (time: number) => {
        if (!this.timeline) return;
        if (this.preventAllScrolling.value) return;
        this.isScrubbing = true;
        this.timeline.pause();
        // console.log('scrub', time);
        // this.animation.currentTime = this.animation.currentTime = time * 1000;;
        this.timeline.time(time);
    }

    public scrubFraction = (timeFraction: number) => {
        if (!this.timeline) return;
        this.isScrubbing = true;
        this.timeline.pause();
        // console.log('scrubFraction', timeFraction);
        // this.animation.currentTime = this.animation.currentTime = time * 1000;;
        this.timeline.time(timeFraction * this.duration);
    }

    public scrubReady = () => {
        this.isScrubbing = false;
    }

}

export type ScrollFrame = { scrollX: number, scrollY: number, x: number, y: number, w: number, h: number, pos: number }

export type ScrollAnimationData = {
    viewportBounds: DOMRect,
    areaSize: FrameSize,
    pagesCoords: Array<Point>,
    pageSize: FrameSize,
    frames: Array<ScrollFrame>,
}
