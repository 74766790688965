export const numberIterator = (start: number, end?: number): Array<number> => {
    if (!end) {
        start = 0;
        end = start;
    }
    return Array.from(Array(end - start + 1).keys()).map(x => x + start);
}

export const round2 = (num: number): number => {
    return Math.round(num * 100) / 100;
}

export const round3 = (num: number): number => {
    return Math.round(num * 1000) / 1000;
}

export const round4 = (num: number): number => {
    return Math.round(num * 10000) / 10000;
}
export const round5 = (num: number): number => {
    return Math.round(num * 100000) / 100000;
}

export function isFloat(n: number) {
    return n === +n && n !== (n | 0);
}