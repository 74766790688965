import React, { useEffect, useRef, useState } from 'react'
// import css from './Player2Sync.module.css';
import "./BarJumpsEditor.css";
import { BarJumpColors } from './Colors';


const BarJumpsEditor = (props: any) => {

    const [selected, setSelected] = useState(null);


    const onSelected = (barJump: any) => {
        console.log('onSelected', barJump);
        setSelected(barJump);
    }

    if (!props.syncData) return null;

    return <div className="Flex FlexColumn Pad05" >
        {/* <details open>
            <summary style={{ fontWeight: 'bold' }}>Important note regarding temporary change in the BarJumps editing</summary>
            <p style={{ padding: '.5rem 0' }}>The following information applies <strong>if the first region in the final setup should be anything other than full-length</strong> (from syncbar 0 to the last syncbar). If so, do as follows:</p>
            <ul>
                <li>Let the initial full length region be as is, and ignore it while doing other edits - it will serve as a "dummy", and <strong>is later to be removed</strong> but needs to be in place while doing further editing.</li>
                <li>Add a new region that will serve as the later first one.</li>
                <li>Keep on setting up the barjump regions as you would normally do.</li>
                <li>Finally, before saving: delete the first "dummy" region.</li>
            </ul>
        </details> */}

        <div className="Flex FlexRow JustifyStretch AlignCenter" style={{ padding: '0 .5rem' }}>
            {<JumpFunctions {...props} selected={selected} />}
        </div>

        <JumpItems syncData={props.syncData} onSelected={onSelected} selected={selected} />
    </div >
}

export default BarJumpsEditor;

const FN_INSERT_BEFORE = 'Insert before';
const FN_INSERT_AFTER = 'Insert after';
const FN_DELETE = 'Delete';
const FN_CHANGE = 'Change';
const FN_ADD_TO_START = 'Add to start';
const FN_ADD_TO_END = 'Add to end';

const JumpFunctions = (props: any) => {
    const [funktion, setFunktion] = useState<string>('');

    return <div className="Flex FlexRow JustifyBetween AlignCenter Flex1">
        <div className="Flex JustifyStart AlignCenter">
            <span>Tool:</span>
            <button onClick={e => setFunktion(FN_ADD_TO_START)} className={`small` + (funktion === FN_ADD_TO_START ? ' active' : '')} > Add to start</button>
            {props.selected && <>
                <button className={`small` + (funktion === FN_INSERT_BEFORE ? ' active' : '')} onClick={e => setFunktion(FN_INSERT_BEFORE)}>Insert before</button>
                <button className={`small` + (funktion === FN_INSERT_AFTER ? ' active' : '')} onClick={e => setFunktion(FN_INSERT_AFTER)}>Insert after</button>
                <button className={`small` + (funktion === FN_DELETE ? ' active' : '')} onClick={e => setFunktion(FN_DELETE)}>Delete</button>
                <button className={`small` + (funktion === FN_CHANGE ? ' active' : '')} onClick={e => setFunktion(FN_CHANGE)}>Change</button>
            </>
            }
            <button onClick={e => setFunktion(FN_ADD_TO_END)} className={`small` + (funktion === FN_ADD_TO_END ? ' active' : '')} > Add to end</button>
        </div >
        {/* {
            !props.selected && props.syncData.barJumps.length == 0 && <div className="BarJumpFunctionbuttons">
                <button className="small" onClick={e => {
                    const first = { from: 0, to: props.syncData.nrOfBars - 1 };
                    console.log(first);
                    props.playerComponent.syncSetBarJumps([first]);
                }}>Create barjumps</button>
            </div>
        } */}

        {funktion && <EditForm {...props} funktion={funktion} />}

    </div >

}

const JumpItems = (props: any) => {
    if (!props.syncData) return null;
    if (!props.syncData.barJumps) return null;

    const jumps = props.syncData.barJumps;

    const bjNumBars = jumps.map((bj: any) => bj.to - bj.from).reduce((sum: number, num: number) => sum + num, 0);
    let prevWidth = 0;
    const jumpItems = jumps.map((bj: any, bjIdx: number) => {
        const leftP = (prevWidth / bjNumBars) * 100 + '%';
        const width = bj.to - bj.from;
        const widthP = (width / bjNumBars) * 100 + '%';
        const selectedBorder = (props.selected) ? bj == props.selected ? '2px solid white' : '' : '';

        const bjStyle = {
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: leftP,
            width: widthP,
            border: selectedBorder,
            backgroundColor: BarJumpColors[bjIdx % 7],
            color: 'white',
        }

        prevWidth += width;
        return <div className="BarSyncItem" key={bjIdx} style={bjStyle as any} onClick={e => {
            if (!props.onSelected) return;
            props.onSelected(props.selected == bj ? null : bj);
        }}>
            <span style={{ color: 'white' }}>{bj.from + '￫'} </span>
            <span style={{ color: 'white' }}>{'￩' + bj.to} </span>
        </div >
    })

    return <div className="BarSyncWrapper" style={{ position: 'relative' }}>
        {jumpItems}
    </div>

}

const EditForm = (props: any) => {


    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [current, setCurrent] = useState('');
    const inputFrom = useRef(null);
    const inputTo = useRef(null);
    const inputbutton = useRef(null);

    useEffect(() => {
        if (!props.selected) return;
        if (!props.funktion) return;
        setFrom(props.selected.from);
        setTo(props.selected.to);
    }, [props.selected, props.funktion]);


    useEffect(() => {
        if (!props.gridAction) return;
        switch (props.gridAction.action) {
            case 'click':
                const itemIdx = props.gridAction.itemIdx;
                console.log('EditFrom', 'GridAction', props.gridAction, itemIdx);
                switch (current) {
                    case 'from':
                        setFrom(itemIdx);
                        if (inputTo.current)
                            (inputTo.current as HTMLElement).focus();

                        break;
                    case 'to':
                        setTo(itemIdx);
                        if (inputbutton.current)
                            (inputbutton.current as HTMLElement).focus();
                        break;
                    default:
                    //
                }
                break;

            default:
            //
        }

    }, [props.gridAction]);


    if (props.funktion && props.funktion === FN_DELETE) return <button className="small alert"
        onClick={e => {
            const currentBarJumps = [...props.syncData.barJumps];

            if (currentBarJumps.length < 2) {
                alert('Can not remove this bar jump item - there must be at least one');
                return;
            }
            if (!window.confirm('Do you want to delete the selected barjump region?')) return;
            const indexOfSelected = props.syncData.barJumps.indexOf(props.selected);
            currentBarJumps.splice(indexOfSelected, 1);
            props.playerComponent.syncSetBarJumps(currentBarJumps);
            return;
        }}> Delete region</button >;

    const onFocus = (item: any) => {
        console.log('onFocus', item);
        setCurrent(item);
    }

    const onBlur = (item: any) => {
        console.log('onBlur', item);
    }

    const onSet = (e: any) => {
        const indexOfSelected = props.syncData.barJumps.indexOf(props.selected);
        const currentBarJumps = [...props.syncData.barJumps];
        switch (props.funktion) {
            case FN_ADD_TO_START:
                currentBarJumps.splice(0, 0, { from, to });
                props.playerComponent.syncSetBarJumps(currentBarJumps);
                return

            case FN_INSERT_BEFORE:
                currentBarJumps.splice(indexOfSelected, 0, { from, to });
                props.playerComponent.syncSetBarJumps(currentBarJumps);
                return
            case FN_INSERT_AFTER:
                currentBarJumps.splice(indexOfSelected + 1, 0, { from, to });
                props.playerComponent.syncSetBarJumps(currentBarJumps);
                return;
            case FN_CHANGE:
                currentBarJumps.splice(indexOfSelected, 1, { from, to });
                props.playerComponent.syncSetBarJumps(currentBarJumps);
                return;
            case FN_ADD_TO_END:
                const newBarJumps = currentBarJumps.concat({ from, to });
                props.playerComponent.syncSetBarJumps(newBarJumps);
                return;
            // case FN_DELETE:
            //     console.log('delete');
            //     if (!window.confirm('Do you want to delete the selected barjump region?')) return;
            //     currentBarJumps.splice(indexOfSelected, 1);
            //     props.playerComponent.syncSetBarJumps(currentBarJumps);
            //     return;


            default:
                return;
        }

    }

    return <form className="Flex FlexRow JustifyEnd AlignCenter" onChange={e => {
        e.preventDefault();
        console.log('form onChange');
    }}
    >
        <span>From:</span>
        <input ref={inputFrom} className="BarSyncInput" type="number" value={from} onChange={e => setFrom(parseInt(e.target.value))} onFocus={e => onFocus('from')} onBlur={e => onBlur('from')}></input>
        <span>To:</span>
        <input ref={inputTo} className="BarSyncInput" type="number" value={to} onChange={e => setTo(parseInt(e.target.value))} onFocus={e => onFocus('to')} onBlur={e => onFocus('to')}></input>
        <button className="small" ref={inputbutton} onFocus={e => onFocus('set')} type="button" onClick={onSet}>{props.funktion}</button>
    </form>



}





// const BarJumpsEditor = () => {
//     return <>
//         <h1>BarJumpsEditor</h1>
//     </>
// }

// export default BarJumpsEditor;