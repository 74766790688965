/* eslint-disable */

import DocumentStore from "../store/DocumentStore";
import VerovioStore from "../store/VerovioStore";
import { VerovioOptions } from "../store/VerovioStore";
import { delay } from "../tools/TimerTools";
import { numberIterator } from '../tools/NumberTools';
import { ArrangementData_2_3, InterestedParty, PlayModeTypeData, PlaymodeType_2_3 } from "../wav/WavPublicApi";
import DisplayStore from "../store/DisplayStore";
import GridVerovioStore from "../store/GridVerovioStore";
import { parseSvgPages } from "../grid/VerovioSvgParser";
import { isFloat } from "../tools/NumberTools";
import { ScorePartwise } from "./xml/MusicXmlElements";
import FixBarPauses from "./xml/fix/FixBarPauses";
import FixTies from "./xml/fix/FixTies";


import { PlayerOptimizationType } from "../playerTypes";
import { VerovioSyncData, VerovioTimemapData } from "../playerTypes";

// export type VerovioTimemapData = Array<WAVExtendedTimeMapEntry>;
// export type VerovioSyncData = {
//     svgObjectsMap: Map<string, VerovioSvgData>,
//     timemapData: VerovioTimemapData,
// }

const LANDSCAPE_MODE_PAGE_EXTRA_HEIGHT = 60;

export default class RenderXml {
    public static beforeXmlRender = () => {
        //
    }

    public static afterXmlRender = () => {
        //
    }

    private static fixMusicXml = (musicXml: string): string => {
        //-----------------------------------------------------
        // fix Finale bar-pauses
        const t0 = performance.now();
        const parser = new DOMParser();
        const xml = parser.parseFromString(musicXml, 'application/xml');
        const score = new ScorePartwise().setup(xml.firstElementChild as Element);
        const barPauseFixer = new FixBarPauses(score);
        barPauseFixer.fixScore();
        const tieFixer = new FixTies(score);
        tieFixer.fixScore();
        musicXml = score.rebuildAsString();
        const t1 = performance.now();
        // console.log(`MusicXML fix FinaleBarPauses and SibeliusTies took ${t1 - t0} milliseconds.`);

        //------------------------------------------
        // revmove print-object attributes
        musicXml = musicXml.replace(/print-object="([a-z]+)"/g, '');

        // remove dangling ending tags                
        musicXml = musicXml.replaceAll(/(\<ending number="")([a-z-"= ]+)(type="stop"\><\/ending\>)/ig, "");

        return musicXml;
    }

    private static fixTempoCompensation(timeMapData: VerovioTimemapData) {

        // timeMapData.forEach((item, idx) => {
        //     console.log(idx, item.tstamp);
        // })

        let measureQTotal = 0;
        timeMapData.forEach((item, idx) => {
            if (item.measureQDuration) {
                measureQTotal += item.measureQDuration;
            }
        })

        // console.log('measureQTotal', measureQTotal)
        timeMapData.forEach((item, idx) => {
            item.tstamp = item.qstamp * 100;
            if (item.measureTDuration) {
                item.measureTDuration = item.measureQDuration * 100;
            }
        });

        // console.log('after:');
        // timeMapData.forEach((item, idx) => {
        //     console.log(idx, item.tstamp);
        // })

        return timeMapData;
    }

    public static checkUseTempoCorrection(playmodeData: PlayModeTypeData): boolean {
        // console.log('playmodeData', playmodeData);
        if (!playmodeData) return false;
        switch (playmodeData.type) {
            case 'Xml': {
                if (playmodeData.timeSync)
                    // console.log(playmodeData.timeSync!.length, playmodeData.tempoCompensation);
                    if (playmodeData.timeSync && playmodeData.timeSync.length > 0 && playmodeData.tempoCompensation !== true) {
                        return false;
                    }
                return true;
            }
            default:
                return false;
        }
    }

    public static async renderPortrait(xmlUrl: string, documentStore: DocumentStore, verovioStore: VerovioStore, gridStore: GridVerovioStore, arrangementData: ArrangementData_2_3 | null, xmlPlaymodeData: PlayModeTypeData, optimization: Array<PlayerOptimizationType>) {
        this.beforeXmlRender();
        documentStore.documentContentsSignal.value = [documentStore.getBlankPage()];
        documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading pages...' };
        //-------------------------------------------------------       
        // xmlUrl = '/dev-assets/iws.musescore.nomulti.xml';
        // xmlUrl = '/dev-assets/iws.musescore.xml';
        // xmlUrl = '/dev-assets/memory/mem5.mus.xml';        
        const useTempoCorrection = RenderXml.checkUseTempoCorrection(xmlPlaymodeData);
        const response = await window.fetch(xmlUrl);
        let musicXml: string = await response.text();
        musicXml = this.fixMusicXml(musicXml);

        const pagesCount: number = verovioStore.loadData(musicXml, VEROVIO_OPTIONS_PAGES);
        // console.log('xml pagesCount', pagesCount);
        let timemapData: VerovioTimemapData = RenderXml.correctTimeMapFloatingPointErrors(verovioStore.renderToTimeMap());
        this.fixTempoCompensation(timemapData);
        documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading page 1/' + pagesCount };
        documentStore.documentContentsSignal.value = numberIterator(0, pagesCount - 1).map(idx => documentStore.getBlankPage());
        await delay(0);
        const svgs: Array<string> = [];
        for (let pageIdx = 0; pageIdx < pagesCount; pageIdx++) {
            documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading page ' + (pageIdx + 1) + '/' + pagesCount };
            await delay(0);
            const svg = verovioStore.renderToSVG(pageIdx);
            svgs.push(svg);
            const div: HTMLElement = document.createElement('div');
            // div.innerHTML = svg;
            this.addSvg(div, svg, 630, 891, pageIdx, optimization);
            //this.addTempoCorrectionFlag(div, useTempoCorrection);
            div.style.height = div.style.width = '100%';
            documentStore.replaceDocumentContentPage(pageIdx, div);
            RenderXml.addArrangementInfo(pageIdx, div, arrangementData);

            // console.log(svg);
        }

        documentStore.documentStatusSignal.value = { status: 'documentSuccess', documentType: { type: PlaymodeType_2_3.Xml, timemapData, svgs } };
        await delay(0);
        const syncData: VerovioSyncData = {
            svgObjectsMap: parseSvgPages(svgs),
            timemapData: timemapData,
        }
        // console.log('XML syncdata ', syncData);
        gridStore.verovioDataSignal.value = syncData;
        this.afterXmlRender();
    }

    public static async renderLandscape(xmlUrl: string, documentStore: DocumentStore, verovioStore: VerovioStore, gridStore: GridVerovioStore, displayStore: DisplayStore, xmlPlaymodeData: PlayModeTypeData, optimization: Array<PlayerOptimizationType>) {
        this.beforeXmlRender();
        documentStore.documentContentsSignal.value = [];
        documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading landscape view' };

        const useTempoCorrection = RenderXml.checkUseTempoCorrection(xmlPlaymodeData);


        await delay(0);

        const response = await window.fetch(xmlUrl);
        let musicXml: string = await response.text();
        musicXml = this.fixMusicXml(musicXml);

        const pagesCount: number = verovioStore.loadData(musicXml, VEROVIO_OPTIONS_LANDSCAPE);
        const timemapData: VerovioTimemapData = RenderXml.correctTimeMapFloatingPointErrors(verovioStore.renderToTimeMap());
        this.fixTempoCompensation(timemapData);
        await delay(0);
        const svgs: Array<string> = [];

        const svg = verovioStore.renderToSVG(0) as string;
        svgs.push(svg);
        // console.log(svg);

        const regexWidth = /svg[^>]*width="(\d+\.?\d+)px"/
        const width = parseInt((svg.match(regexWidth) as RegExpExecArray)[1]) as number;
        const regexHeight = /svg[^>]*height="(\d+\.?\d+)px"/
        const height = parseInt((svg.match(regexHeight) as RegExpMatchArray)[1]) as number;
        displayStore.displayLandscapeSize.value = { width, height: height + LANDSCAPE_MODE_PAGE_EXTRA_HEIGHT };

        const div: HTMLElement = document.createElement('div');
        this.addSvg(div, svg, width, height, 0, optimization);
        div.style.height = '100%';
        div.style.width = '100%';

        documentStore.documentContentsSignal.value = [documentStore.getBlankPage()];
        documentStore.replaceDocumentContentPage(0, div);
        documentStore.documentStatusSignal.value = { status: 'documentSuccess', documentType: { type: PlaymodeType_2_3.Xml, timemapData, svgs } };

        await delay(0);

        const syncData: VerovioSyncData = {
            svgObjectsMap: parseSvgPages(svgs),
            timemapData: timemapData,
        }
        gridStore.verovioDataSignal.value = syncData;
        this.afterXmlRender();
    }

    static removePrintObjectNo(musicXml: string): string {
        musicXml = musicXml.replace(/print-object="([a-z]+)"/g, '');
        // musicXml = musicXml.replace('print-object="yes"', '');
        return musicXml;
    }

    static addArrangementInfo = (pageIdx: number, div: HTMLElement, arrangementData: ArrangementData_2_3 | null) => {
        if (!arrangementData) return;

        const headerDiv = document.createElement('div');
        headerDiv.style.position = 'absolute';
        headerDiv.style.left = '0';
        headerDiv.style.top = '0';
        headerDiv.style.width = '100%';
        headerDiv.style.height = '10%';
        // headerDiv.style.border = '5px solid lime';
        div.appendChild(headerDiv);

        const headerSvg = document.createElementNS(XMLNS, "svg");
        headerSvg.setAttributeNS(null, "viewBox", "0 0 " + 1000 + " 100");
        headerSvg.setAttributeNS(null, "width", '100%');
        headerSvg.setAttributeNS(null, "height", '100%');
        headerSvg.style.position = 'absolute';
        headerSvg.style.left = headerSvg.style.top = '0';
        headerDiv.appendChild(headerSvg);

        switch (pageIdx) {
            case 0: {
                const title = arrangementData.title;
                const subtitle = arrangementData.subtitle;
                const interestedParties = arrangementData.interestedParties;
                RenderXml.addArrangementInfoFirstPage(headerSvg, title, subtitle, interestedParties);
                break;
            }
            default: {
                const title = arrangementData.title;
                RenderXml.addArrangementInfoFollowingPages(pageIdx, headerSvg, title);
                break;
            }
        }
    }

    static addArrangementInfoFirstPage = (headerSvg: SVGSVGElement, title: string, subtitle: string, interestedParties: Array<InterestedParty>) => {
        const marginTop = 50; //subtitle.length > 0 ? 66 : 50;
        const marginLeftX = 70;

        //------------------------------
        const leftText = document.createElementNS(XMLNS, "text");
        leftText.setAttributeNS(null, "y", String(-6 + marginTop));
        leftText.setAttributeNS(null, "x", String(marginLeftX));
        leftText.setAttributeNS(null, "font-size", '30');
        leftText.setAttributeNS(null, "font-weight", "bold");
        leftText.setAttributeNS(null, "letter-spacing", '-1');
        leftText.innerHTML = title;
        headerSvg.appendChild(leftText);

        const subtitleText = document.createElementNS(XMLNS, "text");
        subtitleText.style.fontStyle = 'italic';
        subtitleText.setAttributeNS(null, "y", String(26 + marginTop));
        subtitleText.setAttributeNS(null, "x", String(marginLeftX));
        subtitleText.setAttributeNS(null, "font-size", '16');
        subtitleText.setAttributeNS(null, 'fill', '#888');
        subtitleText.innerHTML = subtitle;
        headerSvg.appendChild(subtitleText);

        const ips = interestedParties;
        const types = (ips != null) ? ips?.map(o => o.type).filter((v, i, a) => a.indexOf(v) === i).filter(t => ((t != 'Artist') && (t != 'Other'))) : [];
        const typeOriginators = types.map(typ => typ + ': ' + ips.filter(o => o.type == typ).map(o => o.name).join(', '));
        const replaceType = (s: string) => {
            return s.replace('Composer', 'Musik').replace('Arranger', 'Arr').replace('Lyricist', 'Text').replace('Translator', 'Övers.');
        }
        typeOriginators.forEach((to: string, idx: number) => {
            const rightText = document.createElementNS(XMLNS, "text");
            rightText.style.fontFamily = 'var(--font-family)';
            rightText.setAttributeNS(null, "font-size", '13');
            rightText.setAttributeNS(null, "x", '960');
            rightText.setAttributeNS(null, "y", String(-3 + marginTop - (idx * 15)));
            rightText.innerHTML = replaceType(to);
            rightText.setAttributeNS(null, "text-anchor", "end");
            headerSvg.appendChild(rightText);
        });

        const line = document.createElementNS(XMLNS, 'line');
        line.setAttributeNS(null, "x1", String(marginLeftX));
        line.setAttributeNS(null, "x2", '960');
        line.setAttributeNS(null, "stroke", '#ccc');
        line.setAttributeNS(null, "strokeWidth", '1.5');
        line.setAttributeNS(null, "y1", String(6 + marginTop));
        line.setAttributeNS(null, "y2", String(6 + marginTop));
        headerSvg.appendChild(line);
    }

    static addArrangementInfoFollowingPages = (pageIdx: number, headerSvg: SVGSVGElement, title: string) => {
        const marginTop = 30;
        const marginLeftX = 70;

        const leftText = document.createElementNS(XMLNS, "text");
        leftText.setAttributeNS(null, "y", String(marginTop));
        leftText.setAttributeNS(null, "x", String(marginLeftX));
        leftText.setAttributeNS(null, "font-size", '15');
        // leftText.setAttributeNS(null, "font-weight", "bold");
        // leftText.setAttributeNS(null, "text-anchor", "end");
        leftText.innerHTML = title;
        headerSvg.appendChild(leftText);

        const rightText = document.createElementNS(XMLNS, "text");
        rightText.setAttributeNS(null, "y", String(marginTop));
        rightText.setAttributeNS(null, "x", '960');
        rightText.setAttributeNS(null, "font-size", '20');
        rightText.setAttributeNS(null, "text-anchor", "end");
        rightText.innerHTML = String(pageIdx + 1);
        headerSvg.appendChild(rightText);

        const line = document.createElementNS(XMLNS, 'line');
        line.setAttributeNS(null, "x1", String(marginLeftX));
        line.setAttributeNS(null, "x2", '960');
        line.setAttributeNS(null, "stroke", '#ccc');
        line.setAttributeNS(null, "strokeWidth", '1.5');
        line.setAttributeNS(null, "y1", String(10 + marginTop));
        line.setAttributeNS(null, "y2", String(10 + marginTop));
        headerSvg.appendChild(line);
    }

    static correctTimeMapFloatingPointErrors = (items: VerovioTimemapData): VerovioTimemapData => {
        let wrongIndexes: Array<number> = [];
        items.forEach((item, itemIdx) => {
            if (itemIdx == items.length - 1) return;
            const nextItem = items[itemIdx + 1];
            if (isFloat(item.tstamp)) {
                // console.log('float', itemIdx, item.tstamp, nextItem.tstamp);
                if (Math.round(item.tstamp) == nextItem.tstamp) {
                    // console.log('AHA', itemIdx, Math.round(item.tstamp), nextItem.tstamp);
                    wrongIndexes.push(itemIdx);
                    if (item.measureOn) nextItem.measureOn = item.measureOn;
                    if (item.on && item.on.length > 0) {
                        if (nextItem.on) {
                            nextItem.on.concat(item.on)
                        } else {
                            nextItem.on = item.on
                        }
                    }
                    if (item.off && item.off.length > 0) {
                        if (nextItem.off) {
                            nextItem.off.concat(item.off)
                        } else {
                            nextItem.off = item.off
                        }
                    }
                    if (item.restsOn && item.restsOn.length > 0) {
                        if (nextItem.restsOn) {
                            nextItem.restsOn.concat(item.restsOn)
                        } else {
                            nextItem.restsOn = item.restsOn
                        }
                    }
                    if (item.restsOff && item.restsOff.length > 0) {
                        if (nextItem.restsOff) {
                            nextItem.restsOff.concat(item.restsOff)
                        } else {
                            nextItem.restsOff = item.restsOff
                        }
                    }
                } else {
                    item.tstamp = Math.round(item.tstamp);
                    // console.log('CORRECT', itemIdx, item.tstamp);
                }
            }
        });

        // console.log('wrongIndexes', wrongIndexes);
        wrongIndexes = wrongIndexes.reverse();
        wrongIndexes.forEach(wrongIndex => items.splice(wrongIndex, 1));

        wrongIndexes = [];

        items.forEach((item, itemIdx) => {
            if (itemIdx > 0) {
                const prevItem = items[itemIdx - 1];
                if (item.tstamp == prevItem.tstamp) {
                    wrongIndexes.push(itemIdx - 1);


                    if (prevItem.measureOn) item.measureOn = prevItem.measureOn;
                    if (prevItem.measureOff) item.measureOff = prevItem.measureOff;

                    if (prevItem.on && prevItem.on.length > 0) {
                        if (item.on) {
                            item.on.concat(prevItem.on)
                        } else {
                            item.on = prevItem.on
                        }
                    }
                    if (prevItem.off && prevItem.off.length > 0) {
                        if (item.off) {
                            item.off.concat(prevItem.off)
                        } else {
                            item.off = prevItem.off
                        }
                    }
                    if (prevItem.restsOn && prevItem.restsOn.length > 0) {
                        if (item.restsOn) {
                            item.restsOn.concat(prevItem.restsOn)
                        } else {
                            item.restsOn = prevItem.restsOn
                        }
                    }
                    if (prevItem.restsOff && prevItem.restsOff.length > 0) {
                        if (item.restsOff) {
                            item.restsOff.concat(prevItem.restsOff)
                        } else {
                            item.restsOff = prevItem.restsOff
                        }
                    }

                }
            }
        });

        wrongIndexes = wrongIndexes.reverse();
        wrongIndexes.forEach(wrongIndex => items.splice(wrongIndex, 1));

        return items;
    }

    private static addSvg(div: HTMLElement, svg: string, width: number, height: number, pageIdx: number, optimization: Array<PlayerOptimizationType>)/*: Promise<boolean>*/ {
        const t0 = performance.now();
        const showInfo = optimization.includes(PlayerOptimizationType.DisplayOptimizationInfo);

        function addDebugDiv(toDiv: Element, color: string, msg: string, perfT0: number) {
            const t1 = performance.now();
            if (!showInfo) return;
            const debugDiv = document.createElement('div');
            debugDiv.style.position = 'absolute';
            debugDiv.style.left = '5px';
            debugDiv.style.top = '5px';
            debugDiv.style.color = color;
            debugDiv.style.fontWeight = 'bold';
            debugDiv.innerText = msg + ' (' + String(t1 - perfT0) + 'ms)';
            toDiv.appendChild(debugDiv);
        }

        function useSvgToCanvas(div: HTMLElement, svg: string, width: number, height: number, pageIdx: number, perfT0: number) {
            const scaling = 1.5;

            const canvas = document.createElement('canvas');
            svg = svg.replace(`width="630px" height="891px"`, `width="${630 * 2}px" height="${891 * 2}px"`);
            canvas.width = width * scaling;
            canvas.height = height * scaling;
            canvas.style.position = 'absolute';
            canvas.style.left = canvas.style.top = '0';
            canvas.style.width = canvas.style.height = '100%';
            const ctx = canvas.getContext('2d');
            const imageCanvas = new Image();
            imageCanvas.onload = function () {
                // console.log('imageCanvas.onload');
                ctx!.drawImage(imageCanvas, 0, 0, width * scaling, height * scaling);
                addDebugDiv(div, 'orange', `SvgToCanvas page ${pageIdx} success`, perfT0);
            }
            imageCanvas.onerror = e => {
                // addDebugDiv(div, 'red', `SvgToCanvas page ${pageIdx} Error: ` + JSON.stringify(e), perfT0);
                div.innerHTML = svg;
                addDebugDiv(div, 'gray', `SvgToDom page ${pageIdx} (no optimization)`, t0);
            }
            imageCanvas.src = "data:image/svg+xml," + encodeURIComponent(svg)
            div.appendChild(canvas);
            const t1 = performance.now();
            // console.log('OPTIMIZATION SvgToCanvas', 'addSvg page', pageIdx, t1 - t0 + 'ms');
        }

        function useSvgToImage(div: HTMLElement, svg: string, width: number, height: number, pageIdx: number, perfT0: number) {

            svg = svg.replaceAll(/\u00a0/g, " "); // avoid u+00a0 wich causes problems
            div.innerHTML = svg;
            const svgElement = div.firstElementChild! as SVGElement;
            const outerHtml = svgElement.outerHTML;
            const blob = new Blob([outerHtml], { type: 'image/svg+xml;charset=utf-8' });
            const url = window.URL;
            const blobUrl = URL.createObjectURL(blob);
            const image = new Image();
            image.onload = () => {
                div.removeChild(svgElement);
                image.style.width = '100%';
                image.style.height = '100%';
                div.appendChild(image);
                const t1 = performance.now();
                // console.log('OPTIMIZATION SvgToImage', 'addSvg page', pageIdx, t1 - t0 + 'ms');
                addDebugDiv(div, 'dodgerblue', `SvgToImage page ${pageIdx} success`, perfT0);
            }
            image.onerror = e => {
                // console.log(svg.substring(0, 200));
                useSvgToCanvas(div, svg, width, height, pageIdx, perfT0);
            }
            image.src = blobUrl;
        }

        if (optimization.includes(PlayerOptimizationType.SvgToCanvas)) {
            useSvgToCanvas(div, svg, width, height, pageIdx, t0);
        } else if (optimization.includes(PlayerOptimizationType.SvgToImage)) {
            useSvgToImage(div, svg, width, height, pageIdx, t0);
        } else {
            div.innerHTML = svg;
            addDebugDiv(div, 'gray', `SvgToDom page ${pageIdx} (no optimization)`, t0);
        }
    }

    // private static addTempoCorrectionFlag(div: Element, useTempoCorrection: boolean) {
    //     if (useTempoCorrection) {
    //         const tempomark = document.createElement('div');
    //         tempomark.style.position = 'absolute';
    //         tempomark.style.right = '5px';
    //         tempomark.style.top = '5px';
    //         tempomark.innerText = 'tempoCopmensation: true';
    //         tempomark.style.color = 'dodgerblue';
    //         div.appendChild(tempomark);
    //     } else {
    //     }
    // }

}


const XMLNS = "http://www.w3.org/2000/svg";

const VEROVIO_OPTIONS_PAGES: VerovioOptions = {
    scale: 30,
    justifyVertically: true,
    spacingSystem: 24,
    footer: 'none',
    header: 'none',
    breaks: 'auto',
    pageMarginTop: 250,
    pageMarginLeft: 200,
    pageMarginRight: 150,
    pageMarginBottom: 120,
    condense: 'auto',
    condenseNotLastSystem: true,
}

const VEROVIO_OPTIONS_LANDSCAPE: VerovioOptions = {
    scale: 40,
    justifyVertically: true,
    spacingSystem: 24,
    footer: 'none',
    header: 'none',
    breaks: 'none',
    pageMarginTop: 0,
    pageMarginLeft: 20,
    pageMarginRight: 20,
    pageMarginBottom: 0,
    condense: 'none',
}

