import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { delete_, get, post } from '../api/Api';
import { MetadataItem } from '../api/ManagementApi';
import { filterArray, removeFromArray } from './metadataTree';

export type ExistingMetadata = {
  items: Array<MetadataItem>;
  mandatory: Array<MetadataItem>;
  missing: Array<MetadataItem>;
  loading: boolean;
  hasInstrumentation: boolean;
  instrumentationTree: Array<MetadataItem>;
};

export const MANDATORY: Array<string> = ['Noter', 'Ljud', 'Vokal'];

export const existingMetadata = createModel<RootModel>()({
  state: {
    items: [],
    mandatory: [],
    missing: [],
    instrumentationTree: [],
    loading: false,
    hasInstrumentation: false,
  } as ExistingMetadata,
  reducers: {
    setState(state, newState: ExistingMetadata) {
      return newState;
    },
  },

  effects: (dispatch) => ({
    async load(payload, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (rootState.metadataTree.type !== 'loaded') return;
      const arrangementId = rootState.arrangement.id;
      const url = `/arrangements/${arrangementId}/metadata`;
      const items = (await get(
        url,
        rootState.user.token,
        {}
      )) as Array<MetadataItem>;
      const treeItems = rootState.metadataTree.itemArray;

      items.forEach((item) => {
        const treeItem = treeItems.find((treeItem) => treeItem.id === item.id);
        if (treeItem) {
          item.labelPathSv = treeItem.labelPathSv;
          item.labelPathDe = treeItem.labelPathDe;
          item.labelPathEn = treeItem.labelPathEn;
        }
      });

      const instrumentationItem = items.find((item) =>
        item.labelPathSv.startsWith('/besättning')
      );
      const hasInstrumentation = instrumentationItem !== undefined;
      const mandatory = filterArray(treeItems, MANDATORY);
      const existingMandatory = filterArray(items, MANDATORY);
      const leftMandatory = removeFromArray(mandatory, existingMandatory);
      const instrumentationTree = rootState.metadataTree.tree[0].children;
      dispatch.existingMetadata.setState({
        items: items,
        missing: leftMandatory,
        mandatory: mandatory,
        loading: false,
        hasInstrumentation,
        instrumentationTree,
      });
      console.log('arrangement metadata loaded', items);
    },

    async addMetadata(data: { id: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      const url = `/arrangements/${arrangementId}/metadata`;
      const payload = { metadataId: data.id };
      await post(url, rootState.user.token, payload);
      await dispatch.existingMetadata.load({});
    },

    async removeMetadata(data: { id: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      const url = `/arrangements/${arrangementId}/metadata/${data.id}`;
      await delete_(url, rootState.user.token, {});
      await dispatch.existingMetadata.load({});
    },
  }),
});
