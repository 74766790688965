import Signal from '../data/Signal';
import type { toolkit as VerovioToolkit, VerovioOptions, TimeMapEntry } from 'verovio';
import { WAVExtendedTimeMapEntry } from '../playerTypes';

export type { VerovioOptions };

export type VerovioState = {
    state: 'verovioNotLoaded',
} | {
    state: 'verovioLoaded',
}

export default class VerovioStore {
    static readonly toolkitSignal = new Signal<VerovioState>({ state: 'verovioNotLoaded' });
    static toolkit: VerovioToolkit | null = null;
    static isInitilizing = false;

    constructor(private onError: (error?: unknown) => void) {
        this.initialize();
    }

    loadData(musicXml: string, options: VerovioOptions): number {
        const tk = this.getToolkit();
        tk.setOptions(options);
        tk.loadData(musicXml);
        return tk.getPageCount();
    }

    renderToSVG(page: number): string {
        return this.getToolkit().renderToSVG(page + 1);
    }

    renderToTimeMap(): Array<WAVExtendedTimeMapEntry> {
        // Using WAV Extended has customized information available in TimeMapEntry
        return this.getToolkit().renderToTimemap().map(i => i as unknown as WAVExtendedTimeMapEntry);
    }

    public getToolkit(): VerovioToolkit {
        if (VerovioStore.toolkit === null) {
            this.onError('Verovio toolkit === null');
        }
        return VerovioStore.toolkit as VerovioToolkit;
    }

    private initialize() {
        if (VerovioStore.toolkit) {
            console.warn('Verovio already initialized!');
            return;
        }
        if (VerovioStore.isInitilizing) {
            console.warn('Verovio already initializing!');
            return;
        }
        VerovioStore.isInitilizing = true;
        // console.debug('Importing and initializing verovio...');
        import('verovio')
            .then((verovio) => {
                console.debug('Verovio imported!');
                verovio.module.onRuntimeInitialized = () => {
                    // console.debug('Verovio initialized!');
                    const vrvToolkit = new verovio.toolkit();
                    // console.info('Verovio toolkit initialized!', vrvToolkit.getVersion());
                    VerovioStore.toolkit = vrvToolkit;
                    VerovioStore.toolkitSignal.value = {
                        state: 'verovioLoaded',
                    }
                }
            })
            .catch((e) => {
                console.error('Failed to import verovio', e);
                this.onError(e)
            });
    }
}
