import { ArrangementStatus } from './../store/ArrangementStore';
import { PlaybackPosition, PlaybackStatus } from '../audio/engine/EngineTypes';
import { PlayModeDetailsStatus } from './../store/PlayModeStore';
import { v4 as uuid } from "uuid";
import { Recording } from '../wav/WavPublicApi';
import { PlayerOptions } from '../playerTypes';
import { post } from '../wav/Api';

const INACTIVITY_LIMIT_IN_MS = 120_000;

export default class Statistics {

    private sessionId: string;
    private arrangementId: string | null;
    private audioStartTime: number | null;
    private visualStartTime: number | null;
    private currentPlayModeType: string | null;
    private currentTime: number | null;
    private recordings: Recording[];
    private isInactive: boolean;
    private inactivityTimeoutId: number | undefined = undefined;

    constructor(private options: PlayerOptions, private onError: (error?: unknown) => void) {
        this.sessionId = uuid();
        this.audioStartTime = null;
        this.visualStartTime = null;
        this.currentPlayModeType = null;
        this.arrangementId = null;
        this.currentTime = null;
        this.recordings = [];
        this.isInactive = false;
    }

    setup() {
        window.addEventListener('beforeunload', this.onBeforeUnload);
        window.addEventListener('visibilitychange', this.onVisibilityChange);

        window.addEventListener('load', this.resetInactivityTime);
        window.addEventListener('click', this.resetInactivityTime);
        window.addEventListener('keypress', this.resetInactivityTime);
        window.addEventListener('mousedown', this.resetInactivityTime);
        window.addEventListener('touchstart', this.resetInactivityTime);
        window.addEventListener('mousemove', this.resetInactivityTime);
        window.addEventListener('mousedown', this.resetInactivityTime);
        window.addEventListener('scroll', this.resetInactivityTime);
    }

    teardown() {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
        window.removeEventListener('visibilitychange', this.onVisibilityChange);

        window.removeEventListener('load', this.resetInactivityTime);
        window.removeEventListener('click', this.resetInactivityTime);
        window.removeEventListener('keypress', this.resetInactivityTime);
        window.removeEventListener('mousedown', this.resetInactivityTime);
        window.removeEventListener('touchstart', this.resetInactivityTime);
        window.removeEventListener('mousemove', this.resetInactivityTime);
        window.removeEventListener('mousedown', this.resetInactivityTime);
        window.removeEventListener('scroll', this.resetInactivityTime);

        if (this.audioStartTime !== null && this.currentTime) {
            this.recordStatistics(Math.round(this.audioStartTime * 1000), Math.round(this.currentTime * 1000), false, 'Recording audio stats on teardown.');
        }

        if (this.visualStartTime !== null) {
            this.recordStatistics(0, Date.now() - this.visualStartTime, true, 'Recording visual stats on teardown.');
        }

    }

    handleArrangementStatus(value: ArrangementStatus) {
        if (value.status === 'arrangementLoading') {
            this.arrangementId = value.arrangementId;
        }
    }

    handlePlaybackChange(value: PlaybackStatus) {
        switch (value.status) {
            case 'enginePlaying':
                this.audioStartTime = value.time;
                break;
            case 'enginePaused':
                if (this.audioStartTime !== null) {
                    this.recordStatistics(Math.round(this.audioStartTime * 1000), Math.round(value.time * 1000), false);
                }
                break;

            default:
                break;
        }
    }

    handlePlaybackPositionChange(value: PlaybackPosition) {
        this.currentTime = value.time;
    }

    handlePlayModeChange(value: PlayModeDetailsStatus) {
        if (value.status === 'selectedPlayModeSuccess') {
            if (this.visualStartTime !== null) {
                this.recordStatistics(0, Date.now() - this.visualStartTime, true);
            }

            if (this.visualStartTime === null && isVisualPlayMode(value.typeData.type)) {
                this.visualStartTime = Date.now();
            }

            this.currentPlayModeType = value.typeData.type;
        }
    }

    handleRecordingsChange(value: Recording[]) {
        this.recordings = value;
    }

    private resetInactivityTime = () => {
        if (this.isInactive) {
            this.visualStartTime = Date.now();
        }
        this.isInactive = false;
        window.clearTimeout(this.inactivityTimeoutId);
        this.inactivityTimeoutId = window.setTimeout(this.onInactivity, INACTIVITY_LIMIT_IN_MS);
    };

    private onInactivity = () => {
        if (!this.isInactive && isVisualPlayMode(this.currentPlayModeType)) {
            this.isInactive = true;
            if (this.visualStartTime !== null) {
                this.recordStatistics(0, Date.now() - this.visualStartTime, true, 'User was inactive for too long.');
            }
        }
    };

    private onVisibilityChange = () => {
        if (document.hidden) {
            this.isInactive = true;
            if (this.visualStartTime !== null) {
                this.recordStatistics(0, Date.now() - this.visualStartTime, true, 'Page was hidden from user view.');
            }
        } else {
            this.isInactive = false;
            if (isVisualPlayMode(this.currentPlayModeType)) {
                this.visualStartTime = Date.now();
            }
        }
    };

    private onBeforeUnload = () => {
        if (this.audioStartTime !== null && this.currentTime) {
            this.recordStatistics(Math.round(this.audioStartTime * 1000), Math.round(this.currentTime * 1000), false, 'Recording audio stats on before unload.');
        }
    };

    private recordStatistics(startTimeInMs: number, endTimeInMs: number, visual: boolean, message?: string) {
        if (visual) {
            this.visualStartTime = null;
        } else {
            this.audioStartTime = null;
        }

        const log = visual ? '[STATS VISUAL]:' : '[STATS AUDIO]:';
        console.log(log, message ?? '', 'Start', startTimeInMs, 'End', endTimeInMs);

        const timeStampUtc = new Date().toISOString();

        const recordings = visual ? null : this.recordings.map(recording => ({
            audioLevel: Math.round(recording.audioLevel * 100) / 100,
            type: recording.channelType,
            id: recording.id
        }));

        const data = {
            sessionId: this.sessionId,
            arrangementId: this.arrangementId,
            previousArrangementId: null,
            timeStampUtc,
            startTime: startTimeInMs,
            endTime: endTimeInMs,
            recordings,
            playMode: this.currentPlayModeType // TODO: post object with id and type
        };

        post(`${this.options.apiUrl}/statistics`, this.options.apiToken, data, this.options.apiVersion, true)
            .catch(this.onError);
    }

}

function isVisualPlayMode(playMode: string | null) {
    if (!playMode) {
        return false;
    }
    return ['Xml', 'Pdf', 'ScorX'].includes(playMode);
}
