import { InterestedParty, InterestedPartyItem } from '../api/ManagementApi';
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { delete_, get, post } from '../api/Api';

export type ExistingInterestedParties = {
  parties: Array<InterestedParty>;
  loading: boolean;
};

export const existingInterestedParties = createModel<RootModel>()({
  state: { parties: [], loading: false } as ExistingInterestedParties,
  reducers: {
    setState(state, newState: ExistingInterestedParties) {
      return newState;
    },
  },

  effects: (dispatch) => ({
    async loadInterestedParties(payload, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      dispatch.existingInterestedParties.setState({
        parties: rootState.existingInterestedParties.parties,
        loading: true,
      });
      const url = `/arrangements/${arrangementId}/interestedParties`;
      const parties = (await get(
        url,
        rootState.user.token,
        {}
      )) as Array<InterestedParty>;
      dispatch.existingInterestedParties.setState({
        parties,
        loading: false,
      });
    },

    async addInterestedParty(
      data: { interestedPartyId: string; type: string },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      dispatch.existingInterestedParties.setState({
        parties: rootState.existingInterestedParties.parties,
        loading: true,
      });
      const url = `/arrangements/${arrangementId}/interestedParties`;
      const postData = {
        interestedPartyId: data.interestedPartyId,
        type: data.type,
      };
      await post(url, rootState.user.token, postData);
      await dispatch.existingInterestedParties.loadInterestedParties({});
    },

    async removeInterestedParty(data: { id: string; type: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      const url = `/arrangements/${arrangementId}/interestedParties/${data.id}`;
      const payload = { type: data.type };
      dispatch.existingInterestedParties.setState({
        parties: rootState.existingInterestedParties.parties,
        loading: true,
      });
      await delete_(url, rootState.user.token, payload);
      await dispatch.existingInterestedParties.loadInterestedParties({});
    },

    async addNewInterestedPartyAs(
      data: { name: string; description: string; type: string },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const interestedParty = (await post(
        '/interestedParties',
        rootState.user.token,
        data
      )) as InterestedPartyItem;
      await dispatch.existingInterestedParties.addInterestedParty({
        interestedPartyId: interestedParty.id,
        type: data.type,
      });
      await dispatch.existingInterestedParties.loadInterestedParties({});
    },

    async setAsFirstVisible(
      payload: { id: string; type: string; sortOrder?: number },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      const url = `/arrangements/${arrangementId}/interestedParties/${payload.id}/move`;
      dispatch.existingInterestedParties.setState({
        parties: rootState.existingInterestedParties.parties,
        loading: true,
      });
      await post(url, rootState.user.token, {
        ...payload,
        sortOrder: payload.sortOrder ?? 0,
      });
      await dispatch.existingInterestedParties.loadInterestedParties({});
    },
  }),
});
