import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

import Select from 'react-select';
import { get } from '../../../api/Api';
import Required from '../../../components/Required';
import ISWCAndICE from '../../../components/ISWCAndICE';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import { isSuperUserOrganization } from '../../../models/superusers';

type Option = { value: string; label: string };
const ArrangementForm = () => {
  const arrState = useSelector((state: RootState) => state.arrangement);
  const dispatch = useDispatch<Dispatch>();
  const userState = useSelector((state: RootState) => state.user);
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [distributingPublisher, setDistributingPublisher] =
    useState<Option | null>(null);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [contentProvider1, setContentProvider1] = useState<Option | null>(null);
  const [contentProvider2, setContentProvider2] = useState<Option | null>(null);
  const [publicDomain, setPublicDomain] = useState(false);
  const [publisherUrl, setPublisherUrl] = useState('');
  const [externalId, setExternalId] = useState('');
  const [iswc, setIswc] = useState('');
  const [ice, setIce] = useState('');

  const handleSearchOptions = useCallback(
    (value: string) => {
      if (userState?.token) {
        get(`/organizations/ou?search=${value}`, userState.token, null).then(
          (res: { items: { id: string; name: string }[] }) => {
            const options = res.items.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            const alreadySelectedOptions = [
              contentProvider1,
              contentProvider2,
              distributingPublisher,
            ].filter((option) => option !== null) as Option[];

            setOrganizationOptions(options.concat(alreadySelectedOptions));
          }
        );
      }
    },
    [userState, contentProvider1, contentProvider2, distributingPublisher]
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (userState) {
        const payload = {
          title: title?.trim(),
          subtitle: subtitle?.trim(),
          description: description?.trim(),
          contentProvider1Id: contentProvider1?.value,
          contentProvider2Id: contentProvider2?.value,
          publisherTempId: distributingPublisher?.value,
          externalId,
          publisherUrl,
          publicDomain,
          iswc: iswc?.trim(),
          ice: ice?.trim(),
        };
        if (arrState.type === 'arrangement') {
          dispatch.arrangement.updateArrangement({
            payload,
          });
        } else {
          dispatch.arrangement.createArrangement({
            payload,
          });
        }
      }
    },
    [
      arrState.type,
      contentProvider1,
      contentProvider2,
      description,
      dispatch.arrangement,
      distributingPublisher,
      externalId,
      ice,
      iswc,
      publicDomain,
      publisherUrl,
      subtitle,
      title,
      userState,
    ]
  );

  useEffect(() => {
    if (arrState.type === 'arrangement') {
      setOrganizationOptions([
        {
          value: arrState.data.contentProvider1?.id,
          label: arrState.data.contentProvider1?.name,
        },
        {
          value: arrState.data.contentProvider2?.id,
          label: arrState.data.contentProvider2?.name,
        },
        {
          value: arrState.data.publisherTemp?.id,
          label: arrState.data.publisherTemp?.name,
        },
      ]);

      setTitle(arrState.data.name);
      setSubtitle(arrState.data.subtitle);
      setDescription(arrState.data.description);
      setIswc(arrState.data.iswc);
      setIce(arrState.data.ice);
      setContentProvider1({
        value: arrState.data.contentProvider1?.id,
        label: arrState.data.contentProvider1?.name,
      });
      setContentProvider2({
        value: arrState.data.contentProvider2?.id,
        label: arrState.data.contentProvider2?.name,
      });
      setDistributingPublisher({
        value: arrState.data.publisherTemp?.id,
        label: arrState.data.publisherTemp?.name,
      });
      setPublicDomain(arrState.data.publicDomain);
      setPublisherUrl(arrState.data.publisherUrl);
      setExternalId(arrState.data.externalId);
    } else if (arrState.type === 'form') {
      const shouldSetDefaultProvider =
        userOrganization && !isSuperUserOrganization(userOrganization);

      setOrganizationOptions(
        shouldSetDefaultProvider
          ? [{ value: userOrganization.id, label: userOrganization.name }]
          : []
      );

      setTitle('');
      setSubtitle('');
      setDescription('');
      setIswc('');
      setIce('');
      setContentProvider1(
        shouldSetDefaultProvider
          ? { value: userOrganization.id, label: userOrganization.name }
          : null
      );
      setContentProvider2(null);
      setDistributingPublisher(null);
      setPublicDomain(false);
      setPublisherUrl('');
      setExternalId('');
    }
  }, [arrState]);

  switch (arrState.type) {
    case 'loading':
      return (
        <div className="FlexCenter">
          <div className="FlexColumn">
            <h3>Loading arrangement...</h3>
          </div>
        </div>
      );
    case 'arrangement':
    case 'form':
      return (
        <div className="FlexCenter Form">
          <form onSubmit={handleSubmit}>
            <label>
              <div style={{ display: 'flex', gap: '2px' }}>
                <Label>Title</Label>
                <Required />
              </div>
              <input
                pattern="[\w\W0-9.-]{4,}"
                onInvalid={() => console.log('invalid')}
                type="text"
                name="Title"
                placeholder="Title"
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>

            <label>
              <Label>Subtitle</Label>
              <input
                type="text"
                name="Subtitle"
                placeholder="Subtitle"
                value={subtitle || ''}
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </label>

            <label>
              <Label>Description</Label>
              <input
                type="text"
                name="Description"
                placeholder="Description"
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>

            <label>
              <Label>Distributing Publisher</Label>
              <Select
                value={organizationOptions.find(
                  (option: Option) =>
                    option.value === distributingPublisher?.value
                )}
                options={organizationOptions}
                onInputChange={(val) => handleSearchOptions(val)}
                isClearable
                onChange={(option) => setDistributingPublisher(option)}
              />
            </label>

            <label>
              <Label>Content Provider 1</Label>
              <Select
                value={organizationOptions.find(
                  (option: Option) => option.value === contentProvider1?.value
                )}
                options={organizationOptions}
                isClearable
                onInputChange={(val) => handleSearchOptions(val)}
                onChange={(option) => setContentProvider1(option)}
              />
            </label>

            <label>
              <Label>Content Provider 2</Label>
              <Select
                value={organizationOptions.find(
                  (option: Option) => option.value === contentProvider2?.value
                )}
                options={organizationOptions}
                isClearable
                onInputChange={(val) => handleSearchOptions(val)}
                onChange={(option) => setContentProvider2(option)}
              />
            </label>

            <label>
              <Label>Public Domain</Label>
              <input
                type="checkbox"
                name="Public Domain"
                placeholder="Public Domain"
                checked={publicDomain}
                onChange={(e) => setPublicDomain(e.target.checked)}
                style={{ alignSelf: 'flex-start' }}
              />
            </label>

            <label>
              <Label>Link to Distributing Publishers sheet</Label>
              <input
                type="text"
                name="Publisher Url"
                placeholder="Publisher Url"
                value={publisherUrl}
                onChange={(e) => setPublisherUrl(e.target.value)}
              />
            </label>

            <label>
              <Label>Distributing Publishers sheet ID</Label>
              <input
                type="text"
                name="External ID"
                placeholder="External ID"
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
              />
            </label>

            <ISWCAndICE
              existing={arrState.type === 'arrangement'}
              ice={ice}
              setIce={setIce}
              iswc={iswc}
              setIswc={setIswc}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{ marginTop: '1rem' }} primary>
                {(arrState.type === 'form' && 'Create') ||
                  (arrState.type === 'arrangement' && 'Update')}
              </Button>
            </div>
          </form>
        </div>
      );
    case 'creating':
      return <h2>Creating arrangement...</h2>;
    default:
      return <></>;
  }
};

export default ArrangementForm;
