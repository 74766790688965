import { GridItem1Details, GridVeroivioItem1 } from "../playerTypes";
import { PlayModeDetailsStatus } from "../store/PlayModeStore";
import { round5 } from "../tools/NumberTools";
import { GridExportItem, TimeSyncData } from "../playerTypes";


export const createExportItems = (itemDetails: GridItem1Details[], timeSync: TimeSyncData): GridExportItem[] => {
    const timeSyncCopy = timeSync ? [...timeSync] : [];
    // console.log('timesync imput', JSON.stringify(timeSyncCopy));
    const expItems: Array<GridExportItem> = itemDetails.map((item, itemIdx) => {
        return {
            idx: itemIdx,
            pageIdx: item.item.pageIdx,
            systemIdx: item.item.systemIdx,
            rootIdx: item.item.idx,
            x: round5(item.item.x),
            y: round5(item.item.y),
            w: round5(item.item.w),
            h: round5(item.item.h),
            mediaStartTime: (item.leftPos),
            mediaEndTime: (item.rightPos),
            scoreStartTime: (item.leftTstampPos),
            scoreEndTime: (item.rightTstampPos),
        }
    });

    for (let itemIdx = 0; itemIdx < expItems.length - 1; itemIdx++) {
        const leftItem = expItems[itemIdx];
        const rightItem = expItems[itemIdx + 1];
        const firstTimeSync = timeSyncCopy[0];
        if (firstTimeSync && firstTimeSync.scorePos >= leftItem.scoreStartTime && firstTimeSync.scorePos < rightItem.scoreStartTime) {
            leftItem.mediaSyncTime = firstTimeSync.mediaPos;
            timeSyncCopy.shift();
        }
    }
    // expItems.forEach((item, idx) => {
    //     console.log(idx, item.mediaSyncTime);
    // })

    return expItems;
}

export const createLegacyGridItems = (items: GridExportItem[]): { recreatedRootItems: GridVeroivioItem1[], recreatedItemDetails: GridItem1Details[] } => {
    const recreatedRootItems: GridVeroivioItem1[] = [];
    items.map((expItem: GridExportItem, expItemIdx) => {
        const newRootItem: GridVeroivioItem1 = {
            id: 'ROOT_ITEM_' + expItem.rootIdx,
            x: expItem.x,
            y: expItem.y,
            w: expItem.w,
            h: expItem.h,
            idx: expItem.rootIdx,
            inDocument: false,
            itemEl: null,
            pageH: 0,
            pageW: 0,
            pageX: 0,
            pageY: 0,
            pageIdx: expItem.pageIdx,
            qstamp: 0,
            tstamp: expItem.scoreStartTime,
            systemIdx: expItem.systemIdx,
            tstampDuration: expItem.scoreEndTime - expItem.scoreStartTime,
        }
        recreatedRootItems[expItem.rootIdx] = newRootItem;
    });

    const recreatedItemDetails: GridItem1Details[] = [];

    items.forEach((expItem: GridExportItem, expItemIdx) => {
        const newItem: GridItem1Details = {
            leftPos: expItem.mediaStartTime,
            rightPos: expItem.mediaEndTime,
            itemId: 'ROOT_ITEM_' + expItem.rootIdx,
            item: recreatedRootItems[expItem.rootIdx],
            jumpPass: 0,
            jumpIdx: 0,
            leftTstampPos: expItem.scoreStartTime,
            originalLeftPos: 0,
            rightTstampPos: expItem.scoreEndTime,
        }
        recreatedItemDetails.push(newItem);
    });

    return { recreatedRootItems, recreatedItemDetails };
}

export const getSvgStatus = (playMode: PlayModeDetailsStatus, useSvgRendering: boolean, svgRenderingActive: boolean): SvgRenderingStatus => {
    if (playMode.status !== 'selectedPlayModeSuccess') return { svgRenderingActive: false, gridItemsVertical: 0, gridItemsHorizontal: 0, svgsHorizontal: 0, svgsVertical: 0, useSvgRendering: false, canUseSvgRendering: false };
    if (playMode.typeData.type !== 'Xml') return { svgRenderingActive: false, gridItemsVertical: 0, gridItemsHorizontal: 0, svgsHorizontal: 0, svgsVertical: 0, useSvgRendering: false, canUseSvgRendering: false };

    const canUseSvg = canUseSvgRendering(playMode);
    const nrOfSvgs = (canUseSvg) ? playMode.typeData.svgs!.length : 0;
    const status: SvgRenderingStatus = {
        svgRenderingActive,
        canUseSvgRendering: canUseSvg,
        svgsHorizontal: playMode.typeData.svgsHorizontal ? playMode.typeData.svgsHorizontal.length : 0,
        svgsVertical: playMode.typeData.svgsVertical ? playMode.typeData.svgsVertical.length : 0,
        gridItemsVertical: playMode.typeData.gridItemsVertical ? playMode.typeData.gridItemsVertical.length : 0,
        gridItemsHorizontal: playMode.typeData.gridItemsHorizontal ? playMode.typeData.gridItemsHorizontal.length : 0,
        useSvgRendering,
    };
    return status;
}

export const canUseSvgRendering = (playMode: PlayModeDetailsStatus): boolean => {
    if (playMode.status !== 'selectedPlayModeSuccess') return false;
    if (playMode.typeData.type !== 'Xml') return false;
    const hasSvgs = (playMode.typeData.svgs && playMode.typeData.svgs.length > 0) as boolean;
    const hasSyncs = (playMode.typeData.syncs && playMode.typeData.syncs.length > 0) as boolean;
    return (hasSvgs && hasSyncs);
}

export type SvgRenderingStatus = {
    svgRenderingActive: boolean,
    canUseSvgRendering: boolean,
    useSvgRendering: boolean,
    svgsVertical: number,
    svgsHorizontal: number,
    gridItemsVertical: number,
    gridItemsHorizontal: number,
}

