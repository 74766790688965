import React, { useEffect, useRef, useState } from 'react';
import PlayerComponent from '../../../player/PlayerComponent';
import { PlayerOptimizationType } from '../../../player/playerTypes';
import appsettings from '../../../appsettings.json';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import BtnPlay from '../../player/BtnPlay';
import { SvgScroll } from '../../player/BtnPlay';
import Player2Sync from './components/Player2Sync';
import MixerBar from '../../player/MixerBar';
import Timeline2 from '../../player/Timeline2';
import { round2 } from '../../../player/tools/NumberTools';
import PlaymodeSelector from '../../player/PlaymodeSelector';
import RenderXml from '../../../player/render/RenderXml';


import {
  deleteSvgsAndSyncData,
  uploadSvgFile,
  uploadSyncData,
} from '../../../player/grid/uploadSvgModeData';
import { SvgRenderingStatus } from '../../../player/grid/gridExportUtils';
import XmlVisualModeSelector from './components/XmlVisualModeSelector';
import {
  DisplayModeState,
  UIDisplayModes,
} from '../../../player/store/DisplayStore';
import { PlayModeDetailsStatus } from '../../../player/store/PlayModeStore';

import { createGridExportItemsFromVerovioData } from '../../../player/grid/gridUtils';
import { delay } from '../../../player/tools/TimerTools';
import {
  GridExportItem,
  VerovioRenderData,
  PageOrientation,
} from '../../../player/playerTypes';
import { createVeriovioRenderDataFromXmlUrl } from '../../../player/render/RenderXmlExportSVG';
import { SvgSyncDevelopmentInfo } from './components/SvgSyncDevelopmentInfo';

const apiUrl = appsettings.API_URL + '/catalog';
const apiVersion = appsettings.WAV_API_VERSION;
const devMode = true;

const PlayerSync = (props: {
  arrangementId: string;
  versionId: string;
  status: string;
}) => {
  const userState = useSelector((state: RootState) => state.user);
  const arrState = useSelector((state: RootState) => state.arrangement);
  const arrVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const savingState = useSelector((state: RootState) => state.saving);

  const dispatch = useDispatch<Dispatch>();
  const playerComponent = useRef<PlayerComponent>(null);

  const timeSyncEditor = useRef(null);

  //------------------------------------------------------
  // handle playModes
  const [playModesData, setPlayModesData] = useState([]);
  const [selectedPlayMode, setSelectedPlayMode] = useState();
  const onPlayModesData = (value: any) => {
    setPlayModesData(value.playModes);
    setSelectedPlayMode(value.selectedPlayMode);
  };

  // const playModeButtons =
  //     playModesData!.map(playMode => <button className={playMode.id == selectedPlayMode.id ? 'selected' : ''} key={playMode.id} onClick={e => playerComponent.current.setPlayModeById(playMode.id)}>{playMode.type}</button>)

  //------------------------------------------------------
  // handle recordingsLoading
  const [loadingStatus, setLoadingStatus] = useState(null);
  // const [loadingMessage, setLoadingMessage] = useState(null);
  const onRecordingsStatus = (value: any) => {
    setLoadingStatus(value);
  };

  //------------------------------------------------------
  //
  const [displayModes, setDisplayModes] = useState<UIDisplayModes>();
  const [currentDisplayMode, setCurrentDisplayMode] =
    useState<DisplayModeState | null>();
  const onAvailableDisplayModes = (displayModes: UIDisplayModes) => {
    setDisplayModes(displayModes);
    setCurrentDisplayMode(displayModes.current);
  };

  //------------------------------------------------------
  // handle playmode details including arrangement information (title, originators etc)
  const [playModeDetails, setPlayModeDetails] = useState<any>(null);
  const onPlayModeDetailsStatus = (value: any) => {
    setPlayModeDetails(value);
  };

  const useTempoCompensation = playModeDetails
    ? RenderXml.checkUseTempoCorrection(playModeDetails!.typeData)
    : false;

  //-----------------------------------------------------------------------------
  // navigation buttons for spread ("swipe") mode
  const [spreadStatus, setSpreadStatus] = useState(null);
  const onSpreadNavigationStatus = (status: any) => {
    setSpreadStatus(status);
  };

  //-----------------------------------------------------------------------------
  // document overlay displaying loading info
  const [documentStatus, setDocumentStatus] = useState(null);
  const onDocumentOverlayStatus = (status: any) => {
    setDocumentStatus(status);
  };

  //-----------------------------------------------------------------
  const [playbackStatus, setPlaybackStatus] = useState(null);
  const onPlaybackStatus = (status: any) => {
    // console.log('onPlaybackStatus', status);
    setPlaybackStatus(status);
  };
  //-----------------------------------------------------------------
  const [playbackPosition, setPlaybackPosition] = useState(null);
  const onPlaybackPosition = (info: any) => {
    setPlaybackPosition(info);
  };

  //-------------------------------------------------------------
  const [recordings, setRecordings] = useState(null);
  const onRecordingsChange = (recordings: any) => {
    setRecordings(recordings);
  };

  //-----------------------------------------------------------------
  const [zoomInfo, setZoomInfo] = useState(null);
  const onZoomChange = (zoomInfo: any) => {
    setZoomInfo(zoomInfo);
  };

  const [gridInfo, setGridInfo] = useState(null);
  const onGridInfo = (info: any) => {
    setGridInfo(info);
  };

  const [gridAction, setGridAction] = useState(null);
  const onGridAction = (action: any) => {
    setGridAction(action);
  };

  const [scrollAnimationData, setScrollAnimationData] = useState(null);
  const onScrollAnimationCreated = (data: any) => {
    setScrollAnimationData(data);
  };

  const [viewportScrollPos, setViewportScrollPos] = useState({
    top: 0,
    left: 0,
  });
  const onGraphicViewportScroll = (scroll: any) => {
    setViewportScrollPos(scroll);
  };

  const [scrollActive, setScrollActive] = useState(false);
  const onScrollActiveChange = (active: any) => {
    setScrollActive(active);
  };

  const [syncData, setSyncData] = useState<any>(null);
  const onSyncDataChange = (syncData: any) => {
    setSyncData(syncData);
  };

  const [svgStatus, setSvgStatus] = useState<SvgRenderingStatus>();
  const onSvgStatus = (status: SvgRenderingStatus) => {
    setSvgStatus(status);
  };

  const [svgIsSaving, setSvgIsSaving] = useState<boolean>(false);

  const saveSvgSyncData = async (
    verticalItems: GridExportItem[],
    horizontalItems: GridExportItem[],
    playModeId: string
  ): Promise<void> => {
    setSvgIsSaving(true);
    console.log('SAVE', verticalItems, horizontalItems);
    if (arrState.type !== 'arrangement') return;
    if (!arrVersion) return;
    if (!useState) return;

    const responseVerticalSync = await uploadSyncData(
      appsettings.API_URL,
      arrState.id,
      arrVersion.id,
      playModeId,
      verticalItems,
      'Vertical',
      userState.token!
    );
    if (!responseVerticalSync.ok) {
      alert('Can not save vertical svg data');
    }

    const responseHorizontalSync = await uploadSyncData(
      appsettings.API_URL,
      arrState.id,
      arrVersion.id,
      playModeId,
      horizontalItems,
      'Horizontal',
      userState.token!
    );
    console.log(responseHorizontalSync);

    if (!responseHorizontalSync.ok) {
      alert('Can not save horizontal svg data');
    }

    setSvgIsSaving(false);
  };

  const onSaveSync = async (
    data: GridExportItem[],
    orientation: PageOrientation
  ): Promise<void> => {
    console.log('onSaveSync', orientation, data);
    if (arrState.type !== 'arrangement') return;
    if (!userState) return;
    const playModeId = playerComponent.current!.getPlaymodeId();
    if (!playModeId) return;

    const arrangementId = arrState.id;
    const versionId = arrVersion!.id;
    const response = await uploadSyncData(
      appsettings.API_URL,
      arrangementId,
      versionId,
      playModeId!,
      data,
      orientation,
      userState.token!
    );

    if (response.ok) {
      console.log('Upload ok');
    } else {
      alert('Could not save sync data: ');
      const statusText = response.statusText;
      console.log(statusText);
    }
  };


  const onPlaymodeDataMonitor = async () => {
    if (arrState.type !== 'arrangement') return;
    if (!arrVersion) return;
    if (!selectedPlayMode) return;
    if (!userState) return;
    if (
      !syncData ||
      !selectedPlayMode ||
      !userState.token ||
      !arrState.id ||
      !arrVersion.id
    ) {
      return;
    }
    console.log('onPlaymodeDataMonitor');
    const url = `/arrangements/${arrState.id}/versions/${arrVersion.id}/playmodes/${(selectedPlayMode as any).id}`;
    const response = await fetch(appsettings.API_URL + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userState.token,
        'api-version': apiVersion,
      },
    })

    const rawData: PlayModeXmlRawData = await response.json();
    console.log('publicData');
    console.log(rawData.publicData);
    console.log('data');
    console.log(rawData.data);
  };
  // const [preventScrolling, setPreventScrolling] = useState(false);
  // const onPreventScrollingChange = async (prevent: boolean) => {

  //   setPreventScrolling(prevent);

  // }

  const handleLegacySaveTimeSyncAndBarJumps = async () => {
    if (arrState.type !== 'arrangement') return;
    if (!arrVersion) return;
    if (!selectedPlayMode) return;
    if (!userState) return;
    if (
      !syncData ||
      !selectedPlayMode ||
      !userState.token ||
      !arrState.id ||
      !arrVersion.id
    ) {
      return;
    }
    dispatch.saving.setState({ isSaving: true });

    // load current data to make sure that svgs and syncs are saved along timesync and barjumps
    const url = `/arrangements/${arrState.id}/versions/${arrVersion.id}/playmodes/${(selectedPlayMode as any).id}`;
    const response = await fetch(appsettings.API_URL + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userState.token,
        'api-version': apiVersion,
      },
    })
    const rawData: PlayModeXmlRawData = await response.json();

    const putBody = {
      enabled: true,
      data: {
        timeSync: syncData.timeSync,
        barJumps: syncData.barJumps,
        tempoCompensation: useTempoCompensation,
        svgs: rawData.data.svgs,
        syncs: rawData.data.syncs,
      },
      name: '',
      default: true,
    };

    const saveResponse = await fetch(appsettings.API_URL + url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userState.token,
        'api-version': apiVersion,
      },
      body: JSON.stringify(putBody),
    });
    console.log(saveResponse);
    dispatch.saving.setState({ isSaving: false });

  };

  //---------------------------------------------------------------
  // closing player
  useEffect(() => {
    if (!playerComponent) return;
    if (!playerComponent.current) return;
    const pc = playerComponent.current;
    return () => {
      pc.playbackStop();
      pc.teardown();
    };
  }, []);

  //---------------------------------------------------------------
  // load arrangement
  useEffect(() => {
    if (!playerComponent.current) return;

    // playerComponent.current.loadArrangement("54f879ac-feac-49bc-ab90-c9931988c268"); // Borgmästar Munthe
    // playerComponent.current.loadArrangement('c0cb2b1f-bb49-4230-989a-9e481e42ae1f');
    // playerComponent.current.setArrangementRawData('test', 'sub', '/dev-assets/Aftonkväde.xml', ['/dev-assets/200.mp3']);
    // console.log(props);

    if (props.status !== 'Published') {
      playerComponent.current.loadArrangement(props.versionId);
    } else {
      playerComponent.current.loadArrangement(props.arrangementId);
    }
  }, [props]);

  return (
    <div className="FlexGap0 Flex1 FlexColumn BGLightGray BD1Gray H100 Gap0">
      <div className="Flex FlexRow Flex1 H100 Gap0">
        {userState?.token && (
          <PlayerComponent
            ref={playerComponent}
            className="PlayerComponent"
            options={{
              apiUrl,
              apiToken: userState.token,
              apiVersion,
              devMode,
              preview: props.status !== 'Published',
              optimization: [
                PlayerOptimizationType.SvgToImage,
                // PlayerOptimizationType.SvgToCanvas,
                // PlayerOptimizationType.DisplayOptimizationInfo,
              ],
            }}
            onError={(e) => alert(`onError: ${e}`)}
            onPlayModeDetailsStatus={onPlayModeDetailsStatus}
            onPlayModesData={onPlayModesData}
            onRecordingsStatus={onRecordingsStatus}
            onAvailableDisplayModes={onAvailableDisplayModes}
            onSpreadNavigationStatus={onSpreadNavigationStatus}
            onDocumentOverlayStatus={onDocumentOverlayStatus}
            onPlaybackStatus={(status) => {
              onPlaybackStatus(status);
            }}
            onPlaybackPosition={(position) => {
              onPlaybackPosition(position);
            }}
            onZoomChange={onZoomChange}
            onRecordingsChange={onRecordingsChange}
            onGridInfo={onGridInfo}
            onGridAction={(action) => {
              onGridAction(action);
            }}
            onScrollAnimationCreated={onScrollAnimationCreated}
            onGraphicViewportScroll={onGraphicViewportScroll}
            onScrollActiveChange={onScrollActiveChange}
            onSyncDataChange={(data) => {
              onSyncDataChange(data);
            }}
            onSvgStatus={onSvgStatus}
          // onPreventScrollingChange={onPreventScrollingChange}
          />
        )}
        <div
          className="Flex FlexColumn JustifyBetween BGddd"
          style={{ borderLeft: '1px solid #bbb', width: '3.5rem' }}
        >
          <PlaymodeSelector
            playModesData={playModesData}
            selectedPlayMode={selectedPlayMode}
            playerComponent={playerComponent.current}
          />

          <div className="Flex FlexColumn AlignCenter">
            <XmlVisualModeSelector
              selectedPlayMode={selectedPlayMode}
              playerComponent={playerComponent.current}
            />
            <SvgScroll onActiveChange={active => {
              playerComponent.current?.setPreventScrolling(active);
            }} />
          </div>

          <div className="Flex FlexColumn Gap02">
            <MixerBar
              recordings={recordings}
              playerComponent={playerComponent.current}
            />
            <span
              style={{
                userSelect: 'none',
                fontSize: '70%',
                padding: '.1rem .2rem',
                margin: '0 .2rem',
                border: '1px solid #bbb',
                borderRadius: '.3rem',
              }}
            >
              {playbackPosition ? round2(playbackPosition['time']) : 0}
            </span>
            <span
              style={{
                textAlign: 'right',
                userSelect: 'none',
                fontSize: '70%',
                padding: '.1rem .2rem',
                margin: '0 .2rem',
                border: '1px solid #bbb',
                borderRadius: '.3rem',
              }}
            >
              {playbackPosition ? round2(playbackPosition['duration']) : 0}
            </span>
            <BtnPlay
              loadingStatus={loadingStatus}
              playbackStatus={playbackStatus}
              position={playbackPosition}
              playerComponent={playerComponent.current}
            />
          </div>
        </div>
      </div>

      <div
        className="Flex FlexColumn "
        style={{ gap: '0', borderTop: '1px solid #bbb', background: '#ddd' }}
      >
        {/* <Timeline playbackPosition={playbackPosition} playerComponent={playerComponent.current} /> */}
        <Timeline2
          playbackPosition={playbackPosition}
          playerComponent={playerComponent.current}
        />
        <Player2Sync
          playerComponent={playerComponent.current!}
          selectedPlayMode={selectedPlayMode}
          gridAction={gridAction}
          playbackStatus={playbackStatus}
          playbackPosition={playbackPosition}
          syncData={syncData}
          onSaveLegacySyncData={handleLegacySaveTimeSyncAndBarJumps}
          isSaving={savingState.isSaving}
          useTempoCompensation={useTempoCompensation}
          onSaveSvgSyncData={saveSvgSyncData}
          isDraft={props.status === 'Draft'}

          onPlaymodeDataMonitor={onPlaymodeDataMonitor}
        // preventScrolling={preventScrolling}
        />

      </div>
    </div>
  );
};

export default PlayerSync;

export type PlayModeXmlRawData = {
  playModeType: string,
  enabled: boolean,
  data: {
    musicXmlUrl: string,
    syncs: { url: string, layout: string }[],
    svgs: { url: string, page: number, widht: number, height: number, layout: string }[],
    musicXml: string,
    condensed: boolean,
    timeSync: { scorePos: number, mediaPos: number }[],
    barJumps: { from: number, to: number }[],
    tempoCompensation: boolean,
  },
  publicData: {
    musicXmlUrl: string,
    syncs: { url: string, layout: string }[],
    svgs: { url: string, page: number, widht: number, height: number, layout: string }[],
    musicXml: string,
    condensed: boolean,
    timeSync: { scorePos: number, mediaPos: number }[],
    barJumps: { from: number, to: number }[],
    tempoCompensation: boolean,
  },
  name: string,
  default: boolean,
  id: string,
  createdUtc: string,
  updatedUtc: string,
}