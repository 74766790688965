import { Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom/client';
import './color.css';
import './index.css';
import App from './App';
import appsettings from './appsettings.json';
import { store } from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain={appsettings.AUTH0_DOMAIN}
    clientId={appsettings.AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={appsettings.AUTH0_AUDIENCE}
    redirect_uri={window.location.origin + '/callback'}
    cacheLocation="localstorage"
    responseType="token"
    scope="openid profile email"
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);
