import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useDebounce from '../../../hooks/useDebounce';
import { Dispatch, RootState } from '../../../store';
import ArrangementSearchResults from './ArrangementSearchResults';

const ArrangementSearch = () => {
  const [search, setSearch] = useState('');
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<Dispatch>();
  const debounceSearch = useDebounce(search, 300);
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );

  useEffect(() => {
    dispatch.arrangements.search(debounceSearch);
  }, [debounceSearch, dispatch.arrangements, userOrganization]);

  if (!userOrganization) return null;
  if (!user) return null;
  return (
    <>
      <h3 style={{ marginTop: '1rem' }}>Arrangements</h3>
      <label>
        <input
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by arrangement title"
        />
      </label>
      <ArrangementSearchResults />
    </>
  );
};

export default ArrangementSearch;
