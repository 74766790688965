import './App.css';
import 'react-tabs/style/react-tabs.css';
import Header from './view/layout/Header';
import Main from './view/layout/Main';
import Footer from './view/layout/Footer';

const App = () => {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
};

export default App;
