import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';

const ArrangementCreate = () => {
  const dispatch = useDispatch<Dispatch>();
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );
  if (!userOrganization) return null;
  return (
    <>
      <h3 style={{ marginTop: '1rem' }}>Create new arrangement</h3>
      <Button
        onClick={() => {
          dispatch.arrangement.setStatus({ type: 'form' });
          dispatch.tabs.setSelectedTab('arrangement');
        }}
      >
        Create
      </Button>
    </>
  );
};

export default ArrangementCreate;
