import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import { Playmode } from '../../../models/playmodes';
import { Dispatch } from '../../../store';

const EditPlaymode = (props: { playmode: Playmode; isDraft: boolean }) => {
  const dispatch = useDispatch<Dispatch>();
  const [name, setName] = useState(
    props.playmode.name || props.playmode.playModeType
  );
  const [isEditing, setIsEditing] = useState(false);

  if (!props.playmode) return null;

  if (!isEditing)
    return (
      <div className="Flex">
        <p>
          <b>{props.playmode.name || props.playmode.playModeType}</b>
        </p>
        {props.isDraft && (
          <Button className="small" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </div>
    );

  return (
    <label>
      <form onSubmit={(e) => e.preventDefault()} className="Flex FlexRow">
        <input
          className="small"
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Button
          className="small"
          onClick={() => {
            dispatch.playmodes.update({
              id: props.playmode.id,
              name: name,
            });
            setIsEditing(false);
          }}
        >
          Update
        </Button>
      </form>
    </label>
  );
};

export default EditPlaymode;
