import { createModel } from '@rematch/core';
import { RootModel } from '.';

export type SavingState = {
  isSaving: boolean;
};

export const saving = createModel<RootModel>()({
  state: { isSaving: false } as SavingState,
  reducers: {
    setState(state, newState: SavingState) {
      return newState;
    },
  },
});
