import { useState, useEffect, useRef } from 'react';
const MixerBar = (props) => {
    if (!props.recordings) return null;
    const items = props.recordings.map(recording => {
        return <div key={recording.id} className="Flex FlexColumn AlignCenter" style={{ width: '3rem', height: '3.5rem', gap: 0, border: '0px solid black', userSelect: false }} >
            <Meter value={recording.audioLevel * 100} onValue={value => {
                props.playerComponent.playbackSetMixerLevel(recording.id, value / 100);
            }} />
            <p style={{ fontSize: '60%', userSelect: 'none', pointerEvent: 'none' }}>{recording.name}</p>
        </div>
    })

    return <div className="Flex FlexColumn AlignCenter" >
        {items}

    </div>
}

export default MixerBar;

const Meter = (props) => {
    const ref = useRef();
    const [value, setValue] = useState(Number.parseFloat(props.value));
    const setRef = (el) => {
        if (!el) return;
        let startY = 0;
        let startX = 0;
        el.onmousedown = e => {
            startY = e.pageY;
            startX = e.pageX;
            const v = value;
            document.onmousemove = e => {
                const deltaY = ((startY - e.pageY) / 15);
                const deltaX = ((startX - e.pageX) / 15);
                const direction = (deltaY - deltaX) > 0;
                const delta = direction ? Math.max(deltaY, -deltaX) : Math.min(deltaY, -deltaX);
                const newValue = Math.max(0, Math.min(v + delta * 10, 100));
                setValue(newValue);
                if (props.onValue) props.onValue(newValue);
            }
            document.onmouseup = e => {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
    }

    return <svg className="Meter" ref={setRef} width="100%" height="100%" viewBox="0 0 100 100" style={{ transform: 'rotate(120deg)', cursor: 'pointer' }}>
        <ellipse cx="50" cy="50" rx="42" ry="42" stroke='white' strokeWidth="14" strokeLinecap='round' fill="none" pathLength="120" strokeDasharray="100 120" />
        <ellipse cx="50" cy="50" rx="42" ry="42" stroke='dodgerblue' strokeWidth="14" strokeLinecap='round' fill="none" pathLength="120" strokeDasharray={String(value) + ' 120'} />
    </svg>


}
