/* eslint-disable */

import XmlElement from "./XmlElement";

export enum ScorePartwiseChildtype {
    work = "work",
    identification = "identification",
    default = "defaults",
    credit = "credit",
    partList = "partList",
    part = "part",
}

export class ScorePartwise extends XmlElement {

    public setup(xml: Element): ScorePartwise {
        super.setup(xml);
        this.assertNodeName('score-partwise');
        return this;
    }
    public readonly parts: Array<Part> = [];

    private partIdx = 0;
    public createChild(xml: Element, nodeName: string): XmlElement {
        switch (nodeName) {
            case ScorePartwiseChildtype.part:
                const e = new Part().setup(xml);
                e.idx = this.partIdx++;
                this.parts.push(e);

                return e;
            default:
                return this.createElement(xml);
        }
    }

    public afterChildrenCreated() {
        // console.log('score partwise after', this.parts);
    };
}

export enum PartChildtype {
    measure = 'measure',
}

export class Part extends XmlElement {
    public setup(xml: Element): Part {
        super.setup(xml);
        return this;
    }
    public idx: number = 0;

    public readonly measures: Array<Measure> = [];
    private measureIdx = 0;


    public createChild(xml: Element, nodeName: string): XmlElement {
        switch (nodeName) {
            case PartChildtype.measure:
                const e = new Measure().setup(xml);
                this.measures.push(e);
                e.idx = this.measureIdx++;
                return e;
            default: return this.createElement(xml);
        }
    }
}

export enum MeasureChildtype {
    attributes = "attributes",
    print = "print",
    note = "note",
    backup = "backup",
    harmony = "harmony",
    sound = "sound",
    direction = "direaction",
}

export class Measure extends XmlElement {
    public setup(xml: Element): Measure {
        super.setup(xml);
        return this;
    }

    public readonly notes: Array<Note> = [];
    public readonly voices: Array<Array<Note>> = [];
    public duration: number = 0;
    public idx: number = 0;

    private currentPosition = 0;
    private noteIdx = 0;
    public createChild(xml: Element, nodeName: string): XmlElement {
        switch (nodeName) {
            case MeasureChildtype.note:
                const n = new Note().setup(xml);
                n.position = this.currentPosition;
                n.idx = this.noteIdx++;
                this.notes.push(n);
                this.currentPosition += n.duration;
                const voiceIdx = n.voice - 1;
                if (this.voices[voiceIdx] === undefined) this.voices[voiceIdx] = [];
                this.voices[voiceIdx].push(n);
                return n;
            case MeasureChildtype.backup:
                const b = new Backup().setup(xml);
                this.currentPosition -= b.duration;
                return b;
            case MeasureChildtype.sound:

                const s = new Sound().setup(xml);
                // console.log('MeasureChildtype sound', xml);
                return s;
            case MeasureChildtype.direction:
                const d = new Direction().setup(xml);
                return d;
            default:
                // console.log('meadureChild', nodeName);
                return this.createElement(xml);
        }
    }

    afterChildrenCreated() {
        this.duration = this.currentPosition;
    }
}

export class Sound extends XmlElement {
    public setup(xml: Element): Sound {
        super.setup(xml);
        return this;
    }
}

export class Direction extends XmlElement {
    public setup(xml: Element): Direction {
        super.setup(xml);
        return this;
    }
}




export enum NoteChildtype {
    pitch = "pitch",
    duration = "duration",
    tie = "tie",
    instrument = "instrument",
    voice = "voice",
    type = "type",
    stem = "stem",
    staff = "staff",
    notations = "notations",
    rest = "rest",

}

export class Note extends XmlElement {
    public setup(xml: Element): Note {
        super.setup(xml);
        return this;
    }

    public position: number = 0;
    public duration: number = 0;
    public voice: number = 0;
    public tieStart: boolean = false;
    public tieStop: boolean = false;
    public notations?: Notations;
    public idx: number = 0;
    public rest?: Rest;
    public noteType?: NoteType;


    public createChild(xml: Element, nodeName: string): XmlElement {
        switch (nodeName) {
            case NoteChildtype.duration:
                const d = new Duration().setup(xml);
                this.duration = parseInt(String(d.pcData?.nodeValue));
                return d;
            case NoteChildtype.voice:
                const v = new Voice().setup(xml);
                this.voice = parseInt(String(v.pcData?.nodeValue));
                return v;
            case NoteChildtype.tie:
                const t = new Tie().setup(xml);
                if (t.type == TieType.start) this.tieStart = true;
                if (t.type == TieType.stop) this.tieStop = true;
                return t;
            case NoteChildtype.notations:
                const n = new Notations().setup(xml);
                this.notations = n;
                return n;
            case NoteChildtype.type:
                const ty = new NoteType().setup(xml) as NoteType;
                this.noteType = ty;
                return ty;

            case NoteChildtype.rest:
                const r = new Rest().setup(xml);
                this.rest = r;
                return r;

            default: return this.createElement(xml);
        }
    }

}

export class NoteType extends XmlElement {
    public typeValue: string = '';
    public setup(xml: Element): XmlElement {
        super.setup(xml);
        this.typeValue = this.pcData?.nodeValue!;
        return this;
    }

    public clearType() {
        console.log('clear type', this.pcData);
    }

}



export class Rest extends XmlElement {

    public measure: string = '';

    public setup(xml: Element): Rest {
        super.setup(xml);
        const measure = xml.getAttribute('measure');
        if (measure) this.measure = measure;
        return this;
    }

}

export class Voice extends XmlElement {
    public setup(xml: Element): Voice {
        super.setup(xml);
        return this;
    }
}

export class Duration extends XmlElement {
    public setup(xml: Element): Duration {
        super.setup(xml);
        return this;
    }
}

export enum BackupChildtype {
    duration = "duration",
}

export class Backup extends XmlElement {
    public setup(xml: Element): Backup {
        super.setup(xml);
        return this;
    }

    public duration = 0;
    public createChild(xml: Element, nodeName: string): XmlElement {
        switch (nodeName) {
            case BackupChildtype.duration:
                const e = new Duration().setup(xml);
                this.duration = parseInt(String(e.pcData?.nodeValue));
                return e;
            default: return this.createElement(xml);
        }
    }
}

export enum TieType {
    start = 'start',
    stop = 'stop',
}

export class Tie extends XmlElement {

    public type?: TieType;
    public setup(xml: Element): Tie {
        super.setup(xml);
        this.type = xml.getAttribute('type') as TieType;
        return this;
    }
}

export enum NotationsChildtype {
    tied = 'tied',
}

export class Notations extends XmlElement {

    constructor() {
        super();
    }

    public setup(xml: Element): Notations {
        super.setup(xml);
        return this;
    }
    public tieStart: boolean = false;
    public tieStop: boolean = false;
    public createChild(xml: Element, nodeName: string): XmlElement {
        switch (nodeName) {
            case NotationsChildtype.tied:
                const t = new Tie().setup(xml);
                if (t.type == TieType.start) this.tieStart = true;
                if (t.type == TieType.stop) this.tieStop = true;
                return t;
            default: return this.createElement(xml);
        }
    }
}
