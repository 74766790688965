import { ExportSvgData, GridExportItem, PageOrientation } from "../playerTypes";

export const uploadSvgFile = async (apiUrl: string, arrangementId: string, versionId: string, playModeId: string, svgData: ExportSvgData, pageIdx: number, orientation: PageOrientation, userToken: string, ignoreStatusCheck?: boolean) => {
    console.log('UPLOAD Svg', orientation);
    const url = `${apiUrl}/arrangements/${arrangementId}/versions/${versionId}/playmodes/${playModeId}/upload/svg`;
    console.log('upload svg', pageIdx, svgData.width, svgData.height);
    const formData = new FormData();
    formData.append('page', String(pageIdx));
    formData.append('layout', orientation);
    const svgFile = new File([svgData.svg], `page${pageIdx}.svg`, { type: "image/svg+xml", });
    formData.append('file', svgFile, 'page0.svg');
    formData.append('width', String(svgData.width));
    formData.append('height', String(svgData.height));
    if (ignoreStatusCheck) {
        formData.append('ignoreStatusCheck', String(ignoreStatusCheck));
    }
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Bearer ${userToken}` }
    });
    if (!response.ok) {
        response.text().then(text => {
            console.error('callApi error', 'text', text);
            alert(`callApi error ${response.status} - ${text}`);
        });
        throw response
    }
    return response;
}

export const uploadSyncData = async (apiUrl: string, arrangementId: string, versionId: string, playModeId: string, data: GridExportItem[], orientation: PageOrientation, userToken: string, ignoreStatusCheck?: boolean) => {
    console.log('UPLOAD Sync', orientation, data);
    const url = `${apiUrl}/arrangements/${arrangementId}/versions/${versionId}/playmodes/${playModeId}/upload/sync`;
    console.log('url', url);
    const formData = new FormData();
    formData.append('layout', orientation);
    const dataFile = new File([JSON.stringify(data)], 'syncdata.json', { type: "application/json", });
    formData.append('file', dataFile, 'data.json');
    if (ignoreStatusCheck) {
        formData.append('ignoreStatusCheck', String(ignoreStatusCheck));
    }
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Bearer ${userToken}` }
    });

    if (!response.ok) {
        response.text().then(text => {
            console.error('callApi error', 'text', text);
            alert(`callApi error ${response.status} - ${text}`);
        });
        throw response
    }
    return response;
}

export const deleteSvgsAndSyncData = async (apiUrl: string, arrangementId: string, versionId: string, playModeId: string, userToken: string) => {
    const url = `${apiUrl}/arrangements/${arrangementId}/versions/${versionId}/playmodes/${playModeId}/upload`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${userToken}` }
    });
    if (!response.ok) {
        response.text().then(text => {
            console.error('callApi error', 'text', text);
            alert(`callApi error ${response.status} - ${text}`);
        });
        throw response
    }
    return response;

}