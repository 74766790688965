import VideoElementStore from '../../store/VideoElementStore';
import { RecordingAndAudio } from '../../wav/WavPublicApi';
import EngineBase from './EngineBase';
import { IMediaEngine } from './EngineTypes';

export default class EngineVideo extends EngineBase implements IMediaEngine {
    private readonly events = [
        'abort',
        'canplay',
        'canplaythrough',
        'durationchange',
        'emptied',
        'ended',
        'error',
        'loadeddata',
        'loadedmetadata',
        'loadstart',
        'pause',
        'play',
        'playing',
        // 'progress',
        'ratechange',
        'seeked',
        'seeking',
        'stalled',
        // 'suspend',
        // 'timeupdate',
        'volumechange',
        'waiting',
    ];
    private videoElement: HTMLVideoElement;
    private intervalId: number | undefined;
    private bufferingCheckIntervalMs = 50;
    private lastVideoCurrentTime = 0;
    private videoCurrentTime = 0;

    constructor(videoStore: VideoElementStore, onError: (error?: unknown) => void, private isDebugEnabled = false) {
        super(onError);
        this.videoElement = videoStore.videoElement;
    }

    override async setup(recordings: RecordingAndAudio[], initialDuration: number): Promise<void> {
        await super.setup(recordings, initialDuration);
        if (this.isDebugEnabled) {
            for (const event of this.events) {
                this.videoElement.addEventListener(event, this.onVideoEvent);
            }
        }
        this.videoElement.addEventListener('durationchange', this.onDurationChange);
        this.videoElement.addEventListener('loadstart', this.onLoadStart);
        this.videoElement.addEventListener('canplaythrough', this.onCanPlayThrough);
        this.videoElement.addEventListener('playing', this.onPlaying);
        this.videoElement.addEventListener('waiting', this.onWaiting);
        this.intervalId = window.setInterval(this.onCheckBuffering, this.bufferingCheckIntervalMs);
    }

    override async teardown() {
        await super.teardown();
        if (this.intervalId) {
            window.clearInterval(this.intervalId);
        }
        if (this.isDebugEnabled) {
            for (const event of this.events) {
                this.videoElement.removeEventListener(event, this.onVideoEvent);
            }
        }
        this.videoElement.removeEventListener('durationchange', this.onDurationChange);
        this.videoElement.removeEventListener('loadstart', this.onLoadStart);
        this.videoElement.removeEventListener('canplaythrough', this.onCanPlayThrough);
        this.videoElement.removeEventListener('playing', this.onPlaying);
        this.videoElement.removeEventListener('waiting', this.onWaiting);
        return true;
    }

    override async start(time: number): Promise<boolean> {
        try {
            await this.videoElement.play();
            await this.setTime(time);
            return true;
        } catch (e) {
            this.onError(e);
            return false;
        }
    }

    override stop(): Promise<boolean> {
        try {
            this.videoElement.pause();
            return Promise.resolve(true);
        } catch (e) {
            this.onError(e);
            return Promise.resolve(false);
        }
    }

    override setTime(time: number): Promise<boolean> {
        this.videoElement.currentTime = time;
        return Promise.resolve(true);
    }

    private onDurationChange = (e: Event) => {
        this.durationS = (e.target as HTMLVideoElement | undefined)?.duration ?? 0;
        this.command({
            cmd: 'setStartStop',
            start: 0,
            stop: this.durationS,
            context: '.onDurationChange'
        }).catch(this.onError);
    };

    private onLoadStart = () => {
        this.command({
            cmd: 'buffering',
            context: '.onLoadStart'
        }).catch(this.onError);
    };

    private onWaiting = () => {
        this.command({
            cmd: 'buffering',
            context: '.onWaiting'
        }).catch(this.onError);
    };

    private onCanPlayThrough = () => {
        this.command({
            cmd: 'ready',
            context: '.onCanPlayThrough'
        }).catch(this.onError);
    };

    private onPlaying = () => {
        this.command({
            cmd: 'ready',
            context: '.onPlaying'
        }).catch(this.onError);
    };

    private onVideoEvent = (e: Event) => {
        console.debug(e.type);
    };

    private onCheckBuffering = () =>  {
        this.videoCurrentTime = this.videoElement.currentTime;

        // checking offset should be at most the check interval
        // but allow for some margin
        const offset = (this.bufferingCheckIntervalMs - 20) / 1000;

        // if no buffering is currently detected,
        // and the position does not seem to increase
        // and the player isn't manually paused...
        if (!this.isBuffering && this.videoCurrentTime < this.lastVideoCurrentTime + offset && this.isPlaying
        ) {
            this.command({
                cmd: 'buffering',
                context: '.onCheckBuffering'
            }).catch(this.onError);
        }

        // if we were buffering but the player has advanced,
        // then there is no buffering
        if (this.isBuffering && this.videoCurrentTime > this.lastVideoCurrentTime + offset && this.isPlaying) {
            this.command({
                cmd: 'ready',
                context: '.onCheckBuffering'
            }).catch(this.onError);
        }
        this.lastVideoCurrentTime = this.videoCurrentTime;
    };
}
