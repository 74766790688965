import { createModel } from '@rematch/core';
import { RootModel } from '.';

export type User = {
  name: string | undefined;
  email: string | undefined;
  token: string | undefined;
  roles: string[];
};

export const user = createModel<RootModel>()({
  state: {
    name: undefined,
    email: undefined,
    token: undefined,
    roles: [],
  } as User,
  reducers: {
    setUser(state, user: User) {
      return user;
    },
  },
  effects: (dispatch) => ({
    async setUser(user: User, rootState) {
      if (!user?.token) return;
      await dispatch.userOrganizations.load({});
    },
  }),
});
