import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { isSuperUser, isSuperUserOrganization } from '../../../models/superusers';
import { Dispatch, RootState } from '../../../store';

const Reports = () => {
  const dispatch = useDispatch<Dispatch>();
  const [selectedStatus, setSelectedStatus] = useState<string>('Open')
  const user = useSelector(
    (state: RootState) => state.user
  );
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );
  const reports = useSelector((state: RootState) => state.reports);

  useEffect(() => {
    dispatch.reports.load({ organization: userOrganization, status: selectedStatus });
  }, [dispatch.reports, userOrganization, selectedStatus]);

  return (
    <div className="Flex FlexColumn">
      <h2>Arrangement feedback reports</h2>
      <p>
        Manage user feedback regarding
        {userOrganization && !isSuperUserOrganization(userOrganization)
          ? ` ${userOrganization.name} `
          : ' '}
        arrangements.
      </p>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Button
          disabled={
            reports.status !== 'loaded' ||
            reports.offset - reports.limit < 0
          }
          onClick={() => {
            if (reports.status !== 'loaded') return;
            dispatch.reports.load({
              organization: userOrganization,
              offset: reports.offset - reports.limit,
              limit: reports.limit,
              status: selectedStatus
            });
          }}
        >
          Previous
        </Button>
        <Button
          disabled={
            reports.status !== 'loaded' ||
            reports.offset + reports.limit >= reports.total
          }
          onClick={() => {
            if (reports.status !== 'loaded') return;
            dispatch.reports.load({
              organization: userOrganization,
              offset: reports.offset + reports.limit,
              limit: reports.limit,
              status: selectedStatus
            });
          }}
        >
          Next
        </Button>
        <select
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.currentTarget.value)
          }}
        >
          <option value="All">All</option>
          <option value="Open">Open</option>
          <option value="InProgress">In progress</option>
          <option value="Closed">Closed</option>
          <option value="Ignored">Ignored</option>
        </select>
      </div>
      {reports.status === 'empty' && <p>No reports</p>}
      {reports.status === 'loading' && <p>Loading reports...</p>}
      {reports.status === 'error' && <p>{reports.error}</p>}
      {reports.status === 'loaded' && (
        <div className="Flex FlexColumn" style={{ gap: '1rem' }}>
          <p className="text secondary">
            <small>
              Showing reports {reports.offset + 1}-
              {reports.offset + Math.min(reports.limit, reports.items.length)} of {reports.total}
            </small>
          </p>
          {reports.items.map((item) => (
            <Card
              greyedOut={item.status !== 'Open' && item.status !== 'InProgress'}
              key={item.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <div className="Flex AlignCenter">
                    <p style={{ fontWeight: 'bold' }}>
                      {item.arrangementTitle}
                    </p>
                    <div className="chip">
                      <small>{item.type}</small>
                    </div>
                  </div>
                  <small>
                    {new Date(item.createdUtc + 'Z').toLocaleString()}
                  </small>
                  <div className="text secondary">
                    <pre>
                      <small>
                        {item.arrangementId} ({item.arrangementVersionId || '-'})
                      </small>
                    </pre>
                  </div>
                  {isSuperUser(user) && (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <div>
                        <small><a href={`https://web.wearevoice.se/sv/player/${item.arrangementId}`} target="_blank">Play on wearevoice.se</a></small>
                      </div>
                      <div>
                        <small><a href={`https://catalog.wearevoice.se/reports/${item.id}`} target="_blank">More details in Catalog</a></small>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    alignItems: 'flex-end',
                  }}
                >
                  <select
                    value={item.status}
                    onChange={(e) => {
                      dispatch.reports.setItemStatus({
                        id: item.id,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="Open">Open</option>
                    <option value="InProgress">In progress</option>
                    <option value="Closed">Closed</option>
                    <option value="Ignored">Ignored</option>
                  </select>
                  <Button
                    onClick={() => {
                      dispatch.arrangement.loadArrangement({
                        id: item.arrangementId,
                        versionId: item.arrangementVersionId,
                      });
                    }}
                  >
                    Load
                  </Button>
                </div>
              </div>
              <p>{item.comments || '-'}</p>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default Reports;
