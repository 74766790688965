import css from "./PlayerComponent.module.css";
import cx from "classnames";
import React from "react";
import { BarJumpsData, GridExportItem, IPlayerComponent, PlayerProps, TimeSyncData, VerovioRenderData, PageOrientation } from "./playerTypes";
import type { toolkit as VerovioToolkit, VerovioOptions, TimeMapEntry } from 'verovio';
import { DisplayModeState } from "./store/DisplayStore";
import { PlayModeDetailsStatus } from "./store/PlayModeStore";
import VerovioStore from "./store/VerovioStore";
import { createVeriovioRenderDataFromXmlString } from "./render/RenderXmlExportSVG";


type PlayerPreviewProps = {
    xml?: string | null;
    className?: string;
    verovioStore: VerovioStore;
}

export default class PlayerPreviewComponent extends React.PureComponent<PlayerPreviewProps>  {

    private playerElementRef = React.createRef<HTMLDivElement>();

    constructor(props: PlayerPreviewProps) {
        super(props);
    }

    private onError = (error?: unknown) => {

        console.error("onError", error);


    };

    teardown(): void {
        throw new Error("Method not implemented.");
    }

    render() {
        return (
            <div className={cx(css.PlayerComponent, this.props.className)} ref={this.playerElementRef} style={{ position: 'relative' }}></div>
        );
    }

    componentDidMount(): void {
        this.setup(this.playerElementRef.current as HTMLElement);
    }

    componentWillUnmount(): void {
    }

    setup = (playerElement: HTMLElement) => {
        playerElement.style.position = 'absolute';
        playerElement.style.left = "0";
        playerElement.style.top = "0";
        playerElement.style.right = "0";
        playerElement.style.bottom = "0";

        playerElement.style.overflow = 'auto';
        playerElement.style.display = 'flex';
        playerElement.style.flexDirection = 'column';
        playerElement.style.gap = '.5rem';
        playerElement.style.padding = '.5rem';
        playerElement.style.alignItems = 'center';

        if (this.props.xml && this.props.verovioStore) {
            this.createSvgPages(this.props.xml, this.props.verovioStore);
        } else {
            alert("no xml or verovio store");
        }
    }

    createSvgPages = async (xml: string, store: VerovioStore) => {
        const verticalData: VerovioRenderData = await createVeriovioRenderDataFromXmlString(xml, store, 'Vertical');
        for (let svgPage of verticalData.svgs) {
            const el = document.createElement('div');
            el.style.backgroundColor = 'white';
            el.innerHTML = svgPage.svg;
            el.style.width = svgPage.width + 'px';
            el.style.height = svgPage.height + 'px';
            this.playerElementRef.current?.appendChild(el);
        }


    }


}