import classNames from 'classnames';

type Props = {
  status?: string;
};

export default function StatusBadge({ status }: Props) {
  if (!status) {
    return null;
  }

  return <span className={classNames('ArrStatus ', status)}>{status}</span>;
}
