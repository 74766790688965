import { PlayerMode, Recording, RecordingAndAudio } from '../wav/WavPublicApi';
import Signal from '../data/Signal';
import Mp4Audio from '../audio/Mp4Audio';
import WebAudio from '../audio/WebAudio';
import AACAudio, { ADTSObject } from '../audio/AACAudio';
import PlayModeStore from './PlayModeStore';

export type RecordingsStatus = {
    status: 'recordingsEmpty',
} | {
    status: 'recordingsLoading',
    arrangementId: string,
    recordings: Array<Recording>,
    progress: number,
} | {
    status: 'recordingsSuccess',
    arrangementId: string,
    recordings: Array<RecordingAndAudio>,
    mode: PlayerMode,
    duration: number,
}

export default class RecordingsStore {
    public constructor(private onError: (error?: unknown) => void) { }
    readonly recordingsStatusSignal = new Signal<RecordingsStatus>({ status: 'recordingsEmpty' });
    readonly currentRecordingsSignal = new Signal<Array<Recording>>([]);

    readonly currentDuration = new Signal<number>(0);

    private ongoingRequests: Array<XMLHttpRequest> = [];
    private currentRecordingsIds: Array<string> = [];
    private progressArray: Array<number> = [];

    public filterRecordingsFun = (r: Recording) => true;

    async loadAudioRecordings(arrangementId: string, recordings: Array<Recording>, mode: PlayerMode, useCache = true) {

        if (recordings.length > 1)
            recordings = recordings.filter(this.filterRecordingsFun).sort((a, b) => a.sortOrder - b.sortOrder);

        if (!recordings || recordings.length == 0) {
            console.warn('No recordings for this playMode');
            return;
        }

        const recordingsLength = recordings.length;
        recordings = recordings.filter(recording => !(!recording.url));
        if (recordings.length !== recordingsLength) {
            console.warn('One or more recordings has invalid url');
            this.onError('One or more recordings has invalid url');
        }

        //------------------------------------------------------
        // if useCache, don't reload the recordings when changing playmode if thery're identical to the current recordings        
        // console.log('RECORDINGS', recordings);
        const recordingsIds = recordings.map(r => r.id);

        if (useCache && (this.currentRecordingsIds.toString() == recordingsIds.toString())) {
            console.log('(no need to reload recordings)');
            switch (this.recordingsStatusSignal.value.status) {
                case 'recordingsSuccess':
                    return; // exit this method!
                default:
                // continue
            }
        }

        this.currentRecordingsSignal.value = recordings;
        this.currentRecordingsIds = recordingsIds;
        //------------------------------------------------------
        this.recordingsStatusSignal.value = { status: 'recordingsLoading', arrangementId, recordings, progress: 0 };

        if (this.ongoingRequests.length > 0) {
            this.ongoingRequests.forEach(or => or.abort());
        }

        const progressCb: ProgressCallback = (arrangementId: string, recordingIdx: number, channelType: string, loaded: number, total: number) => {
            this.progressArray[recordingIdx] = loaded / total;
            const progress: number = this.progressArray.reduce((t: number, v: number) => t + v, 0) / recordings.length;
            this.recordingsStatusSignal.value = { status: 'recordingsLoading', arrangementId, recordings, progress };
        };

        const durationCb: DurationCallback = (recordingId: string, duration: number) => {
            // console.log(recordingId, 'duration', duration);
        }

        const promises = recordings.map((recording, idx) => {
            const promise = this.loadRecordingAudio(arrangementId, recording, idx, progressCb, mode, durationCb);
            return promise;
        });

        const recordingsExt: Array<RecordingAndAudio> = await Promise.all(promises);
        const duration = recordingsExt[0]?.duration ?? 0;
        this.currentDuration.value = duration;
        this.recordingsStatusSignal.value = { status: 'recordingsSuccess', arrangementId, recordings: recordingsExt, mode, duration };
    }

    loadRecordingAudio = (arrangementId: string, recording: Recording, recordingIdx: number, progressCb: ProgressCallback, mode: PlayerMode, durationCb: DurationCallback): Promise<RecordingAndAudio> => {
        const request = new XMLHttpRequest();
        return new Promise<RecordingAndAudio>((res, rej) => {
            request.open('GET', recording.url, true);
            request.responseType = 'arraybuffer';
            request.onload = () => {
                if (request.status != 200) {
                    console.log('Could not laod recording');
                    rej('Could not load recording ' + recording);
                }
                this.ongoingRequests = this.ongoingRequests.filter(i => i != request);
                const arrayBuffer: ArrayBuffer = request.response;
                // console.log('arrayBuffer', arrayBuffer);
                let recordingExt: RecordingAndAudio;
                try {
                    switch (mode) {
                        case PlayerMode.Standard:
                            // console.log('PlayerMode.Standard', recording.channelType);
                            // if standard player, decode the arrayBuffer into an audioBuffer
                            WebAudio.decodeArrayBuffer(arrayBuffer).then(audioBuffer => {
                                recordingExt = { ...recording, type: 'audioBuffer', audioBuffer, duration: audioBuffer.duration };
                                durationCb(recording.id, audioBuffer.duration);
                                res(recordingExt);
                            }).catch(e => rej(e));
                            break;
                        case PlayerMode.Segmented:
                            // console.log('PlayerMode.Segmented', recording.channelType);
                            // if segmented player, grab the aac arrayBuffer out of the mp4 arrayBuffer
                            // no decoding needed here, as it's done during playback
                            const aacArrayBuffer = Mp4Audio.extractRawAudioFromMp4ArrayBuffer(arrayBuffer);
                            // console.log('aacArrayBuffer', aacArrayBuffer);
                            const adts: ADTSObject = AACAudio.parseAacArrayBufferToADTSSync(aacArrayBuffer);
                            const duration = adts.duration;
                            durationCb(recording.id, duration);
                            recordingExt = { ...recording, type: 'aacArrayBuffer', aacArrayBuffer, duration };
                            res(recordingExt);
                            break;
                    }
                } catch (e) {
                    const message = `Can not extract audio from ${recording.channelType} channel (recording.id ${recording.id}). PlayerMode: ${mode}`;
                    this.onError(message);
                    rej(message);
                }
            };
            request.onerror = e => rej('Loading error $recording : $e');
            request.onprogress = e => {
                const factor = mode === PlayerMode.Standard ? .9 : 1.; // compensate for decompression time
                if (progressCb != null)
                    progressCb(arrangementId, recordingIdx, recording.channelType, e.loaded * factor, e.total);
            };
            request.onabort = e => {
                console.log('(request for recording ' + arrangementId + ':' + recording.channelType + ' was aborted)');
            };
            request.send();
            this.ongoingRequests.push(request);
        });
    };

    public setMixerLevel(recordingId: string, newLevel: number) {
        const recording = this.currentRecordingsSignal.value.find(recording => recording.id == recordingId);
        if (recording) {
            recording.audioLevel = newLevel;
        } else {
            console.log(`recording id ${recordingId} does not extst`);
        }
        this.currentRecordingsSignal.notifyListeners();
    }

    public getDuration(): number {
        switch (this.recordingsStatusSignal.value.status) {
            case 'recordingsSuccess': return this.recordingsStatusSignal.value.duration;
            default: return 0;
        }
    }

    public clear = () => {
        this.recordingsStatusSignal.value = { status: 'recordingsEmpty' };
        this.ongoingRequests = [];
        this.currentRecordingsIds = [];
        this.progressArray = [];
    };

}

type ProgressCallback = (arrangementId: string, recordingIdx: number, channelType: string, loaded: number, total: number) => void;
type DurationCallback = (recordingId: string, duration: number) => void;