import { VerovioSyncData, VerovioTimemapData, SvgMeasureData, SvgNoteData, SvgPageData, BarJumpsData, ScorxGridItem, ScorxPageItem, TimeSyncData, GridVerovioInfo1, ReducedTimemapItem, GridVeroivioItem1, GridTimeDetails, GridItem1Details, GridMouseActionType } from "../playerTypes";
import { PagesLayoutBase } from "./DocumentStore";
import css from '../PlayerComponent.module.css'
import { numberIterator } from "../tools/NumberTools";
import { round5 } from "../tools/NumberTools";
import Signal from "../data/Signal";

// import barsWithJumps from '../prodXmlWithBarjumps.json';

// prepare breaking out timeSync and barJumps to separate signal



const GRID_ITEM_Y_MARGIN = 0.016;

export default class GridVerovioStore {
    public constructor(private onError: (error?: unknown) => void) {
    }

    public readonly gridVerovioInfoSignal = new Signal<GridVerovioInfo1 | null>(null);
    public readonly verovioDataSignal = new Signal<VerovioSyncData | null>(null);

    public resetSignals = () => {
        this.verovioDataSignal.setSilently(null);
        this.gridVerovioInfoSignal.setSilently(null);
    }

    public createScorxGridItems = (scorxGridData: Array<ScorxGridItem>, scorxPages: Array<ScorxPageItem>): GridVerovioInfo1 => {
        let currentH = 0;
        let currentY = 0;
        const items: Array<GridVeroivioItem1> = scorxGridData.map((item, idx) => {
            if (item.system) {
                currentY = item.y;
                currentH = item.h;
            }
            return { itemEl: null, id: 'item-' + idx, inDocument: false, x: item.x, y: currentY, w: item.w, h: currentH, qstamp: 0, tstamp: item.pos, tstampDuration: 0, idx, pageIdx: item.pageNr, systemIdx: 0, pageX: 0, pageY: 0, pageW: 0, pageH: 0 };
        });

        //------------------------------------------------
        // calc tstamp duration
        items.forEach((item, idx) => {
            if (idx < 1) return;
            const prevItem = items[idx - 1];
            prevItem.tstampDuration = item.tstamp - prevItem.tstamp;
        });

        return { items: items };
    }

    public createVerovioGridItems = (syncData: VerovioSyncData): GridVerovioInfo1 => {
        // const td = syncData.timemapData.slice(0, 20));        
        const reducedTimemapItems: Array<ReducedTimemapItem> = reduceTimemapData(syncData.timemapData);
        const map = syncData.svgObjectsMap;
        const mapKeys = Array.from(map.keys());
        const pageKeys = mapKeys.filter(k => k.startsWith('page'));
        const pagesInfo: Array<SvgPageData> = pageKeys.map(pageKey => map.get(pageKey)) as Array<SvgPageData>;

        const items: Array<GridVeroivioItem1> = reducedTimemapItems.map((item, idx) => {
            const measureItem: SvgMeasureData = map.get(item.measureId) as SvgMeasureData;
            const noteRestItem: SvgNoteData = map.get(item.noteRestId) as SvgNoteData;
            const pageInfo = pagesInfo[measureItem.pageIdx];
            const systemIdx = measureItem.systemIdx;
            const x = (noteRestItem ? noteRestItem.x : measureItem.x) / pageInfo.w;
            const y = (measureItem.y) / pageInfo.h;
            const h = (measureItem.y2 - measureItem.y) / pageInfo.h;
            const qstamp = item.qstamp;
            const tstamp = item.tstamp;
            const w = (measureItem.x2 - (noteRestItem ? noteRestItem.x : measureItem.x)) / pageInfo.w;
            const pageIdx = measureItem.pageIdx;
            // console.log('item', idx, item.tstamp, item.tstampDuration);
            return { itemEl: null, id: `${item.measureId}-${item.noteRestId}`, inDocument: false, x, y, w, h, qstamp, tstamp, tstampDuration: item.tstampDuration, idx, pageIdx, systemIdx, pageX: 0, pageY: 0, pageW: 0, pageH: 0 };
        });

        //------------------------------------------------
        // calc tstamp duration
        items.forEach((item, idx) => {
            if (idx < 1) return;
            const prevItem = items[idx - 1];
            prevItem.tstampDuration = item.tstamp - prevItem.tstamp;
        });

        if (!items[items.length - 1].tstampDuration) items[items.length - 1].tstampDuration = 0;

        //------------------------------------------------
        // fix item w
        let prevItem: GridVeroivioItem1 | null = null;
        items.forEach((item: GridVeroivioItem1, idx: number) => {
            if (prevItem) {
                if (prevItem.systemIdx === item.systemIdx) {
                    prevItem.w = item.x - prevItem.x;
                }
                //---------------------------------------
                // check for strange x's and w's - typically last bar
                if (prevItem.systemIdx === item.systemIdx) {
                    if (prevItem.x > item.x) {
                        // console.log('PROBLEM 1', idx, prevItem.x, item.x);                        
                        prevItem.w = (item.x + item.w) - prevItem.x;
                        item.x = prevItem.x + prevItem.w;
                        item.w = .01;
                    }
                }
            }
            prevItem = item;
        });



        return { items };
    }

    public createGridHtmlElements = (layout: PagesLayoutBase, gridInfo: GridVerovioInfo1, isMobile: boolean, devMode: boolean) => {
        layout.overlayEl.innerHTML = '';

        // will be used for spread display mode
        const filteredItems = gridInfo.items.filter(item => {
            return (item.pageIdx >= layout.firstPageNr && item.pageIdx < (layout.firstPageNr + layout.visibleNrOfPages));
        });

        filteredItems.forEach((item, itemIdx) => {
            const pageCoords = layout.pagesRelCoords[item.pageIdx - layout.firstPageNr];
            item.inDocument = true;
            const pageW = pageCoords.w;
            const pageH = pageCoords.h;
            const pageX = pageCoords.x;
            const pageY = pageCoords.y;

            item.pageX = (item.x * pageW) + pageX; // här sätts item-koordinater
            item.pageY = (item.y * pageH) + pageY - GRID_ITEM_Y_MARGIN / layout.rows;
            item.pageW = item.w * pageW;
            item.pageH = item.h * pageH + (2 * (GRID_ITEM_Y_MARGIN / layout.rows));

            const itemDiv = document.createElement('div');
            itemDiv.classList.add(css.GridItem);
            itemDiv.style.position = 'absolute';

            itemDiv.style.left = item.pageX * 100 + '%';
            itemDiv.style.top = item.pageY * 100 + '%';
            itemDiv.style.width = item.pageW * 100 + '%'
            itemDiv.style.height = item.pageH * 100 + '%';
            if (devMode) {
                itemDiv.title = 'Syncbar ' + item.idx; //  + ',  time:' + item.scoreStartTime
                itemDiv.style.borderWidth = '1px';
            }
            itemDiv.id = item.id;

            itemDiv.style.pointerEvents = isMobile ? 'none' : 'all';
            if (!isMobile) {
                itemDiv.onclick = this.onGridItemDivClick;
                itemDiv.onmousemove = this.onGridItemDivMouseMove;
                itemDiv.onmouseout = this.onGridItemDivMouseOut;
            }
            item.itemEl = itemDiv;
            // itemDiv.style.textAlign = 'center';
            layout.overlayEl.appendChild(itemDiv);
        });
        // this.gridItems1WithCoordinatesSignal.value = { items: filteredItems, layout: layout };
    }

    private getMouseXFractionInElement(e: MouseEvent, el: HTMLElement): number {
        const rect = el.getBoundingClientRect();
        const xFraction = (e.clientX - rect.left) / rect.width;
        return xFraction;
    }

    public onGridItemDivMouseOut = (e: MouseEvent) => {
        const el = (e.target as HTMLElement);
        this.onGridItemMouseEventAction(e, el.id, 'mouseout', this.getMouseXFractionInElement(e, el));

    }
    public onGridItemDivClick = (e: MouseEvent) => {
        const el = (e.target as HTMLElement);
        this.onGridItemMouseEventAction(e, el.id, 'click', this.getMouseXFractionInElement(e, el));
    }

    public onGridItemDivMouseMove = (e: MouseEvent) => {
        const el = (e.target as HTMLElement);
        this.onGridItemMouseEventAction(e, el.id, 'mousemove', this.getMouseXFractionInElement(e, el));
    }

    public onGridItemMouseEventAction(e: MouseEvent, elementId: string, action: GridMouseActionType, xFraction: number) {
        // 
    }

    public calculateGridTimeDetails = (gridInfo: GridVerovioInfo1, duration: number, timeSync: TimeSyncData, barJumps: BarJumpsData, arrId: string): GridTimeDetails | null => {



        // let barJumps = this.timeSyncAndBarJumps.barJumps;
        // let timeSync = this.timeSyncAndBarJumps.timeSync;
        const itemsLength = gridInfo.items.length;

        if (!timeSync) timeSync = [];
        // if (!barJumps || !barJumps.length || barJumps.length == 0) barJumps = [];
        if (!barJumps || !barJumps.length || barJumps.length == 0) barJumps = [{ from: 0, to: itemsLength - 1 }];
        // create item array from barJumps
        const jumpItems: Array<GridVeroivioItem1> = [];
        const jumpPasses: Array<number> = [];
        let itemIdx = 0;

        //-------------------------------------------------------------
        // const bwjs: Array<BarJumpsFromJson> = barsWithJumps;
        // const arrBarJumpsFromJson = bwjs.find((bjs: BarJumpsFromJson) => bjs.id == arrId);

        // if (arrBarJumpsFromJson && arrBarJumpsFromJson.useBarJumpsFix) {
        //     console.log('[ REPLACING BARJUMPS from json ]', 'original', barJumps, 'replace', arrBarJumpsFromJson.barJumps);
        //     barJumps = arrBarJumpsFromJson.barJumps;
        // }
        // if (arrBarJumpsFromJson && arrBarJumpsFromJson.useTimeSyncFix) {
        //     console.log('[ REPLACING TIMESYNC from json ]', 'original', timeSync, 'replace', arrBarJumpsFromJson.timeSync);
        //     timeSync = arrBarJumpsFromJson.timeSync as TimeSyncData;
        // }
        //----------------------------------------------------

        // constrain barJumps within itemsLength limit
        barJumps.forEach((barJump, jumpPass) => {
            if (barJump.to > itemsLength - 1) {
                barJump.to = itemsLength - 1;
                if (barJump.from >= barJump.to) barJump.from = barJump.to - 1;
            }
        })
        //---------------------------------------------------------
        barJumps.forEach((barJump, jumpPass) => {
            numberIterator(barJump.from, barJump.to).forEach(idx => {
                const item: GridVeroivioItem1 = gridInfo.items[idx];
                jumpItems.push(item);
                jumpPasses.push(jumpPass);
                itemIdx++;
            });
        });

        const timeStampPositions: Array<number> = [];
        // calc stamp positions;
        let currentTstampPosition = 0;
        jumpItems.forEach((item, idx) => {
            timeStampPositions.push(currentTstampPosition);
            currentTstampPosition += (item.tstampDuration) ? item.tstampDuration : 0;
        });
        const finalTstampPosition = currentTstampPosition;
        // console.log('finalTstampPosition', finalTstampPosition);

        // calc time fractions:        
        const timefractionPositions: Array<number> = [];
        timeStampPositions.forEach(timePos => {
            timefractionPositions.push(timePos / finalTstampPosition);
        })

        // const timeToItemMap = new Map<number, GridItem1>();
        //-------------------------------------------------------------------------------                
        const audioPositions = timefractionPositions;
        let resultPositions: Array<number> = [];
        const syncModifiers = timeSync.map(ts => { return { originalPos: ts.scorePos, newPos: ts.mediaPos, ratio: 0 } });

        // console.log('-------------------');
        // console.log('timeSync', timeSync);
        // console.log('sync modifiers', syncModifiers);
        // console.log('sync modifiers[0]', syncModifiers[0]);
        // console.log('-------------------');


        if (timeSync.length == 0) {
            resultPositions = audioPositions;
        } else {
            // perform sync
            const audioTimes: Array<number> = [];
            let prev = .0;
            audioPositions.forEach(audioPos => {
                const audioTime = (audioPos - prev);
                audioTimes.push(audioTime);
                prev = audioPos;
            })

            let finalModifier = { originalPos: 0, newPos: 0, ratio: 0 };
            syncModifiers.forEach((modifier, modifierIdx) => {
                let ratio = -0;
                if (modifierIdx == 0) {
                    ratio = (modifier.originalPos == 0) ? -modifier.newPos : modifier.newPos / modifier.originalPos; // special case if modifier.originalPos = 0: then set ratio to negative modifier.newPos
                } else if (modifierIdx < syncModifiers.length) {
                    ratio = (modifier.newPos - syncModifiers[modifierIdx - 1].newPos) / (modifier.originalPos - syncModifiers[modifierIdx - 1].originalPos);
                }
                modifier.ratio = ratio as number;
                finalModifier = modifier;
            })

            if (syncModifiers[syncModifiers.length - 1].originalPos < 1) {
                const lastModifier = { originalPos: 1.0, newPos: 1.0, ratio: (1 - finalModifier.newPos) / (1 - finalModifier.originalPos) };
                syncModifiers.push(lastModifier);
            }

            const resultTimes: Array<number> = [];

            audioPositions.forEach((audioPos, itemIdx) => {
                if (syncModifiers[0].originalPos >= audioPos) {
                } else {
                    syncModifiers.shift();
                }

                const ratio = syncModifiers[0].ratio;

                const audioTime = audioTimes[itemIdx];
                const newTime = ratio > 0 ? audioTime * ratio : -ratio; // special case when negative ratio
                resultTimes.push(newTime);
            });

            let prevPosition = .0;
            resultTimes.forEach((resultTime, idx) => {
                const resultPosition = round5(prevPosition + resultTime);
                resultPositions.push(resultPosition);
                prevPosition = resultPosition;
            })
            const lastResult = resultPositions[resultPositions.length - 1];

            // adjust calculation errors
            if (lastResult > 1) {
                const diff = lastResult;
                resultPositions.forEach((pos, idx) => {
                    const factor = diff;
                    const newPos = pos / factor;
                    resultPositions[idx] = newPos;
                });
            }
        }

        let idx = 0;
        const resultRightPositions: Array<number> = [];
        for (const pos of resultPositions) {
            if (idx > 0) {
                resultRightPositions[idx - 1] = round5(pos);
            }
            if (idx == resultPositions.length - 1) {
                resultRightPositions[idx] = 1;
            }
            idx++;
        }

        if (jumpItems.length != resultPositions.length) {
            this.onError('positions length error');
            return null;
        }

        // calculate time positions
        const itemDetails = new Array<GridItem1Details>();
        resultPositions.forEach((pos, idx) => {
            try {
                const item = jumpItems[idx];
                const jumpPass = jumpPasses[idx];
                itemDetails.push({ jumpPass, jumpIdx: 0, itemId: item.id, item, leftPos: pos, rightPos: resultRightPositions[idx], originalLeftPos: audioPositions[idx], leftTstampPos: 0, rightTstampPos: 0 });
                // console.log(idx, audioPositions[idx], pos, resultRightPositions[idx]);
            } catch (e) {
                this.onError(e)
            }
        });

        // calculate tstamp positions
        const totalTstampDuration = itemDetails.reduce((sum, itemDetail) => {
            return (itemDetail.item.tstampDuration) ? itemDetail.item.tstampDuration + sum : sum;
        }, 0);

        //const lastItemDuration = itemDetails[itemDetails.length - 1].item.tstampDuration;
        // console.log('3.13', 'totalTstampDuration', totalTstampDuration);

        let currentTstampPosition2 = 0;
        itemDetails.forEach((itemDetails, itemDetailsIdx) => {
            itemDetails.leftTstampPos = currentTstampPosition2 / totalTstampDuration;
            currentTstampPosition2 += itemDetails.item.tstampDuration;
            itemDetails.rightTstampPos = currentTstampPosition2 / totalTstampDuration;
            // console.log('ididx', itemDetailsIdx, itemDetails.leftTstampPos, itemDetails.rightTstampPos);
        });


        // itemDetails.forEach((itemDetails: GridItem1Details, itemDetailsIdx) => {
        //     let jumpIdx = 0;
        //     for (let barJump of barJumps) {
        //         if ((itemDetails.item.idx >= barJump.from) && (itemDetails.item.idx <= barJump.to)) {
        //             jumpIdx++;
        //         }                
        //     }
        //     console.log('itemIdx', itemDetailsIdx, 'item', itemDetails.item.idx, itemDetails.jumpPass, 'jumpIdx', jumpIdx);

        // });
        let jmap: Map<number, number> = new Map();
        itemDetails.forEach((i, idx) => jmap.set(idx, 0));
        let itemIdx2 = 0;
        for (let barJump of barJumps) {
            let jumpLength = barJump.to - barJump.from + 1;
            // console.log('jumpLenght', jumpLength);
            for (let jdx = 0; jdx < jumpLength; jdx++) {
                let itemIdx = jdx + barJump.from;
                jmap.set(itemIdx, jmap.get(itemIdx)! + 1);
                // console.log(itemIdx2, '- jdx', jdx, itemIdx, (jmap.get(itemIdx)! - 1));
                itemDetails[itemIdx2].jumpIdx = jmap.get(itemIdx)! - 1;
                itemIdx2++;
            }
        }

        // this.timeSyncAndBarJumps = { timeSync, barJumps, nrOfBars: itemsLength, nrOfLayoutBars: jumpItems.length };

        return { items: jumpItems, itemDetails, barJumps, timeSync, totalTstampDuration };
    }



    public calculateGridTimeDetailsScorx = (gridInfo: GridVerovioInfo1, duration: number): GridTimeDetails => {
        // console.log('GridVerovioStore.calculateGridTimeScorx');
        const jumpItems = gridInfo.items;
        const itemDetails = Array<GridItem1Details>();
        jumpItems.forEach((pos, idx) => {
            const item = jumpItems[idx];
            const jumpPass = 0; //jumpPasses[idx];
            const leftPos = item.tstamp;
            const rightPos = item.tstamp + item.tstampDuration;
            itemDetails.push({ jumpPass, jumpIdx: 0, itemId: item.id, item, leftPos: leftPos, rightPos: rightPos, originalLeftPos: leftPos, leftTstampPos: 0, rightTstampPos: 0 });
            // console.log(idx, leftPos, rightPos);
        });

        return { items: jumpItems, itemDetails, barJumps: [], timeSync: [], totalTstampDuration: 0 };
    }

}

const reduceTimemapData = (timemapData: VerovioTimemapData): Array<ReducedTimemapItem> => {
    // console.log('timemapData', '3.13', timemapData);

    const reducedItems: Array<ReducedTimemapItem> = [];
    let measureId = '';
    timemapData.forEach(((item, itemIdx: number) => {
        if (item.measureOn) {
            measureId = item.measureOn;
        }

        let noteRestId = '';
        if (item.restsOn) {
            noteRestId = item.restsOn[0];
        }
        if (item.on) {
            noteRestId = item.on[0];
        }
        let measureOff = '';
        if (item.measureOff) {
            measureOff = item.measureOff;
        }
        const qstamp = item.qstamp;
        const tstamp = item.tstamp;
        const tstampDuration = item.measureTDuration;
        const reducedItem: ReducedTimemapItem = { noteRestId, qstamp, tstamp, measureId, measureOff, tstampDuration };
        reducedItems.push(reducedItem);
    }))

    // console.log('reducedItems', '3.13', reducedItems);

    return reducedItems;
}


// export type TimeSyncAndBarJumps = {
//     timeSync: TimeSyncData,
//     barJumps: BarJumpsData,
//     // duration: number,
// }

