type SignaListener<T> = (value: T) => void;

export default class Signal<T> {
    constructor(initialValue: T) {
        this._value = initialValue;
        this.listeners = [];
    }
    private _value: T;
    public set value(v: T) {
        this._value = v;
        this.listeners.forEach(f => f !== null ? f(this._value) : null);
    }
    public get value(): T {
        return this._value;
    }

    listeners: Array<SignaListener<T>>;

    add = (listener: SignaListener<T>, fireOnAdd = false) => {
        this.listeners.push(listener);
        if (fireOnAdd) listener(this._value);
    }

    remove = (listener: SignaListener<T>) => {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    cleanup = () => {
        this.listeners = [];
    }

    setSilently(v: T) {
        this._value = v;
    }

    notifyListeners = () => {
        this.listeners.forEach(f => f !== null ? f(this._value) : null);
    }
}





