import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { InterestedParty } from '../../../api/ManagementApi';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { Dispatch, RootState } from '../../../store';

const ExistingInterestedParties = () => {
  const dispatch = useDispatch<Dispatch>();
  const data = useSelector(
    (state: RootState) => state.existingInterestedParties
  );
  return (
    <div
      className="ItemList"
      style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
    >
      {data.parties && data.parties.length > 0 && <h2>Interested parties</h2>}
      {data.parties &&
        data.parties.map((party: InterestedParty, idx: number) => {
          return (
            <Card key={party.id}>
              <div
                className="Flex FlexColumn"
                style={{ gap: '0', width: '40%' }}
              >
                <small className="text secondary">{party.type}</small>
                <p>
                  <b>{party.name}</b>
                </p>
              </div>
              {idx > 0 && (
                <Button
                  onClick={() => {
                    dispatch.existingInterestedParties.setAsFirstVisible({
                      id: party.id,
                      type: party.type,
                    });
                  }}
                >
                  Set as first visible
                </Button>
              )}

              <Button
                onClick={() => {
                  dispatch.existingInterestedParties.removeInterestedParty({
                    id: party.id,
                    type: party.type,
                  });
                }}
              >
                Delete
              </Button>
            </Card>
          );
        })}
      {data.loading && <h3>Loading...</h3>}
    </div>
  );
};

export default ExistingInterestedParties;
