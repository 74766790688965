import Signal from "../data/Signal";

import GraphicElementStore from "./GraphicElementStore";
import { PAGE_WIDTH_MARGIN, DEFAULT_A4_HEIGHT, DEFAULT_A4_WIDTH } from "./DocumentStore";
import { PageOrientation } from "../playerTypes";

export type PortraitZoomType = 'height' | 'width' | 'full' | 'zoom';
export type LandscapeZoomType = 'height' | 'zoom';
export type PageFormatType = 'portrait' | 'landscape';
export type PageLayoutType = 'pages' | 'spread';

export type DisplayModeState = {
    state: 'displayModePortrait',
    type: PortraitZoomType,
} | {
    state: 'displayModeLandscape',
    type: LandscapeZoomType,
} | {
    state: 'displayModeSpread',
    pageNr: number,
}

export type UIDisplayModes = {
    available: Record<string, DisplayModeState>,
    current: DisplayModeState | null,
}

export type LandscapeSize = { width: number, height: number }

const ZOOM_CHANGE_DELTA_FACTOR_PORTRAIT = 0.015;
const ZOOM_CHANGE_DELTA_FACTOR_LANDSCAPE = 0.03;
const MAX_ZOOM = 3;
const MIN_ZOOM = .15;

export default class DisplayStore {
    private graphicElementStore: GraphicElementStore;

    public constructor(graphicElementStore: GraphicElementStore, devMode: boolean, private onError: (error?: unknown) => void) {
        this.graphicElementStore = graphicElementStore;

        const initialDisplayMode: DisplayModeState = devMode ? { state: 'displayModePortrait', type: 'zoom' } : { state: 'displayModePortrait', type: 'full' };
        // const initialDisplayMode: DisplayModeState = devMode ? { state: 'displayModeLandscape', type: 'height' } : { state: 'displayModePortrait', type: 'full' };

        this.zoomScalePortrait = devMode ? new Signal(.6) : new Signal(1.0);
        this.zoomScaleLandscape = new Signal(1.0);
        this.displayModeSignal = new Signal<DisplayModeState>(initialDisplayMode);
        this.displayFormatChange = new Signal<PageFormatType>(this.getPageFormatType(initialDisplayMode));
        this.previousZoomState = this.displayModeSignal.value;

        this.displayModeSignal.add(v => {
            const newZoomType: PageFormatType = this.getPageFormatType(v);
            if (newZoomType != this.getPageFormatType(this.previousZoomState)) {
                this.displayFormatChange.value = newZoomType;
            }
            this.previousZoomState = v;
        });
    }

    readonly displayModeSignal: Signal<DisplayModeState>;
    readonly displayFormatChange: Signal<PageFormatType>;
    readonly zoomScalePortrait: Signal<number>;
    readonly zoomScaleLandscape: Signal<number>;

    readonly displayLandscapeSize = new Signal<LandscapeSize>({ width: 2000, height: 300 });

    //-----------------------------------------------------------
    private previousZoomState: DisplayModeState;

    private getPageFormatType(v: DisplayModeState): PageFormatType {
        switch (v.state) {
            case 'displayModeLandscape':
                return 'landscape';
            default:
                return 'portrait';
        }
    }
    public getCurrentOrientation(): PageOrientation {
        switch (this.displayModeSignal.value.state) {
            case 'displayModeLandscape':
                return 'Horizontal';
            default:
                return 'Vertical';
        }
    }


    public getZoomValueFromPageFormat(): number {

        if (this.graphicElementStore.graphicFrameSizeSignal.value.width === 0) return 1;

        switch (this.displayModeSignal.value.state) {
            case 'displayModePortrait':
                switch (this.displayModeSignal.value.type) {
                    case 'width':
                        return (this.graphicElementStore.graphicFrameSizeSignal.value.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH;
                    case 'height':
                        return (this.graphicElementStore.graphicFrameSizeSignal.value.height / DEFAULT_A4_HEIGHT);
                    case 'full':
                        return Math.min((this.graphicElementStore.graphicFrameSizeSignal.value.height / DEFAULT_A4_HEIGHT), (this.graphicElementStore.graphicFrameSizeSignal.value.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH);
                    case 'zoom':
                        return this.zoomScalePortrait.value
                }
            case 'displayModeLandscape':
                switch (this.displayModeSignal.value.type) {
                    case 'height':
                        return (this.graphicElementStore.graphicFrameSizeSignal.value.height / this.displayLandscapeSize.value.height);
                    case 'zoom':
                        return this.zoomScaleLandscape.value;
                }

            case 'displayModeSpread':
                return 0;
        }
    }

    public setZoomDelta = (delta: number) => {
        switch (this.displayModeSignal.value.state) {
            case 'displayModePortrait': {
                let currentZoomFactor = this.zoomScalePortrait.value;
                switch (this.displayModeSignal.value.type) {
                    case 'width':
                        currentZoomFactor = (this.graphicElementStore.graphicFrameSizeSignal.value.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH;
                        break;
                    case 'height':
                        currentZoomFactor = (this.graphicElementStore.graphicFrameSizeSignal.value.height / DEFAULT_A4_HEIGHT);
                        break;
                    case 'full':
                        currentZoomFactor = Math.min((this.graphicElementStore.graphicFrameSizeSignal.value.height / DEFAULT_A4_HEIGHT), (this.graphicElementStore.graphicFrameSizeSignal.value.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH);
                        break;
                    case 'zoom':
                }

                switch (this.displayModeSignal.value.type) {
                    case 'width':
                    case 'height':
                    case 'full':
                        this.displayModeSignal.value = { state: 'displayModePortrait', type: 'zoom' };
                        break;
                    case 'zoom':
                }
                // console.log(currentZoomFactor, currentZoomFactor * 0.01);
                const zoomChangeAmount = currentZoomFactor * ZOOM_CHANGE_DELTA_FACTOR_PORTRAIT;
                const newZoom = this.restrictZoomValue(currentZoomFactor + -this.sign(delta) * zoomChangeAmount);
                this.zoomScalePortrait.value = newZoom;
            }
                break;
            case 'displayModeLandscape': {
                const currentZoomFactor = this.zoomScaleLandscape.value;
                const zoomChangeAmount = currentZoomFactor * ZOOM_CHANGE_DELTA_FACTOR_LANDSCAPE;
                const newZoom = this.restrictZoomValue(currentZoomFactor + -this.sign(delta) * zoomChangeAmount);
                this.zoomScaleLandscape.value = newZoom;
            } break;
            case 'displayModeSpread':
            // do nothing

        }
    }

    restrictZoomValue(value: number) {
        return Math.max(MIN_ZOOM, Math.min(value, MAX_ZOOM));
    }

    public changeZoomValue(factor: number) {
        console.log('changezoom', factor, this.getCurrentZoomValue(), this.getCurrentZoomValue() + factor);
        this.setZoomValue(this.getCurrentZoomValue() + factor);
    }

    public setZoomValue(value: number) {
        value = this.restrictZoomValue(value);
        switch (this.displayModeSignal.value.state) {
            case 'displayModePortrait': {
                switch (this.displayModeSignal.value.type) {
                    case 'width':
                    case 'height':
                    case 'full':
                        this.displayModeSignal.value = { state: 'displayModePortrait', type: 'zoom' };
                        break;
                    case 'zoom':
                }
                this.zoomScalePortrait.value = this.restrictZoomValue(value);
                break;
            }
            case 'displayModeLandscape':
                switch (this.displayModeSignal.value.type) {
                    case 'height':
                        this.displayModeSignal.value = { state: 'displayModeLandscape', type: 'zoom' };
                        break;
                }
                this.zoomScaleLandscape.value = this.restrictZoomValue(value);
                break;
            case 'displayModeSpread':
            // do nothing
        }
    }

    public getCurrentZoomValue(): number {
        return this.getZoomValueFromPageFormat();
    }

    public setDisplayMode(displayMode: DisplayModeState) {

        this.beforeSetDisplayMode();

        this.displayModeSignal.value = displayMode;
    }

    public beforeSetDisplayMode = () => {
        // console.log('%%%%%% do before setDisplayMode');
    }

    sign = (v: number) => {
        return v < 0 ? -1 : v > 0 ? 1 : 0;
    }

    public goToSpreadPageNr(pageNr: number) {
        switch (this.displayModeSignal.value.state) {
            case 'displayModeSpread':
                this.displayModeSignal.value = { state: 'displayModeSpread', pageNr: pageNr };
                break;
            default:
                this.onError('not spread mode!');
        }
    }
}

