import { Notations, Note, ScorePartwise } from "../MusicXmlElements";
import { numberIterator } from "../../../tools/NumberTools";
import { ScoreFixer } from "./ScoreFixer";


export default class FixTies implements ScoreFixer {
    private scorePartwise: ScorePartwise;
    constructor(scorePartwise: ScorePartwise) {
        this.scorePartwise = scorePartwise;
    }
    public fixScore = () => {
        this.scorePartwise.parts.forEach((part, partIdx) => {
            for (const voiceIdx of numberIterator(1)) {
                let prevNote: Note | null = null;
                part.measures.forEach((measure, measureIdx) => {


                    if (measure.voices[voiceIdx])
                        if (voiceIdx <= (measure.voices.length - 1))
                            measure.voices[voiceIdx].forEach((note, noteIdx) => {
                                // console.log('part', partIdx, 'vocie', voiceIdx, 'measure', measureIdx, 'note', noteIdx, note.tieStart, note.tieStop);
                                if (prevNote) {
                                    // add tie stop on note element
                                    if (prevNote.tieStart && !note.tieStop) {
                                        const tieNode = new DOMParser().parseFromString('<tie type="stop" />', 'application/xml');
                                        note.xml?.appendChild(tieNode.firstElementChild as Element);
                                        note.tieStop = true;
                                        // console.log('correction note.tieStop = true');
                                    }

                                    // add tie stop on notations element
                                    let notations;
                                    if (prevNote.notations && prevNote.notations.tieStart) {

                                        if (!note.notations) {
                                            const doc = new DOMParser().parseFromString(`<notations/>`, 'application/xml');
                                            notations = new Notations().setup(doc.firstElementChild as Element);
                                            note.notations = notations;
                                        } else if (!note.notations.tieStop) {
                                            notations = note.notations!;
                                        }
                                        if (notations) {
                                            const tieNode = new DOMParser().parseFromString('<tied type="stop" />', 'application/xml');
                                            notations.xml?.appendChild(tieNode.firstElementChild as Element);
                                            notations.tieStop = true;
                                            // console.log('correction note.notations.tiedStop = true');
                                        }
                                    }
                                }
                                prevNote = note;
                            });
                });
            }
        });

    }

}

/*
export const tryFixTies = async () => {
    const r = await window.fetch('/ties-bothcases.xml');
    // const r = await window.fetch('/gbatlat-short.xml');
    const xmlString = await r.text();
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlString, 'application/xml');
    const score = new ScorePartwise().setup(xml.firstElementChild as Element);
    const tieFixer = new FixTies(score);


    tieFixer.fixScore();

    console.log('---------------------------------');
    score.parts.forEach((part, partIdx) => {
        for (let voiceIdx of numberIterator(1)) {
            part.measures.forEach((measure, measureIdx) => {
                if (voiceIdx <= (measure.voices.length - 1))
                    measure.voices[voiceIdx].forEach((note, noteIdx) => {
                        console.log('part', partIdx, 'vocie', voiceIdx, 'measure', measureIdx, 'note', noteIdx, note.tieStart, note.tieStop);
                    });
            });
        }
    });
}

*/