import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get } from '../api/Api';
import { isSuperUser, SUPER_USER_ORGANIZATION } from './superusers';

type UserMeDetails = {
  id: string;
};

type UserDetails = {
  id: string;
  organizationalUnits: OrganizationalUnit[];
};

export type OrganizationalUnit = {
  id: string;
  name: string;
};

export type UserOrganizationsState = {
  items: OrganizationalUnit[];
  isLoading: boolean;
  error?: string;
};

export const userOrganizations = createModel<RootModel>()({
  state: {
    items: [],
    isLoading: true,
  } as UserOrganizationsState,
  reducers: {
    setState(state, newState: UserOrganizationsState) {
      return newState;
    },
  },
  effects: (dispatch) => ({
    async load(_payload, rootState) {
      if (!rootState.user.token) {
        return;
      }
      dispatch.userOrganizations.setState({
        items: [],
        isLoading: true,
      });
      let organizations: OrganizationalUnit[] = [];
      try {
        const userDetails = (await get(
          '/users/me/details',
          rootState.user.token,
          {}
        )) as UserMeDetails;
        const userDetailsExt = (await get(
          `/users/${userDetails.id}/details`,
          rootState.user.token,
          {}
        )) as UserDetails;
        organizations = userDetailsExt.organizationalUnits;
      } catch (e) {
        dispatch.userOrganizations.setState({
          error: `Failed to get organizations: ${e}`,
          items: [],
          isLoading: false,
        });
        return;
      }

      if (isSuperUser(rootState.user)) {
        organizations = [SUPER_USER_ORGANIZATION, ...organizations];
      }

      dispatch.userOrganizations.setState({
        items: organizations,
        isLoading: false,
      });
      dispatch.userOrganization.setState(organizations[0] || null);
    },
  }),
});

export const userOrganization = createModel<RootModel>()({
  state: null as OrganizationalUnit | null,
  reducers: {
    setState(state, organization: OrganizationalUnit | null) {
      return organization;
    },
  },
});
