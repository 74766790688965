import { ArrangementData_2_3, InterestedParty } from "../wav/WavPublicApi";

const XMLNS = "http://www.w3.org/2000/svg";

export const addArrangementInfo = (pageIdx: number, div: HTMLElement, arrangementData: ArrangementData_2_3 | null) => {
    if (!arrangementData) return;

    const headerDiv = document.createElement('div');
    headerDiv.style.position = 'absolute';
    headerDiv.style.left = '0';
    headerDiv.style.top = '0';
    headerDiv.style.width = '100%';
    headerDiv.style.height = '10%';
    // headerDiv.style.border = '5px solid lime';
    div.appendChild(headerDiv);

    const headerSvg = document.createElementNS(XMLNS, "svg");
    headerSvg.setAttributeNS(null, "viewBox", "0 0 " + 1000 + " 100");
    headerSvg.setAttributeNS(null, "width", '100%');
    headerSvg.setAttributeNS(null, "height", '100%');
    headerSvg.style.position = 'absolute';
    headerSvg.style.left = headerSvg.style.top = '0';
    headerDiv.appendChild(headerSvg);

    switch (pageIdx) {
        case 0: {
            const title = arrangementData.title;
            const subtitle = arrangementData.subtitle;
            const interestedParties = arrangementData.interestedParties;
            addArrangementInfoFirstPage(headerSvg, title, subtitle, interestedParties);
            break;
        }
        default: {
            const title = arrangementData.title;
            addArrangementInfoFollowingPages(pageIdx, headerSvg, title);
            break;
        }
    }
}

export const addArrangementInfoFirstPage = (headerSvg: SVGSVGElement, title: string, subtitle: string, interestedParties: Array<InterestedParty>) => {
    const marginTop = 50; //subtitle.length > 0 ? 66 : 50;
    const marginLeftX = 70;

    //------------------------------
    const leftText = document.createElementNS(XMLNS, "text");
    leftText.setAttributeNS(null, "y", String(-6 + marginTop));
    leftText.setAttributeNS(null, "x", String(marginLeftX));
    leftText.setAttributeNS(null, "font-size", '30');
    leftText.setAttributeNS(null, "font-weight", "bold");
    leftText.setAttributeNS(null, "letter-spacing", '-1');
    leftText.innerHTML = title;
    headerSvg.appendChild(leftText);

    const subtitleText = document.createElementNS(XMLNS, "text");
    subtitleText.style.fontStyle = 'italic';
    subtitleText.setAttributeNS(null, "y", String(26 + marginTop));
    subtitleText.setAttributeNS(null, "x", String(marginLeftX));
    subtitleText.setAttributeNS(null, "font-size", '16');
    subtitleText.setAttributeNS(null, 'fill', '#888');
    subtitleText.innerHTML = subtitle;
    headerSvg.appendChild(subtitleText);

    const ips = interestedParties;
    const types = (ips != null) ? ips?.map(o => o.type).filter((v, i, a) => a.indexOf(v) === i).filter(t => ((t != 'Artist') && (t != 'Other'))) : [];
    const typeOriginators = types.map(typ => typ + ': ' + ips.filter(o => o.type == typ).map(o => o.name).join(', '));
    const replaceType = (s: string) => {
        return s.replace('Composer', 'Musik').replace('Arranger', 'Arr').replace('Lyricist', 'Text').replace('Translator', 'Övers.');
    }
    typeOriginators.forEach((to: string, idx: number) => {
        const rightText = document.createElementNS(XMLNS, "text");
        rightText.style.fontFamily = 'var(--font-family)';
        rightText.setAttributeNS(null, "font-size", '13');
        rightText.setAttributeNS(null, "x", '960');
        rightText.setAttributeNS(null, "y", String(-3 + marginTop - (idx * 15)));
        rightText.innerHTML = replaceType(to);
        rightText.setAttributeNS(null, "text-anchor", "end");
        headerSvg.appendChild(rightText);
    });

    const line = document.createElementNS(XMLNS, 'line');
    line.setAttributeNS(null, "x1", String(marginLeftX));
    line.setAttributeNS(null, "x2", '960');
    line.setAttributeNS(null, "stroke", '#ccc');
    line.setAttributeNS(null, "strokeWidth", '1.5');
    line.setAttributeNS(null, "y1", String(6 + marginTop));
    line.setAttributeNS(null, "y2", String(6 + marginTop));
    headerSvg.appendChild(line);
}

export const addArrangementInfoFollowingPages = (pageIdx: number, headerSvg: SVGSVGElement, title: string) => {
    const marginTop = 30;
    const marginLeftX = 70;

    const leftText = document.createElementNS(XMLNS, "text");
    leftText.setAttributeNS(null, "y", String(marginTop));
    leftText.setAttributeNS(null, "x", String(marginLeftX));
    leftText.setAttributeNS(null, "font-size", '15');
    // leftText.setAttributeNS(null, "font-weight", "bold");
    // leftText.setAttributeNS(null, "text-anchor", "end");
    leftText.innerHTML = title;
    headerSvg.appendChild(leftText);

    const rightText = document.createElementNS(XMLNS, "text");
    rightText.setAttributeNS(null, "y", String(marginTop));
    rightText.setAttributeNS(null, "x", '960');
    rightText.setAttributeNS(null, "font-size", '20');
    rightText.setAttributeNS(null, "text-anchor", "end");
    rightText.innerHTML = String(pageIdx + 1);
    headerSvg.appendChild(rightText);

    const line = document.createElementNS(XMLNS, 'line');
    line.setAttributeNS(null, "x1", String(marginLeftX));
    line.setAttributeNS(null, "x2", '960');
    line.setAttributeNS(null, "stroke", '#ccc');
    line.setAttributeNS(null, "strokeWidth", '1.5');
    line.setAttributeNS(null, "y1", String(10 + marginTop));
    line.setAttributeNS(null, "y2", String(10 + marginTop));
    headerSvg.appendChild(line);
}
