import { useEffect, useState } from "react";
import ReactSlider from "./ReactSlider";
import "./Timeline2.css";

const Timeline2 = (props: any) => {
    const pc = props.playerComponent;
    const pp = props.playbackPosition;
    const { startTime, stopTime, time, duration } = pp ?? {
        startTime: 0,
        stopTime: 0,
        time: 0,
        duration: 0,
    };

    const [timeValues, setTimeValues] = useState([time]);
    useEffect(() => {
        setTimeValues([time]);
    }, [time]);

    const onChange = (value: number) => {
        setTimeValues([value]);
        pc.playbackScrub(value);
    }

    const onAfterChange = (value: number) => {
        pc.playbackScrubReady();
        // pc.playbackSetStartStop(values[0], values[2]);
        pc.playbackSetTime(value);
    }

    return <div className="Flex Pad05 FlexColumn Gap0 ">
        <ReactSlider
            onAfterChange={onAfterChange}
            onChange={onChange}
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            value={timeValues}
            pearling
            minDistance={0}
            withTracks={true}
            step={.001}
            max={duration}
        />
    </div>
}

export default Timeline2;

/*
const Timeline2 = (props: any) => {
    const pc = props.playerComponent;
    const pp = props.playbackPosition;
    const { startTime, stopTime, time, duration } = pp ?? {
        startTime: 0,
        stopTime: 0,
        time: 0,
        duration: 0,
    };

    const [timeValues, setTimeValues] = useState([startTime, time, stopTime]);
    useEffect(() => {
        setTimeValues([startTime, time, stopTime]);
    }, [time, startTime, stopTime]);

    const onChange = (values: number[]) => {
        setTimeValues(values);
        pc.playbackScrub(values[1]);
    }

    const onAfterChange = (values: number[]) => {
        pc.playbackScrubReady();
        pc.playbackSetStartStop(values[0], values[2]);
        pc.playbackSetTime(values[1]);
    }

    return <div className="Flex Pad05 FlexColumn Gap0 ">
        <ReactSlider
            onAfterChange={onAfterChange}
            onChange={onChange}
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            value={timeValues}
            pearling
            minDistance={0}
            withTracks={true}
            step={.001}
            max={duration}
        />
        </div>
    }
*/