import Signal from '../../data/Signal';
import { PlaybackCommand, PlaybackStatus, PeriodicPlaybackStatus, IMediaEngine } from './EngineTypes';
import { PlaybackPosition } from './EngineTypes';
import { RecordingAndAudio } from '../../wav/WavPublicApi';

const PERIODIC_TIMER_INTERVAL_MS = 1000; // ms, used for perodically syncing playhead and scroll animation
const TIMER_INTERVAL_MS = 16;

export default abstract class EngineBase implements IMediaEngine {
    private timerHandle: number | undefined;
    private lastOnTimerUpdateAt: number | undefined = undefined;
    private periodicTimerHandle: number | undefined;

    protected isPlaying = false;
    protected isBuffering = false;
    protected isLoaded = false;
    protected startTimeS = 0;
    protected stopTimeS = 0;
    protected durationS = 0;
    protected timeMs = 0;

    readonly playbackStatusSignal: Signal<PlaybackStatus>;
    readonly playbackLoopSignal: Signal<boolean>;
    readonly playbackPositionSignal: Signal<PlaybackPosition>;
    readonly playbackPeriodicStatusSignal: Signal<PeriodicPlaybackStatus>;

    constructor(protected onError: (error?: unknown) => void) {
        this.playbackStatusSignal = new Signal<PlaybackStatus>({
            status: 'engineEmpty'
        });
        this.playbackLoopSignal = new Signal<boolean>(false);
        this.playbackPositionSignal = new Signal<PlaybackPosition>({
            time: 0,
            duration: 0,
            loop: false,
            startTime: 0,
            stopTime: 0
        });
        this.playbackPeriodicStatusSignal = new Signal<PeriodicPlaybackStatus>({
            playbackPosition: {
                time: 0,
                duration: 0,
                loop: false,
                startTime: 0,
                stopTime: 0
            },
            playbackStatus: {
                status: 'engineEmpty'
            }
        });
    }

    async setup(recordings: RecordingAndAudio[], initialDuration: number): Promise<void> {
        this.durationS = initialDuration;
        await this.command({
            cmd: 'setStartStop',
            start: 0,
            stop: initialDuration,
            context: '.setup'
        });
        await this.command({
            cmd: 'stop',
            context: '.setup'
        });
        this.setRecordings(recordings);
        this.timerHandle = window.setInterval(this.onTimerUpdate, TIMER_INTERVAL_MS);
        this.periodicTimerHandle = window.setInterval(this.onPeriodicTimer, PERIODIC_TIMER_INTERVAL_MS);
        this.isLoaded = true;
    }

    teardown(): Promise<boolean> {
        window.clearInterval(this.timerHandle);
        window.clearInterval(this.periodicTimerHandle);
        this.isLoaded = false;

        return Promise.resolve(true);
    }


    async command(command: PlaybackCommand): Promise<boolean> {
        // console.debug('command', command);
        switch (command.cmd) {
            case 'start':
                if (this.isPlaying) {
                    return Promise.resolve(true);
                }
                if (!this.isLoaded) {
                    return Promise.resolve(false);
                }

                if (this.timeMs >= this.stopTimeS * 1000) {
                    await this.command({
                        cmd: 'setTime',
                        time: this.startTimeS,
                        context: `${command.context ?? ''}.start`
                    });
                }
                this.isPlaying = true;
                await this.start(this.getTimeS());

                this.playbackStatusSignal.value = {
                    status: 'enginePlaying',
                    time: this.getTimeS(),
                    duration: this.durationS
                };
                this.playbackPeriodicStatusSignal.value = {
                    playbackStatus: this.playbackStatusSignal.value,
                    playbackPosition: this.playbackPositionSignal.value
                };

                return true;
            case 'stop':
                this.isPlaying = false;
                await this.stop();

                this.playbackStatusSignal.value = {
                    status: 'enginePaused',
                    time: this.getTimeS(),
                    duration: this.durationS
                };
                this.playbackPeriodicStatusSignal.value = {
                    playbackStatus: this.playbackStatusSignal.value,
                    playbackPosition: this.playbackPositionSignal.value
                };

                return true;
            case 'setTime': {
                const setTimeWhilePlaying = this.isPlaying;
                if (setTimeWhilePlaying) {
                    await this.command({
                        cmd: 'stop',
                        context: `${command.context ?? ''}.setTime`
                    });
                }
                this.timeMs = Math.min(Math.max(command.time, this.startTimeS), this.stopTimeS) * 1000;
                await this.setTime(this.getTimeS());
                if (setTimeWhilePlaying) {
                    await this.command({
                        cmd: 'start',
                        context: `${command.context ?? ''}.setTime`
                    });
                }

                this.playbackPositionSignal.value = {
                    time: this.getTimeS(),
                    duration: this.durationS,
                    startTime: this.startTimeS,
                    stopTime: this.stopTimeS,
                    loop: this.playbackLoopSignal.value
                };

                this.playbackPeriodicStatusSignal.value = {
                    playbackStatus: this.playbackStatusSignal.value,
                    playbackPosition: this.playbackPositionSignal.value
                };

                return true;
            }
            case 'buffering':
                this.isBuffering = true;

                this.playbackStatusSignal.value = {
                    status: 'engineBuffering'
                };

                return Promise.resolve(true);
            case 'ready':
                this.isBuffering = false;

                this.playbackStatusSignal.value = {
                    status: this.isPlaying ? 'enginePlaying' : 'enginePaused',
                    time: this.getTimeS(),
                    duration: this.durationS
                };

                return Promise.resolve(true);
            case 'setLoop':
                this.playbackLoopSignal.value = command.loop;
                this.playbackPositionSignal.value = {
                    time: this.getTimeS(),
                    duration: this.durationS,
                    startTime: this.startTimeS,
                    stopTime: this.stopTimeS,
                    loop: this.playbackLoopSignal.value
                };
                this.playbackPeriodicStatusSignal.value = {
                    playbackStatus: this.playbackStatusSignal.value,
                    playbackPosition: this.playbackPositionSignal.value
                };

                return Promise.resolve(true);
            case 'setStartStop': {
                this.startTimeS = Math.max(command.start, 0);
                this.stopTimeS = Math.min(command.stop, this.durationS);

                const currentTimeS = this.getTimeS();
                if (currentTimeS <= this.startTimeS || currentTimeS >= this.stopTimeS) {
                    await this.command({
                        cmd: 'stop',
                        context: `${command.context ?? ''}.setStartStop`
                    });
                    await this.command({
                        cmd: 'setTime',
                        time: Math.max(Math.min(currentTimeS, this.stopTimeS), this.startTimeS),
                        context: `${command.context ?? ''}.setStartStop`
                    });
                }

                this.playbackPositionSignal.value = {
                    time: this.getTimeS(),
                    duration: this.durationS,
                    startTime: this.startTimeS,
                    stopTime: this.stopTimeS,
                    loop: this.playbackLoopSignal.value
                };
                this.playbackPeriodicStatusSignal.value = {
                    playbackStatus: this.playbackStatusSignal.value,
                    playbackPosition: this.playbackPositionSignal.value
                };

                return Promise.resolve(false);
            }
            default:
                return Promise.resolve(false);
        }
    }

    getDuration = () => {
        return this.durationS;
    };

    setMixerLevel(_recordingId: string, _newLevel: number) {
        return;
    }

    protected start(_time: number): Promise<boolean> {
        return Promise.resolve(true);
    }

    protected stop(): Promise<boolean> {
        return Promise.resolve(true);
    }

    protected setRecordings(_recordings: RecordingAndAudio[]) {
        return;
    }

    protected setTime(_time: number): Promise<boolean> {
        return Promise.resolve(true);
    }

    private onTimerUpdate = () => {
        const delta = this.lastOnTimerUpdateAt ? (Date.now() - this.lastOnTimerUpdateAt) : 0;
        this.lastOnTimerUpdateAt = Date.now();

        if (!this.isPlaying || this.isBuffering) {
            return;
        }

        this.timeMs += delta;

        let isStopReached = false;
        const maxTimeMs = this.stopTimeS * 1000;
        if (this.timeMs > maxTimeMs) {
            this.timeMs = maxTimeMs;
            isStopReached = true;
        }

        // console.log('onTimerUpdate', this.startTimeS, this.timeMs, this.stopTimeS, isStopReached, this.playbackLoopSignal.value);

        this.playbackPositionSignal.value = {
            time: this.getTimeS(),
            duration: this.durationS,
            startTime: this.startTimeS,
            stopTime: this.stopTimeS,
            loop: this.playbackLoopSignal.value
        };

        if (isStopReached) {
            this.command({
                cmd: 'stop',
                context: 'onTimerUpdate'
            }).then(() => {
                if (!this.playbackLoopSignal.value) {
                    return Promise.resolve(true);
                }
                return this.command({
                    cmd: 'start',
                    context: 'onTimerUpdate'
                });
            }).catch(this.onError);
        }
    };

    private onPeriodicTimer = () => {
        if (!this.isPlaying) {
            return;
        }

        this.playbackPeriodicStatusSignal.value = {
            playbackPosition: this.playbackPositionSignal.value,
            playbackStatus: this.playbackStatusSignal.value
        };
    };

    private getTimeS = () => {
        return this.timeMs / 1000;
    };
}
