import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { post } from '../api/Api';
import { RecordingDB, RecordingPostData } from '../api/ManagementApi';
import appsettings from '../appsettings.json';
import { RecordingType } from '../player/wav/WavPublicApi';

export type RecordingUploadState =
  | {
    type: 'file';
    file: File;
  }
  | {
    type: 'parsed';
    sourceFile: string;
    size: number;
    voiceType: VoiceType;
    label: string;
    audioLevel: number;
    sortOrder: number;
    recordingType: RecordingType;
    file: File;
  }
  | {
    type: 'uploading';
    filename: string,
    loaded: number;
    total: number;
  };

export type RecordingUploadsState = {
  items: RecordingUploadState[];
};

export const recordingUploads = createModel<RootModel>()({
  state: { items: [] } as RecordingUploadsState,
  reducers: {
    setState(state, newState: RecordingUploadsState) {
      return newState;
    },
  },
  effects: (dispatch) => ({

    async removeSourceFile(filenamme: string, rootState) {
      const items = rootState.recordingUploads.items.filter(item => {
        if (item.type !== 'parsed') return true;
        return item.sourceFile !== filenamme;
      });
      dispatch.recordingUploads.setState({ items });
    },


    async createRecording(
      data: { recording: RecordingUploadState; index: number },
      rootState
    ): Promise<void> {

      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;




      const arrId = rootState.arrangement.id;
      const versionId = rootState.arrangementVersion.id;
      const recording = data.recording;
      if (recording.type !== 'parsed') return;
      const idx = data.index;
      const postData: RecordingPostData = {
        audioLevel: recording.audioLevel,
        downloadUrl: '',
        duration: 0,
        name: recording.label,
        recordingType: recording.recordingType,
        size: 0,
        sortOrder: recording.sortOrder,
        sourceUrl: recording.sourceFile,
        type: VoiceType[recording.voiceType],
      };
      const recordingDb = (await post(
        `/arrangements/${arrId}/versions/${versionId}/audiotracks`,
        rootState.user.token,
        postData
      )) as RecordingDB;
      await dispatch.playmodes.load({});
      await dispatch.recordingUploads.uploadRecordingData({
        recordingDb,
        file: recording.file,
        index: idx,
      });
      return Promise.resolve();

    },
    async uploadRecordingData(
      data: {
        recordingDb: RecordingDB;
        file: File;
        index: number;
      },
      rootState
    ) {
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      if (!rootState.user.token) return;

      const arrId = rootState.arrangement.id;
      const versionId = rootState.arrangementVersion.id;
      const recordingId = data.recordingDb.id;
      const url =
        appsettings.API_URL +
        `/arrangements/${arrId}/versions/${versionId}/audiotracks/${recordingId}/upload`;
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      formData.append('file', data.file);
      xhr.upload.onloadstart = () => console.log('upload start');
      xhr.upload.onprogress = (e) => {
        if (e.loaded < e.total) {
          const updatedRecording: RecordingUploadState = {
            type: 'uploading',
            loaded: e.loaded,
            total: e.total,
            filename: data.file.name,
          };
          const items = rootState.recordingUploads.items;
          items[data.index] = updatedRecording;
          dispatch.recordingUploads.setState({ items });
          dispatch.recordings.load({});
        } else {
          // console.log('uploading ready');
          // const items = [...rootState.recordingUploads.items];
          // console.log('items before', items);
          // items.splice(data.index, 1);
          // console.log('items after', items);
          // dispatch.recordingUploads.setState({ items });
          // dispatch.recordings.load({});
        }

      };
      xhr.upload.onloadend = () => {
        console.log('onloadended');
        // alert('upload ended ' + data.index);
        const items = rootState.recordingUploads.items.filter((item: RecordingUploadState) => {
          item.type === 'uploading' && item.filename !== data.file.name;
        });
        dispatch.recordingUploads.setState({ items });
        // setTimeout(() => {
        //   dispatch.recordings.load({});
        // }, 1000);
      };
      xhr.upload.onerror = (e) => alert('upload error: ' + e);
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Accept', 'text/plain');
      xhr.setRequestHeader('Authorization', 'Bearer ' + rootState.user.token);
      xhr.setRequestHeader('api-version', '2.3');
      xhr.send(formData);
    },
  }),
});

export const remapTypeFileToTypeParsed = (
  typeFile: RecordingUploadState
): RecordingUploadState => {
  if (typeFile.type !== 'file') {
    alert('Error1');
    return typeFile;
  }
  const voiceType: VoiceType = findVoiceType(typeFile.file.name);
  return {
    type: 'parsed',
    sourceFile: typeFile.file.name,
    size: typeFile.file.size,
    voiceType,
    label: VoiceType[voiceType],
    audioLevel: 0.7,
    sortOrder: toSortOrder(voiceType),
    recordingType: typeFile.file.type.includes('video')
      ? RecordingType.Video
      : RecordingType.Audio,
    file: typeFile.file,
  };
};

export enum VoiceType {
  Unknown = 0,
  Master = 1,
  Background = 2,
  Other = 3,
  Synthetic = 10,
  Treble = 70,
  Treble1 = 71,
  Treble2 = 72,
  Obligato = 73,
  TrebleVoice = 74,
  Stem1 = 75,
  Stem2 = 76,
  Solo = 80,
  Solo1 = 81,
  Solo2 = 82,
  Voice = 85,
  Lead = 90,
  Lead1 = 91,
  Lead2 = 92,
  Soprano = 100,
  Soprano1 = 101,
  Soprano2 = 102,
  Soprano3 = 103,
  Soprano4 = 104,
  Alto = 110,
  Alto1 = 111,
  Alto2 = 112,
  Alto3 = 113,
  Alto4 = 114,
  CounterTenor = 118,
  Tenor = 120,
  Tenor1 = 121,
  Tenor2 = 122,
  Tenor3 = 123,
  Tenor4 = 124,
  Bass = 130,
  Bass1 = 131,
  Bass2 = 132,
  Bass3 = 133,
  Bass4 = 134,
  Mezzosoprano = 140,
  Mezzosoprano1 = 141,
  Mezzosoprano2 = 142,
  Mezzosoprano3 = 143,
  Mezzosoprano4 = 144,
  Baritone = 150,
  Baritone1 = 151,
  Baritone2 = 152,
  Baritone3 = 153,
  Baritone4 = 154,
  Choir = 180,
  Choir1 = 181,
  Choir2 = 182,
  Choir3 = 183,
  Choir4 = 184,
  Unisono = 190,
  Unisono1 = 191,
  Unisono2 = 192,
  Congregation = 195,
  Liturgist = 196,
  Accomp = 200,
  AccompPlusSolo = 201,
  AccompPlusLead = 202,
  AccompGroup = 203,
}

export const findVoiceType = (str: string): VoiceType => {
  if (!str) return VoiceType.Unknown;
  str = str.toLowerCase();

  if (str.indexOf('sopran 1') > -1) return VoiceType.Soprano1;
  if (str.indexOf('sopran1') > -1) return VoiceType.Soprano1;
  if (str.indexOf('sopran 2') > -1) return VoiceType.Soprano2;
  if (str.indexOf('sopran2') > -1) return VoiceType.Soprano2;
  if (str.indexOf('sopran') > -1) return VoiceType.Soprano;

  if (str.indexOf('alt 1') > -1) return VoiceType.Alto1;
  if (str.indexOf('alt1') > -1) return VoiceType.Alto1;
  if (str.indexOf('alt 2') > -1) return VoiceType.Alto2;
  if (str.indexOf('alt2') > -1) return VoiceType.Alto2;
  if (str.indexOf('alt') > -1) return VoiceType.Alto;

  if (str.indexOf('tenor 1') > -1) return VoiceType.Tenor1;
  if (str.indexOf('tenor1') > -1) return VoiceType.Tenor1;
  if (str.indexOf('tenor 2') > -1) return VoiceType.Tenor2;
  if (str.indexOf('tenor2') > -1) return VoiceType.Tenor2;
  if (str.indexOf('tenor') > -1) return VoiceType.Tenor;

  if (str.indexOf('bas 1') > -1) return VoiceType.Bass1;
  if (str.indexOf('bas1') > -1) return VoiceType.Bass1;
  if (str.indexOf('bas 2') > -1) return VoiceType.Bass2;
  if (str.indexOf('bas2') > -1) return VoiceType.Bass2;
  if (str.indexOf('bas') > -1) return VoiceType.Bass;

  if (str.indexOf('ackomp') > -1) return VoiceType.Background;
  if (str.indexOf('komp') > -1) return VoiceType.Background;

  if (str.indexOf('mix') > -1) return VoiceType.Master;




  const key = Object.keys(VoiceType)
    .reverse()
    .find((voicetype) => {
      if (!isNaN(Number(voicetype))) return false;
      return str.indexOf(voicetype.toLowerCase()) > -1;
    });

  if (key !== undefined) return VoiceType[key as keyof typeof VoiceType];
  return VoiceType.Unknown;
};

export const toSortOrder = (voiceType: VoiceType): number => {
  switch (voiceType) {
    case VoiceType.Lead:
      return 0;
    case VoiceType.Lead1:
      return 1;
    case VoiceType.Lead2:
      return 2;
    case VoiceType.Solo:
      return 0;
    case VoiceType.Solo1:
      return 1;
    case VoiceType.Solo2:
      return 2;

    case VoiceType.Soprano:
      return 10;
    case VoiceType.Soprano1:
      return 11;
    case VoiceType.Soprano2:
      return 12;
    case VoiceType.Soprano3:
      return 13;
    case VoiceType.Soprano4:
      return 14;
    case VoiceType.Alto:
      return 20;
    case VoiceType.Alto1:
      return 21;
    case VoiceType.Alto2:
      return 22;
    case VoiceType.Alto3:
      return 23;
    case VoiceType.Alto4:
      return 24;
    case VoiceType.Tenor:
      return 30;
    case VoiceType.Tenor1:
      return 31;
    case VoiceType.Tenor2:
      return 32;
    case VoiceType.Tenor3:
      return 33;
    case VoiceType.Tenor4:
      return 34;
    case VoiceType.Bass:
      return 40;
    case VoiceType.Bass1:
      return 41;
    case VoiceType.Bass2:
      return 42;
    case VoiceType.Bass3:
      return 43;
    case VoiceType.Bass4:
      return 44;
    case VoiceType.Background:
      return 90;
    case VoiceType.Master:
      return 100;
    default:
      return 0;
  }
};


