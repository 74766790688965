import WebAudio from "./WebAudio";

export default class AACAudio {

    static parseAacArrayBufferToADTS = (arrayBuffer: ArrayBuffer): Promise<ADTSObject> => { // 
        return new Promise<ADTSObject>((res, rej) => {
            const adts = AACAudio.parse(new Uint8Array(arrayBuffer));
            res(adts);
        });
    }

    static parseAacArrayBufferToADTSSync = (arrayBuffer: ArrayBuffer): ADTSObject => {
        return AACAudio.parse(new Uint8Array(arrayBuffer));
    }

    static copyIntoAudioBuffer = (targetAudioBuffer: AudioBuffer, sourceAudioBuffer: AudioBuffer, pos: number): Promise<boolean> => {
        return new Promise((res, rej) => {
            const tempBuffer = new Float32Array(sourceAudioBuffer.length);
            sourceAudioBuffer.copyFromChannel(tempBuffer, 0, 0);
            targetAudioBuffer.copyToChannel(tempBuffer, 0, pos);
            const channelCount = sourceAudioBuffer.numberOfChannels;
            if (channelCount > 1) {
                sourceAudioBuffer.copyFromChannel(tempBuffer, 1, 0);
                targetAudioBuffer.copyToChannel(tempBuffer, 1, pos);
            }
            res(true);
        });
    }

    static decodeAacFramesToAudioBuffer = (arrayBuffer: ArrayBuffer, adts: ADTSObject, firstFrame: number, nrOfFrames: number): Promise<AudioBuffer | null> => {
        if (firstFrame >= adts.frames.length - 1) {
            return Promise.resolve(null);
        }
        const lastFrame: number = Math.min(firstFrame + nrOfFrames, adts.frames.length - 1);
        const frameStart = adts.frames[firstFrame].frameStart;
        const frameEnd = adts.frames[lastFrame].frameEnd;
        const arrayBufferSection = arrayBuffer.slice(frameStart, frameEnd);
        return WebAudio.decodeArrayBuffer(arrayBufferSection);
    }

    static parse(source: Uint8Array, cursor = 0): ADTSObject {
        return AACAudio.parseAdtsFrames(source, cursor, 0);
    }

    static parseFirstFrame(source: Uint8Array, cursor = 0): ADTSObject {
        return AACAudio.parseAdtsFrames(source, cursor, 1);
    }

    static parseAdtsHeader(source: Uint8Array, cursor = 0): FrameHeader {
        const frameStart = cursor;
        const byte0: number = source[cursor];
        const byte1: number = source[cursor + 1];
        const byte2: number = source[cursor + 2];
        const byte3: number = source[cursor + 3];
        const byte4: number = source[cursor + 4];
        const byte5: number = source[cursor + 5];
        const byte6: number = source[cursor + 6];
        const byte7: number = source[cursor + 7];
        const byte8: number = source[cursor + 8];

        const syncword: boolean = (byte0 == 0xFF) && ((byte1 & 0xF0) == 0xF0);
        const mpegVersion: number = (byte1 & 0x08) == 1 ? 2 : 4;
        const layer: boolean = (byte1 & 0x06) == 0 ? true : false;
        const crcProtection: boolean = (byte1 & 0x01) == 0 ? true : false;
        const audioObjectType: number = ((byte2 & 0xC0) >> 6) + 1;
        const samplingRate: number = AACAudio.SAMPLING_RATES[(byte2 & 0x3C) >> 2];
        const channels: number = AACAudio.CHANNELS[((byte2 & 0x1) << 2 | byte3 & 0xC0) >> 6];

        const adtsFrameLength: number = (byte3 & 0x03) << 11 | byte4 << 3 | (byte5 & 0xe0) >> 5;
        const bufferFullness: number = (byte5 & 0x1f) << 6 | byte6 >> 2;
        const rdbsInFrame: number = (byte6 & 0x03);
        const adtsHeaderLength = 7;
        const crcLength: number = crcProtection ? 2 : 0;
        const rawDataBlockStart: number = cursor + adtsHeaderLength;
        const rawDataBlockEnd: number = cursor + adtsFrameLength;
        const crc1: number = crcProtection ? (((byte7 << 8) | byte8) >>> 0) : 0;
        const crc2: number = crc1;
        let error = false;

        if (!syncword || !layer || audioObjectType != 2) { // 2 = AAC-LC
            error = true;
        }
        if (crc1 != crc2) {
            error = true;
        }

        return {
            frameStart: frameStart,
            frameEnd: frameStart + adtsFrameLength,
            mpegVersion: mpegVersion,
            crcProtection: crcProtection,
            audioObjectType: audioObjectType,
            samplingRate: samplingRate,
            channels: channels,
            adtsFrameLength: adtsFrameLength,
            adtsHeaderLength: adtsHeaderLength,
            crcLength: crcLength,
            rawDataBlockStart: rawDataBlockStart,
            rawDataBlockEnd: rawDataBlockEnd,
            bufferFullness: bufferFullness,
            rdbsInFrame: rdbsInFrame,
            error: error,
        };
    }

    static parseAdtsFrames = (source: Uint8Array, cursor: number, maxFrames: number): ADTSObject => {
        // trace('parseAdtsFrames');
        // trace(source.length);
        const view = { source: source, cursor: cursor };
        const sourceLimit: number = view.source.length;
        // trace('sourceLimit: ' + sourceLimit);
        const frames = []; // ADTSFrameObjectArray - [ADTSFrame, ...]
        const rawDataBlocks = [];
        let headerBytes = 0;
        let rawDataBytes = 0;
        let errorBytes = 0;
        let contamination = false;
        let mpegVersion = 0;
        let audioObjectType = 0;
        let samplingRate = 0;
        let channels = 0;

        // --- contamination check ---
        let latest_mpegVersion = 0;
        let latest_audioObjectType: number | null = null;
        let latest_channels = 0.0;
        let latest_samplingRate = 0;

        while (view.cursor < sourceLimit) {
            const header: FrameHeader = AACAudio.parseAdtsHeader(view.source, view.cursor);
            if (header.error) {
                view.cursor++; // skip unknown byte
                errorBytes++;
            } else {
                mpegVersion = header.mpegVersion;
                audioObjectType = header.audioObjectType;
                channels = header.channels;
                samplingRate = header.samplingRate;

                if (latest_mpegVersion != null && latest_mpegVersion != mpegVersion) {
                    // trace("MPEG Version unmatched", mpegVersion, latest_mpegVersion);
                    contamination = true;
                }
                if (latest_audioObjectType != null && latest_audioObjectType != audioObjectType) {
                    // trace("Audio Object Type unmatched", audioObjectType, latest_audioObjectType);
                    contamination = true;
                }
                if (latest_channels != null && latest_channels != channels) {
                    // trace("Channels unmatched", channels, latest_channels);
                    contamination = true;
                }
                if (latest_samplingRate != null && latest_samplingRate != samplingRate) {
                    // trace("Sampling Rate unmatched", samplingRate, latest_samplingRate);
                    contamination = true;
                }

                latest_mpegVersion = mpegVersion;
                latest_audioObjectType = audioObjectType;
                latest_channels = channels;
                latest_samplingRate = samplingRate;

                // store begin/end position pair.
                rawDataBlocks.push(header.rawDataBlockStart);
                rawDataBlocks.push(header.rawDataBlockEnd);

                headerBytes += header.adtsHeaderLength + header.crcLength;
                rawDataBytes += header.rawDataBlockEnd + header.rawDataBlockStart;
                view.cursor += header.adtsFrameLength;
                frames.push(header);
            }
            if (maxFrames > 0 && frames.length >= maxFrames) {
                break;
            }
        }

        const durations: DurationInfo = AACAudio.calcDurations(frames.length, frames[0].samplingRate);

        const adtsObject: ADTSObject = {
            samplingRate: frames[0].samplingRate,
            durations: durations,
            duration: durations.estimatedDuration,
            channels: frames[0].channels,
            frames: frames,
            rawDataBlocks: rawDataBlocks,
            headerBytes: headerBytes,
            rawDataBytes: rawDataBytes,
            errorBytes: errorBytes,
            contamination: contamination,
        }
        return adtsObject;
    }


    static calcDurations = (frameLength: number, samplingRate: number): DurationInfo => {
        const secondsPerSample = (1 / samplingRate); // (1 / 44100) = 0.00002267573696
        const estimatedDuration = secondsPerSample * 1024 * frameLength;
        const primingSamples = 2112;
        const remainderSamples = 0; // = Math.max(1024, 1024 - ((primingSamples + actualSamples) % 1024))

        return {
            samplingRate: samplingRate,
            frameLength: frameLength,
            primingSamples: primingSamples,
            primingDuration: primingSamples * secondsPerSample,
            remainderSamples: remainderSamples,
            remainderDuration: remainderSamples * secondsPerSample,
            estimatedDuration: estimatedDuration,
        };
    }

    static SAMPLING_RATES: Record<number, number> = {
        0: 96000,  // `0000` - 96000 Hz
        1: 88200,  // `0001` - 88200 Hz
        2: 64000,  // `0010` - 64000 Hz
        3: 48000,  // `0011` - 48000 Hz
        4: 44100,  // `0100` - 44100 Hz
        5: 32000,  // `0101` - 32000 Hz
        6: 24000,  // `0110` - 24000 Hz
        7: 22050,  // `0111` - 22050 Hz
        8: 16000,  // `1000` - 16000 Hz
        9: 12000,  // `1001` - 12000 Hz
        10: 11025,  // `1010` - 11025 Hz
        11: 8000,  // `1011` - 8000 Hz
        12: 7350,  // `1100` - 7350 Hz
        13: 0,  // `1101` - Reserved
        14: 0,  // `1110` - Reserved
        15: 0,  // `1111` - other
    };

    static CHANNELS: Record<number, number> = {
        0: 0.0,
        1: 1.0,    //   1 ch - (Front:       center)
        2: 2.0,    //   2 ch - (Front: left,         right)
        3: 3.0,    //   3 ch - (Front: left, center, right)
        4: 4.0,    //   4 ch - (Front: left, center, right)                   (Rear:       center)
        5: 5.0,    //   5 ch - (Front: left, center, right)                   (Rear: left,        right)
        6: 5.1,    // 5.1 ch - (Front: left, center, right)                   (Rear: left,        right, subwoofer)
        7: 7.1,    // 7.1 ch - (Front: left, center, right)(Side: left, right)(Rear: left,        right, subwoofer)
    };

}


export type ADTSObject = {
    samplingRate: number,
    durations: DurationInfo,
    duration: number,
    channels: number,
    frames: Array<Frame>,
    rawDataBlocks: Array<number>,
    headerBytes: number,
    rawDataBytes: number,
    errorBytes: number,
    contamination: boolean,
}

export type DurationInfo = {
    samplingRate: number,
    frameLength: number,
    primingSamples: number,
    primingDuration: number,
    remainderSamples: number,
    remainderDuration: number,
    estimatedDuration: number,
}

export type Frame = any;

export type FrameHeader = {
    frameStart: number,
    frameEnd: number,
    mpegVersion: number,
    crcProtection: boolean,
    audioObjectType: number,
    samplingRate: number,
    channels: number,
    adtsFrameLength: number,
    adtsHeaderLength: number,
    crcLength: number,
    rawDataBlockStart: number,
    rawDataBlockEnd: number,
    bufferFullness: number,
    rdbsInFrame: number,
    error: boolean,
}