
import { Recording } from "./wav/WavPublicApi";
import { WebAudioOptions } from "./audio/WebAudio";
import { PlaybackPosition, PlaybackStatus } from "./audio/engine/EngineTypes";
import { SvgRenderingStatus } from "./grid/gridExportUtils";
import { ScrollAnimationData } from "./store/AnimationScrollStore";
import { DisplayModeState, UIDisplayModes } from "./store/DisplayStore";
import { DocumentStatus, PagesLayoutBase, SpreadLayoutStatus } from "./store/DocumentStore";
import { ArrangementPlayModes, PlayModeDetailsStatus } from "./store/PlayModeStore";
import { RecordingsStatus } from "./store/RecordingsStore";

export interface PlayerProps {
    options: PlayerOptions;
    className?: string;
    onError?: (error?: unknown) => void;
    onPlayModesData?: (playModesData: ArrangementPlayModes) => void;
    onRecordingsStatus?: (recordingsStatus: RecordingsStatus) => void;
    onPlayModeDetailsStatus?: (status: PlayModeDetailsStatus) => void;
    onAvailableDisplayModes?: (displayModes: UIDisplayModes) => void;
    onSpreadNavigationStatus?: (status: SpreadLayoutStatus) => void;
    onDocumentOverlayStatus?: (status: DocumentStatus) => void;
    onPlaybackStatus?: (status: PlaybackStatus) => void;
    onPlaybackPosition?: (info: PlaybackPosition) => void;
    onRecordingsChange?: (recordings: Array<Recording>) => void;
    onZoomChange?: (zoomInfo: ZoomInformation) => void;
    onPlayerCreated?: (
        graphicElement: HTMLElement,
        videoElement: HTMLElement
    ) => void;
    onGridInfo?: (gridInfo: GridTimeDetails) => void;
    onGridAction?: (gridItemAction: GridItemAction) => void;
    onScrollAnimationCreated?: (scrollAnimationData: ScrollAnimationData) => void;
    onGraphicViewportScroll?: (scroll: { top: number; left: number }) => void;
    onScrollActiveChange?: (active: boolean) => void;
    onSyncDataChange?: (syncData: TimeSyncAndBarJumps) => void;

    onSvgStatus?: (status: SvgRenderingStatus) => void;
    onPreventScrollingChange?: (prevent: boolean) => void;
}

const SCROLL_ANIMATION_REBUILD_TIMEOUT = 1000;

export interface IPlayerComponent {
    setup(playerElement: HTMLElement): void;
    teardown(): void;
    loadArrangement(arrangementId: string): Promise<void>;
    setDisplayMode(displayMode: DisplayModeState): void;
    getPlaymodeId(): string | null;
    getPlaymodeDetails(): PlayModeDetailsStatus | null;
    getGridExportData(orientation: PageOrientation): GridExportItem[];
    playbackStop(): void;
    playbackStart(): void;
    playbackSetTime(time: number): void;
    playbackScrub(time: number): void;
    playbackScrubReady(): void;
    setSvgMode(use: boolean): void;
    forceRedraw(): void;
    forceReloadPlaymode(): void;
    getSyncData(): { timeSync: TimeSyncData, barJumps: BarJumpsData };
    setPreventScrolling(prevent: boolean): void;
    getPreventScrolling(): boolean;
}



export type ReducedTimemapItem = {
    noteRestId: string;
    measureId: string;
    measureOff: string;
    qstamp: number;
    tstamp: number;
    tstampDuration: number;
}

export type GridMouseActionType = 'click' | 'mousemove' | 'mouseout';

export type GridItem1Details = {
    leftPos: number,
    rightPos: number,
    originalLeftPos: number,
    item: GridVeroivioItem1,
    itemId: string,
    jumpPass: number,
    jumpIdx: number,
    leftTstampPos: number,
    rightTstampPos: number,
}


export type BarJumpsFromJson = {
    title: string,
    useBarJumpsFix?: boolean,
    barJumps: Array<{ from: number, to: number }>,
    id: string,
    comment?: string,
    useTimeSyncFix?: boolean,
    timeSync?: Array<{
        scorePos: number,
        mediaPos: number,
    }>,
}

export type GridVeroivioItem1 = {
    id: string,
    x: number,
    y: number,
    w: number,
    h: number,
    qstamp: number,
    tstamp: number,
    tstampDuration: number,
    // tstampPosition: number,
    idx: number,
    pageIdx: number,
    systemIdx: number,
    pageX: number,
    pageY: number,
    pageW: number,
    pageH: number,
    inDocument: boolean,
    itemEl: HTMLElement | null,
}

export type GridVerovioInfo1 = {
    items: Array<GridVeroivioItem1>;
}


export type Grid1ItemsWithCoordinates = {
    items: Array<GridVeroivioItem1>,
    layout: PagesLayoutBase,
}

export type GridTimeDetails = { // layouted grid
    items: Array<GridVeroivioItem1>,
    itemDetails: Array<GridItem1Details>,
    barJumps: BarJumpsData,
    timeSync: TimeSyncData,
    totalTstampDuration: number,
}

export type ErrorHandler = (error?: unknown) => void;

export enum PlayerOptimizationType {
    SvgToImage = 'SvgToImage',
    SvgToCanvas = 'SvgToCanvas',
    DisplayOptimizationInfo = 'DisplayOptimizationInfo'
}

export type PlayerOptions = WebAudioOptions & {
    apiToken: string;
    apiUrl: string;
    apiVersion?: string;
    preview?: boolean;
    devMode?: boolean;
    optimization?: Array<PlayerOptimizationType>;
    useXmlLegacy?: boolean;
};

export type ZoomInformation = {
    zoomValue: number;
    isZoomable: boolean;
};

export type GridItemAction = {
    event: MouseEvent;
    itemId: string;
    action: GridMouseActionType;
    timePos: number;
    tstampPos: number;
    foundItem: GridItem1Details;
    itemIdx: number;
    itemJumpIdx: number;
};




export type SvgSystemData = {
    pageIdx: number,
    systemIdx: number,
    systemNr: number,
    x: number,
    y: number,
    y2: number,
    x2: number,
}

export type SvgMeasureData = {
    pageIdx: number,
    systemIdx: number,
    systemNr: number,
    measureIdx: number,
    measureNr: number,
    x: number,
    y: number,
    x2: number,
    y2: number,
}

export type SvgNoteData = {
    measureNr: number,
    x: number, y: number,
    isPause: boolean,
}

export type SvgPageData = {
    pageIdx: number,
    w: number,
    h: number,
}

export type VerovioSvgData = SvgPageData | SvgSystemData | SvgMeasureData | SvgNoteData;
export type TimeSyncData = Array<{ scorePos: number, mediaPos: number }>;
export type BarJumpsData = Array<{ from: number, to: number }>;

export type TimeSyncAndBarJumps = {
    timeSync: TimeSyncData,
    barJumps: BarJumpsData,
    nrOfBars?: number,
    nrOfLayoutBars?: number,
}

export type ScorxGridItem = {
    x: number,
    w: number,
    top: number,
    y: number,
    h: number,
    bottom: number,
    system: boolean,
    pos: number,
    pageNr: number,
}

export type ScorxPageItem = {
    index: number, url: string
}

export type XmlSvgInfo = {
    url: string,
    page: number,
    layout: PageOrientation,
    width?: number,
    height?: number,
}

export type XmlSyncInfo = {
    url: string,
    page: number,
    layout: PageOrientation
}

export type PageOrientation = 'Vertical' | 'Horizontal';

export type GridExportItem = {
    idx: number,
    pageIdx: number,
    systemIdx: number,
    rootIdx: number,
    x: number,
    y: number,
    w: number,
    h: number,
    mediaSyncTime?: number,
    mediaStartTime: number,
    mediaEndTime: number,
    scoreStartTime: number,
    scoreEndTime: number,
}

export type GridDocumentItem = GridExportItem & {
    inDocument: boolean;
    pageX: number;
    pageY: number;
    pageW: number;
    pageH: number;
    itemEl: HTMLElement;
}


export type WAVExtendedTimeMapEntry = TimeMapEntry & {
    measureOn?: string;
    measureOff?: string;
    restsOn?: Array<string>;
    restsOff?: Array<string>;
    measureTDuration: number;
    measureQDuration: number;
};

export interface TimeMapEntry {
    tstamp: number;
    qstamp: number;
    on?: string[];
    off?: string[];
    tempo?: number;
}

export type VerovioRenderData = {
    svgs: Array<ExportSvgData>,
    timeMapData: VerovioTimemapData,
}

export type ExportSvgData = {
    svg: string, width: number, height: number,
}

export type ExportData = {
    portraitSvgs: Array<ExportSvgData>,
    portraitSync: GridExportItem[],
    landscapeSvgs: Array<ExportSvgData>,
    landscapeSync: GridExportItem[],
}

export type VerovioTimemapData = Array<WAVExtendedTimeMapEntry>;
export type VerovioSyncData = {
    svgObjectsMap: Map<string, VerovioSvgData>,
    timemapData: VerovioTimemapData,
}
