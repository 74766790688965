

export default class XmlElement {
    public xml?: Element;
    public nodeName?: string;
    protected children?: Array<XmlElement>;
    public pcData?: ChildNode;

    constructor() {
        //
    }

    public setup(xml: Element): XmlElement {
        this.xml = xml;
        this.children = [];
        this.nodeName = this.xml.nodeName as string;
        this.pcData = this.xml.firstChild as ChildNode;
        // console.log('new XmlElements', this.nodeName, this.pcData);

        for (const element of this.xml.children) {
            const childElement: XmlElement = this.createChild(element, element.nodeName);
            this.children.push(childElement);
        }
        this.afterChildrenCreated();
        this.xml.replaceChildren('');
        // this.print();
        return this;
    }

    public rebuild = (): Element => {
        if (this.pcData) {
            this.xml?.appendChild(this.pcData);
        }
        for (const child of this.children as Array<XmlElement>) {
            child.rebuild();
            this.xml?.appendChild(child.xml as Element);
        }
        // this.print();
        return this.xml as Element;
    }

    public setPcData = (v: string) => {
        const doc = new DOMParser().parseFromString(`<x>${v}</x>`, 'application/xml');
        this.pcData = doc.firstElementChild?.firstChild as ChildNode;
        console.log('setPcData', this.pcData);
    }

    public createChild(xml: Element, nodeName: string): XmlElement {
        // console.log('XmlElement.createChild');
        return this.createElement(xml);
    }

    protected createElement(xml: Element): XmlElement {
        return new XmlElement().setup(xml);
    }

    assertNodeName = (nodeName: string): void => {
        // console.log(this.nodeName, nodeName);
        if (this.nodeName !== nodeName) {
            throw 'Node name "' + this.nodeName + '" not accepted - "' + nodeName + '" is required';
        }
    }

    public afterChildrenCreated() {
        //
    }

    public rebuildAsString(): string {
        return new XMLSerializer().serializeToString(this.rebuild() as Element);
    }

    public getChildFromNodename = (name: string): XmlElement | null => {
        for (const element of this.children!) {
            if (element.nodeName === name) {
                return element;
            }
        }
        return null;
    }

    public removeXmlElement(nodeName: string) {
        setTimeout(() => {
            const nodes = (this.xml?.getElementsByTagName(nodeName));
            const node = nodes!.item(0);
            if (node)
                node.parentNode?.removeChild(node);
            const nodes2 = (this.xml?.getElementsByTagName(nodeName));
            this.children = this.children?.filter(child => child.nodeName != nodeName);
        }, 0);

    }
}


