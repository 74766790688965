import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Dispatch, RootState } from '../../store';
import ArrangementForm from '../tabs/arrangement/ArrangementForm';
import Help from '../tabs/help/Help';
import Home from '../tabs/home/Home';
import InterestedParties from '../tabs/interestedparties/InterestedParties';
import Metadata from '../tabs/metadata/Metadata';
import PlayerSync from '../tabs/playerSync/PlayerSync';
import Playmodes from '../tabs/playmodes/Playmodes';
import Publish from '../tabs/publish/Publish';
import Recordings from '../tabs/recordings/Recordings';
import Reports from '../tabs/reports/Reports';

const Main = () => {
  const dispatch = useDispatch<Dispatch>();
  const tabs = useSelector((state: RootState) => state.tabs);
  const arrState = useSelector((state: RootState) => state.arrangement);
  const arrVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );

  useEffect(() => {
    dispatch.tabs.setTabEnabled('reports', !!userOrganization);
    dispatch.tabs.setTabEnabled(
      'arrangement',
      arrState.type === 'arrangement' ||
      arrState.type === 'creating' ||
      arrState.type === 'form' ||
      arrState.type === 'loading'
    );
    dispatch.tabs.setTabEnabled('recordings', arrState.type === 'arrangement');
    dispatch.tabs.setTabEnabled('playmodes', arrState.type === 'arrangement');
    dispatch.tabs.setTabEnabled(
      'interestedParties',
      arrState.type === 'arrangement'
    );
    dispatch.tabs.setTabEnabled('metadata', arrState.type === 'arrangement');
    dispatch.tabs.setTabEnabled('sync', arrState.type === 'arrangement');
    dispatch.tabs.setTabEnabled('publish', arrState.type === 'arrangement');
  }, [arrState.type, dispatch.tabs, userOrganization]);
  return (
    <main className="Flex FlexColumn AlignStretch JustifyStretch">
      <Tabs
        focusTabOnClick={false}
        selectedIndex={tabs.selectedIndex}
        onSelect={(selectedIndex) => {
          dispatch.tabs.setSelectedIndex(selectedIndex);
        }}
      >
        <TabList>
          {tabs.items.map((t) => (
            <Tab key={t.type} disabled={!t.enabled}>
              {t.name}
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <Home />
        </TabPanel>
        <TabPanel>
          <Reports />
        </TabPanel>
        <TabPanel>
          <ArrangementForm />
        </TabPanel>
        <TabPanel>
          <Recordings />
        </TabPanel>
        <TabPanel>
          <Playmodes />
        </TabPanel>
        <TabPanel>
          <InterestedParties />
        </TabPanel>
        <TabPanel>
          <Metadata />
        </TabPanel>
        <TabPanel>
          {arrState.type === 'arrangement' && (
            <PlayerSync
              arrangementId={arrState.id}
              versionId={arrVersion?.id ?? ''}
              status={arrVersion?.status ?? ''}
            />
          )}
        </TabPanel>
        <TabPanel>
          <Publish />
        </TabPanel>
        <TabPanel>
          <Help />
        </TabPanel>
      </Tabs>
    </main>
  );
};

export default Main;
