import Signal from "../data/Signal";
import GraphicElementStore from "./GraphicElementStore";
import { GridVerovioInfo1, GridTimeDetails } from "../playerTypes";
import { round2, round5 } from "../tools/NumberTools";
export default class AnimationPlayheadStore {
    readonly showAnimattionSignal = new Signal<boolean>(true);

    private animation: Animation | null = null;

    public constructor(graphicElementStore: GraphicElementStore) {
        //
    }

    public keyframes: Array<Keyframe> = [];

    private duration = 0;
    private playheadElement: HTMLElement | null = null;

    public createAnimation = (gridInfo: GridVerovioInfo1, gridDetails: GridTimeDetails, duration: number, playheadElement: HTMLElement) => {

        this.duration = duration;
        this.playheadElement = playheadElement;
        this.playheadHide();
        // console.log('createAnimation', gridInfo.items, gridDetails.items, playheadElement);
        this.keyframes = [];
        const keyframesAdd = (kf: Keyframe) => {
            this.keyframes.push(kf);
        }

        gridDetails.itemDetails.forEach((detailedItem, itemIdx) => {
            const item = gridInfo.items[detailedItem.item.idx];
            if (!item.inDocument) return;

            if (itemIdx == 0 && detailedItem.leftPos > 0) {
                keyframesAdd({
                    left: item.pageX * 100 + '%',
                    top: item.pageY * 100 + '%',
                    height: item.pageH * 100 + '%',
                    offset: 0,
                });
            }

            const keyFrame = {
                left: item.pageX * 100 + '%',
                top: item.pageY * 100 + '%',
                height: item.pageH * 100 + '%',
                offset: detailedItem.leftPos,
            }

            keyframesAdd(keyFrame);

            if (keyFrame.offset < 1) {
                keyframesAdd({
                    left: (item.pageX + item.pageW) * 100 + '%',
                    top: item.pageY * 100 + '%',
                    height: item.pageH * 100 + '%',
                    offset: detailedItem.rightPos - 0.0001,
                });
            }
        })

        // add extra keyframe if still needed
        const lastKeyframe: Keyframe = this.keyframes[this.keyframes.length - 1];

        // filter out keframes with offset > 1
        this.keyframes = this.keyframes.filter(kf => kf.offset <= 1);

        if (lastKeyframe && lastKeyframe.offset < 1) {
            this.keyframes.push({ height: lastKeyframe.height, top: lastKeyframe.top, left: lastKeyframe.left, offset: 1 });
        }

        //------------------------------------------------
        // Fix for wrongordered keyframes in scorx songs
        let prevKeyframe: Keyframe | null = null;
        this.keyframes.forEach((keyframe, keyframeIdx) => {
            if (prevKeyframe) {
                if (prevKeyframe.offset >= keyframe.offset) {
                    if (keyframeIdx == this.keyframes.length - 1) {
                        keyframe.offset = 1;
                    } else {
                        keyframe.offset = prevKeyframe.offset;
                    }
                }
            }
            prevKeyframe = keyframe;
        });

        try {
            const options: KeyframeAnimationOptions = { duration: duration * 1000, fill: 'forwards' };
            this.animation = playheadElement.animate(this.keyframes, options);
            // this.animation.pause();
            this.animation.currentTime = 0;
            this.animation.pause();
            this.playheadShow();
        } catch (error) {
            console.log('Error', 'AnimationPlayheadStore', 'createAnimation', error);
        }
    }

    public updateAnimation = () => {
        //
    }

    public syncTimeFraction = (timeFraction: number) => {
        if (!this.animation) return;
        if (this.isScrubbing) return;
        // console.log('syncTimeFraction', timeFraction);
        this.animation.currentTime = timeFraction * this.duration * 1000;
    }

    public syncTime = (time: number) => {
        if (!this.animation) return;
        if (this.isScrubbing) return;
        // console.log('syncTime', time);
        this.animation.currentTime = time * 1000;
    }

    public play = () => {
        if (!this.animation) return;
        if (this.isScrubbing) return;
        this.animation.play();
    }

    public stop = () => {
        // console.log('playheadStop');
        if (!this.animation) return;
        this.animation.pause();
    }

    public playheadHide = () => {
        if (this.playheadElement)
            this.playheadElement.style.visibility = 'hidden';
    }

    public playheadShow = () => {
        if (this.playheadElement)
            this.playheadElement.style.visibility = 'visible';
    }

    public scrub = (time: number) => {
        if (!this.animation) return;
        this.isScrubbing = true;
        this.animation.pause();
        this.animation.currentTime = this.animation.currentTime = time * 1000;;
    }

    public scrubFraction = (timeFraction: number) => {
        if (!this.animation) return;
        this.isScrubbing = true;
        this.animation.pause();
        this.animation.currentTime = this.animation.currentTime = timeFraction * this.duration * 1000;;
    }

    public scrubReady = () => {
        this.isScrubbing = false;
    }

    private isScrubbing = false;

}

type Keyframe = {
    left: string,
    top: string,
    height: string,
    offset: number,
}