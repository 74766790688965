import React, { useEffect, useRef, useState } from 'react'
import css from './Player2Sync.module.css';

import cx from 'classnames';
import { BarJumpColors } from './Colors';
import "./BarJumpsEditor.css";

type TimeStamp = {
    scorePos: number,
    mediaPos: number,
}


const TimeSyncEditor = (props: any) => {

    const [scorePositions, setScorePositions] = useState<number[]>([]);
    const [mediaPositions, setMediaPositions] = useState<number[]>([]);
    const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
    const [minute, setMinute] = useState<number>(1);
    useEffect(() => {
        if (!props.playbackPosition) return;
        setMinute(60 / props.playbackPosition.duration);

    }, [props.playbackPosition]);


    useEffect(() => {
        if (!props.syncData) return;
        if (!props.syncData.timeSync) return;
        const timeSync = props.syncData.timeSync;
        console.log('timeSync', timeSync);

        setScorePositions(timeSync.map((ts: TimeStamp) => ts.scorePos));
        setMediaPositions(timeSync.map((ts: TimeStamp) => ts.mediaPos));
    }, [props.syncData])

    const [hoverItem, setHoveritem] = useState<any>(null);
    useEffect(() => {
        if (!props.gridAction) return;
        const item = props.gridAction.action != 'mouseout' ? props.gridAction : null;
        setHoveritem(item);
    }, [props.gridAction]);

    useEffect(() => {
        if (!props) return;
        if (!props.keyCommand) return;
        if (!props.keyCommand.keyCode) return;
        if (!props.playbackPosition) return;
        // console.log(props.keyCommand.keyCode);
        if (props.keyCommand.keyCode)
            switch (props.keyCommand.keyCode) {
                case 83:
                    if (props.playbackStatus.status != 'enginePlaying') {
                        console.log('player not running');
                        return;
                    }

                    const mediaPos = props.playbackPosition.time / props.playbackPosition.duration;
                    if (mediaPositions.indexOf(mediaPos) > -1) {
                        // console.log('mediaPosition already exists', mediaPos);
                        return;
                    }


                    const kc = props.keyCommand;
                    const scorePos = props.gridAction.tstampPos;
                    // const scorePos = props.gridAction.timePos;
                    let mediaPosIdx = 0;
                    // console.log(mediaPositions);
                    mediaPositions.forEach((pos: number, idx: number) => {
                        if (pos < mediaPos) {
                            mediaPosIdx++;
                        }
                    });
                    let scorePosIdx = 0;
                    scorePositions.forEach((pos, idx) => {
                        if (pos < scorePos) {
                            scorePosIdx++;
                        }
                    });

                    if (scorePosIdx !== mediaPosIdx) {
                        console.log('Can not create syncPoint');
                    } else {
                        mediaPositions.splice(mediaPosIdx, 0, mediaPos);
                        scorePositions.splice(mediaPosIdx, 0, scorePos);
                        setScorePositions(scorePositions);
                        setMediaPositions(mediaPositions);
                        updatePlayerTimeSync(scorePositions, mediaPositions);
                    }
                    break;
                case 32:
                    break;
                default:
                    console.log(props.keyCommand.keyCode, props.playbackPosition, props.playbackStatus);
            }
    }, [props.keyCommand]);


    const btnsScorePosition = scorePositions.length > 0 ? scorePositions.map((position: number, positionIdx: number) => {
        let className = '';
        switch (positionIdx % 3) {
            case 0: className = cx(css.TimeSyncItemButton, css.SyncItem0); break;
            case 1: className = cx(css.TimeSyncItemButton, css.SyncItem1); break;
            case 2: className = cx(css.TimeSyncItemButton, css.SyncItem2); break;
        }
        const selected = { border: 'white 2px solid' };
        return <button
            className={className}
            key={position}
            style={{ border: positionIdx === selectedIdx ? '2px solid white' : '0px', left: (position * 0.979) * 100 + '%' }}
            onClick={e => {
                positionIdx === selectedIdx ? setSelectedIdx(null) : setSelectedIdx(positionIdx);
            }}
        >{positionIdx}</button>
    }) : [];

    const btnsMediaPosition = mediaPositions.length > 0 ? mediaPositions.map((position, positionIdx) => {
        let className = '';
        switch (positionIdx % 3) {
            case 0: className = cx(css.TimeSyncItemButton, css.SyncItem0); break;
            case 1: className = cx(css.TimeSyncItemButton, css.SyncItem1); break;
            case 2: className = cx(css.TimeSyncItemButton, css.SyncItem2); break;
        }
        return <button
            className={className}
            key={position}
            style={{ border: positionIdx === selectedIdx ? '2px solid white' : '0px', left: (position * 0.979) * 100 + '%' }}
            onClick={e => {
                positionIdx === selectedIdx ? setSelectedIdx(null) : setSelectedIdx(positionIdx);
            }}
        >{positionIdx}</button>
    }) : [];

    const hoverDisplay = hoverItem ? <div style={{ left: hoverItem.tstampPos * 100 + '%' }} className={css.HoverDisplay}></div> : null;

    const itemDisplay = hoverItem ? <div className={css.ItemDisplay} style={{ left: hoverItem.tstampPos * 100 + '%', background: BarJumpColors[hoverItem.foundItem.jumpPass % 5] }}>{hoverItem.itemIdx}</div> : null;


    const updatePlayerTimeSync = (_scorePositions: number[], _mediaPositions: number[]) => {
        const timeSync = _mediaPositions.map((mv, idx) => {
            return { mediaPos: _mediaPositions[idx], scorePos: _scorePositions[idx] };
        });
        console.log(props.playerComponent);
        props.playerComponent.syncSetTimeSync(timeSync);
    }

    const functionButtons = selectedIdx !== null ? [
        <button
            className="small alert"
            key={selectedIdx}
            onClick={e => {
                if (e.ctrlKey) {
                    if (window.confirm('Delete all syncpoints?')) {
                        setScorePositions([]);
                        setMediaPositions([]);
                        setSelectedIdx(null);
                        updatePlayerTimeSync([], []);
                    }
                } else {
                    const s = [...scorePositions];
                    const m = [...mediaPositions];
                    s.splice(selectedIdx, 1);
                    m.splice(selectedIdx, 1);
                    setScorePositions(s);
                    setMediaPositions(m);
                    setSelectedIdx(null);
                    updatePlayerTimeSync(s, m);

                }
            }}> Delete syncpoint</button >

    ] : null;

    const second = minute / 60;
    const showNudge = selectedIdx !== null;

    return <div className="Flex FlexColumn" style={{ padding: '0 0 0.5rem 0' }}>
        <div className={css.TimeSyncTimelineButtons}>
            {btnsScorePosition}
            {itemDisplay}
        </div>

        {showNudge && <div className="Grid3ColsX Pad0-05"  >
            <div />
            <div className="Flex">
                <div>Score</div>
                <div>
                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = scorePositions[selectedIdx] + second;
                        scorePositions[selectedIdx] = Math.min(1, Math.max(0, scorePositions[selectedIdx] - second));
                        setMediaPositions([...scorePositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudgeLeft">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
                            <g transform="matrix(1,0,0,1,-869.444,-298.887)">
                                <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,2087.71,937.798)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                                <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,1936.92,937.798)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>

                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = scorePositions[selectedIdx] + second;
                        scorePositions[selectedIdx] = Math.min(1, Math.max(0, scorePositions[selectedIdx] - (second / 10)));
                        setMediaPositions([...scorePositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudge">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
                            <g transform="matrix(1,0,0,1,-1020.24,-298.887)">
                                <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,2087.71,937.798)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>

                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = scorePositions[selectedIdx] + second;
                        scorePositions[selectedIdx] = Math.min(1, Math.max(0, scorePositions[selectedIdx] + (second / 10)));
                        setMediaPositions([...scorePositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudge">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
                            <g transform="matrix(1,0,0,1,-869.444,-298.887)">
                                <g transform="matrix(1.03429,0,0,1.05969,-20.425,-34.4239)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>

                    </button>

                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = scorePositions[selectedIdx] + second;
                        scorePositions[selectedIdx] = Math.min(1, Math.max(0, scorePositions[selectedIdx] + second));
                        setMediaPositions([...scorePositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudge nudgeRight">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1"  >
                            <g transform="matrix(1,0,0,1,-869.444,-298.887)">
                                <g transform="matrix(1.03429,0,0,1.05969,-20.425,-34.4239)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                                <g transform="matrix(1.03429,0,0,1.05969,130.371,-34.4239)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            {functionButtons}
        </div>
        }

        <div className={css.TimeSyncTimelineButtons}>
            {btnsMediaPosition}
        </div>

        {showNudge && <div className="Grid3ColsX Pad0-05" >
            <div />
            <div className="Flex">
                <div>Audio</div>
                <div>
                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = mediaPositions[selectedIdx] + second;
                        mediaPositions[selectedIdx] = Math.min(1, Math.max(0, mediaPositions[selectedIdx] + second));
                        setMediaPositions([...mediaPositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudgeLeft">

                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
                            <g transform="matrix(1,0,0,1,-869.444,-298.887)">
                                <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,2087.71,937.798)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                                <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,1936.92,937.798)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>
                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = mediaPositions[selectedIdx] + second;
                        mediaPositions[selectedIdx] = Math.min(1, Math.max(0, mediaPositions[selectedIdx] + (second / 10)));
                        setMediaPositions([...mediaPositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudge">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
                            <g transform="matrix(1,0,0,1,-1020.24,-298.887)">
                                <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,2087.71,937.798)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>
                    <button onClick={e => {
                        if (selectedIdx === null) return;
                        const newPosition = mediaPositions[selectedIdx] + second;
                        mediaPositions[selectedIdx] = Math.min(1, Math.max(0, mediaPositions[selectedIdx] - (second / 10)));

                        setMediaPositions([...mediaPositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudge">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
                            <g transform="matrix(1,0,0,1,-869.444,-298.887)">
                                <g transform="matrix(1.03429,0,0,1.05969,-20.425,-34.4239)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>
                    <button onClick={() => {
                        if (selectedIdx === null) return;
                        const newPosition = mediaPositions[selectedIdx] + second;

                        mediaPositions[selectedIdx] = Math.min(1, Math.max(0, mediaPositions[selectedIdx] - second));
                        setMediaPositions([...mediaPositions]);
                        updatePlayerTimeSync([...scorePositions], [...mediaPositions]);
                    }} className="small nudge nudgeRight">
                        <svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1"  >
                            <g transform="matrix(1,0,0,1,-869.444,-298.887)">
                                <g transform="matrix(1.03429,0,0,1.05969,-20.425,-34.4239)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                                <g transform="matrix(1.03429,0,0,1.05969,130.371,-34.4239)">
                                    <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <div />
        </div>
        }
    </div >
}

export default TimeSyncEditor;


// const TimeSyncEditor = () => {
//     return <>
//         <p>TimeSyncEditor</p>
//     </>
// }

// export default TimeSyncEditor;


<svg width="1rem" height=".5rem" viewBox="0 0 243 306" version="1.1" >
    <g transform="matrix(1,0,0,1,-869.444,-298.887)">
        <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,2087.71,937.798)">
            <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
        </g>
        <g transform="matrix(-1.03429,-1.26664e-16,1.29775e-16,-1.05969,1936.92,937.798)">
            <path d="M868.003,359.086C857.643,348.725 857.849,332.103 868.465,321.991C879.08,311.878 896.11,312.08 906.471,322.441L1024.44,440.406C1034.63,450.596 1034.63,466.861 1024.44,477.051L906.471,595.017C896.11,605.378 879.08,605.579 868.465,595.467C857.849,585.354 857.643,568.732 868.003,558.371L967.646,458.729L868.003,359.086Z" />
        </g>
    </g>
</svg>


