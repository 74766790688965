import { useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { useDispatch } from "react-redux";
import PlayerPreviewComponent from "../../../player/PlayerPreviewComponent";
import { useRef } from "react";
import VerovioStore from "../../../player/store/VerovioStore";
import Button from "../../../components/Button";

const verovioStore = new VerovioStore((error) => console.error(error));

export default function XmlPreview(props: { close?: () => void, xml: string }) {
    // const xmlPreview = useSelector((state: RootState) => state.xmlPreview);
    const dispatch = useDispatch<Dispatch>();
    const playerComponent = useRef<PlayerPreviewComponent>(null);
    console.log('close', props.close);

    return <div className="FlexGap0 Flex1 FlexColumn BGLightGray BD1Gray H100 Gap0">
        <header className="Flex Flexrow JustifyBetween"><h3>MusicXml Preview</h3>

            <button onClick={() => {
                console.log(props.close);
                if (props.close) props.close();
            }}>Close</button>
        </header>
        <div className="Flex1" style={{ position: 'relative' }}>
            <PlayerPreviewComponent
                className="PlayerComponent"
                ref={playerComponent}
                verovioStore={verovioStore}
                xml={props.xml as string} />
        </div>
    </div>
}