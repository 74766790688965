import React from 'react';
import classNames from 'classnames';

type Props = {
  onClick?: () => void;
  primary?: boolean;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
};

export default function Button({
  onClick,
  primary,
  style,
  children,
  className,
  disabled,
}: Props) {
  return (
    <button
      style={{
        ...style,
        backgroundColor: primary ? 'black' : '#EFEFEF',
        color: primary ? 'white' : 'black',
        fontFamily: 'inherit',
      }}
      className={classNames('Button', className, disabled && 'DisabledButton')}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
