import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ArrangementVersion } from '../api/ManagementApi';

export const arrangementVersion = createModel<RootModel>()({
  state: null as ArrangementVersion | null,
  reducers: {
    setState(state, newState: ArrangementVersion | null) {
      console.log('arrVersion', newState?.id);
      return newState;
    },
  },
});
