import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get } from '../api/Api';
import {
  ArrangementsResponseArrangement_2_3,
  ListResponse,
} from '../api/ManagementApi';
import { isSuperUserOrganization } from './superusers';

type ArrangementsStatus =
  | {
    status: 'empty';
  }
  | {
    status: 'loading';
  }
  | {
    status: 'loaded';
    items: Array<ArrangementsResponseArrangement_2_3>;
    total: number;
  }
  | {
    status: 'error';
    error: string;
  };

export const arrangements = createModel<RootModel>()({
  state: { status: 'empty' } as ArrangementsStatus,
  reducers: {
    setLoading(state) {
      return { status: 'loading' };
    },
    setLoaded(
      state,
      items: Array<ArrangementsResponseArrangement_2_3>,
      total: number
    ) {
      return { status: 'loaded', items, total };
    },
    setEmpty(state) {
      return { status: 'empty' };
    },
    setError(state, error: string) {
      return { status: 'error', error };
    },
  },
  effects: (dispatch) => ({
    async search(search: string, rootState) {
      if (!rootState.user.token) {
        return;
      }
      dispatch.arrangements.setLoading();
      let url = `/arrangements?search=${search}`;
      if (isSuperUserOrganization(rootState.userOrganization)) {
        if (!search) {
          dispatch.arrangements.setEmpty();
          return;
        }
      } else {
        // Fallback to GUID to ensure no arrangements are found
        url += `&organizationalUnitId=${rootState.userOrganization?.id ||
          'b9d30cf6-4c15-4cb0-bf5e-bef360ce2e1a'
          }`;
        // Include user arrangements (when not super user)
        url += `&includeUserArrangements=true`;
      }
      try {
        const data = (await get(
          url,
          rootState.user.token,
          {}
        )) as ListResponse<ArrangementsResponseArrangement_2_3>;
        dispatch.arrangements.setLoaded(data.items, data.total);
      } catch (e) {
        console.error(e);
        dispatch.arrangements.setError(`Failed to load arrangements: ${e}`);
      }
    },

    async kak(dummy: any, rootState) {
      if (!rootState.user.token) {
        return;
      }
      dispatch.arrangements.setLoading();
      const url = `/arrangements?organizationalUnitId=5b977901-b3e8-4f4c-881f-5f3c3ff7f5a7&limit=3000`;
      try {
        const data = (await get(
          url,
          rootState.user.token,
          {}
        )) as ListResponse<ArrangementsResponseArrangement_2_3>;
        dispatch.arrangements.setLoaded(data.items, data.total);
      } catch (e) {
        console.error(e);
        dispatch.arrangements.setError(`Failed to load arrangements: ${e}`);
      }

    }
  }),
});
