import { useSelector } from 'react-redux';
import StatusBadge from '../../../components/StatusBadge';
import { RootState } from '../../../store';
import ExistingPlaymodes from './ExistingPlaymodes';
import UploadGraphicFiles from './UploadGraphicFiles';

const Playmodes = () => {
  const arrangementVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const arrangementVersions = useSelector(
    (state: RootState) => state.arrangementVersions
  );
  const isLatest =
    arrangementVersions.versions[0]?.id === arrangementVersion?.id;
  if (!arrangementVersion) return null;
  return (
    <>
      {arrangementVersion.status !== 'Draft' && (
        <div className="Flex FlexColumn">
          <p>
            This arrangement version is{' '}
            <StatusBadge status={arrangementVersion?.status} />. You can not
            change graphic files and playmodes associated with an arrangement in
            this status. Create a Draft copy that can be safely edited.
          </p>
          {!isLatest && (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              This is NOT the latest version of this arrangement!
            </span>
          )}
        </div>
      )}
      {arrangementVersion.status === 'Draft' && <UploadGraphicFiles />}
      <ExistingPlaymodes />
    </>
  );
};

export default Playmodes;
