import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ArrangementCreate from './ArrangementCreate';
import ArrangementLoad from './ArrangementLoad';
import ArrangementSearch from './ArrangementSearch';
import OrganizationSelect from './OrganizationSelect';

const Home = () => {
  const user = useSelector((state: RootState) => state.user);
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );

  if (!user?.token) {
    return (
      <div className="Centered">
        <h1>Welcome, Guest</h1>
        <p>You need to log in to use this service.</p>
      </div>
    );
  }

  return (
    <div className="Flex FlexColumn AlignCenter">
      <div
        className="Flex FlexColumn"
        style={{ maxWidth: '40rem', minWidth: '30rem' }}
      >
        <OrganizationSelect />
        {userOrganization && (
          <>
            <ArrangementCreate />
            <ArrangementLoad />
            <ArrangementSearch />
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
