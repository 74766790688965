import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get, put } from '../api/Api';
import { ArrangementFeedbackReport, ListResponse } from '../api/ManagementApi';
import { isSuperUserOrganization } from './superusers';
import { OrganizationalUnit } from './userOrganization';

export type ReportsState =
  | {
    status: 'empty';
  }
  | {
    status: 'loading';
  }
  | {
    status: 'loaded';
    total: number;
    offset: number;
    limit: number;
    items: ArrangementFeedbackReport[];
    organizationId: string;
  }
  | {
    status: 'error';
    error: string;
  };

const DEFAULT_LIMIT = 50;

export const reports = createModel<RootModel>()({
  state: { status: 'empty' } as ReportsState,
  reducers: {
    setState(state, newState: ReportsState) {
      return newState;
    },
  },
  effects: (dispatch) => ({
    async load(
      payload: {
        organization: OrganizationalUnit | null;
        search?: string;
        offset?: number;
        limit?: number;
        status?: string;
      },
      rootState
    ) {
      if (
        !rootState.user.token ||
        !rootState.userOrganization ||
        !payload.organization
      ) {
        dispatch.reports.setState({ status: 'empty' });
        return;
      }
      if (
        rootState.reports.status === 'loaded' &&
        rootState.reports.organizationId === payload.organization.id &&
        !payload.offset &&
        !payload.limit &&
        !payload.search &&
        !payload.status
      ) {
        // Already loaded
        return;
      }
      dispatch.reports.setState({ status: 'loading' });
      let url = `/arrangements/feedback?limit=${payload.limit ?? DEFAULT_LIMIT
        }&offset=${payload.offset ?? 0}&search=${payload.search ?? ''}`;

      if (payload.status !== 'All') {
        url += `&status=${payload.status}`
      }


      if (!isSuperUserOrganization(payload.organization)) {
        url += `&organizationalUnitId=${payload.organization.id}`;
      }
      try {
        const data = (await get(
          url,
          rootState.user.token,
          {}
        )) as ListResponse<ArrangementFeedbackReport>;
        dispatch.reports.setState({
          status: 'loaded',
          items: data.items,
          total: data.total,
          limit: data.limit,
          offset: data.offset,
          organizationId: payload.organization.id,
        });
      } catch (e) {
        console.error(e);
        dispatch.reports.setState({
          status: 'error',
          error: `Failed to load reports: ${e}`,
        });
      }
    },
    async setItemStatus(payload: { id: string; status: string }, rootState) {
      if (!rootState.user.token || rootState.reports.status !== 'loaded') {
        return;
      }
      const newItems: ArrangementFeedbackReport[] = [];
      for (const item of rootState.reports.items) {
        if (item.id === payload.id) {
          newItems.push({
            ...item,
            status: payload.status,
          });
          continue;
        }
        newItems.push(item);
      }
      try {
        await put(
          `/arrangements/feedback/${payload.id}/status`,
          rootState.user.token,
          {
            status: payload.status,
          }
        );
        dispatch.reports.setState({
          ...rootState.reports,
          items: newItems,
        });
      } catch (e) {
        console.error(e);
      }
    },
  }),
});
