import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { useState, useEffect } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import ExistingInterestedParties from './ExistingInterestedParties';
import { InterestedParty } from '../../../api/ManagementApi';
import Button from '../../../components/Button';
import Card from '../../../components/Card';

const InterestedParties = () => {
  return (
    <>
      <h2>Add interested party</h2>
      <p>
        Add the interested parties who have contributed to the song and the
        arrangement, for example the composer and lyricist, the arranger and the
        producers of the xml and audio.
      </p>
      <p>These parties will then be searchable for users.</p>
      <p>Start by searching for the name in the database:</p>
      <IpSearchForm />
      <IpListResult />
      <ExistingInterestedParties />
    </>
  );
};

export default InterestedParties;

const IpListResult = () => {
  const dispatch = useDispatch<Dispatch>();
  const searchState = useSelector((state: RootState) => state.ipSearch);
  switch (searchState.type) {
    case 'empty':
      return null;
    case 'searching':
      return <p>searching...</p>;
    case 'result':
      const items = searchState.items.map((item, itemIdx) => (
        <Card yellow key={itemIdx}>
          <label>
            <p>
              <b>{item.name}</b>
            </p>
          </label>
          <AddButton item={item} dispatch={dispatch} />
        </Card>
      ));
      return (
        <div
          className="ItemList"
          style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
        >
          {items}
          <p>If name is not found:</p>
          <details>
            <summary>click here to create a new name</summary>
            <CreateName />
          </details>
        </div>
      );
  }
};

const IpSearchForm = () => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    const s = debouncedSearch.trim();
    if (s.length === 0) {
      dispatch.ipSearch.setStatus({ type: 'empty' });
      return;
    }
    const terms = [debouncedSearch.trim()];
    dispatch.ipSearch.searchForTerms({ terms });
  }, [debouncedSearch, dispatch.ipSearch]);

  return (
    <form
      style={{ padding: '.5rem 0' }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input
        type="search"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder="Search interested party name..."
      />
    </form>
  );
};

const AddButton = (props: { item: InterestedParty; dispatch: Dispatch }) => {
  const [type, setType] = useState('');
  return (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <select onChange={(e) => setType(e.target.value)}>
        <option value="">Select type</option>
        <option value="Composer">Composer</option>
        <option value="Arranger">Arranger</option>
        <option value="Lyricist">Lyricist</option>
        <option value="Translator">Translator</option>
        <option value="Artist">Artist</option>
        <option value="Other">Other</option>
      </select>
      <Button
        disabled={!type}
        onClick={() => {
          props.dispatch.existingInterestedParties.addInterestedParty({
            interestedPartyId: props.item.id,
            type: type,
          });
        }}
      >
        Add
      </Button>
    </div>
  );
};

const CreateName = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const dispatch = useDispatch<Dispatch>();
  return (
    <div className="Flex JustifyBetween Pad05 AlignEnd CreateNew">
      <label>
        <span>name</span>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name"
        />
      </label>
      <label>
        <span>description</span>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Add details as birth of year etc here"
        />
      </label>

      <label>
        <span>type</span>
        <select onChange={(e) => setType(e.target.value)}>
          <option value="">Select type</option>
          <option value="Composer">Composer</option>
          <option value="Arranger">Arranger</option>
          <option value="Lyricist">Lyricist</option>
          <option value="Translator">Translator</option>
          {/* <option value="Producer">Producer</option> */}
          <option value="Artist">Artist</option>
          <option value="Other">Other</option>
        </select>
      </label>
      <Button
        disabled={!type}
        onClick={() => {
          dispatch.existingInterestedParties.addNewInterestedPartyAs({
            name,
            description,
            type,
          });
        }}
      >
        Create new
      </Button>
    </div>
  );
};
