import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { isSuperUser } from '../../../models/superusers';
import { Dispatch, RootState } from '../../../store';
import EditPlaymode from './EditPlaymode';
import { Playmode } from '../../../models/playmodes';
import { useState } from 'react';
import { generateAndUploadSvgsAndSyncs } from '../../../models/playmodeUploads';
import { deleteSvgsAndSyncData } from '../../../player/grid/uploadSvgModeData';
import appsettings from '../../../appsettings.json';
import { delay } from '../../../player/tools/TimerTools';
import Modal from '../../modal/Modal';
import useModal from '../../../hooks/useModal';
import XmlPreview from '../xmlpreview/XmlPreview';
import { Menu, MenuItem, MenuButton, SubMenu, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { downloadBlob } from '../../../player/helpers/DownloadBlob';
import slug from 'slug';
import { PlayModeTypeData } from '../../../player/wav/WavPublicApi';
import { type } from 'os';

const ExistingPlaymodes = () => {

  const dispatch = useDispatch<Dispatch>();
  const playmodes = useSelector((state: RootState) => state.playmodes);
  const uploadState = useSelector((state: RootState) => state.playmodeUploads);
  const arrData = useSelector((state: RootState) => state.arrangement);
  const userData = useSelector((state: RootState) => state.user);
  if (!userData) return null;
  if (arrData.type !== 'arrangement') return null;

  return (
    <>
      <div className="Flex AlignCenter JustifyBetween">
        <h2>Playmodes</h2>
        <Button
          onClick={() => {
            dispatch.playmodes.load({});
          }}
          className="small"
        >
          Reload
        </Button>
      </div>
      <div className="Flex FlexColumn">
        {playmodes.items.map((item) => (
          <PlaymodeCard key={item.id} item={item} />
        ))}
      </div>
      {playmodes.loading && <p>Loading...</p>}
      {uploadState.msg && <p>{uploadState.msg}</p>}
    </>
  );
};

export default ExistingPlaymodes;

const PlaymodeCard = (props: { item: Playmode }) => {
  const arrData = useSelector((state: RootState) => state.arrangement);
  const userData = useSelector((state: RootState) => state.user);
  const arrVersion = useSelector((state: RootState) => state.arrangementVersion);
  if (!userData) return null;
  if (arrData.type !== 'arrangement') return null;

  return <Card >
    <PlaymodeCardInfo item={props.item} />
    <div className="Flex AlignCenter ">
      {props.item.playModeType === 'Xml' && ( // && isSuperUser(userData)
        <PlaymodeCardXmlFunctions item={props.item} />
      )}
      {props.item.playModeType === 'Pdf' && (
        <PlaymodeCardPdfFunctions item={props.item} />
      )}

    </div>
  </Card>
}

const PlaymodeCardInfo = (props: { item: Playmode }) => {
  const arrVersion = useSelector((state: RootState) => state.arrangementVersion);
  let svgsCount = 0;
  let syncsCount = 0;
  switch (props.item.typeData?.type) {
    case 'Xml':
      if (props.item.typeData.svgs)
        svgsCount = props.item.typeData.svgs.length;
      if (props.item.typeData.syncs)
        syncsCount = props.item.typeData.syncs.length;
      break;
    default:
  }

  return <div className="Flex FlexColumn">
    <div className="Flex AlignCenter">
      <EditPlaymode
        playmode={props.item}
        isDraft={arrVersion?.status === 'Draft'}
      />
      <div className="chip">
        <small>{props.item.playModeType}</small>
      </div>

      {props.item.playModeType === 'Xml' &&
        <>
          <div><small>Svgs: {svgsCount}</small></div>
          <div><small>Syncs: {syncsCount}</small></div>
        </>
      }
    </div>

    <div className="text secondary">
      <small>
        <pre>{props.item.id}</pre>
      </small>
    </div>
  </div>
}

const PlaymodeCardPdfFunctions = (props: { item: Playmode }) => {
  const arrData = useSelector((state: RootState) => state.arrangement);
  const arrVersion = useSelector((state: RootState) => state.arrangementVersion);
  const arrState = useSelector((state: RootState) => state.arrangement);
  const dispatch = useDispatch<Dispatch>();

  if (arrState.type !== 'arrangement') return null;
  // if (props.item.typeData!.type !== 'Pdf') return null;
  if (!props.item.typeData) return null;
  const typedata: any = props.item.typeData;
  const pdfUrl: string = typedata.pdfUrl;


  return <>
    <Menu menuButton={<MenuButton className="MenuBurger Flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 260" width="26"><g fill="none" stroke="#000" strokeWidth="49.336" strokeLinecap="round"><path d="M29.684 229.663H280.33M29.684 129.663H280.33M29.684 29.663H280.33" /></g></svg></MenuButton>}>
      <MenuItem onClick={async () => {
        if (!(arrData.type === 'arrangement')) return;
        const segments = pdfUrl.split('/');
        const filename = segments[segments.length - 1];
        const res = await fetch(pdfUrl);
        const blob: Blob = await res.blob();
        downloadBlob(blob, (filename + '.pdf'));
      }} >Download Pdf file</MenuItem>

      <MenuItem
        disabled={!(arrVersion?.status === 'Draft')}
        onClick={async () => {
          if (
            !window.confirm(
              `Do you really want to delete playmode ${props.item.playModeType}?`
            )
          )
            return;
          if (arrData.type !== 'arrangement') return;
          dispatch.playmodes.delete({
            id: props.item.id,
          });
        }} >Delete playmode</MenuItem>

    </Menu >
  </>
}


const PlaymodeCardXmlFunctions = (props: { item: Playmode }) => {
  const { isOpen, toggle, close } = useModal();
  const [xmlString, setXmlString] = useState('');
  const arrData = useSelector((state: RootState) => state.arrangement);
  const arrState = useSelector((state: RootState) => state.arrangement);
  const arrVersion = useSelector((state: RootState) => state.arrangementVersion);
  const userState = useSelector((state: RootState) => state.user);

  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useDispatch<Dispatch>();
  const [generatingSvgsSyncsStatus, setGeneratingSvgsSyncsStatus] = useState('');

  if (!userState) return null;
  if (arrState.type !== 'arrangement') return null;
  if (!arrVersion) return null;
  if (!props.item.typeData) return null;
  if (props.item.typeData.type !== 'Xml') return null;

  console.log('CLOSE', close);

  const btnGenerate = <Button onClick={async () => {
    await generateAndUploadSvgsAndSyncs(userState, arrState, arrVersion, props.item, setGeneratingSvgsSyncsStatus);
    await delay(500);
    dispatch.playmodes.load({});
  }}>Recreate Svgs and Syncs</Button>


  const btnDelete = <Button onClick={async () => {
    const response = await deleteSvgsAndSyncData(
      appsettings.API_URL,
      arrState.id,
      arrVersion.id,
      props.item.id,
      userState.token!
    );
    console.log(response);
    await delay(500);
    dispatch.playmodes.load({});
  }}>Delete Svgs and Syncs</Button>;

  // const btnPreview = <>
  //   <button onClick={async () => {
  //     const url =
  //       'https://prdassetseuwwav.blob.core.windows.net/' +
  //       arrState.data.version.musicXmlSourceUrl;
  //     const response = await fetch(url);
  //     const xml = await response.text();
  //   }}>Pre</button>
  //   <Button onClick={toggle}>Preview XML</Button>
  //   <Modal isOpen={isOpen} toggle={toggle}>
  //     <XmlPreview close={close} />
  //   </Modal>
  // </>

  return <>
    {generatingSvgsSyncsStatus && <p>{generatingSvgsSyncsStatus}</p>}
    {/* {!generatingSvgsSyncsStatus && btnGenerate} */}
    {/* {btnDelete} */}
    {/* {xmlPreview.xml && <ButtonPreview xmlUrl={arrState.data.version.musicXmlSourceUrl} />} */}

    {/* <Button
      onClick={async () => {
        const url =
          'https://prdassetseuwwav.blob.core.windows.net/' +
          arrState.data.version.musicXmlSourceUrl;
        const response = await fetch(url);
        const xml = await response.text();
        window.navigator.clipboard.writeText(xml);
        alert('MusicXml data copied to clipboard');
      }}
    >
      Copy xml
    </Button> */}

    <Menu menuButton={<MenuButton className="MenuBurger Flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 260" width="26"><g fill="none" stroke="#000" strokeWidth="49.336" strokeLinecap="round"><path d="M29.684 229.663H280.33M29.684 129.663H280.33M29.684 29.663H280.33" /></g></svg></MenuButton>}>
      <MenuItem onClick={async () => {
        if (!(arrState.type === 'arrangement')) return;
        const xml = await downloadXmlStromg('https://prdassetseuwwav.blob.core.windows.net/' + arrState.data.version.musicXmlSourceUrl);
        setXmlString(xml);
        toggle();
      }} >Preview MusicXml</MenuItem>
      <MenuDivider />

      <MenuItem onClick={async () => {
        if (!(arrData.type === 'arrangement')) return;
        const filename = arrData.data.name;
        const res = await fetch('https://prdassetseuwwav.blob.core.windows.net/' + arrState.data.version.musicXmlSourceUrl);
        const blob: Blob = await res.blob();
        downloadBlob(blob, (filename + '-musicxml.xml'));
      }}>Download MusicXml file</MenuItem>

      <MenuItem onClick={async () => {
        const xml = await downloadXmlStromg('https://prdassetseuwwav.blob.core.windows.net/' + arrState.data.version.musicXmlSourceUrl);
        window.navigator.clipboard.writeText(xml);
        alert('MusicXml data copied to clipboard');
      }}>Copy MusicXml to clipboard</MenuItem>
      <MenuDivider />
      {/* 
      <MenuItem
        disabled={false}
        // disabled={!!generatingSvgsSyncsStatus}
        onClick={async () => {
          await generateAndUploadSvgsAndSyncs(userState, arrState, arrVersion, props.item, setGeneratingSvgsSyncsStatus);
          dispatch.playmodes.load({});
        }}>Recreate svgs and syncs</MenuItem>

      <MenuItem
        disabled={false}
        onClick={async () => {
          const response = await deleteSvgsAndSyncData(
            appsettings.API_URL,
            arrState.id,
            arrVersion.id,
            props.item.id,
            userState.token!
          );
          console.log(response);
          dispatch.playmodes.load({});
        }}>Delete svgs and syncs</MenuItem>

      <MenuDivider /> */}

      <MenuItem
        disabled={!(arrVersion?.status === 'Draft')}
        onClick={async () => {
          if (
            !window.confirm(
              `Do you really want to delete playmode ${props.item.playModeType}?`
            )
          )
            return;
          if (arrData.type !== 'arrangement') return;
          dispatch.playmodes.delete({
            id: props.item.id,
          });
        }} >Delete playmode</MenuItem>
    </Menu >

    {xmlString && <Modal isOpen={isOpen} toggle={toggle}><XmlPreview xml={xmlString as string} close={close} /></Modal>

    }

  </>
}


// //xmlUrl={arrState.data.version.musicXmlSourceUrl}
// const ButtonPreview = (props: { xmlUrl: string }) => {
//   const { isOpen, toggle, close } = useModal();
//   const [xmlString, setXmlString] = useState('');
//   const arrState = useSelector((state: RootState) => state.arrangement);

//   return <>
//     <Button onClick={async () => {
//       if (!(arrState.type === 'arrangement')) return;
//       const url =
//         'https://prdassetseuwwav.blob.core.windows.net/' +
//         arrState.data.version.musicXmlSourceUrl;
//       const response = await fetch(url);
//       const xml = await response.text();
//       setXmlString(xml);
//       toggle();
//     }}>Pre</Button>
//     {xmlString && <Modal isOpen={isOpen} toggle={toggle}><XmlPreview xml={xmlString as string} /></Modal>}

//   </>
// }

const downloadXmlStromg = async (url: string) => {
  const response = await fetch(url);
  return await response.text();
}