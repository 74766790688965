import { User } from './user';
import { OrganizationalUnit } from './userOrganization';

const SUPER_USERS = ['jonasnys@gmail.com', 'fredrik@leafstreet.se'];

export const isSuperUser = (user: User) =>
  user?.email && (SUPER_USERS.includes(user.email) || user?.email.includes('@wearevoice.se'));

const SUPER_USER_ORGANIZATION_ID = '92b7875e-bc8c-4adf-b52d-e845d95fbd36';

export const SUPER_USER_ORGANIZATION: OrganizationalUnit = {
  id: SUPER_USER_ORGANIZATION_ID,
  name: 'Superadmin (unrestricted access)',
};

export const isSuperUserOrganization = (
  organization: OrganizationalUnit | null
) => organization?.id === SUPER_USER_ORGANIZATION_ID;
