import { IMediaEngine } from './EngineTypes';
import { RecordingAndAudio } from '../../wav/WavPublicApi';
import EngineBase from './EngineBase';
import WebAudio from '../WebAudio';
import AACAudio, { ADTSObject } from '../AACAudio';

export default class EngineSegmented extends EngineBase implements IMediaEngine {
    private recordings: Array<RecordingAndAudio>;
    private mixerGainNodes: Map<string, GainNode>;

    constructor(onError: (error?: unknown) => void) {
        super(onError);
        this.recordings = [];
        this.mixerGainNodes = new Map();
    }

    private queueTimerHandler: number | null = null;
    private arrayBufferMap: Map<string, ArrayBuffer> = new Map();
    private adtsMap: Map<string, ADTSObject> = new Map();
    private framesCount = 0;
    private chunkLength = 500;
    private framesToSek = 21.354666666666667 / (1000 / this.chunkLength);
    private frameDuration = 21.354666666666667 / 1000;

    override setRecordings(recordings: RecordingAndAudio[]) {
        this.recordings = recordings;

        // skapa mixerGainNodes
        this.recordings.forEach((recording) => {
            const gainNode = WebAudio.context.createGain();
            gainNode.gain.setValueAtTime(recording.audioLevel, 0);
            gainNode.connect(WebAudio.context.destination);
            this.mixerGainNodes.set(recording.id, gainNode);
        });

        // store arrayBuffers in a recordingId->arrayBuffer map
        this.arrayBufferMap.clear();
        this.recordings.forEach(recording => {
            switch (recording.type) {
                case 'audioBuffer':
                    this.onError('AudioBuffer should not ge used in Segment player');
                    break;
                case 'aacArrayBuffer':
                    this.arrayBufferMap.set(recording.id, recording.aacArrayBuffer);
                    break;
            }
        });

        // store adts information in a recordingId->adts map
        this.adtsMap.clear();
        this.recordings.forEach(recording => {
            switch (recording.type) {
                case 'audioBuffer':
                    this.onError('AudioBuffer should not ge used in Segment player');
                    break;
                case 'aacArrayBuffer': {
                    // this.arrayBufferMap.set(rd.id, rd.aacArrayBuffer);
                    const adts = AACAudio.parseAacArrayBufferToADTSSync(recording.aacArrayBuffer);
                    this.adtsMap.set(recording.id, adts);
                    break;
                }
            }
        });

        // framesCount from first recording adts
        const firstRecordings = this.recordings[0];
        const firstChannelAdts: ADTSObject = this.adtsMap.get(firstRecordings.id) as ADTSObject;
        this.framesCount = firstChannelAdts.frames.length;
    }

    override async start(time: number): Promise<boolean> {
        await WebAudio.context.resume();
        const audioBuffersIds = await this.decodeSegmentFromPlayTime(time);
        this.playSegment(audioBuffersIds, WebAudio.context.currentTime);
        if (this.queueTimerHandler) {
            window.clearInterval(this.queueTimerHandler);
        }
        this.queueTimerHandler = window.setInterval(() => this.checkSegmentsQueue(), 2000);
        return true;
    }

    override stop(): Promise<boolean> {
        this.stopSegments();
        this.emptyPlayingSegments();
        if (this.queueTimerHandler) {
            window.clearInterval(this.queueTimerHandler);
        }
        this.queueTimerHandler = null;
        return Promise.resolve(true);
    }

    override setMixerLevel(recordingId: string, newLevel: number) {
        const gainNode = this.mixerGainNodes.get(recordingId);
        if (gainNode == null) return;
        gainNode.gain.setValueAtTime(newLevel, 0);
    }

    override async teardown(): Promise<boolean> {
        await super.teardown();
        this.recordings = [];
        this.mixerGainNodes.clear();
        this.arrayBufferMap.clear();
        this.adtsMap.clear();
        return Promise.resolve(true);
    }

    //-------------------------------------------------------------------------------------------

    private playingSegments: Array<NoteItemsSegment> = [];

    private playSegment = (audioBuffersIds: AudioBuffersInfo, startTime: number) => {
        if (WebAudio.context.state != 'running') {
            console.warn(`AudioContext state is not "running", but: "${WebAudio.context.state}"`);
        }
        const nodesItems: Array<NodeItem> = audioBuffersIds.buffersIds.map((bufferId) => {
            const { audioBuffer: buffer, id } = bufferId;
            const node = WebAudio.context.createBufferSource();
            node.buffer = buffer;
            const bufferDuration = buffer.duration;
            // add gainNode to smoothly crossfade between segments
            const gainNode = WebAudio.context.createGain();
            gainNode.gain.setValueAtTime(0, startTime);
            gainNode.gain.linearRampToValueAtTime(1, startTime + GAIN_FADE_IN);
            gainNode.gain.setValueAtTime(1, startTime + bufferDuration - GAIN_FADE_OUT);
            gainNode.gain.linearRampToValueAtTime(0, startTime + bufferDuration);
            node.connect(gainNode);
            const mixerGainNode = this.mixerGainNodes.get(id) as GainNode;
            gainNode.connect(mixerGainNode);
            return { node: node, id: id };
        });

        // kickoff node playback
        nodesItems.forEach(nodeId => {
            nodeId.node.start(startTime);
            // console.log('[ START NODE ]', nodeId.id);
        });

        const deFactoStartTime = WebAudio.context.currentTime;

        // objekt som lagrar information om det segment vars noder just startas
        const noteItemsSegment: NoteItemsSegment = {
            start: audioBuffersIds.start,
            stop: audioBuffersIds.stop,
            startTime,
            startFrame: audioBuffersIds.startFrame,
            endFrame: audioBuffersIds.endFrame,
            nodesItems: nodesItems,
            deFactoStartTime,
            duration: audioBuffersIds.duration
        };

        this.playingSegments.push(noteItemsSegment);
    };

    private stopSegments = () => {
        this.playingSegments.forEach(segment => {
            segment.nodesItems.forEach(nodeId => {
                nodeId.node.stop();
                // console.log('[ STOP NODE ]', nodeId.id);
            });
        });
    };

    private removePlayingSegments = () => {
        this.playingSegments = this.playingSegments.filter(segment => {
            const segmentStopTime = segment.startTime + segment.duration;
            const keep = segmentStopTime > WebAudio.context.currentTime;
            return keep;
        });
    };

    private emptyPlayingSegments = () => {
        this.playingSegments = [];
    };

    private checkSegmentsQueue = () => {
        if (!this.isPlaying) {
            return;
        }
        this.removePlayingSegments();
        const queueLength = this.playingSegments.length;
        if (queueLength == 0) {
            this.onError('Queue length 0 - Do all sound tracks have equal duration?');
        } else if (queueLength >= 2) {
            return;
        } else {
            const lastNodesIds = this.playingSegments[this.playingSegments.length - 1];
            const lastStartTime = lastNodesIds.startTime;
            const lastDuration = lastNodesIds.duration;
            const lastEndFrame = lastNodesIds.endFrame;
            this.decodeToAudioBuffersIds(lastEndFrame + 1).then((audioBuffersIds) => {
                if (audioBuffersIds) {
                    this.playSegment(audioBuffersIds, lastStartTime + lastDuration);
                }
            }).catch(e => {
                console.log('EngineSegmented: decodeToAudioBuffersIds returning null at end of playback - seems safe not throwing this error for now', e);
                // this.onError(e)
            }
            );
        }
    };

    //------------------------------------------------------------------------------------------
    private decodeSegmentFromPlayTime = async (playTime: number) => {
        const startF = playTime / this.durationS;
        const startFrame = Math.floor(this.framesCount * startF);
        return this.decodeToAudioBuffersIds(startFrame);
    };

    private decodeToAudioBuffersIds = async (startFrame: number): Promise<AudioBuffersInfo> => {
        const startTime = startFrame * this.frameDuration;
        const stopTime = startTime + (this.chunkLength * this.frameDuration);
        const endFrame = Math.min(startFrame + this.chunkLength, this.framesCount);
        return new Promise((res, rej) => {

            const getAudioBuffer = async (id: string): Promise<AudioBufferId> => {
                const arrayBuffer = this.arrayBufferMap.get(id) as ArrayBuffer;
                const adts: ADTSObject = this.adtsMap.get(id) as ADTSObject;
                const audioBuffer: AudioBuffer = await AACAudio.decodeAacFramesToAudioBuffer(arrayBuffer, adts, startFrame, this.chunkLength) as AudioBuffer;
                return { audioBuffer: audioBuffer, id };
            };

            const promises: Array<Promise<AudioBufferId>> = [];
            this.arrayBufferMap.forEach((value: ArrayBuffer, key: string) => {
                promises.push(getAudioBuffer(key));
            });


            Promise.all(promises).then(audioBuffersIds => {
                if (audioBuffersIds[0] && audioBuffersIds[0].audioBuffer) {
                    const duration = audioBuffersIds[0].audioBuffer.duration;
                    res({ start: startTime, stop: stopTime, buffersIds: audioBuffersIds, startFrame, endFrame, duration });
                } else {
                    // returns null at end of playback
                    rej(null);
                }
            });

            // rej(null);

        });

    };

}


type AudioBufferId = {
    audioBuffer: AudioBuffer,
    id: string,
}

type AudioBuffersInfo = {
    start: number,
    stop: number,
    buffersIds: Array<AudioBufferId>,
    startFrame: number,
    endFrame: number,
    duration: number,
}

const GAIN_FADE_IN = 0;
const GAIN_FADE_OUT = 0.001;

type NoteItemsSegment = {
    start: number,
    stop: number,
    startTime: number,
    startFrame: number,
    endFrame: number,
    nodesItems: Array<NodeItem>,
    deFactoStartTime: number,
    duration: number,
};

type NodeItem = {
    node: AudioBufferSourceNode,
    id: string
}

