import DocumentStore from '../store/DocumentStore';
import { numberIterator } from '../tools/NumberTools';
import { PlaymodeType_2_3 } from '../wav/WavPublicApi';
import { delay } from '../tools/TimerTools';

export default class RenderScorX {
    public static async renderScorX(pages: Array<{ index: number, url: string }>, documentStore: DocumentStore) {
        documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading pages...' };
        documentStore.documentContentsSignal.value = numberIterator(0, pages.length - 1).map(idx => documentStore.getBlankPage());
        pages.forEach((page, pageIdx) => {
            const img: HTMLImageElement = document.createElement('img');
            img.style.height = img.style.width = '100%';
            img.style.userSelect = 'none';
            img.src = page.url;
            img.ondragstart = e => e.preventDefault();
            documentStore.replaceDocumentContentPage(pageIdx, img);
        });
        documentStore.documentStatusSignal.value = { status: 'documentSuccess', documentType: { type: PlaymodeType_2_3.ScorX } };

    }


}