import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { useDropzone } from 'react-dropzone';
import { remapTypeFileToTypeParsed } from '../../../models/recordingUploads';

const UploadRecordings = () => {
  const dispatch = useDispatch<Dispatch>();
  const arrVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const recordingUploads = useSelector(
    (state: RootState) => state.recordingUploads
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'audio/*': [],
      'video/*': [],
    },
    onDropAccepted: (files) => {
      const newItems = files.map((file) =>
        remapTypeFileToTypeParsed({ type: 'file', file })
      );
      const items = [...newItems, ...recordingUploads.items];
      dispatch.recordingUploads.setState({
        items,
      });
    },
    onDropRejected: (files) => {
      console.warn('Rejected', files);
    },
  });

  if (arrVersion?.status === 'Published') return null;

  return (
    <>
      <h2>Add audio and video files</h2>
      <p>
        Recordings are created by adding audio or video files to the
        arrangement.
      </p>
      <section className="container">
        <div {...getRootProps({ className: 'Dropzone' })}>
          <input {...getInputProps()} />
          <p>
            Drag and drop files here, or click to select files. For best audio
            quality, use WAV files.
          </p>
        </div>
      </section>
    </>
  );
};

export default UploadRecordings;
