import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RecordingDB } from '../../../api/ManagementApi';
import Button from '../../../components/Button';
import { Dispatch } from '../../../store';

const EditRecording = (props: { recording: RecordingDB; isDraft: boolean }) => {
  const dispatch = useDispatch<Dispatch>();
  const [name, setName] = useState(
    props.recording.name || props.recording.type
  );
  const [isEditing, setIsEditing] = useState(false);

  if (!props.recording) return null;

  if (!isEditing)
    return (
      <div className="Flex">
        <p>
          <b>{props.recording.name || props.recording.type}</b>
        </p>
        {props.isDraft && (
          <Button className="small" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </div>
    );

  return (
    <label>
      <form onSubmit={(e) => e.preventDefault()} className="Flex FlexRow">
        <input
          className="small"
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Button
          className="small"
          onClick={() => {
            dispatch.recordings.update({
              id: props.recording.id,
              name: name,
            });
            setIsEditing(false);
          }}
        >
          Update
        </Button>
      </form>
    </label>
  );
};

export default EditRecording;
