import { IMediaEngine } from './EngineTypes';
import { RecordingAndAudio } from '../../wav/WavPublicApi';
import EngineBase from './EngineBase';
import WebAudio from '../WebAudio';

export default class EngineStandard extends EngineBase implements IMediaEngine {
    private recordings: Array<RecordingAndAudio>;
    private sourceBuffers: Array<AudioBufferSourceNode>;
    private mixerGainNodes: Map<string, GainNode>;

    constructor(onError: (error?: unknown) => void) {
        super(onError);
        this.recordings = [];
        this.sourceBuffers = [];
        this.mixerGainNodes = new Map();
    }

    override setRecordings(recordings: RecordingAndAudio[]) {
        this.recordings = recordings;
    }

    override async start(time: number): Promise<boolean> {
        await WebAudio.context.resume();
        this.mixerGainNodes.clear();
        this.sourceBuffers = this.recordings.map(rd => {
            const sourceBuffer = WebAudio.context.createBufferSource();
            const gainNode = WebAudio.context.createGain();
            switch (rd.type) {
                case 'audioBuffer':
                    sourceBuffer.buffer = rd.audioBuffer;
                    break;
                case 'aacArrayBuffer':
                    this.onError('aacArrayBuffer should not ge used in Segment player');
                    break;
            }
            sourceBuffer.connect(gainNode);
            gainNode.connect(WebAudio.context.destination);
            gainNode.gain.setValueAtTime(rd.audioLevel, 0);
            this.mixerGainNodes.set(rd.id, gainNode);
            sourceBuffer.start(0, time);
            return sourceBuffer;
        });

        return Promise.resolve(true);
    }

    override stop(): Promise<boolean> {
        this.sourceBuffers.forEach(sourceBuffer => {
            sourceBuffer.stop();
        });
        return Promise.resolve(true);
    }

    override setMixerLevel(recordingId: string, newLevel: number) {
        this.recordings.find(r => r.id == recordingId)!.audioLevel = newLevel;
        const gainNode = this.mixerGainNodes.get(recordingId);
        if (gainNode == null) {
            return;
        }
        gainNode.gain.setValueAtTime(newLevel, 0);
    }


    override async teardown(): Promise<boolean> {
        await super.teardown();
        this.recordings = [];
        this.sourceBuffers = [];
        this.mixerGainNodes.clear();
        return Promise.resolve(true);
    }
}
