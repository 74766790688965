import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get } from '../api/Api';
import { InterestedParty } from '../api/ManagementApi';

export type InterestedPartySearch =
  | {
      type: 'empty';
    }
  | {
      type: 'searching';
      terms: Array<string>;
    }
  | {
      type: 'result';
      items: Array<InterestedParty>;
    };

export const ipSearch = createModel<RootModel>()({
  state: { type: 'empty' } as InterestedPartySearch,

  reducers: {
    setStatus(state, s: InterestedPartySearch) {
      return s;
    },
  },

  effects: (dispatch) => ({
    async searchForTerms(data: { terms: string[] }, rootState) {
      if (!rootState.user.token) return;
      dispatch.ipSearch.setStatus({ type: 'searching', terms: data.terms });
      const token = rootState.user.token;
      const promises = data.terms.map((term) =>
        get(`/interestedParties?search=${term}`, token, {})
      );
      const results = (await Promise.all(promises)) as Array<IpSearchResult>;
      const allItems = results.flatMap((result) => result.items);
      const allIds = allItems.map((item) => item.id);
      const uniqueIds = [...new Set<string>(allIds)];
      const uniqueItems = uniqueIds.map((id) =>
        allItems.find((item) => item.id === id)
      ) as Array<InterestedParty>;
      dispatch.ipSearch.setStatus({ type: 'result', items: uniqueItems });
    },
  }),
});

type IpSearchResult = {
  items: Array<InterestedParty>;
};
