import css from '../PlayerComponent.module.css';

export default class VideoElementStore {
    readonly videoElement: HTMLVideoElement;
    readonly videoContainerElement: HTMLElement;

    constructor() {
        this.videoContainerElement = document.createElement('div');
        this.videoContainerElement.classList.add(css.VideoElementContainer);
        this.videoElement = document.createElement('video');
        this.videoElement.classList.add(css.VideoElement);
        this.videoElement.muted = false;
        // TODO: seems like autoplay required in Safari to load the first preview frame (otherwise just transparent)
        // this.videoElement.autoplay = true;
        this.videoElement.controls = false;
        this.videoElement.playsInline = true;
        this.videoElement.preload = 'auto';
        this.videoElement.disablePictureInPicture = true;
        this.videoContainerElement.appendChild(this.videoElement);
        this.setVisible(false);
    }

    setup = (playerElement: HTMLElement) => {
        playerElement.appendChild(this.videoContainerElement);
    };

    setVisible = (visible: boolean) => {
        this.videoContainerElement.style.display = visible ? 'inherit' : 'none';
    };

    setVideoUrl = (url: string) => {
        this.videoElement.src = url;
    };
}

