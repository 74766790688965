import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { Dispatch, RootState } from '../../../store';
import EditRecording from './EditRecording';
import { Menu, MenuItem, MenuButton, SubMenu, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { downloadBlob } from '../../../player/helpers/DownloadBlob';

const ExistingRecordings = () => {
  const dispatch = useDispatch<Dispatch>();
  const arrData = useSelector((state: RootState) => state.arrangement);
  const recordings = useSelector((state: RootState) => state.recordings);
  const arrVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );

  const isDraft = arrVersion?.status === 'Draft'


  return (
    <div>
      <div className="Flex AlignCenter JustifyBetween">
        <h2>Recordings</h2>
        <Button
          onClick={() => {
            dispatch.recordings.load({});
          }}
          className="small"
        >
          Reload
        </Button>
      </div>
      <div className="RecordingsList">
        {recordings.items.map((item) => {
          return (
            <Card key={item.id}>
              <div className="Flex FlexColumn">
                <div className="Flex AlignCenter">
                  <EditRecording
                    recording={item}
                    isDraft={isDraft}
                  />
                  <div className="chip">
                    <small>{item.type}</small>
                  </div>
                  <div className="chip">
                    <small>{item.recordingType}</small>
                  </div>
                  <div>🔊 {item.audioLevel}</div>
                  <div>&uarr; {item.sortOrder}</div>
                </div>
                <div className="text secondary">
                  <small>
                    <pre>{item.id}</pre>
                  </small>
                </div>
              </div>
              {(item.duration <= 0 || !!item.status) && (
                <div>Encoding ({item.progress ?? 0}%)</div>
              )}

              <Menu menuButton={<MenuButton className="MenuBurger Flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 260" width="26"><g fill="none" stroke="#000" strokeWidth="49.336" strokeLinecap="round"><path d="M29.684 229.663H280.33M29.684 129.663H280.33M29.684 29.663H280.33" /></g></svg></MenuButton>}>
                <MenuItem
                  disabled={false} // !!item.status || item.duration <= 0 ||
                  onClick={async () => {
                    if (!(arrData.type === 'arrangement')) return;
                    const url = 'https://prdassetseuwwav.blob.core.windows.net/' + item.sourceUrl;
                    const res = await fetch(url);
                    const blob: Blob = await res.blob();
                    const filename = arrData.data.name + '-' + item.type + '.' + item.sourceUrl.split('.').pop();
                    downloadBlob(blob, filename);
                  }}>Download audio source file</MenuItem>
                <MenuDivider />
                <MenuItem
                  disabled={arrVersion?.status !== 'Draft'} // !!item.status || item.duration <= 0 ||
                  onClick={() => {
                    if (
                      !window.confirm(
                        `Do you really want to delete recording ${item.name || item.type
                        }?`
                      )
                    )
                      return;
                    dispatch.recordings.delete(item.id);
                  }}
                >Delete recording</MenuItem>
              </Menu>
            </Card>
          );
        })}
      </div>
      {recordings.loading && <p>Loading...</p>}
    </div>
  );
};

export default ExistingRecordings;
