
import { VerovioTimemapData, BarJumpsData, ScorxGridItem, ScorxPageItem, TimeSyncData, XmlSvgInfo, XmlSyncInfo, GridExportItem, VerovioRenderData } from "../playerTypes";


export type ArrangementData_2_3 = {
    title: string,
    subtitle: string,
    description: string,
    duration: number,
    composition: string,
    contentType: string,
    playModes: Array<Playmode_2_3>,
    permissions: Array<string>,
    interestedParties: Array<InterestedParty>,
    tags: Array<{ type: string, name: string, label: string }>,
    image: string,
    owner: any,
    id: string,
    createdUtc: string,
    updatedUtc: string,
}

export type PlayModeData = {
    condensed?: boolean,
    musicXml?: string,
    timeSync?: TimeSyncData,
    barJumps?: BarJumpsData,
    tempoCompensationion?: boolean,
    grid?: Array<ScorxGridItem>,
    pages?: Array<ScorxPageItem>,
    pdfUrl?: string,
    videoUrl?: string,
}

export type Playmode_2_3 = {
    id: string;
    type: PlaymodeType_2_3;
    name: string;
    data?: PlayModeData;
}

export enum PlaymodeType_2_3 {
    Audio = 'Audio',
    ScorX = 'ScorX',
    Pdf = 'Pdf',
    Xml = 'Xml',
    Video = 'Video',
}

export type InterestedParty = {
    id: string,
    name: string,
    type: InterestedPartyType,
}

export enum InterestedPartyType {
    Composer = 'Composer',
    Arranger = 'Arranger',
    Lyricist = 'Lyricist',
    Translator = 'Translator',
    Artist = 'Artist',
    Other = 'Other',
}

export type Recording = {
    id: string,
    sortOrder: number,
    name: string,
    recordingType: RecordingType,
    channelType: string,
    audioLevel: number,
    audioPan: number,
    url: string,
}

export enum PlayerMode {
    Standard = 'Standard',
    Segmented = 'Segmented'
}

export type RecordingAndAudio = Recording & ({
    type: 'aacArrayBuffer',
    aacArrayBuffer: ArrayBuffer,
    duration: number,
} | {
    type: 'audioBuffer',
    audioBuffer: AudioBuffer,
    duration: number,
})

export enum RecordingType {
    Audio = 'Audio',
    Video = 'Video'
}

export type PlayModeBaseData_2_3 = {
    name: string;
    recordings: Array<Recording>;
    playMode: Playmode_2_3;
}

export type PlayModeTypeData =
    | {
        type: 'Xml',
        condensed?: boolean,
        musicXmlUrl?: string,
        musicXml?: string,
        timeSync?: TimeSyncData,
        barJumps?: BarJumpsData,
        tempoCompensation?: boolean,

        svgs?: Array<XmlSvgInfo>,
        syncs?: Array<XmlSyncInfo>,

        gridItemsVertical?: Array<GridExportItem>,
        gridItemsHorizontal?: Array<GridExportItem>,

        svgsVertical?: Array<XmlSvgInfo>,
        svgsHorizontal?: Array<XmlSvgInfo>,

        verovioVerticalData?: VerovioRenderData,
        verovioHorizontalData?: VerovioRenderData,

    }
    | {
        type: 'ScorX',
        grid?: Array<ScorxGridItem>,
        pages?: Array<ScorxPageItem>,
    }
    | {
        type: 'Pdf',
        pdfUrl?: string,
    }
    | {
        type: 'Audio',
    }
    | {
        type: 'Video',
        videoUrl?: string,
    }

