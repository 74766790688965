import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get } from '../api/Api';
import { MetadataItem, MetadataItems } from '../api/ManagementApi';

export type MetadataTreeStatus =
  | {
      type: 'empty';
    }
  | {
      type: 'loading';
    }
  | {
      type: 'loaded';
      tree: MetadataItems;
      itemArray: Array<MetadataItem>;
    };

export const metadataTree = createModel<RootModel>()({
  state: { type: 'empty' } as MetadataTreeStatus,
  reducers: {
    setStatus(state, s: MetadataTreeStatus) {
      return s;
    },
  },
  effects: (dispatch) => ({
    async loadMetadataTree(data, rootState) {
      if (!rootState.user.token) return;

      dispatch.metadataTree.setStatus({ type: 'loading' });
      const tree: MetadataItems = (await get(
        `/metadata`,
        rootState.user.token,
        {}
      )) as MetadataItems;
      createLabelPath(tree, '', '', '');
      const itemArray = createArray(tree, []);
      dispatch.metadataTree.setStatus({ type: 'loaded', tree, itemArray });
      console.log('metadata tree loaded', itemArray);
    },
  }),
});

const createLabelPath = (
  items: MetadataItems,
  parentSv: string,
  parentDe: string,
  parentEn: string
) => {
  for (const item of items) {
    item.labelPathSv = parentSv + '/' + item.labelSv;
    item.labelPathDe = parentDe + '/' + item.labelDe;
    item.labelPathEn = parentEn + '/' + item.labelEn;
    createLabelPath(
      item.children,
      item.labelPathSv,
      item.labelPathDe,
      item.labelPathEn
    );
  }
};

export const createArray = (
  items: Array<MetadataItem>,
  array: Array<MetadataItem>
): Array<MetadataItem> => {
  for (const item of items) {
    array.push(item);
    createArray(item.children, array);
  }
  return array;
};

export const filterArray = (
  items: Array<MetadataItem>,
  labels: Array<string>
): Array<MetadataItem> => {
  let result: Array<MetadataItem> = [];
  for (const label of labels) {
    const found = items.filter((item) => item.labelSv.indexOf(label) > -1);
    result = result.concat(found);
  }
  return result;
};

export const removeFromArray = (
  items: Array<MetadataItem>,
  remove: Array<MetadataItem>
): Array<MetadataItem> => {
  let items2 = [...items];
  for (const rem of remove) {
    items2 = items2.filter((item) => item.id != rem.id);
  }
  return items2;
};

export const filterUnique = (
  items: Array<MetadataItem>
): Array<MetadataItem> => {
  const ids = items.map((item) => item.id);
  const uniqueIds = [...new Set(ids)];
  return uniqueIds.map(
    (id) => items.find((item) => item.id === id) as MetadataItem
  );
};
