import { Dispatch, SetStateAction } from 'react';
import Button from './Button';
import Label from './Label';
import Required from './Required';

const NULL = 'NULL';

type Props = {
  ice: string;
  setIce: Dispatch<SetStateAction<string>>;
  iswc: string;
  setIswc: Dispatch<SetStateAction<string>>;
  existing?: boolean;
};

export default function ISWCAndICE({
  ice,
  setIce,
  iswc,
  setIswc,
  existing,
}: Props) {
  if (existing) {
    return (
      <>
        <label>
          <Label>Iswc</Label>
          {(!iswc || iswc === NULL || iswc.length < 4) && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              Iswc number not present
            </p>
          )}
          <input
            pattern="[\w\W0-9.-]{4,}"
            type="text"
            name="ISWC"
            placeholder="ISWC"
            value={iswc || ''}
            onChange={(e) => setIswc(e.target.value)}
          />
        </label>

        <label>
          <Label>Ice</Label>
          {(!ice || ice === NULL || ice.length < 4) && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              Ice number not present
            </p>
          )}
          <input
            pattern="[\w\W0-9.-]{4,}"
            type="text"
            name="ICE"
            placeholder="ICE"
            value={ice || ''}
            onChange={(e) => setIce(e.target.value)}
          />
        </label>
      </>
    );
  }

  return (
    <>
      <p style={{ paddingTop: '1rem' }}>Important!</p>
      <p>
        <strong>ICE</strong> and <strong>ISWC</strong> number for the work -
        this can be retrieved from you local collecting society{' '}
      </p>

      <label>
        <div style={{ display: 'flex', gap: '2px' }}>
          <Label>ISWC</Label>
          <Required />
        </div>
        <div className="Flex">
          {iswc == NULL && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              Iswc number not present
            </p>
          )}
          {iswc != NULL && (
            <>
              <input
                pattern="[\w\W0-9.-]{4,}"
                type="text"
                name="ISWC"
                placeholder="ISWC"
                value={iswc || ''}
                onChange={(e) => setIswc(e.target.value)}
              />
              <Button onClick={() => setIswc(NULL)}>Can't find iswc</Button>
            </>
          )}
        </div>
      </label>

      <label>
        <div style={{ display: 'flex', gap: '2px' }}>
          <Label>ICE</Label>
          <Required />
        </div>
        <div className="Flex">
          {ice == NULL && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              Ice number not present
            </p>
          )}
          {ice != NULL && (
            <>
              <input
                pattern="[\w\W0-9.-]{4,}"
                type="text"
                name="ICE"
                placeholder="ICE"
                value={ice || ''}
                onChange={(e) => setIce(e.target.value)}
              />
              <Button onClick={() => setIce(NULL)}>Can't find iswc</Button>
            </>
          )}
        </div>
      </label>
    </>
  );
}
