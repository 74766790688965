import { createModel } from '@rematch/core';
import { RootModel } from '.';

export type LanguageState = 'swedish' | 'english' | 'german';

export const language = createModel<RootModel>()({
  state: 'swedish' as LanguageState,
  reducers: {
    setState(state, newState: LanguageState) {
      return newState;
    },
  },
});
