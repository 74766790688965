import css from "./PlayerComponent.module.css";
import cx from "classnames";
import React from "react";
import PlayModeStore, { ArrangementPlayModes, PlayModeDetailsStatus, } from "./store/PlayModeStore";
import { Playmode_2_3, ArrangementData_2_3, Recording, InterestedPartyType, RecordingType, PlayModeTypeData, InterestedParty, } from './wav/WavPublicApi';
import { TimeSyncAndBarJumps, TimeSyncData, BarJumpsData, GridExportItem, PageOrientation, VerovioRenderData, VerovioSyncData, PlayerOptions, ZoomInformation, GridItemAction, PlayerOptimizationType, PlayerProps, IPlayerComponent } from "./playerTypes";
import VideoElementStore from "./store/VideoElementStore";
import GraphicElementStore, { PageInteraction, } from "./store/GraphicElementStore";
import ArrangementStore, { ArrangementStatus } from "./store/ArrangementStore";
import VerovioStore from "./store/VerovioStore";
import RecordingsStore, { RecordingsStatus } from "./store/RecordingsStore";
import DocumentStore, { SpreadLayoutStatus, DocumentStatus, PagesLayoutBase, } from "./store/DocumentStore";
import { PlayerMode, PlaymodeType_2_3 } from "./wav/WavPublicApi";
import DisplayStore, { DisplayModeState, UIDisplayModes, } from "./store/DisplayStore";
import RenderXml, { } from "./render/RenderXml";
import RenderPdf from "./render/RenderPdf";
import RenderAudio from "./render/RenderAudio";
import { PlaybackStatus, PlaybackPosition } from "./audio/engine/EngineTypes";
import { detectBrowser, Browser } from "./helpers/BrowserDetection";
import { IMediaEngine } from "./audio/engine/EngineTypes";
import EngineStandard from "./audio/engine/EngineStandard";
import RenderScorX from "./render/RenderScorX";
import EngineSegmented from "./audio/engine/EngineSegmented";
import GridVerovioStore from "./store/GridVerovioStore";
import { GridItem1Details, GridTimeDetails, GridMouseActionType, GridVerovioInfo1, GridVeroivioItem1 } from "./playerTypes";
import deepEqual from "deep-equal";
import Statistics from "./statistics/Statistics";
import AnimationScrollStore, { ScrollAnimationData, } from "./store/AnimationScrollStore";
import WebAudio, { WebAudioOptions } from "./audio/WebAudio";
import EngineVideo from "./audio/engine/EngineVideo";
import IdleTimer from "./tools/IdleTimer";
import AnimationPlayheadStore from "./store/AnimationPlayheadStore";

// import barsWithJumps from "./prodXmlWithBarjumps.json";

import { round3, round5 } from "./tools/NumberTools";
import { renderXmlSvg } from "./render/RenderXmlSvg";
import { modifyTimeSyncs, recreateBarJumps, recreateTimeSync, resetGridMediaTimeToScoreTime } from "./grid/gridImportUtils";
import { SvgRenderingStatus, canUseSvgRendering, createExportItems, createLegacyGridItems, getSvgStatus } from "./grid/gridExportUtils";
import { uploadSyncData } from "./grid/uploadSvgModeData";
import Signal from "./data/Signal";
import { addArrangementInfo } from "./grid/pageHeader";

import { createGridExportItemsFromVerovioData } from "./grid/gridUtils";
import { createVeriovioRenderDataFromXmlUrl } from "./render/RenderXmlExportSVG";

const SCROLL_ANIMATION_REBUILD_TIMEOUT = 300;

export default class PlayerComponent extends React.PureComponent<PlayerProps> implements IPlayerComponent {
    private playerElementRef = React.createRef<HTMLDivElement>();
    private mediaEngine: IMediaEngine | null = null;
    private scrollIdleTimer: IdleTimer | null = null;
    private isMobile = true;
    private setupCount = 0;
    private arrangementId = "";
    private readonly videoElementStore = new VideoElementStore();
    private readonly graphicElementStore = new GraphicElementStore();
    private readonly arrangementStore: ArrangementStore;
    private readonly playModeStore: PlayModeStore;
    public readonly verovioStore: VerovioStore;
    private readonly recordingsStore: RecordingsStore;
    private readonly gridStore: GridVerovioStore;
    private readonly animationScrollStore = new AnimationScrollStore();
    private readonly displayStore: DisplayStore;
    private readonly documentStore: DocumentStore;
    private readonly animationPlayheadStore: AnimationPlayheadStore;
    private readonly statistics: Statistics;
    private optimization: Array<PlayerOptimizationType>;
    // private shouldUseSvgMode: boolean = true;
    private readonly SvgRenderingActiveSignal: Signal<boolean> = new Signal(false);

    constructor(props: PlayerProps) {
        super(props);
        this.verovioStore = new VerovioStore(this.onError);
        this.arrangementStore = new ArrangementStore(this.props.options, this.onError);
        this.playModeStore = new PlayModeStore(this.props.options, this.onError);
        this.recordingsStore = new RecordingsStore(this.onError);
        this.gridStore = new GridVerovioStore(this.onError);
        this.displayStore = new DisplayStore(this.graphicElementStore, !!this.props.options.devMode, this.onError);
        this.documentStore = new DocumentStore(this.graphicElementStore, this.displayStore, this.playModeStore, this.onError);
        this.animationPlayheadStore = new AnimationPlayheadStore(this.graphicElementStore);
        this.statistics = new Statistics(this.props.options, this.onError);
        this.optimization = [];
    }


    render() {
        return (
            <div className={cx(css.PlayerComponent, this.props.className)} ref={this.playerElementRef}></div>
        );
    }

    componentDidMount(): void {
        WebAudio.init(this.props.options);
        this.setup(this.playerElementRef.current as HTMLElement);
        this.statistics.setup();
    }

    componentWillUnmount(): void {
        this.statistics.teardown();
        this.teardown();
    }

    setup = (playerElement: HTMLElement) => {
        if (this.setupCount++ > 0) {
            this.onError("Multiple setups!");
            return;
        }
        if (!this.props.options.apiUrl) {
            this.onError("No apiUrl specified!");
            return;
        }
        if (!this.props.options.apiToken) {
            this.onError("No apiToken specified!");
            return;
        }

        // alert(JSON.stringify(this.props.options));

        this.isMobile = window.matchMedia("(hover: none)").matches;
        this.optimization = this.props.options.optimization ?? [];

        this.videoElementStore.setup(playerElement);
        this.graphicElementStore.setup(playerElement, this.isMobile);
        if (this.props.onPlayerCreated) {
            this.props.onPlayerCreated(
                this.graphicElementStore.graphicElement,
                this.videoElementStore.videoContainerElement
            );
        }

        // --------------------------------------------------------------------------------
        // key handler to manually trig methods during develop
        if (this.props.options.devMode) {
            document.addEventListener("keypress", (e) => {
                switch (e.key) {
                    // case "a":
                    //     this.gridCreate();
                    //     break;
                    // case "c":
                    //     console.log('"barJumps": ' + JSON.stringify(this._timeSyncAndBarJumps?.barJumps) + ' , "useBarJumpsFix": true');
                    //     console.log('"timeSync": ' + JSON.stringify(this._timeSyncAndBarJumps?.timeSync) + ' , "useTimeSyncFix": true');
                    //     break;
                    case "ä":
                        this.SvgRenderingActiveSignal.value = !this.SvgRenderingActiveSignal.value;
                        this.playModeKickoffGraphicsLoadingHandler();
                        break;
                    default:
                    // console.log(e.key);
                }
            });
        }

        //-------------
        // set filter function for recordings
        this.recordingsStore.filterRecordingsFun = (r: Recording) =>
            r.channelType !== "Master";
        //-------------


        this.arrangementStore.arrangementStatusSignal.add(this.playModeLoadingKickoffHandler, true);

        this.playModeStore.playModeDetailsSignal.add(this.recordingsKickoffLoadingHandler, true);
        this.playModeStore.playModeDetailsSignal.add(this.displayModeNotifyUIAboutAvailable, true);
        this.displayStore.displayModeSignal.add(this.displayModeNotifyUIAboutAvailable, true);

        this.playModeStore.arrangementPlayModesSignal.add(this.displayModeAvoidLandscapeInOtherThanXml, true);
        this.displayStore.displayModeSignal.add(this.displayModeAvoidLandscapeInOtherThanXml, true);

        this.graphicElementStore.pageInteractionSignal.add(this.displayOnPageSwipePinchInteraction, true);
        this.recordingsStore.recordingsStatusSignal.add(this.recordingStatusDisplay, true);
        this.recordingsStore.recordingsStatusSignal.add(this.mediaEngineKickoffHandler);
        this.recordingsStore.currentRecordingsSignal.add(this.recordingsOnChangeNotifyUi, true);


        this.playModeStore.playModeDetailsSignal.add(this.playModeKickoffGraphicsLoadingHandler, true);
        VerovioStore.toolkitSignal.add(this.playModeKickoffGraphicsLoadingHandler, true);

        this.displayStore.displayFormatChange.add(this.gridVeriovioClear);
        this.displayStore.beforeSetDisplayMode = this.gridClear;
        this.displayStore.displayModeSignal.add(this.gridClear, false);

        this.displayStore.displayModeSignal.add(this.gridCreate, false);
        this.documentStore.documentStatusSignal.add(this.gridCreate, false);
        this.gridStore.verovioDataSignal.add(this.gridCreate, false);
        this.recordingsStore.recordingsStatusSignal.add(this.gridCreate, false);
        this.documentStore.pagesLayoutBaseSignal.add(this.gridCreate, false);
        this.gridStore.gridVerovioInfoSignal.add(this.gridCreate, false);



        this.displayStore.displayFormatChange.add(this.displaySwitchBetweenPortraitAndLandscapeHandler, true);
        this.displayStore.displayModeSignal.add(this.gridHideAnimationsExceptXmlAndScorxPageAndLandscape, true);

        this.playModeStore.arrangementPlayModesSignal.add(this.gridHideAnimationsExceptXmlAndScorxPageAndLandscape, true);
        this.playModeStore.arrangementPlayModesSignal.add(this.gridOverlayClear, true);
        this.playModeStore.arrangementPlayModesSignal.add(this.displayZoomChangeNotifyUI, false);

        this.displayStore.displayModeSignal.add(this.documentStore.updatePageLayout, false);
        this.displayStore.zoomScalePortrait.add(this.documentStore.updatePageLayout, false);
        this.displayStore.zoomScaleLandscape.add(this.documentStore.updatePageLayout, false);
        this.displayStore.displayLandscapeSize.add(this.documentStore.updatePageLayout, false);
        this.graphicElementStore.graphicFrameSizeSignal.add(this.documentStore.updatePageLayout, false);
        this.documentStore.pagesLayoutDetailedSignal.add(this.displayZoomChangeNotifyUI, true);

        this.documentStore.documentStatusSignal.add(this.displayDocumentRenderingOverlay, false);
        this.documentStore.spreadLayoutStatusSignal.add(this.displayUpdateSpreadNavigationStatus, true);
        this.graphicElementStore.zoomChangeSignalSignal.add(this.displayHandleZoomDeltaChange);

        if (this.isMobile) {
            // handle touchpad interaction from graphicElement
            this.graphicElementStore.onGridItemMouseEventAction =
                this.gridOnItemMouseEventAction;
        } else {
            // handle mouse interaction from gridItem elements
            this.gridStore.onGridItemMouseEventAction =
                this.gridOnItemMouseEventAction;
        }

        // scroll animation
        this.scrollIdleTimer = new IdleTimer(
            SCROLL_ANIMATION_REBUILD_TIMEOUT,
            this.animationScrollUpdate
        );
        this.animationScrollStore.scrollAnimationDataSignal.add(this.animationScrollNotifyUI, false);
        this.graphicElementStore.graphicFrameScrollSignal.add(this.graphicViewportScrollNotifyUI, true);
        this.graphicElementStore.graphicFrameScrollSignal.add(this.animationScrollHandlePlayheadOutOfSight, true);
        this.animationScrollStore.scrollActiveSignal.add(this.animationScrollHandleActiveChange, true);
        this.animationScrollStore.preventAllScrolling.add(this.onPreventAllScrollingChange, true);

        //
        this.playModeStore.playModeDetailsSignal.add(this.statsOnPlayModeDetails, false);
        this.arrangementStore.arrangementStatusSignal.add(this.statsOnArrangementStatus, true);
        this.recordingsStore.currentRecordingsSignal.add(this.statsRecordingsChange, true);

        RenderXml.beforeXmlRender = this.gridOverlayHide;
        RenderXml.afterXmlRender = this.gridOverlayShow;

        if (this.props.onPlayModesData) {
            this.playModeStore.arrangementPlayModesSignal.add(this.props.onPlayModesData, true);
        }
        if (this.props.onRecordingsStatus) {
            this.recordingsStore.recordingsStatusSignal.add(this.props.onRecordingsStatus, true);
        }
        if (this.props.onPlayModeDetailsStatus) {
            this.playModeStore.playModeDetailsSignal.add(this.props.onPlayModeDetailsStatus, true);
        }
    };

    private setupMediaEngine(engine: IMediaEngine) {
        engine.playbackStatusSignal.add(this.playbackStatusChange, true);
        engine.playbackPositionSignal.add(this.playbackPositionChange, true);
        engine.playbackStatusSignal.add(this.statsOnPlaybackChange, false);
        engine.playbackPositionSignal.add(this.statsOnPlaybackPosition, false);
        // engine.playbackStatusSignal.add(this.syncPlayheadAnimation, true);
        engine.playbackPeriodicStatusSignal.add(
            this.animationsSyncToPlaybackTime,
            true
        );

        if (this.scrollIdleTimer && !(engine instanceof EngineVideo)) {
            this.scrollIdleTimer.setup();
            this.documentStore.pagesLayoutDetailedSignal.add(
                this.scrollIdleTimer.touch,
                false
            );
        }
    }

    teardown = () => {
        this.arrangementStore.arrangementStatusSignal.remove(this.playModeLoadingKickoffHandler);
        this.playModeStore.playModeDetailsSignal.remove(this.recordingsKickoffLoadingHandler);
        this.playModeStore.playModeDetailsSignal.remove(this.playModeKickoffGraphicsLoadingHandler);
        this.playModeStore.playModeDetailsSignal.remove(this.displayModeNotifyUIAboutAvailable);
        this.displayStore.displayModeSignal.remove(this.displayModeNotifyUIAboutAvailable);
        this.playModeStore.arrangementPlayModesSignal.remove(this.displayModeAvoidLandscapeInOtherThanXml);
        this.displayStore.displayModeSignal.remove(this.displayModeAvoidLandscapeInOtherThanXml);
        this.graphicElementStore.pageInteractionSignal.remove(this.displayOnPageSwipePinchInteraction);
        this.recordingsStore.recordingsStatusSignal.remove(this.recordingStatusDisplay);
        this.recordingsStore.recordingsStatusSignal.remove(this.mediaEngineKickoffHandler);
        this.recordingsStore.currentRecordingsSignal.remove(this.recordingsOnChangeNotifyUi);
        this.displayStore.displayFormatChange.remove(this.displaySwitchBetweenPortraitAndLandscapeHandler);
        this.displayStore.displayModeSignal.remove(this.documentStore.updatePageLayout);
        this.displayStore.zoomScalePortrait.remove(this.documentStore.updatePageLayout);
        this.displayStore.zoomScaleLandscape.remove(this.documentStore.updatePageLayout);
        this.displayStore.displayLandscapeSize.remove(this.documentStore.updatePageLayout);
        this.graphicElementStore.graphicFrameSizeSignal.remove(this.documentStore.updatePageLayout);
        this.documentStore.pagesLayoutDetailedSignal.remove(this.displayZoomChangeNotifyUI);
        this.documentStore.documentStatusSignal.remove(this.displayDocumentRenderingOverlay);
        this.documentStore.spreadLayoutStatusSignal.remove(this.displayUpdateSpreadNavigationStatus);
        this.documentStore.documentStatusSignal.remove(this.gridCreate);
        this.graphicElementStore.zoomChangeSignalSignal.remove(this.displayHandleZoomDeltaChange);


        this.displayStore.displayModeSignal.remove(this.gridClear);
        this.documentStore.documentStatusSignal.remove(this.gridCreate);
        this.gridStore.verovioDataSignal.remove(this.gridCreate);
        this.recordingsStore.recordingsStatusSignal.remove(this.gridCreate);
        this.documentStore.pagesLayoutBaseSignal.remove(this.gridCreate);
        this.gridStore.gridVerovioInfoSignal.remove(this.gridCreate);

        this.displayStore.displayModeSignal.remove(this.gridHideAnimationsExceptXmlAndScorxPageAndLandscape);
        this.playModeStore.arrangementPlayModesSignal.remove(this.gridHideAnimationsExceptXmlAndScorxPageAndLandscape);
        this.playModeStore.arrangementPlayModesSignal.remove(this.gridOverlayClear);
        this.playModeStore.arrangementPlayModesSignal.remove(this.displayZoomChangeNotifyUI);

        // scroll animation
        this.scrollIdleTimer?.teardown();
        if (this.scrollIdleTimer) {
            this.documentStore.pagesLayoutDetailedSignal.remove(this.scrollIdleTimer.touch);
        }
        this.animationScrollStore.scrollAnimationDataSignal.remove(this.animationScrollNotifyUI);
        this.graphicElementStore.graphicFrameScrollSignal.remove(this.graphicViewportScrollNotifyUI);
        this.graphicElementStore.graphicFrameScrollSignal.remove(this.animationScrollHandlePlayheadOutOfSight);
        this.animationScrollStore.scrollActiveSignal.remove(this.animationScrollHandleActiveChange);
        this.animationScrollStore.preventAllScrolling.remove(this.onPreventAllScrollingChange);

        this.playModeStore.playModeDetailsSignal.remove(this.statsOnPlayModeDetails);
        this.arrangementStore.arrangementStatusSignal.remove(this.statsOnArrangementStatus);
        this.recordingsStore.currentRecordingsSignal.remove(this.statsRecordingsChange);

        if (this.props.onPlayModesData) {
            this.playModeStore.arrangementPlayModesSignal.remove(this.props.onPlayModesData);
        }
        if (this.props.onRecordingsStatus) {
            this.recordingsStore.recordingsStatusSignal.remove(this.props.onRecordingsStatus);
        }
        if (this.props.onPlayModeDetailsStatus) {
            this.playModeStore.playModeDetailsSignal.remove(this.props.onPlayModeDetailsStatus);
        }
    };

    private teardownMediaEngine(engine: IMediaEngine) {
        engine.playbackStatusSignal.remove(this.playbackStatusChange);
        engine.playbackPositionSignal.remove(this.playbackPositionChange);
        engine.playbackStatusSignal.remove(this.statsOnPlaybackChange);
        engine.playbackPositionSignal.remove(this.statsOnPlaybackPosition);
        engine.playbackPeriodicStatusSignal.remove(
            this.animationsSyncToPlaybackTime
        );
    }

    // ================================================================
    // stats
    // ================================================================

    private statsOnPlayModeDetails = (value: PlayModeDetailsStatus) => {
        if (!this.props.options.devMode) this.statistics.handlePlayModeChange(value);
    };

    private statsOnArrangementStatus = (value: ArrangementStatus) => {
        if (!this.props.options.devMode) this.statistics.handleArrangementStatus(value);
    };

    private statsRecordingsChange = (value: Recording[]) => {
        if (!this.props.options.devMode) this.statistics.handleRecordingsChange(value);
    };

    private statsOnPlaybackChange = (value: PlaybackStatus) => {
        if (!this.props.options.devMode) this.statistics.handlePlaybackChange(value);
    };

    private statsOnPlaybackPosition = (value: PlaybackPosition) => {
        if (!this.props.options.devMode) this.statistics.handlePlaybackPositionChange(value);
    };

    // ================================================================
    // playModes
    // ================================================================

    private playModeLoadingKickoffHandler = (s: ArrangementStatus) => {
        this.playModeLoadingKickoff(s).catch(this.onError);
    };

    private playModeLoadingKickoff = async (s: ArrangementStatus) => {
        switch (s.status) {
            case "arrangementSuccess":
                this.animationPlayheadStore.playheadHide();
                // console.log('playModes', s.arrangementData.playModes);
                return this.playModeStore.setPlayModes(
                    s.arrangementId,
                    s.arrangementData,
                    s.defaultPlayModeId
                );
            default:
                return Promise.resolve();
        }
    };

    private playModeKickoffGraphicsLoadingHandler = () => {
        this.playModeKickoffGraphicsLoading().catch(this.onError);
    };

    private playModeKickoffGraphicsLoading = async () => {
        // dont rely on in-parmeters as this is called from multiple stores
        // console.debug('VerovioStore.toolkitSignal.value', VerovioStore.toolkitSignal.value);
        if (VerovioStore.toolkitSignal.value.state !== 'verovioLoaded') return;
        this.gridOverlayHide();
        this.documentStore.clearPageElements();
        const selectedPlayMode = this.playModeStore.playModeDetailsSignal.value;

        switch (selectedPlayMode.status) {
            case "selectedPlayModeSuccess":
                switch (selectedPlayMode.typeData.type) {
                    case PlaymodeType_2_3.Xml:

                        let useSvgMode = this.SvgRenderingActiveSignal.value;
                        if (!canUseSvgRendering(selectedPlayMode)) useSvgMode = false;

                        if (useSvgMode) {
                            //====================================================================
                            // SVG MODE
                            //====================================================================

                            // can show this display mode?
                            switch (this.displayStore.getCurrentOrientation()) {
                                case 'Vertical':
                                    if (!selectedPlayMode.typeData.svgsVertical) {
                                        alert('No svgs for Vertical mode');
                                        return;
                                    }
                                    break;
                                case 'Horizontal':
                                    if (!selectedPlayMode.typeData.svgsHorizontal) {
                                        alert('No svgs for Horizontal mode');
                                        return;
                                    }

                                    const landscapeSvg = selectedPlayMode.typeData!.svgsHorizontal![0];
                                    if (landscapeSvg) {
                                        const width: number = landscapeSvg.width ?? 100;
                                        const height: number = landscapeSvg.height ?? 100;
                                        console.log('set landscape', { width, height });
                                        this.displayStore.displayLandscapeSize.value = { width, height };
                                    }


                                    break;
                            }
                            const pages = selectedPlayMode.typeData.svgs!.filter(p => p.layout === this.displayStore.getCurrentOrientation());
                            const pageDivs = await renderXmlSvg(pages, this.documentStore, this.displayStore.getCurrentOrientation());

                            if (this.displayStore.getCurrentOrientation() === 'Vertical') {
                                pageDivs.forEach((pageDiv, pageIdx) => {
                                    addArrangementInfo(pageIdx, pageDiv, this.arrangementStore.getArrangementData());
                                })
                            }


                        } else {
                            //====================================================================
                            // XML LEGACY MODE
                            //====================================================================
                            if (!selectedPlayMode.typeData.musicXmlUrl) selectedPlayMode.typeData.musicXmlUrl = selectedPlayMode.typeData.musicXml;
                            switch (this.displayStore.displayFormatChange.value) {
                                case "portrait":
                                    await RenderXml.renderPortrait(
                                        selectedPlayMode.typeData.musicXmlUrl ?? '',
                                        this.documentStore,
                                        this.verovioStore,
                                        this.gridStore,
                                        this.arrangementStore.getArrangementData(),
                                        selectedPlayMode.typeData,
                                        this.optimization,
                                    );
                                    break;
                                case "landscape":
                                    await RenderXml.renderLandscape(
                                        selectedPlayMode.typeData.musicXmlUrl ?? '',
                                        this.documentStore,
                                        this.verovioStore,
                                        this.gridStore,
                                        this.displayStore,
                                        selectedPlayMode.typeData,
                                        this.optimization,
                                    );
                            }
                        }
                        break;
                    case PlaymodeType_2_3.Pdf:
                        await RenderPdf.renderPdf(
                            selectedPlayMode.typeData.pdfUrl ?? '',
                            this.documentStore
                        );
                        break;
                    case PlaymodeType_2_3.Audio:
                        RenderAudio.renderAudio(this.documentStore);
                        break;
                    case PlaymodeType_2_3.ScorX:
                        await RenderScorX.renderScorX(
                            selectedPlayMode.typeData.pages ?? [],
                            this.documentStore
                        );
                        break;
                    case PlaymodeType_2_3.Video:
                        RenderAudio.renderAudio(this.documentStore);
                        break;
                    default:
                        this.onError(
                            `Playmode not supported: ${selectedPlayMode.typeData.type.toString()}`
                        );
                }
                break;
            default:
        }
    };

    // ================================================================
    // recordings
    // ================================================================

    private recordingsKickoffLoadingHandler = (s: PlayModeDetailsStatus) => {
        this.recordingsKickoffLoading(s).catch(this.onError);
    };

    private recordingsKickoffLoading = async (s: PlayModeDetailsStatus) => {
        switch (s.status) {
            case "selectedPlayModeSuccess":
                switch (s.typeData.type) {
                    case PlaymodeType_2_3.Video:
                        await this.mediaEngineKickoff();
                        this.videoElementStore.setVideoUrl(s.typeData.videoUrl ?? '');
                        this.videoElementStore.setVisible(true);
                        this.graphicElementStore.setVisible(false);
                        this.recordingsOnChangeNotifyUi([]);
                        break;
                    default: {
                        await this.mediaEngineKickoff();
                        this.graphicElementStore.setVisible(true);
                        this.videoElementStore.setVisible(false);
                        this.videoElementStore.setVideoUrl('');
                        const playerMode =
                            detectBrowser() == Browser.FIREFOX
                                ? PlayerMode.Standard
                                : PlayerMode.Segmented;
                        await this.recordingsStore.loadAudioRecordings(
                            s.arrangementData.id,
                            s.baseData.recordings,
                            playerMode,
                            true
                        );
                        break;
                    }
                }
                break;
            default:
        }
    };

    private recordingStatusDisplay = (s: RecordingsStatus) => {
        switch (s.status) {
            case "recordingsSuccess":
                //console.log('All recordings loaded');
                break;
            case "recordingsLoading":
                // console.log('recordings loading', s.progress.round1());
                break;
            default:
        }
    };

    private recordingsOnChangeNotifyUi = (recordings: Array<Recording>) => {
        if (this.props.onRecordingsChange) {
            this.props.onRecordingsChange(recordings);
        }
    };

    // ================================================================
    // display
    // ================================================================

    private displaySwitchBetweenPortraitAndLandscapeHandler = () => {
        this.displaySwitchBetweenPortraitAndLandscape().catch(this.onError);
    };

    private displaySwitchBetweenPortraitAndLandscape = async () => {
        const selectedPlayMode =
            this.playModeStore.arrangementPlayModesSignal.value.selectedPlayMode;
        if (selectedPlayMode.type !== PlaymodeType_2_3.Xml) {
            return;
        }
        await this.playModeKickoffGraphicsLoading();
    };

    private displayModeNotifyUIAboutAvailable = () => {
        // if callback doesn't exist - do nothing
        if (!this.props.onAvailableDisplayModes) {
            return;
        }
        const playModeDetailStatus = this.playModeStore.playModeDetailsSignal.value;
        const currentDisplayMode = this.displayStore.displayModeSignal.value;
        const uiDisplayModes: UIDisplayModes = {
            available: {},
            current: currentDisplayMode,
        };
        const addPortraitDisplayModes = () => {
            uiDisplayModes.available["Portrait-Height"] = {
                state: "displayModePortrait",
                type: "height"
            };
            uiDisplayModes.available["Portrait-Width"] = {
                state: "displayModePortrait",
                type: "width"
            };
            uiDisplayModes.available["Portrait-Full"] = {
                state: "displayModePortrait",
                type: "full"
            };
            uiDisplayModes.available["Portrait-Zoom"] = {
                state: "displayModePortrait",
                type: "zoom"
            };
        };

        const addSpreadDisplayMode = () => {
            uiDisplayModes.available.Spread =
                currentDisplayMode.state === "displayModeSpread"
                    ? currentDisplayMode
                    : {
                        state: "displayModeSpread",
                        pageNr: 0,
                    };
        };

        const addLandscapeDisplayModes = () => {
            uiDisplayModes.available["Landscape-Height"] = {
                state: "displayModeLandscape",
                type: 'height'
            };
            uiDisplayModes.available["Landscape-Zoom"] = {
                state: "displayModeLandscape",
                type: 'zoom'
            };
        };

        switch (playModeDetailStatus.status) {
            case "selectedPlayModeSuccess":
                switch (playModeDetailStatus.typeData.type) {
                    case PlaymodeType_2_3.Video:
                    case PlaymodeType_2_3.Audio:
                        // no displayModes
                        break;
                    case PlaymodeType_2_3.ScorX:
                    case PlaymodeType_2_3.Pdf:
                        addPortraitDisplayModes();
                        addSpreadDisplayMode();
                        break;
                    case PlaymodeType_2_3.Xml:
                        addPortraitDisplayModes();
                        addSpreadDisplayMode();
                        addLandscapeDisplayModes();
                        break;
                }
                break;
            default:
        }

        this.props.onAvailableDisplayModes(uiDisplayModes);
    };

    private displayModeAvoidLandscapeInOtherThanXml = () => {
        const selectedPlayMode: Playmode_2_3 =
            this.playModeStore.arrangementPlayModesSignal.value.selectedPlayMode;
        const selectedDisplayMode: DisplayModeState =
            this.displayStore.displayModeSignal.value;
        switch (selectedPlayMode.type) {
            case PlaymodeType_2_3.Xml:
                break;
            default:
                switch (selectedDisplayMode.state) {
                    case "displayModeLandscape":
                        this.displayStore.setDisplayMode({
                            state: "displayModeSpread",
                            pageNr: 0,
                        });
                }
        }
    };

    private displayUpdateSpreadNavigationStatus = (s: SpreadLayoutStatus) => {
        if (this.props.onSpreadNavigationStatus) {
            this.props.onSpreadNavigationStatus(s);
        }
    };

    private displayDocumentRenderingOverlay = (s: DocumentStatus) => {
        if (this.props.onDocumentOverlayStatus) {
            this.props.onDocumentOverlayStatus(s);
        }
    };

    private displayOnPageSwipePinchInteraction = (s: PageInteraction) => {
        switch (s.type) {
            case "swipe": {
                const nrOfPages =
                    this.documentStore.pagesLayoutBaseSignal.value?.totalNrOfPages ?? 0;
                const visibleNrOfPages =
                    this.documentStore.pagesLayoutDetailedSignal.value
                        ?.visibleNrOfPages ?? 1;
                const currentPageNr =
                    this.displayStore.displayModeSignal.value.state ===
                        "displayModeSpread"
                        ? this.displayStore.displayModeSignal.value.pageNr
                        : 0;
                // console.log('current', currentPageNr, 'visible', visibleNrOfPages, 'total', nrOfPages);
                switch (this.displayStore.displayModeSignal.value.state) {
                    case "displayModeSpread":
                        switch (s.type) {
                            case "swipe":
                                switch (s.direction) {
                                    case "left": {
                                        const goToPage = Math.min(
                                            currentPageNr + visibleNrOfPages,
                                            nrOfPages - visibleNrOfPages
                                        );
                                        this.displayStore.goToSpreadPageNr(goToPage);
                                        break;
                                    }
                                    case "right": {
                                        const goToPage = Math.max(
                                            currentPageNr - visibleNrOfPages,
                                            0
                                        );
                                        this.displayStore.goToSpreadPageNr(goToPage);
                                        break;
                                    }
                                }
                        }
                        break;
                    default:
                }
                break;
            }
            case "pinch":
                this.displayStore.changeZoomValue(s.change);
                break;
            default:
            //console.log(s);
        }
    };

    private _displayPrevZoomState: ZoomInformation | null = null;
    private displayZoomChangeNotifyUI = () => {
        if (!this.props.onZoomChange) {
            return;
        }

        let zs: ZoomInformation | null = null;
        switch (this.playModeStore.arrangementPlayModesSignal.value.selectedPlayMode.type) {
            case PlaymodeType_2_3.Audio:
            case PlaymodeType_2_3.Video:
                zs = { isZoomable: false, zoomValue: 0 };
                break;
            default:
                switch (this.displayStore.displayModeSignal.value.state) {
                    case "displayModeLandscape":
                        zs = {
                            zoomValue: this.displayStore.getZoomValueFromPageFormat(),
                            isZoomable: true,
                        };
                        break;
                    case "displayModePortrait":
                        zs = {
                            zoomValue: this.displayStore.getZoomValueFromPageFormat(),
                            isZoomable: true,
                        };
                        break;
                    default:
                        zs = {
                            zoomValue: 0,
                            isZoomable: false,
                        };
                }
        }
        if (!deepEqual(zs, this._displayPrevZoomState)) {
            this.props.onZoomChange(zs);
            this._displayPrevZoomState = zs;
        } else {
            // console.log('no need to update zoomState');
        }
    };

    private displayHandleZoomDeltaChange = (v: number) => {
        this.displayStore.setZoomDelta(v);
    };

    // ================================================================
    // grid
    // ================================================================
    private _gridInfo: GridVerovioInfo1 | null = null;
    private _gridTimeDetails: GridTimeDetails | null = null;
    private _timeSyncAndBarJumps: TimeSyncAndBarJumps | null = null;

    private gridCreate = async () => {
        // console.log('gridCreate');
        if (this.recordingsStore.recordingsStatusSignal.value.status != "recordingsSuccess") {
            // console.log("## gridCreate", "recordingsStore.recordingsStatusSignal != recordingsSuccess, return!");
            return;
        }
        // const documentStatus = this.documentStore.documentStatusSignal.value;
        const playModeValue = this.playModeStore.playModeDetailsSignal.value;
        switch (playModeValue.status) {
            case "selectedPlayModeSuccess":
                // prepare grid creation specific for xml or scorx playmode

                switch (playModeValue.typeData.type) {
                    case PlaymodeType_2_3.Xml:

                        this.gridOverlayHide();

                        let useSvgMode = this.SvgRenderingActiveSignal.value;
                        // let useSvgMode = false;

                        if (!canUseSvgRendering(playModeValue)) useSvgMode = false;

                        if (this.props.onSvgStatus) {
                            this.props.onSvgStatus(getSvgStatus(playModeValue, useSvgMode, this.SvgRenderingActiveSignal.value));
                        }

                        // let svgMode = (playModeValue.typeData.syncs !== null);
                        // svgMode = false;



                        //====================================================================
                        // XML LEGACY MODE
                        //====================================================================
                        if (!useSvgMode) {
                            if (!this.gridStore.verovioDataSignal.value) {
                                return;
                            }

                            //----------------------------------------------------------
                            // get TimeSyncAndBarJumps data
                            if (!this._timeSyncAndBarJumps) {
                                // console.log("*** get timeSyncAndBarJumps from database/json");
                                this._timeSyncAndBarJumps = {
                                    timeSync: playModeValue.typeData.timeSync ?? [],
                                    barJumps: playModeValue.typeData.barJumps ?? [],
                                };
                            }

                            //------------------------------------------------------------------
                            // get _gridInfo, if needed
                            if (!this._gridInfo) {
                                // console.log("*** generate _gridVerovioInfo");
                                const syncData: VerovioSyncData = this.gridStore.verovioDataSignal
                                    .value as VerovioSyncData;
                                // console.log(syncData.timemapData);
                                try {
                                    this._gridInfo = this.gridStore.createVerovioGridItems(syncData);

                                } catch (error) {
                                    console.warn(error);
                                    return;
                                }
                                this._timeSyncAndBarJumps.nrOfBars = this._gridInfo.items.length;
                            }
                            //------------------------------------------------------------------
                            // get _gridTimeDetails, if needed
                            if (!this._gridTimeDetails) {
                                // console.log("***´get gridTimeDetailsSignall");
                                const duration = this.recordingsStore.getDuration();

                                this._gridTimeDetails = this.gridStore.calculateGridTimeDetails(
                                    this._gridInfo,
                                    duration,
                                    this._timeSyncAndBarJumps.timeSync,
                                    this._timeSyncAndBarJumps.barJumps,
                                    this.arrangementId
                                );
                            }
                        } // (!svgMode)

                        //====================================================================
                        // SVG MODE
                        //====================================================================
                        if (useSvgMode) {
                            const xmlSyncInfo = playModeValue.typeData.syncs!.find(s => s.layout === this.displayStore.getCurrentOrientation());
                            const response = await fetch(xmlSyncInfo!.url);
                            const json = await response.json();
                            const syncItems: GridExportItem[] = json;

                            const newRootItems: Array<GridVeroivioItem1> = [];
                            const newItemDetails: Array<GridItem1Details> = [];
                            syncItems.forEach((expItem: GridExportItem, expItemIdx) => {
                                const newRootItem: GridVeroivioItem1 = {
                                    id: 'ROOT_ITEM_' + expItem.rootIdx,
                                    x: expItem.x,
                                    y: expItem.y,
                                    w: expItem.w,
                                    h: expItem.h,
                                    idx: expItem.rootIdx,
                                    inDocument: false,
                                    itemEl: null,
                                    pageH: 0,
                                    pageW: 0,
                                    pageX: 0,
                                    pageY: 0,
                                    pageIdx: expItem.pageIdx,
                                    qstamp: 0,
                                    tstamp: expItem.scoreStartTime,
                                    systemIdx: expItem.systemIdx,
                                    tstampDuration: expItem.scoreEndTime - expItem.scoreStartTime,
                                }
                                newRootItems[expItem.rootIdx] = newRootItem;
                            });

                            syncItems.forEach((expItem: GridExportItem, expItemIdx) => {
                                const newItem: GridItem1Details = {
                                    leftPos: expItem.mediaStartTime,
                                    rightPos: expItem.mediaEndTime,
                                    itemId: 'ROOT_ITEM_' + expItem.rootIdx,
                                    item: newRootItems[expItem.rootIdx],
                                    jumpPass: 0,
                                    jumpIdx: 0,
                                    leftTstampPos: expItem.scoreStartTime,
                                    originalLeftPos: 0,
                                    rightTstampPos: expItem.scoreEndTime,
                                }
                                newItemDetails.push(newItem);
                            });

                            // replace with created data
                            this._gridInfo = {
                                items: newRootItems,
                            }

                            //----------------------------------------------------------
                            // get TimeSyncAndBarJumps data
                            if (!this._timeSyncAndBarJumps) {
                                // console.log("*** get timeSyncAndBarJumps from database/json");
                                this._timeSyncAndBarJumps = {
                                    timeSync: playModeValue.typeData.timeSync ?? [],
                                    barJumps: playModeValue.typeData.barJumps ?? [],
                                };
                            }

                            this._timeSyncAndBarJumps.nrOfBars = this._gridInfo.items.length;

                            if (this._timeSyncAndBarJumps.barJumps.length === 0)
                                this._timeSyncAndBarJumps.barJumps.push({ from: 0, to: this._gridInfo.items.length - 1 });

                            // replace with created data
                            this._gridTimeDetails = {
                                items: newRootItems,
                                itemDetails: newItemDetails,
                                barJumps: this._timeSyncAndBarJumps.barJumps,
                                timeSync: this._timeSyncAndBarJumps.timeSync,
                                totalTstampDuration: 0,
                            }
                        } // svgMode

                        break;
                    case PlaymodeType_2_3.ScorX:
                        console.log(
                            "gridCreate scorx /////"
                        );
                        if (!this._gridInfo) {
                            this._gridInfo = this.gridStore.createScorxGridItems(
                                playModeValue.typeData.grid ?? [],
                                playModeValue.typeData.pages ?? []
                            );
                        }

                        if (!this._gridTimeDetails) {
                            const duration = this.recordingsStore.getDuration();
                            this._gridTimeDetails =
                                this.gridStore.calculateGridTimeDetailsScorx(
                                    this._gridInfo,
                                    duration
                                );
                        }

                        break;
                    default:
                    // no grid for playmodes other than xml or scorx
                }

                switch (playModeValue.typeData.type) {
                    case PlaymodeType_2_3.Xml:
                        //========================================================
                        // Export items 


                        let expItems = createExportItems(this._gridTimeDetails!.itemDetails, this._gridTimeDetails!.timeSync);
                        const expItemsFromPlaymode = this.displayStore.getCurrentOrientation() === 'Vertical' ? playModeValue.typeData.gridItemsVertical : playModeValue.typeData.gridItemsHorizontal;
                        // console.log('EXPORT ITEMS', expItems.length, expItemsFromPlaymode?.length);
                        let useSvgMode = this.SvgRenderingActiveSignal.value;
                        if (!canUseSvgRendering(playModeValue)) useSvgMode = false;

                        if (useSvgMode) {
                            if (expItemsFromPlaymode) {
                                if (expItems.length === expItemsFromPlaymode!.length) {

                                    // console.log('Running on expItems from playmode');
                                } else {
                                    // console.log('Playmode griddata error - not same size as _gridTimeDetails');
                                }
                                expItems = expItemsFromPlaymode!;
                            } else {
                                alert('No playmode data for export items')
                            }


                            //========================================================
                            // Recreate items 
                            const { recreatedRootItems: newRootItems, recreatedItemDetails: newItemDetails } = createLegacyGridItems(expItems);
                            this._gridInfo!.items = newRootItems;
                            this._gridTimeDetails!.itemDetails = newItemDetails;
                            //========================================================
                            // Recreate timeSync and barJumps                
                            const recreatedTimeSync = recreateTimeSync(expItems);
                            // console.log('recreatedTimeSync', recreatedTimeSync);
                            const recreatedBarJumps = recreateBarJumps(expItems);
                            if (this._gridTimeDetails) {
                                this._gridTimeDetails.timeSync = recreatedTimeSync;
                                this._gridTimeDetails.barJumps = recreatedBarJumps;
                            }
                            if (this._timeSyncAndBarJumps) {
                                this._timeSyncAndBarJumps.timeSync = recreatedTimeSync;
                                this._timeSyncAndBarJumps.barJumps = recreatedBarJumps;
                            }
                            //========================================================
                        }
                        break;
                    default:
                }

                // console.log('gridInfo', this._gridInfo);


                // finsish grid creation common to xml and scorx
                switch (playModeValue.typeData.type) {
                    case PlaymodeType_2_3.Xml:
                    case PlaymodeType_2_3.ScorX:
                        const layout = this.documentStore.pagesLayoutBaseSignal.value as PagesLayoutBase;
                        this.gridStore.createGridHtmlElements(layout, this._gridInfo!, this.isMobile, !!this.props.options.devMode);
                        // this._gridInfo!.items.forEach(i => { console.log('itemEl', i.idx, i.itemEl) });
                        // no animations in spread mode
                        if (this.displayStore.displayModeSignal.value.state != "displayModeSpread") {
                            this.gridCreatePlayheadAnimation();
                            // this.animationScrollUpdate();
                        } else {
                            // console.log('no antimations in spread mode');
                        }
                        // console.log(this.mediaEngine?.playbackPositionSignal.value.time);
                        if (this.props.options.devMode) {
                            switch (this.displayStore.displayModeSignal.value.state) {
                                case 'displayModeLandscape':
                                case 'displayModePortrait':
                                    this.gridAddSignpostsForBarjumps();
                                    this.gridAddFlagsForTimeSyncs();
                                    this.gridSyncInfoNotifyUI();
                                    break;
                                default:
                                // don't show in spread mode
                            }
                        }
                        this.gridOverlayShow();
                        // console.log("gridCreate ready \\\\\ ");

                        break;
                    default:
                    // no grid for playmodes other than xml or scorx
                }

                break;
        }
    };

    private gridClear = () => {
        // console.log("?????????? gridClear -----------------------------------");
        this._gridInfo = null;
        this._gridTimeDetails = null;
        this._timeSyncAndBarJumps = null;
    };

    private gridVeriovioClear = () => {
        // console.log("?????????? gridVeroivioClear -----------------------------------");
        this.gridStore.verovioDataSignal.value = null;
    }

    private gridAddFlagsForTimeSyncs = () => {
        if (!this._gridTimeDetails) return;
        if (!this._gridInfo) return;

        try {
            this._gridTimeDetails.timeSync.forEach((timeSync, timeSyncIdx) => {
                const scorePos = timeSync.scorePos;
                this._gridTimeDetails?.itemDetails.forEach((itemDetails, itemDetailsIdx) => {


                    if ((scorePos >= itemDetails.leftTstampPos) && (scorePos <= itemDetails.rightTstampPos)) {
                        const xFraction = (scorePos - round3(itemDetails.leftTstampPos)) / (round3(itemDetails.rightTstampPos) - round3(itemDetails.leftTstampPos));
                        // console.log('scorePos', timeSync.scorePos, itemDetails.leftPos, itemDetails.leftTstampPos);
                        // if ((scorePos >= itemDetails.leftPos) && (scorePos <= itemDetails.rightPos)) {
                        //     const xFraction = (scorePos - round3(itemDetails.leftPos)) / (round3(itemDetails.rightPos) - round3(itemDetails.rightPos));
                        // console.log(itemDetails.item.itemEl);
                        // console.log(itemDetails.item.idx);
                        // console.log(this._gridInfo!.items[itemDetails.item.idx].itemEl);
                        // const itemEl = itemDetails.item.itemEl;
                        // const itemEl = this._gridInfo!.items[itemDetails.item.idx].itemEl;

                        const itemEl = this._gridInfo!.items[itemDetails.item.idx].itemEl;

                        const syncEl = document.createElement('div') as HTMLElement;
                        syncEl.setAttribute('data-text', `${timeSyncIdx}`);
                        const leftPercent = xFraction * 100 + '%';
                        syncEl.setAttribute('style', `positin:absolute; left:${leftPercent}`);
                        switch (timeSyncIdx % 3) {
                            case 0:
                                syncEl.className = cx(css.GridSyncFlag, css.SyncItem0);
                                break;
                            case 1:
                                syncEl.className = cx(css.GridSyncFlag, css.SyncItem1);
                                break;
                            case 2:
                                syncEl.className = cx(css.GridSyncFlag, css.SyncItem2);
                                break;
                        }
                        itemEl!.appendChild(syncEl);
                        return;
                    }
                });
            });
        } catch (error) {
            console.warn(error);
        }

    };

    private gridAddSignpostsForBarjumps = () => {
        if (!this._gridTimeDetails) return;
        if (!this._gridInfo) return;

        try {
            const addSignpost2 = (
                itemEl: HTMLElement,
                barJumpIdx: number,
                itemIdx: number,
                levelIdx: number,
                from: number,
                to: number,
            ) => {
                itemEl.style.display = 'flex';
                itemEl.style.flexDirection = 'column';
                itemEl.style.gap = '.1rem';

                const sign = document.createElement("div") as HTMLElement;
                sign.title = 'Barjump region ' + (barJumpIdx + 1) + ' from Syncbar ' + from + ' to ' + to + ' (current Syncbar ' + itemIdx + ')';
                // sign.textContent = `${itemIdx}:${barJumpIdx}}`;
                // sign.style.position = 'absolute';
                // sign.style.left = sign.style.right = '0';
                // sign.style.top = barJumpIdx * .8 + 'rem';
                sign.style.height = '.8rem';
                // sign.style.flex = '1';
                sign.style.width = '100%';
                // sign.style.border = '1px solid red';
                // sign.style.borderRadius = '.2rem';
                sign.className = 'HoverVisible';
                switch (barJumpIdx) {
                    case 0:
                    case 8:
                        sign.style.background = '#1E90FF'; break;
                    case 1:
                    case 9:
                        sign.style.background = '#499b5d'; break;
                    case 2:
                    case 10:
                        sign.style.background = 'rgb(141, 112, 69)'; break;
                    case 3: sign.style.background = 'rgb(146, 76, 140)'; break;
                    case 4: sign.style.background = 'rgb(209, 89, 41)'; break;
                    case 5: sign.style.background = '#829b49'; break;
                    case 6: sign.style.background = 'rgb(95, 76, 146)'; break;
                    case 7: sign.style.background = 'rgb(146, 76, 97)'; break;
                    default:
                }
                itemEl.appendChild(sign);
                sign.onmousemove = e => {
                    this.gridOnItemMouseEventAction(e, itemEl.id, 'mousemove', 0, barJumpIdx, levelIdx);
                    e.stopPropagation();
                }
                sign.onclick = e => {
                    this.gridOnItemMouseEventAction(e, itemEl.id, 'click', 0, barJumpIdx, levelIdx);
                    e.stopPropagation();
                }
            };

            const gridItems = this._gridInfo.items;

            let levelMap = new Map<number, number>();
            gridItems.forEach((gridItem, gridItemIdx) => levelMap.set(gridItemIdx, 0));

            gridItems.forEach((gridItem, gridItemIdx) => {
                this._gridTimeDetails!.barJumps.forEach((barJump, barJumpPassIdx) => {
                    // console.log('-barJump', barJumpPassIdx, gridItemIdx, barJump.from, barJump.to);
                    if ((barJump.from <= gridItemIdx) && (barJump.to >= gridItemIdx)) {
                        addSignpost2(gridItem.itemEl!, barJumpPassIdx, gridItemIdx, levelMap.get(gridItemIdx)!, barJump.from, barJump.to);
                        levelMap.set(gridItemIdx, levelMap.get(gridItemIdx)! + 1);
                    }
                });
            });

            this.gridSyncInfoNotifyUI();

        } catch (e) {

        }

    };

    private gridSyncInfoNotifyUI = () => {
        if (this.props.onSyncDataChange) {
            // console.log('gridSyncInfoNotifyUI', this._timeSyncAndBarJumps?.barJumps)
            if (!this._gridTimeDetails) return;
            if (!this._timeSyncAndBarJumps) return;
            this._timeSyncAndBarJumps!.barJumps = this._gridTimeDetails!.barJumps;

            this.props.onSyncDataChange({ ...this._timeSyncAndBarJumps as TimeSyncAndBarJumps }
            );
        }
    };

    private gridOverlayHide = () => {
        // console.log('gridOveralyHide');
        this.graphicElementStore.pagesOverlayElement.style.visibility = "hidden";
        this.graphicElementStore.playheadOverlayElement.style.visibility = "hidden";
        this.graphicElementStore.playheadElement.style.visibility = "hidden";
        this.graphicElementStore.playheadElement.style.left = this.graphicElementStore.playheadElement.style.top = '-1000px';

    };

    private gridOverlayShow = (hidePlayhead = true) => {
        // console.log('gridOverlayShow');
        this.graphicElementStore.pagesOverlayElement.style.visibility = "inherit";
        this.graphicElementStore.playheadOverlayElement.style.visibility = "inherit";
        if (hidePlayhead) {
            this.graphicElementStore.playheadElement.style.left = this.graphicElementStore.playheadElement.style.top = '-1000px';
        } else {
            this.graphicElementStore.playheadElement.style.visibility = "inherit";
        }

    };

    private gridOverlayClear = () => {
        // console.log('removeGrid');
        this.graphicElementStore.pagesOverlayElement.innerHTML = "";
    };

    private gridCreatePlayheadAnimation = () => {
        // console.log('gridCreatePlayheadAnimation')
        if (!this._gridTimeDetails) {
            return;
        }
        if (!this._gridInfo) {
            return;
        }
        if (
            this.displayStore.displayModeSignal.value.state === "displayModeSpread"
        ) {
            return;
        }
        const duration = this.recordingsStore.getDuration();
        this.animationPlayheadStore.createAnimation(
            this._gridInfo,
            this._gridTimeDetails,
            duration,
            this.graphicElementStore.playheadElement
        );
        this.scrollIdleTimer?.touch();
    };

    gridOnItemMouseEventAction = (
        e: MouseEvent,
        elementId: string,
        action: GridMouseActionType,
        xFraction: number,
        barJumpIdx: number = 0,
        levelIdx: number = 0
    ) => {

        if (this._gridTimeDetails) {
            const foundItems = this._gridTimeDetails.itemDetails.filter(
                (item) => item.itemId == elementId
            );
            let foundItem: GridItem1Details = foundItems[levelIdx];
            if (foundItem) {
                const timePos = (foundItem.rightPos - foundItem.leftPos) * xFraction + foundItem.leftPos;
                const tstampPos = (foundItem.rightTstampPos - foundItem.leftTstampPos) * xFraction + foundItem.leftTstampPos;
                const itemIdx = foundItem.item.idx;
                const itemJumpIdx = this._gridTimeDetails.itemDetails.indexOf(foundItem);
                // console.log('timePos', timePos, foundItem.leftPos, foundItem.rightPos, xFraction);
                // console.log('tstampPos', tstampPos, foundItem.leftTstampPos, foundItem.rightTstampPos, xFraction);
                if (this.props.onGridAction) {
                    // notify ui about grid item action
                    this.props.onGridAction({
                        event: e,
                        action,
                        itemId: elementId,
                        foundItem,
                        timePos,
                        tstampPos,
                        itemIdx,
                        itemJumpIdx,
                    });
                }
                switch (action) {
                    case "click":
                        this.gridItemHandleClick(timePos).catch(this.onError);
                        break;
                    case "mousemove":
                        //
                        break;
                    case "mouseout":
                        //
                        break;
                }
            }
        }
    };

    private gridItemHandleClick = async (pos: number) => {
        const duration = this.mediaEngine?.getDuration() ?? 0;
        await this.playbackSetTime(pos * duration);
        this.animationPlayheadStore.syncTimeFraction(pos);
    };

    private gridHideAnimationsExceptXmlAndScorxPageAndLandscape = () => {
        switch (
        this.playModeStore.arrangementPlayModesSignal.value.selectedPlayMode.type
        ) {
            case PlaymodeType_2_3.Xml:
            case PlaymodeType_2_3.ScorX:
                switch (this.displayStore.displayModeSignal.value.state) {
                    case "displayModePortrait":
                    case "displayModeLandscape":
                        break;
                    default:
                        this.animationPlayheadStore.playheadHide();
                }
                break;
            default:
                this.animationPlayheadStore.playheadHide();
        }
    };

    // ================================================================
    // media engine
    // ================================================================

    private mediaEngineTeardown = async () => {
        if (this.mediaEngine) {
            await this.mediaEngine.command({ cmd: "stop" });
            this.teardownMediaEngine(this.mediaEngine);
            await this.mediaEngine.teardown();
            this.mediaEngine = null;
        }
        if (this.scrollIdleTimer) {
            this.scrollIdleTimer.teardown();
            this.documentStore.pagesLayoutDetailedSignal.remove(
                this.scrollIdleTimer.touch
            );
        }
    };

    private mediaEngineKickoffHandler = () => {
        this.mediaEngineKickoff().catch(this.onError);
    };

    private mediaEngineKickoff = async () => {
        switch (
        this.playModeStore.arrangementPlayModesSignal.value.selectedPlayMode.type
        ) {
            case PlaymodeType_2_3.Video:
                await this.mediaEngineTeardown();
                this.mediaEngine = new EngineVideo(
                    this.videoElementStore,
                    this.onError
                );
                this.setupMediaEngine(this.mediaEngine);
                await this.mediaEngine.setup([], 0);
                break;
            default: {
                const s = this.recordingsStore.recordingsStatusSignal.value;
                switch (s.status) {
                    case "recordingsSuccess":
                        // teardown current mediaEngine
                        await this.mediaEngineTeardown();
                        // teardown kickoff the new one
                        switch (s.mode) {
                            case PlayerMode.Standard:
                                this.mediaEngine = new EngineStandard(this.onError);
                                this.setupMediaEngine(this.mediaEngine);
                                await this.mediaEngine.setup(s.recordings, s.duration);
                                break;
                            case PlayerMode.Segmented:
                                this.mediaEngine = new EngineSegmented(this.onError);
                                this.setupMediaEngine(this.mediaEngine);
                                await this.mediaEngine.setup(s.recordings, s.duration);
                                break;
                        }
                        break;
                    case "recordingsEmpty":
                    case "recordingsLoading":
                        // Ignore
                        break;
                }
            }
        }
    };

    // ================================================================
    // scroll animation
    // ================================================================
    setPreventScrolling(prevent: boolean): void {
        console.log("PlayerComponent setPreventScrolling", prevent);
        this.animationScrollStore.preventAllScrolling.value = prevent;
        if (prevent) {
            console.log('STOP SCROLLING');
            this.animationScrollStore.stop();
        } else {
            this.animationScrollUpdate();
        }
    }

    getPreventScrolling(): boolean {
        return this.animationScrollStore.preventAllScrolling.value;
    }

    private animationScrollUpdate = () => {
        try {

            // console.log('ANIM 1');
            const playModeState = this.playModeStore.arrangementPlayModesSignal.value;
            switch (playModeState.selectedPlayMode.type) {
                case PlaymodeType_2_3.ScorX:
                case PlaymodeType_2_3.Xml:
                    break;
                default:
                    return; // return if not xml or
            }
            const displayMode: DisplayModeState =
                this.displayStore.displayModeSignal.value;
            if (displayMode.state === "displayModeSpread") {
                return;
            } // no animation form spread mode

            if (!this._gridInfo) return;
            if (!this._gridTimeDetails) return;
            const layoutDetails = this.documentStore.pagesLayoutDetailedSignal.value!;
            if (!layoutDetails)
                return;


            const duration = this.mediaEngine?.getDuration() ?? 0;
            const currentTime =
                this.mediaEngine?.playbackPositionSignal.value.time ?? 0;

            // console.log('ANIM 2');
            this.animationScrollStore.updateScrollAnimation(
                this._gridInfo,
                this._gridTimeDetails,
                layoutDetails,
                duration,
                displayMode,
                currentTime
            );
            this.animationScrollStore.scrollActiveSignal.value = true; // activate scroll
            // console.log('SyncTime 1');
            this.animationPlayheadStore.syncTime(currentTime);
            if (!this.animationScrollStore.preventAllScrolling.value)
                this.animationScrollStore.syncTime(currentTime);
        } catch (error) {
            console.log('ERROR animationScrollUpdate', error)
        }

    };

    private animationScrollNotifyUI = () => {
        if (this.props.onScrollAnimationCreated) {
            this.props.onScrollAnimationCreated(
                this.animationScrollStore.scrollAnimationDataSignal.value!
            );
        }
        this.graphicViewportScrollNotifyUI();
    };

    private graphicViewportScrollNotifyUI = () => {
        const scroll = this.graphicElementStore.graphicFrameScrollSignal.value;
        if (this.props.onGraphicViewportScroll) {
            this.props.onGraphicViewportScroll(
                this.graphicElementStore.graphicFrameScrollSignal.value
            );
        }
    };

    private animationsSyncToPlaybackTime = () => {
        const periodicStatus = this.mediaEngine?.playbackPeriodicStatusSignal.value;

        switch (periodicStatus?.playbackStatus.status) {
            case "enginePlaying":
                // console.log('SyncTime 2');
                this.animationPlayheadStore.syncTime(
                    periodicStatus.playbackPosition.time
                );
                this.animationPlayheadStore.play();
                //
                if (!this.animationScrollStore.preventAllScrolling.value) {
                    this.animationScrollStore.syncTime(
                        periodicStatus.playbackPosition.time
                    );
                    this.animationScrollStore.play();
                }


                break;
            case "enginePaused":
                this.animationPlayheadStore.stop();
                //                
                if (!this.animationScrollStore.preventAllScrolling.value) {
                    this.animationScrollStore.stop();
                }
                break;
            case "engineBuffering":
                break;
            case "engineEmpty":
                break;
        }
    };

    private animationScrollHandlePlayheadOutOfSight = () => {
        if (this.mediaEngine === null) {
            return;
        }
        if (
            this.mediaEngine.playbackStatusSignal.value.status !== "enginePlaying"
        ) {
            return;
        }

        window.setTimeout(() => {
            const playheadRect =
                this.graphicElementStore.playheadElement.getBoundingClientRect();
            const graphicsRect =
                this.graphicElementStore.graphicElement.getBoundingClientRect();
            if (
                playheadRect.top > graphicsRect.bottom ||
                playheadRect.bottom < 0 ||
                playheadRect.left > graphicsRect.right ||
                playheadRect.right < 0
            ) {
                console.log(
                    "playhead scrolled out of sight, scroll animation turned of"
                );
                this.animationScrollStore.scrollActiveSignal.value = false;
            } else {
                // console.log('PLAYHEAD IN SIGHT');
            }
        }, 700);
    };

    private animationScrollHandleActiveChange = () => {

        const active = this.animationScrollStore.scrollActiveSignal.value;
        if (this.props.onScrollActiveChange) {
            this.props.onScrollActiveChange(
                this.animationScrollStore.scrollActiveSignal.value
            );
        }
    };

    private onPreventAllScrollingChange = () => {
        if (this.props.onPreventScrollingChange) {
            this.props.onPreventScrollingChange(
                this.animationScrollStore.preventAllScrolling.value
            );
        }
    }

    // ================================================================
    // playback
    // ================================================================

    private playbackStatusChange = (s: PlaybackStatus) => {
        if (this.props.onPlaybackStatus) {
            this.props.onPlaybackStatus(s);
        }
    };

    private playbackPositionChange = (s: PlaybackPosition) => {
        if (this.props.onPlaybackPosition) {
            this.props.onPlaybackPosition(s);
        }
    };

    private onError = (error?: unknown) => {
        if (this.props.onError) {
            console.error("onError", error);
            this.props.onError(error);
        }
    };

    //-------------------------------------------------------------------------------
    // Publika metoder

    loadArrangement = (arrangementId: string): Promise<void> => {
        this.arrangementId = arrangementId;
        this.gridVeriovioClear();
        this.gridClear();

        return this.arrangementStore
            .loadArrangement(arrangementId)
            .catch(this.onError);
    };

    setPlayMode = (playMode: Playmode_2_3): Promise<void> => {
        return this.playModeStore.setPlayMode(playMode).catch(this.onError);
    };

    setPlayModeById = (id: string): Promise<void> => {
        return this.playModeStore.setPlayModeById(id).catch(this.onError);
    };

    setDisplayMode = (displayMode: DisplayModeState) => {
        this.displayStore.setDisplayMode(displayMode);
    };

    setZoomValue = (value: number) => {
        this.displayStore.setZoomValue(value);
    };

    setBottomMargin = (margin: number) => {
        margin = Math.min(200, Math.max(0, margin));
        this.documentStore.bottomMarginSignal.value = margin;
    };

    setScrollActive = (active: boolean) => {
        this.animationScrollStore.scrollActiveSignal.value = active;
    };

    playbackStart = (): Promise<void> => {
        if (!this.mediaEngine) {
            return Promise.resolve();
        }
        return this.mediaEngine
            .command({
                cmd: "start",
                context: ".playbackStart",
            })
            .then(() => {
                return;
            })
            .catch(this.onError);
    };

    playbackScrub(time: number) {
        this.animationPlayheadStore.scrub(time);
        this.animationScrollStore.scrub(time);
    }

    playbackScrubFraction(timeFraction: number) {
        this.animationPlayheadStore.scrubFraction(timeFraction);
        this.animationScrollStore.scrubFraction(timeFraction);
    }

    playbackScrubReady() {
        this.animationPlayheadStore.scrubReady();
        this.animationScrollStore.scrubReady();
    }

    playbackStop = (): Promise<void> => {
        if (!this.mediaEngine) {
            return Promise.resolve();
        }
        return this.mediaEngine
            .command({
                cmd: "stop",
                context: ".playbackStop",
            })
            .then(() => {
                return;
            })
            .catch(this.onError);
    };

    playbackSetStartStop = (start: number, stop: number): Promise<void> => {
        if (!this.mediaEngine) {
            return Promise.resolve();
        }
        return this.mediaEngine
            .command({
                cmd: "setStartStop",
                start,
                stop,
                context: ".playbackSetStartStop",
            })
            .then(() => {
                return;
            })
            .catch(this.onError);
    };

    playbackSetLoop = (loop: boolean): Promise<void> => {
        if (!this.mediaEngine) {
            return Promise.resolve();
        }
        return this.mediaEngine
            .command({
                cmd: "setLoop",
                loop,
                context: ".playbackSetLoop",
            })
            .then(() => {
                return;
            })
            .catch(this.onError);
    };

    playbackSetTime = (time: number): Promise<void> => {
        if (!this.mediaEngine) {
            return Promise.resolve();
        }
        return this.mediaEngine
            .command({
                cmd: "setTime",
                time,
                context: ".playbackSetTime",
            })
            .then(() => {
                return;
            })
            .catch(this.onError);
    };

    playbackSetMixerLevel = (recordingId: string, newLevel: number) => {
        this.mediaEngine?.setMixerLevel(recordingId, newLevel);
        this.recordingsStore.setMixerLevel(recordingId, newLevel);
    };

    //-----------------------------------------------------------
    async syncSetTimeSync(timeSync: TimeSyncData) {
        console.log("syncSetTimeSync", timeSync);
        this._timeSyncAndBarJumps!.timeSync = timeSync;

        //-----------------------------------------------------
        //----------------------------------------------------
        // Update SVG Sync data
        const playMode = this.getPlaymodeDetails();
        if (!playMode) return;
        if (playMode?.status === 'selectedPlayModeSuccess' && playMode.typeData.type === 'Xml') {
            //-------------------------------------------------------------------------------------
            // Vertical

            if (!playMode.typeData.verovioVerticalData) {
                const xmlUrl = (playMode.typeData.musicXml ?? playMode.typeData.musicXmlUrl) as string;

                const portraitData: VerovioRenderData = await createVeriovioRenderDataFromXmlUrl(xmlUrl, this.verovioStore, 'Vertical');
                playMode.typeData.verovioVerticalData = portraitData;
            }

            const gridItemsVertical = await createGridExportItemsFromVerovioData(
                playMode.typeData.verovioVerticalData,
                this._timeSyncAndBarJumps!.timeSync,
                this._timeSyncAndBarJumps!.barJumps,
                (e: any) => alert(e)
            );
            console.log(gridItemsVertical);
            playMode.typeData.gridItemsVertical = gridItemsVertical!;

            //-------------------------------------------------------------------------------------
            // Horizontal
            if (!playMode.typeData.verovioHorizontalData) {
                const xmlUrl = (playMode.typeData.musicXml ?? playMode.typeData.musicXmlUrl) as string;
                const horizontalData: VerovioRenderData = await createVeriovioRenderDataFromXmlUrl(xmlUrl, this.verovioStore, 'Horizontal');
                playMode.typeData.verovioHorizontalData = horizontalData;
            }
            const gridItemsHorizontal = await createGridExportItemsFromVerovioData(
                playMode.typeData.verovioHorizontalData,
                this._timeSyncAndBarJumps!.timeSync,
                this._timeSyncAndBarJumps!.barJumps,
                (e: any) => alert(e)
            );
            console.log(gridItemsHorizontal);
            playMode.typeData.gridItemsHorizontal = gridItemsHorizontal!;
        }
        //-----------------------------------------------------
        //----------------------------------------------------

        this._gridTimeDetails = null;
        this.gridCreate();

    }

    async syncSetBarJumps(barJumps: BarJumpsData) {
        console.log("syncSetTimeSync", barJumps);
        this._timeSyncAndBarJumps!.barJumps = barJumps;

        //-----------------------------------------------------
        //----------------------------------------------------
        // Update SVG Sync data
        const playMode = this.getPlaymodeDetails();
        if (!playMode) return;
        if (playMode?.status === 'selectedPlayModeSuccess' && playMode.typeData.type === 'Xml') {
            //-------------------------------------------------------------------------------------
            // Vertical

            if (!playMode.typeData.verovioVerticalData) {
                const xmlUrl = (playMode.typeData.musicXml ?? playMode.typeData.musicXmlUrl) as string;

                const portraitData: VerovioRenderData = await createVeriovioRenderDataFromXmlUrl(xmlUrl, this.verovioStore, 'Vertical');
                playMode.typeData.verovioVerticalData = portraitData;
            }

            const gridItemsVertical = await createGridExportItemsFromVerovioData(
                playMode.typeData.verovioVerticalData,
                this._timeSyncAndBarJumps!.timeSync,
                this._timeSyncAndBarJumps!.barJumps,
                (e: any) => alert(e)
            );
            console.log(gridItemsVertical);
            playMode.typeData.gridItemsVertical = gridItemsVertical!;

            //-------------------------------------------------------------------------------------
            // Horizontal
            if (!playMode.typeData.verovioHorizontalData) {
                const xmlUrl = (playMode.typeData.musicXml ?? playMode.typeData.musicXmlUrl) as string;
                const horizontalData: VerovioRenderData = await createVeriovioRenderDataFromXmlUrl(xmlUrl, this.verovioStore, 'Horizontal');
                playMode.typeData.verovioHorizontalData = horizontalData;
            }
            const gridItemsHorizontal = await createGridExportItemsFromVerovioData(
                playMode.typeData.verovioHorizontalData,
                this._timeSyncAndBarJumps!.timeSync,
                this._timeSyncAndBarJumps!.barJumps,
                (e: any) => alert(e)
            );
            console.log(gridItemsHorizontal);
            playMode.typeData.gridItemsHorizontal = gridItemsHorizontal!;
        }
        //-----------------------------------------------------
        //----------------------------------------------------

        this._gridTimeDetails = null;
        this.gridCreate();
    }

    getSyncData(): { timeSync: TimeSyncData, barJumps: BarJumpsData } {
        return { timeSync: this._timeSyncAndBarJumps!.timeSync, barJumps: this._timeSyncAndBarJumps!.barJumps }
    }

    //-----------------------------------------------------------

    getPlaymodeId(): string | null {
        if (this.playModeStore.playModeDetailsSignal.value.status !== 'selectedPlayModeSuccess') return null;
        return this.playModeStore.playModeDetailsSignal.value.baseData.playMode.id;
    }

    getPlaymodeDetails(): PlayModeDetailsStatus | null {
        return this.playModeStore.playModeDetailsSignal.value;
    }


    getGridExportData(orientation: PageOrientation): GridExportItem[] {
        if (this.displayStore.getCurrentOrientation() !== orientation) {
            alert('Wrong orientation for grid export');
            return [];
        }
        if (this._gridTimeDetails) {
            const expItems = createExportItems(this._gridTimeDetails!.itemDetails, this._gridTimeDetails!.timeSync);
            return expItems;
        }
        console.log('this._gridTimeDetails', this._gridTimeDetails);
        return [];
    }

    setSvgMode(use: boolean): void {
        console.log('setSvgMode', use);

        // if (use) {
        //     if (canUseSvgMode(this.playModeStore.playModeDetailsSignal.value)) {
        //         this.usingSvgModeSignal.value = use;
        //     } else {
        //         alert('Can not use Svg mode here');
        //     }
        // } else {
        //     this.usingSvgModeSignal.value = use;
        // }
        this.SvgRenderingActiveSignal.value = use;

        this.forceRedraw();
    }

    forceRedraw(): void {
        this._timeSyncAndBarJumps = null;
        this._gridInfo = null;
        this._gridTimeDetails = null;
        this.playModeKickoffGraphicsLoadingHandler();
    }

    //-----------------------------------------------------------

    forceReloadPlaymode(): void {
        this.playModeLoadingKickoff(this.arrangementStore.arrangementStatusSignal.value);
    }


}


// type GridExportItem = {
//     idx: number,
//     pageIdx: number,
//     systemIdx: number,
//     rootIdx: number,
//     x: number,
//     y: number,
//     w: number,
//     h: number,
//     mediaStartTime: number,
//     mediaEndTime: number,
//     scoreStartTime: number,
//     scoreEndTime: number,
// }