export const BarJumpColors: Array<string> = [
    "#1E90FF",
    "#499b5d",
    "rgb(141, 112, 69)",
    "rgb(146, 76, 140)",
    "rgb(209, 89, 41)",
    "#829b49",
    "rgb(95, 76, 146)",
    "rgb(146, 76, 97)",
]


/*
    .Pass0 {
        background: #1E90FF;


.Pass1 {
    background: #499b5d;
}

.Pass2 {
    background: rgb(141, 112, 69);
}

.Pass3 {
    background: rgb(146, 76, 140);
}

.Pass4 {
    background: rgb(209, 89, 41);
}

.Pass5 {
    background: #829b49;
}

.Pass6 {
    background: rgb(95, 76, 146);
}

.Pass7 {
    background: rgb(146, 76, 97);
}
*/