export default class IdleTimer {
    private ms: number;
    private handler: number | null = null;
    private cb: () => void;
    public constructor(ms: number, cb: (a?: any) => void, immediateReset = false) {
        this.ms = ms;
        this.cb = cb;
    }

    public setup() {
        if (this.handler !== null)
            window.clearTimeout(this.handler);
        this.handler = window.setTimeout(this.cb, this.ms);
    }

    private reset = () => {
        if (this.handler !== null)
            window.clearTimeout(this.handler);
        this.handler = window.setTimeout(this.cb, this.ms);
    };

    public touch = (a?: any) => {
        this.reset();
    };

    public teardown() {
        if (this.handler !== null)
            window.clearTimeout(this.handler);
    }

}