import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import appsettings from '../../appsettings.json';
import Button from '../../components/Button';

const Header = () => {
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );
  const dispatch = useDispatch<Dispatch>();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    if (!user) return;
    (async () => {
      const claims = await getIdTokenClaims();
      const token = claims?.__raw;
      dispatch.user.setUser({
        name: user.name,
        email: user.email,
        token: token,
        roles: user['https://wearevoice.se/roles'],
      });
    })();
  }, [dispatch.user, getAccessTokenSilently, getIdTokenClaims, user]);
  useEffect(() => {
    error && alert(error);
  }, [error]);
  return (
    <header className="Flex FlexRow AlignCenter JustifyBetween Pad05">
      <div
        className="Flex AlignCenter"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch.arrangement.unloadArrangement();
        }}
      >
        <svg width="30" height="30" viewBox="0 0 69 69" version="1.1">
          <path
            style={{ fill: 'black', fillRule: 'evenodd' }}
            d="M68.814,16.634c0,-9.18 -7.453,-16.634 -16.634,-16.634l-35.546,-0c-9.18,-0 -16.634,7.454 -16.634,16.634l-0,35.546c-0,9.181 7.454,16.634 16.634,16.634l35.546,0c9.181,0 16.634,-7.453 16.634,-16.634l0,-35.546Zm-28.452,4.429c4.691,2.213 7.941,6.984 7.941,12.509c0,7.627 -6.192,13.819 -13.819,13.819c-7.627,0 -13.819,-6.192 -13.819,-13.819c-0,-5.536 3.262,-10.315 7.967,-12.521c2.43,-1.14 3.477,-4.038 2.337,-6.468c-1.14,-2.43 -4.038,-3.477 -6.467,-2.337c-8.012,3.758 -13.563,11.899 -13.563,21.326c0,12.995 10.55,23.545 23.545,23.545c12.995,-0 23.545,-10.55 23.545,-23.545c-0,-9.409 -5.53,-17.537 -13.518,-21.305c-2.427,-1.145 -5.328,-0.104 -6.473,2.323c-1.145,2.427 -0.104,5.328 2.324,6.473Z"
          ></path>
        </svg>
        <p>
          <b>Upload</b>
        </p>
        <pre>20230424.0-{appsettings.WAV_ENVIRONMENT}</pre>
      </div>

      <div className="Flex AlignCenter">
        {isLoading && <p>Loading user...</p>}
        {isAuthenticated && (
          <>
            <div className="Flex">
              <p>{user?.name ?? 'Unknown'}</p>
              {userOrganization && (
                <p>
                  <small>{userOrganization.name}</small>
                </p>
              )}
            </div>
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log out
            </Button>
          </>
        )}
        {!isAuthenticated && (
          <Button onClick={loginWithRedirect}>Log in</Button>
        )}
      </div>

    </header>
  );
};

export default Header;
