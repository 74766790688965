import { BarJumpsData, TimeSyncData } from '../player/playerTypes';

export type ListResponse<T> = {
  total: number;
  limit: number;
  offset: number;
  next: string;
  previous: string;
  sort: string;
  items: T[];
};

export type ArrangementsResponseArrangement_2_3 = {
  id: string;
  name: string;
  title: string;
  subtitle: string;
  composition: string;
  description: string;
  arranger: any;
  contentProvider1: string;
  contentProvider2: string;
  createdUtc: string;
  updatedUtc: string;
  song: any;
  version: Version;
};

export type MigrationArrangementVersionPlaymode = Playmode_2_3 & {
  data: {
    musicXml?: string;
    musicXmlUrl?: string;
    svgs?: { url: string; page: number; layout: 'Horizontal' | 'Vertical' }[];
    syncs?: { url: string; layout: 'Horizontal' | 'Vertical' }[];
    timeSync?: TimeSyncData;
    barJumps?: BarJumpsData;
  };
  publicData: {
    musicXml?: string;
    musicXmlUrl?: string;
    svgs?: { url: string; page: number; layout: 'Horizontal' | 'Vertical' }[];
    syncs?: { url: string; layout: 'Horizontal' | 'Vertical' }[];
    timeSync?: TimeSyncData;
    barJumps?: BarJumpsData;
  };
};

export type MigrationArrangementApi = {
  id: string;
  title: string;
  versions: MigrationArrangementVersion[];
};

export type MigrationArrangementVersion = {
  id: string;
  musicXmlSourceUrl: string;
  playModes: MigrationArrangementVersionPlaymode[];
};

export type SongCreate = {
  name: string;
  description: string;
  iswc: string;
  ice: string;
};

export type ArrarangementCreate = SongCreate & {
  subtitle: string;
  songId: string;
};

export type Version = {
  duration: number;
  bpm: number;
  status: string;
  createdUtc: string;
  updatedUts: string;
  musicXmlSourceUrl: string;
};

export type ArrangementData_2_3 = {
  title: string;
  subtitle: string;
  description: string;
  duration: number;
  composition: string;
  contentType: string;
  playModes: Array<Playmode_2_3>;
  permissions: Array<string>;
  interestedParties: Array<InterestedParty>;
  tags: Array<{ type: string; name: string; label: string }>;
  image: string;
  owner: any;
  id: string;
  createdUtc: string;
  updatedUtc: string;
};

export type Playmode_2_3 = {
  id: string;
  type: PlaymodeType_2_3;
  name: string;
};

export enum PlaymodeType_2_3 {
  Audio = 'Audio',
  ScorX = 'ScorX',
  Pdf = 'Pdf',
  Xml = 'Xml',
  Video = 'Video',
}

export type InterestedPartyItem = {
  id: string;
  description: string;
  name: string;
};

export type InterestedParty = {
  id: string;
  name: string;
  type: InterestedPartyType;
};

export enum InterestedPartyType {
  Composer = 'Composer',
  Arranger = 'Arranger',
  Lyricist = 'Lyricist',
  Translator = 'Translator',
  Artist = 'Artist',
  Other = 'Other',
}

export type Recording = {
  id: string;
  sortOrder: number;
  name: string;
  recordingType: RecordingType;
  channelType: string;
  audioLevel: number;
  audioPan: number;
  url: string;
};

export enum RecordingType {
  Audio = 'Audio',
  Video = 'Video',
}

export type Provider = {
  id: string;
  createdUtc: string;
  updatedUtc: string;
  name: string;
};

export type ArrangementBase = Provider & {
  subtitle: string;
  description: string;
  iswc: string;
  ice: string;
  version: ArrangementVersion;
  arranger: Provider;
  contentProvider1: Provider;
  contentProvider2: Provider;
  publisherUrl: string;
  externalId: string;
  distributingPublisher: any;
  song: Song;
  image: { id: string; createdUtc: string; updatedUtc: string; url: string };
  genres: Array<Provider>;
  composition: any;
  notes: string;
  publisherTemp: Provider;
  catalog: any;
  publicDomain: boolean;
};

export type Song = Provider & {
  originalArtist: Provider;
};

export type ArrangementVersionsResponse = {
  items: Array<ArrangementVersion>;
};

export type ArrangementVersion = {
  musicXmlSourceUrl: string;
  duration: number;
  bpm: number;
  status: string;
  id: string;
  createdUtc: string;
  updatedUtc: string;
};

export type ArrangementCreated = ArrangementBase & {
  subtitle: string;
  version: ArrangementVersion;
};

export type ArrangementUpdated = ArrangementCreated;

export type MetadataItems = Array<MetadataItem>;

export type MetadataItem = {
  id: string;
  type: string;
  name: string;
  labelDe: string;
  labelEn: string;
  labelSv: string;
  level: number;
  path: Array<string>;
  children: MetadataItems;
  labelPathSv: string;
  labelPathEn: string;
  labelPathDe: string;
};

export type RecordingPostData = {
  audioLevel: number;
  downloadUrl: string;
  duration: number;
  name: string;
  recordingType: string;
  size: number;
  sortOrder: number;
  sourceUrl: string;
  type: string;
};

export type RecordingDB = RecordingPostData & {
  id: string;
  createdUtc: string;
  updatedUts: string;
};

export type ArrangementFeedbackReport = {
  id: string;
  createdUtc: string;
  updatedUtc: string;
  arrangementId: string;
  arrangementTitle: string;
  type: string;
  status: string;
  ownerClientUserId: string;
  ownerClientUserDisplayName: string;
  arrangementVersionId: string;
  playModeId: string;
  reporterUserId: string;
  comments: string;
  metadata: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
};
