import { unmute } from '../libs/unmute';

export type WebAudioOptions = {
    /**
     * Optional.
     * Default true.
     * unmute is a disgusting hack that helps..
     * 	1) automatically resume web audio contexts on user interaction
     * 	2) automatically pause and resume web audio when the page is hidden.
     * 	3) ios only: web audio play on the media channel rather than the ringer channel
     * 	4) ios only: disable the media playback widget and airplay when:
     *
     * WebAudio is automatically resumed by checking context state and resuming whenever possible.
     *
     * WebAudio pausing is accomplished by watching the page visilibility API. When on iOS, page focus
     * is also used to determine if the page is in the foreground because Apple's page vis api implementation is buggy.
     *
     * iOS Only: Forcing WebAudio onto the media channel (instead of the ringer channel) works by playing
     * a short, high-quality, silent html audio track continuously when web audio is playing.
     *
     * iOS Only: Hiding the media playback widgets on iOS is accomplished by completely nuking the silent
     * html audio track whenever the app isn't in the foreground.
     *
     * iOS detection is done by looking at the user agent for iPhone, iPod, iPad. This detects the phones fine, but
     * apple likes to pretend their new iPads are computers (lol right..). Newer iPads are detected by finding 
     * mac osx in the user agent and then checking for touch support by testing navigator.maxTouchPoints > 0.
     *
     * This is all really gross and apple should really fix their janky browser.
     * This code isn't optimized in any fashion, it is just whipped up to help someone out on stack overflow, its just meant as an example.
     */
    unmute?: boolean;
    /**
     * Optional.
     * Default false.
     * Allows audio to continue to play in the background. This is not recommended because it will burn battery and display playback controls on the iOS lockscreen.
     */
    allowBackgroundPlayback?: boolean;
    /**
     * Optional.
     * Default false.
     * Forces behavior to that which is on iOS. This *could* be used in the event the iOS detection fails (which it shouldn't). It is strongly recommended NOT to use this.
     */
    forceIOSBehavior?: boolean;
}

export default class WebAudio {
    private static audioContext: AudioContext | undefined;

    static init(options: WebAudioOptions) {
        if (WebAudio.audioContext) {
            console.warn('AudioContext already initialized!');
            return;
        }
        WebAudio.audioContext = ((window.AudioContext as unknown as AudioContext | undefined)
            ? new window.AudioContext()
            : (window.webkitAudioContext as unknown as AudioContext | undefined)
                ? new window.webkitAudioContext()
                : undefined);
        if (!WebAudio.audioContext) {
            throw new Error('Failed to create AudioContext!');
        }
        const webAudioOptions: WebAudioOptions = {
            unmute: options.unmute ?? true,
            allowBackgroundPlayback: options.allowBackgroundPlayback ?? true,
            forceIOSBehavior: options.forceIOSBehavior ?? false,
        };
        if (webAudioOptions.unmute) {
            unmute(WebAudio.audioContext, webAudioOptions.allowBackgroundPlayback, webAudioOptions.forceIOSBehavior);
        }
        console.info('Initialized AudioContext', webAudioOptions);
    }

    static get context(): AudioContext {
        if (!WebAudio.audioContext) {
            throw new Error('AudioContext not initialized!');
        }
        return WebAudio.audioContext;
    }

    static createAudiobuffer = (duration: number, sampleRate: number): Promise<AudioBuffer> => {
        return new Promise<AudioBuffer>((res, rej) => {
            const audioBuffer = WebAudio.context.createBuffer(2, Math.floor(duration * sampleRate), sampleRate);
            res(audioBuffer);
        });
    };

    static decodeArrayBuffer = (arrayBuffer: ArrayBuffer): Promise<AudioBuffer> => {
        // console.log('decodeArrayBuffer', arrayBuffer.byteLength);
        return WebAudio.context.decodeAudioData(arrayBuffer);
    };
}
