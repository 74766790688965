import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get, put } from '../api/Api';
import {
  ListResponse,
  MigrationArrangementApi,
  MigrationArrangementVersionPlaymode,
  PlaymodeType_2_3,
} from '../api/ManagementApi';
import appsettings from '../appsettings.json';
import { createVeriovioRenderDataFromXmlUrl } from '../player/render/RenderXmlExportSVG';
import { createGridExportItemsFromVerovioData } from '../player/grid/gridUtils';
import {
  uploadSvgFile,
  uploadSyncData,
} from '../player/grid/uploadSvgModeData';
import VerovioStore from '../player/store/VerovioStore';

type MigrationState =
  | {
    status: 'empty';
  }
  | {
    status: 'loading';
  }
  | {
    status: 'loaded';
    items: Array<MigrationArrangement>;
    options: {
      limit: number;
      offset: number;
      total: number;
    };
  }
  | {
    status: 'error';
    error: string;
  };

type MigrationStatus = 'NotMigrated' | 'Migrating' | 'Migrated';

type MigrationArrangement = MigrationArrangementApi & {
  status: MigrationStatus;
};

const isMigrated = (pm: MigrationArrangementVersionPlaymode) =>
  pm.type === PlaymodeType_2_3.Xml &&
  !!pm.data.svgs?.length &&
  !!pm.data.syncs?.length &&
  !!pm.data.musicXmlUrl;

const getMigrationStatus = (item: MigrationArrangementApi): MigrationStatus => {
  if (item.versions.every((v) => v.playModes.every((pm) => isMigrated(pm)))) {
    return 'Migrated';
  }
  return 'NotMigrated';
};

export const handleUploadSvgsAndSyncData = async ({
  publicXmlUrl,
  musicXmlSourceUrl,
  verovioStore,
  arrangementId,
  versionId,
  playMode,
  token,
}: {
  publicXmlUrl: string;
  musicXmlSourceUrl: string;
  verovioStore: VerovioStore;
  arrangementId: string;
  versionId: string;
  playMode: MigrationArrangementVersionPlaymode;
  token: string;
}) => {
  await put(
    `/arrangements/${arrangementId}/versions/${versionId}/playmodes/${playMode.id}?ignoreStatusCheck=true`,
    token,
    {
      ...playMode,
      data: {
        ...playMode.data,
        musicXmlUrl: musicXmlSourceUrl,
      },
    }
  );

  const verticalData = await createVeriovioRenderDataFromXmlUrl(
    publicXmlUrl,
    verovioStore,
    'Vertical'
  );
  let pageIdx = 0;
  for (const svg of verticalData.svgs) {
    console.log('Upload portrait svg, page ' + pageIdx);
    const response = await uploadSvgFile(
      appsettings.API_URL,
      arrangementId,
      versionId,
      playMode.id,
      svg,
      pageIdx,
      'Vertical',
      token,
      true
    );
    console.log('page', pageIdx, 'Vertical', response);
    pageIdx++;
  }

  const exportItemsPortrait = await createGridExportItemsFromVerovioData(
    verticalData,
    playMode.data.timeSync ?? [],
    playMode.data.barJumps ?? [],
    (e) => console.error(e)
  );

  if (exportItemsPortrait) {
    await uploadSyncData(
      appsettings.API_URL,
      arrangementId,
      versionId,
      playMode.id,
      exportItemsPortrait,
      'Vertical',
      token,
      true
    );
  }

  const horizontalData = await createVeriovioRenderDataFromXmlUrl(
    publicXmlUrl,
    verovioStore,
    'Horizontal'
  );

  pageIdx = 0;
  for (const svg of horizontalData.svgs) {
    console.log('Upload portrait svg, page ' + pageIdx);
    const response = await uploadSvgFile(
      appsettings.API_URL,
      arrangementId,
      versionId,
      playMode.id,
      svg,
      pageIdx,
      'Horizontal',
      token,
      true
    );
    console.log('page', pageIdx, 'Horizontal', response);
    pageIdx++;
  }

  const exportItemsLandscape = await createGridExportItemsFromVerovioData(
    horizontalData,
    playMode.data.timeSync ?? [],
    playMode.data.barJumps ?? [],
    (e) => console.error(e)
  );

  if (exportItemsLandscape) {
    await uploadSyncData(
      appsettings.API_URL,
      arrangementId,
      versionId,
      playMode.id,
      exportItemsLandscape,
      'Horizontal',
      token,
      true
    );
  }
};

export const DEFAULT_LIMIT = 10;

export const migrationArrangements = createModel<RootModel>()({
  state: { status: 'empty' } as MigrationState,
  reducers: {
    setLoading(state) {
      return { status: 'loading' };
    },
    setLoaded(
      state,
      items: Array<MigrationArrangement>,
      options: {
        total: number;
        limit: number;
        offset: number;
      }
    ) {
      return { status: 'loaded', items, options };
    },
    setEmpty(state) {
      return { status: 'empty' };
    },
    setError(state, error: string) {
      return { status: 'error', error };
    },
  },
  effects: (dispatch) => ({
    async load(
      payload: { search: string; offset: number; limit: number },
      rootState
    ) {
      if (!rootState.user.token) {
        return;
      }

      dispatch.migrationArrangements.setLoading();

      let result: ListResponse<MigrationArrangementApi>;
      try {
        result = await get(
          `/arrangements/versions/playmodes/xml?limit=${payload.limit > 0 ? payload.limit : DEFAULT_LIMIT
          }&offset=${payload.offset}&search=${payload.search ?? ''}`,
          rootState.user.token,
          {}
        );
      } catch (e) {
        dispatch.migrationArrangements.setError(
          `Failed to load migration arrangements: ${e}`
        );
        return;
      }

      dispatch.migrationArrangements.setLoaded(
        result.items.map((item) => ({
          ...item,
          status: getMigrationStatus(item),
        })),
        {
          total: result.total,
          limit: result.limit,
          offset: result.offset,
        }
      );
    },

    async migrate(item: MigrationArrangementApi, rootState) {
      console.log('migrating', item.id);
      if (
        !rootState.user.token ||
        rootState.migrationArrangements.status !== 'loaded'
      ) {
        return;
      }
      const items = [...rootState.migrationArrangements.items];

      const idx = items.findIndex((_item) => _item.id === item.id);

      items[idx] = {
        ...item,
        status: 'Migrating',
      };

      dispatch.migrationArrangements.setLoaded(items, {
        total: rootState.migrationArrangements.options.total,
        limit: rootState.migrationArrangements.options.limit,
        offset: rootState.migrationArrangements.options.offset,
      });

      const verovioStore = new VerovioStore((error) => console.error(error));

      for (const v of item.versions) {
        for (const pm of v.playModes) {
          try {
            await handleUploadSvgsAndSyncData({
              publicXmlUrl: pm.publicData.musicXmlUrl || pm.publicData.musicXml || '',
              musicXmlSourceUrl: v.musicXmlSourceUrl,
              arrangementId: item.id,
              playMode: pm,
              versionId: v.id,
              verovioStore,
              token: rootState.user.token,
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
      const newItems = [...items];

      newItems[idx] = {
        ...item,
        status: 'Migrated',
      };

      dispatch.migrationArrangements.setLoaded(newItems, {
        total: rootState.migrationArrangements.options.total,
        limit: rootState.migrationArrangements.options.limit,
        offset: rootState.migrationArrangements.options.offset,
      });
    },

    async migrateBatch(payload: any, rootState) {
      if (rootState.migrationArrangements.status !== 'loaded') {
        return;
      }
      for (const item of rootState.migrationArrangements.items) {
        await dispatch.migrationArrangements.migrate(item);
      }
    },
  }),
});
