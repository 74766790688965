import { Models } from '@rematch/core';
import { arrangement } from './arrangement';
import { arrangements } from './arrangements';
import { arrangementVersion } from './arrangementVersion';
import { existingInterestedParties } from './existingInterestedParties';
import { existingMetadata } from './existingMetadata';
import { playmodes } from './playmodes';
import { playmodeUploads } from './playmodeUploads';
import { recordings } from './recordings';
import { recordingUploads } from './recordingUploads';
import { arrangementVersions } from './arrangementVersions';
import { ipSearch } from './ipSearch';
import { language } from './language';
import { metadataTree } from './metadataTree';
import { saving } from './saving';
import { reports } from './reports';
import { tabs } from './tabs';
import { user } from './user';
import { userOrganization, userOrganizations } from './userOrganization';
import { migrationArrangements } from './migrationArrangements';


export interface RootModel extends Models<RootModel> {
  arrangements: typeof arrangements;
  user: typeof user;
  userOrganizations: typeof userOrganizations;
  userOrganization: typeof userOrganization;
  arrangement: typeof arrangement;
  metadataTree: typeof metadataTree;
  ipSearch: typeof ipSearch;
  recordingUploads: typeof recordingUploads;
  recordings: typeof recordings;
  existingInterestedParties: typeof existingInterestedParties;
  existingMetadata: typeof existingMetadata;
  playmodes: typeof playmodes;
  arrangementVersions: typeof arrangementVersions;
  arrangementVersion: typeof arrangementVersion;
  playmodeUploads: typeof playmodeUploads;
  saving: typeof saving;
  language: typeof language;
  reports: typeof reports;
  tabs: typeof tabs;
  migrationArrangements: typeof migrationArrangements;
}

export const models: RootModel = {
  arrangements,
  user,
  userOrganizations,
  userOrganization,
  arrangement,
  metadataTree,
  ipSearch,
  recordingUploads,
  recordings,
  existingInterestedParties,
  existingMetadata,
  playmodes,
  arrangementVersions,
  arrangementVersion,
  playmodeUploads,
  saving,
  language,
  reports,
  tabs,
  migrationArrangements,
};
