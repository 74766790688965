import { Note, NoteChildtype, ScorePartwise } from "../MusicXmlElements";
import { ScoreFixer } from "./ScoreFixer";

export default class FixBarPauses implements ScoreFixer {
    private scorePartwise: ScorePartwise;
    constructor(scorePartwise: ScorePartwise) {
        this.scorePartwise = scorePartwise;
    }
    fixScore(): void {
        this.scorePartwise.parts.forEach((part, partIdx) => {
            part.measures.forEach((measure, measureIdx) => {
                measure.voices.forEach((voice, voiceIdx) => {
                    // console.log('part', partIdx, 'measure', measureIdx, 'vocie', voiceIdx, voice.length);
                    if (voice.length === 1) {
                        const note: Note = voice[0];
                        if (note.rest && note.noteType && note.noteType.typeValue == 'whole') {
                            note.rest.measure = 'yes';
                            note.rest.xml?.setAttribute('measure', 'yes');
                            note.removeXmlElement(NoteChildtype.type);
                        }
                    }
                });
            });

        });
    }
}

/*
export const tryFixBarPauses = async () => {
    const r = await window.fetch('/gbatlat-short.xml');
    const xmlString = await r.text();
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlString, 'application/xml');
    const score = new ScorePartwise().setup(xml.firstElementChild as Element);
    const fixer = new FixBarPauses(score);
    fixer.fixScore();
}
*/


