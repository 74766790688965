import { useSelector, useDispatch } from 'react-redux';
import StatusBadge from '../../components/StatusBadge';
import Button from '../../components/Button';
import { RootState, Dispatch } from '../../store';
import { isSuperUser } from '../../models/superusers';
import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu';
import { get } from '../../api/Api';

const Footer = () => {
  const user = useSelector((state: RootState) => state.user);
  const arrState = useSelector((state: RootState) => state.arrangement);
  const arrVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const dispatch = useDispatch<Dispatch>();
  const arrangements = useSelector((state: RootState) => state.arrangements);
  return (
    <footer>

      <button onClick={e => {
        //
        dispatch.arrangements.kak({});


      }}>Test</button>

      {arrState.type === 'arrangement' ? (
        <>

          <div className="Flex AlignCenter" style={{ gap: '1rem' }}>
            <p className="arrangementName"><b>{arrState.data.name}</b></p>
            {arrVersion && <StatusBadge status={arrVersion.status} />}
          </div>

          {/* <div className="Flex AlignCenter">
            <div className="Flex AlignCenter">
              <p><small>ID</small></p>
              <Button
                onClick={() => navigator.clipboard.writeText(arrState.id)}
                className="Flex AlignEnd NoWrap"
              >
                <pre>{arrState.id}</pre>
              </Button>
            </div>

            <div className="Flex AlignCenter">
              <p><small>Version</small></p>
              {arrVersion && (
                <Button
                  onClick={() => navigator.clipboard.writeText(arrVersion.id)}
                  className="Flex AlignCenter NoWrap"
                >
                  <pre>{arrVersion.id}</pre>
                </Button>
              )}
              {arrVersion && <StatusBadge status={arrVersion.status} />}
            </div>
          </div>
           */}



          <div className="Flex AlignCenter" style={{ gap: '1rem' }}>
            <div style={{ gap: '.1rem', columnGap: '.6rem', display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center' }}>
              <small style={{ textAlign: 'right', color: 'gray' }}>Arrangement ID:</small>
              <small onClick={() => navigator.clipboard.writeText(arrState.id)} style={{ cursor: 'pointer' }} >{arrState.id}</small>
              {arrVersion && <small style={{ textAlign: 'right', color: 'gray' }}>Version ID:</small>}
              {arrVersion && <small onClick={() => navigator.clipboard.writeText(arrVersion.id)} style={{ cursor: 'pointer' }} >{arrVersion.id}</small>}
            </div>

            {isSuperUser(user) && (
              <Menu menuButton={<MenuButton className="MenuBurger Flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 260" width="26"><g fill="none" stroke="#000" strokeWidth="49.336" strokeLinecap="round"><path d="M29.684 229.663H280.33M29.684 129.663H280.33M29.684 29.663H280.33" /></g></svg></MenuButton>}>
                <MenuItem ><a href={`https://catalog.wearevoice.se/arrangements/${arrState.id}${arrVersion?.id ? `/versions/${arrVersion.id}` : ''}`} target="_blank">Open in Catalog</a></MenuItem>
                <MenuItem ><a href={`https://web.wearevoice.se/player/${arrState.id}`} target="_blank">Open in Webapp</a></MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => navigator.clipboard.writeText(arrState.id)}>Copy arrangement id</MenuItem>
                {arrVersion && <MenuItem onClick={() => navigator.clipboard.writeText(arrVersion.id)}>Copy version id</MenuItem>}
              </Menu>
            )}
            <Button
              onClick={() => {
                dispatch.arrangement.unloadArrangement();
              }}
            >
              Unload
            </Button>

          </div>

        </>
      ) : arrState.type === 'loading' ? (
        <p>Loading...</p>
      ) : (
        <p>No arrangement loaded</p>
      )}
    </footer >
  );
};

export default Footer;
