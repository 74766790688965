import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get, post, put } from '../api/Api';
import {
  ArrangementBase,
  ArrangementCreated,
  ArrangementUpdated,
} from '../api/ManagementApi';
import { isSuperUserOrganization } from './superusers';

export type ArrangementStatus =
  | {
    type: 'empty';
  }
  | {
    type: 'form';
  }
  | {
    type: 'creating';
  }
  | {
    type: 'loading';
  }
  | {
    type: 'arrangement';
    id: string;
    data: ArrangementCreated;
  };

export const arrangement = createModel<RootModel>()({
  state: { type: 'empty' } as ArrangementStatus,
  reducers: {
    setStatus(state, s: ArrangementStatus) {
      return s;
    },
  },
  effects: (dispatch) => ({
    async createArrangement(
      data: {
        payload: {
          title: string;
          subtitle: string;
          description: string;
          iswc: string;
          ice: string;
          publisherTempId: string | undefined;
          contentProvider1Id: string | undefined;
          contentProvider2Id: string | undefined;
          publicDomain: boolean;
          publisherUrl: string;
          externalId: string;
        };
      },
      rootState
    ) {
      if (!rootState.user.token) return;

      dispatch.arrangement.setStatus({ type: 'creating' });
      const dataSong = {
        name: data.payload.title?.trim(),
        description: data.payload.description?.trim(),
        iswc: data.payload.iswc?.trim(),
        ice: data.payload.ice?.trim(),
      };
      const songCreated = (await post(
        `/songs`,
        rootState.user.token,
        dataSong
      )) as ArrangementBase;
      console.log('songCreated', songCreated);
      const dataArr = {
        name: data.payload.title?.trim(),
        title: data.payload.title?.trim(),
        subtitle: data.payload.subtitle?.trim(),
        description: data.payload.description?.trim(),
        songId: songCreated.id,
        iswc: data.payload.iswc?.trim(),
        ice: data.payload.ice?.trim(),
        contentProvider1Id: data.payload.contentProvider1Id,
        contentProvider2Id: data.payload.contentProvider2Id,
        publisherTempId: data.payload.publisherTempId,
        publicDomain: data.payload.publicDomain,
        publisherUrl: data.payload.publisherUrl,
        externalId: data.payload.externalId,
      };

      const arrCreated = (await post(
        `/arrangements`,
        rootState.user.token,
        dataArr
      )) as ArrangementCreated;

      dispatch.arrangement.loadArrangement({ id: arrCreated.id });
    },

    async updateArrangement(
      data: {
        payload: {
          title: string;
          subtitle: string;
          description: string;
          iswc: string;
          ice: string;
          contentProvider1Id: string | undefined;
          contentProvider2Id: string | undefined;
          publisherTempId: string | undefined;
          publicDomain: boolean;
          publisherUrl: string;
          externalId: string;
        };
      },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;

      const dataArr = {
        ...rootState.arrangement.data,
        name: data.payload.title,
        title: data.payload.title,
        subtitle: data.payload.subtitle,
        description: data.payload.description,
        iswc: data.payload.iswc,
        ice: data.payload.ice,
        contentProvider1Id: data.payload.contentProvider1Id,
        contentProvider2Id: data.payload.contentProvider2Id,
        publisherTempId: data.payload.publisherTempId,
        publicDomain: data.payload.publicDomain,
        publisherUrl: data.payload.publisherUrl,
        externalId: data.payload.externalId,
      };
      dispatch.arrangement.setStatus({ type: 'loading' });
      const arrUpdated = (await put(
        `/arrangements/${rootState.arrangement.id}`,
        rootState.user.token,
        dataArr
      )) as ArrangementUpdated;
      dispatch.arrangement.setStatus({
        type: 'arrangement',
        id: arrUpdated.id,
        data: arrUpdated,
      });
    },

    async createDraftCopy(
      payload: { id: string; versionId: string },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (!payload.id) return;
      if (!payload.versionId) return;
      try {
        await post(
          `/arrangements/${payload.id}/versions/${payload.versionId}/copy`,
          rootState.user.token
        );
        dispatch.arrangementVersion.setState(null);
        await dispatch.arrangement.loadArrangement({
          id: payload.id,
          preventChangeTab: true,
        });
      } catch (e) {
        console.error(e);
      }
    },

    async publish(
      payload: {
        id: string;
        versionId: string;
        currentStatus: string;
      },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (!payload.id) return;
      if (!payload.versionId) return;
      if (!payload.currentStatus) return;
      try {
        if (payload.currentStatus === 'Draft') {
          await post(
            `/arrangements/${payload.id}/versions/${payload.versionId}/status`,
            rootState.user.token,
            {
              status: 'ReadyForPublish',
            }
          );
        }
        await post(
          `/arrangements/${payload.id}/versions/${payload.versionId}/status`,
          rootState.user.token,
          {
            status: 'Published',
          }
        );
        await dispatch.arrangement.loadArrangement({
          id: payload.id,
          versionId: payload.versionId,
          preventChangeTab: true,
        });
      } catch (e) {
        console.error(e);
      }
    },

    async unpublish(
      payload: {
        id: string;
        versionId: string;
      },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (!payload.id) return;
      if (!payload.versionId) return;
      try {
        await post(
          `/arrangements/${payload.id}/versions/${payload.versionId}/status`,
          rootState.user.token,
          {
            status: 'PreviouslyPublished',
          }
        );
        await dispatch.arrangement.loadArrangement({
          id: payload.id,
          versionId: payload.versionId,
          preventChangeTab: true,
        });
      } catch (e) {
        console.error(e);
      }
    },

    async loadArrangement(
      payload: { id: string; versionId?: string; preventChangeTab?: boolean },
      rootState
    ) {
      if (!rootState.user.token) return;
      if (!rootState.userOrganization) return;
      if (!payload.id) return;

      // consume webapp player urls to
      if (payload.id.startsWith('http')) {
        const segments = payload.id.split('/');
        payload.id = segments[segments.length - 1];
      }

      dispatch.arrangement.setStatus({ type: 'loading' });
      const arr = (await get(
        `/arrangements/${payload.id}`,
        rootState.user.token,
        {}
      )) as ArrangementBase;

      if (!isSuperUserOrganization(rootState.userOrganization)) {
        if (
          arr.contentProvider1?.id !== rootState.userOrganization.id &&
          arr.contentProvider2?.id !== rootState.userOrganization.id &&
          arr.publisherTemp?.id !== rootState.userOrganization.id
        ) {
          dispatch.arrangement.setStatus({ type: 'empty' });
          return;
        }
      }

      dispatch.arrangement.setStatus({
        type: 'arrangement',
        id: arr.id,
        data: arr,
      });
      if (!payload.preventChangeTab) {
        dispatch.tabs.setSelectedTab('arrangement');
      }
      await dispatch.arrangementVersions.load({ versionId: payload.versionId });
      await dispatch.recordings.load({});
      await dispatch.existingInterestedParties.loadInterestedParties({});
      await dispatch.playmodes.load({});
      if (rootState.metadataTree.type === 'empty') {
        await dispatch.metadataTree.loadMetadataTree({});
      }
      await dispatch.existingMetadata.load({});
    },

    async unloadArrangement() {
      dispatch.tabs.setSelectedTab('home');
      dispatch.arrangement.setStatus({ type: 'empty' });
      dispatch.arrangementVersions.setState({
        versions: [],
        isLoading: false,
      });
      dispatch.recordings.setState({
        items: [],
        loading: false,
      });
      dispatch.recordingUploads.setState({
        items: [],
      });
      dispatch.existingInterestedParties.setState({
        parties: [],
        loading: false,
      });
      dispatch.existingMetadata.setState({
        items: [],
        mandatory: [],
        missing: [],
        hasInstrumentation: false,
        instrumentationTree: [],
        loading: false,
      });
      dispatch.playmodes.setState({
        items: [],
        loading: false,
      });
      dispatch.playmodeUploads.setState({
        items: [], msg: ''
      });
    },
  }),
});
