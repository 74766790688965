import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { Dispatch, RootState } from '../../../store';

const ExistingMetadataItem = () => {
  const dispatch = useDispatch<Dispatch>();
  const language = useSelector((state: RootState) => state.language);
  const data = useSelector((state: RootState) => state.existingMetadata);
  return (
    <div
      className="ItemList"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        paddingRight: '1rem',
      }}
    >
      {data.items.map((item, index) => {
        return (
          <Card key={index}>
            <label>
              <p>
                <b>
                  {language === 'swedish'
                    ? item.labelSv
                    : language === 'german'
                    ? item.labelDe
                    : item.labelEn}
                </b>
              </p>
              <small className="text secondary">
                {language === 'swedish'
                  ? item.labelPathSv
                  : language === 'german'
                  ? item.labelPathDe
                  : item.labelPathEn}
              </small>
            </label>
            <Button
              onClick={() => {
                dispatch.existingMetadata.removeMetadata({ id: item.id });
              }}
            >
              Delete
            </Button>
          </Card>
        );
      })}
    </div>
  );
};
export default ExistingMetadataItem;
