
import React, { useEffect, useRef, useState } from 'react'
import BarJumpsEditor from './/BarJumpsEditor';
import TimeSyncEditor from './TimeSyncEditor';
import "./BarJumpsEditor.css";
import PlayerComponent from '../../../../player/PlayerComponent';
import { GridExportItem, PageOrientation } from '../../../../player/playerTypes';
import { PlayModeDetailsStatus } from '../../../../player/store/PlayModeStore';

type P2SProps = {
    playerComponent: any,
    gridAction: any,
    playbackStatus: any,
    playbackPosition: any,
    syncData: any,
    selectedPlayMode: any,
    isSaving: boolean,
    useTempoCompensation: boolean,
    onSaveLegacySyncData: () => Promise<void>,
    onSaveSvgSyncData: ((verticalItems: GridExportItem[], horizontalItems: GridExportItem[], playModeId: string) => void) | null,
    onPlaymodeDataMonitor: () => Promise<void>,
    // isSavingSvgData: boolean,
    isDraft: boolean,
    // preventScrolling: boolean,
}

type P2SState = {
    showBarJumps: boolean,
    showTimeSync: boolean,
    keyDown: boolean,
    shiftDown: boolean,
    ctrlDown: boolean,
    altDown: boolean,
    keyCode: number,
    isSaving: boolean,
}

const KEY_SCRUB_SMALL = 2;
const KEY_SCRUB_LARGE = 5;

export default class Player2Sync extends React.Component<P2SProps, P2SState> {
    constructor(props: P2SProps) {
        super(props);
        this.state = {
            showTimeSync: false,
            showBarJumps: false,
            keyDown: false,
            shiftDown: false,
            ctrlDown: false,
            altDown: false,
            keyCode: 0,
            isSaving: false,
        }
    }

    onKeypress = (e: Event) => {
        // e.preventDefault();
        // e.stopPropagation();
    }

    onKeyDown = (e: KeyboardEvent) => {
        // if (this.props && this.props.playbackPosition)
        //     console.log(this.props.playbackPosition.time, this.state.shiftDown);        
        switch (e.key) {
            case 'Shift':
                this.setState({ shiftDown: true, keyCode: 0 });
                return;
            case 'Control':
                this.setState({ ctrlDown: true, keyCode: 0 });
                return;
            case 'Alt':
                this.setState({ altDown: true, keyCode: 0 });
                return;
            default:
                if (this.state.keyDown) return;
                this.setState({ keyDown: true, keyCode: e.keyCode });

                switch (e.keyCode) {
                    case 32:
                        e.preventDefault();
                        switch (this.props.playbackStatus.status) {
                            case 'enginePlaying': this.props.playerComponent.playbackStop(); break;
                            case 'enginePaused': this.props.playerComponent.playbackStart(); break;
                            default:
                        }
                        break;
                    case 37:
                    case 68: {
                        e.preventDefault();
                        if (!this.props.playbackPosition) return;
                        const currentTime = this.props.playbackPosition.time;
                        const newTime = Math.max(currentTime - (this.state.ctrlDown ? KEY_SCRUB_LARGE : KEY_SCRUB_SMALL), 0);
                        this.props.playerComponent.playbackSetTime(newTime);
                        this.props.playerComponent.playbackScrub(newTime);
                        window.setTimeout(() => this.props.playerComponent.playbackScrubReady(), 50);
                    }
                        break;
                    case 39:
                    case 70: {
                        e.preventDefault();
                        if (!this.props.playbackPosition) return;
                        const currentTime = this.props.playbackPosition.time;
                        const newTime = Math.min(currentTime + (this.state.ctrlDown ? KEY_SCRUB_LARGE : KEY_SCRUB_SMALL), this.props.playbackPosition.duration);
                        this.props.playerComponent.playbackSetTime(newTime);
                        this.props.playerComponent.playbackScrub(newTime);
                        window.setTimeout(() => this.props.playerComponent.playbackScrubReady(), 50);
                    }
                        break;
                    default:
                        console.log('keyCode:', e.keyCode);
                }
        }
    }

    onKeyUp = (e: KeyboardEvent) => {
        this.setState({ keyCode: 0 });
        switch (e.key) {
            case 'Shift':
                this.setState({ shiftDown: false });
                return;
            case 'Control':
                this.setState({ ctrlDown: false });
                return;
            case 'Alt':
                this.setState({ altDown: false });
                return;
            default:
                this.setState({ keyDown: false });
        }
    }

    componentDidMount() {
        // console.log('com did mount');

        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
        document.addEventListener('kepress', this.onKeypress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keyup', this.onKeyUp);
        document.removeEventListener('kepress', this.onKeypress);
    }

    render() {
        if (!this.props.selectedPlayMode) return null;
        if (this.props.selectedPlayMode.type !== 'Xml')
            return null;
        if (!this.props.isDraft) return <div className="Flex JustifyCenter AlignCenter Pad05"><p>Arrangement must be in <span className="ArrStatus Draft">Draft</span> mode to enable synchronization.</p></div>

        return <>
            {this.state.showBarJumps &&
                <BarJumpsEditor
                    playerComponent={this.props.playerComponent}
                    gridAction={this.props.gridAction}
                    playbackStatus={this.props.playbackStatus}
                    playbackPosition={this.props.playbackPosition}
                    syncData={this.props.syncData}
                    isDraft={this.props.isDraft}
                />}

            {this.state.showTimeSync &&
                <TimeSyncEditor
                    playerComponent={this.props.playerComponent}
                    gridAction={this.props.gridAction}
                    playbackStatus={this.props.playbackStatus}
                    playbackPosition={this.props.playbackPosition}
                    syncData={this.props.syncData}
                    keyCommand={{
                        keyCode: this.state.keyCode,
                        shiftDown: this.state.shiftDown,
                        ctrlDown: this.state.ctrlDown,
                        altDown: this.state.altDown,
                    }}
                />}

            <div className="Flex JustifyBetween" style={{ padding: '0 .5rem .5rem .5rem' }} >
                <div className="Flex">
                    <p>MusicXML Sync Editor</p>
                    {/* <button className={'small ' + (this.props.useTempoCompensation ? 'selected' : '')}>Tempo compensation</button> */}
                </div>
                <div className="Flex">
                    <button className={'small ' + (this.state.showBarJumps ? 'active' : '')} onClick={e => { this.setState({ showBarJumps: !this.state.showBarJumps }) }}>BarJumps</button>
                    <button className={'small ' + (this.state.showTimeSync ? 'active' : '')} onClick={e => { this.setState({ showTimeSync: !this.state.showTimeSync }) }}>TimeSync</button>
                    {/* <button className={'small ' + (this.props.isSaving ? 'ok' : '')} onClick={this.props.onSave}>{this.props.isSaving ? 'Saving...' : 'Save Legacy data'}</button> */}
                    {this.state.isSaving && <p>Saving...</p>}
                    {!this.state.isSaving && <>
                        <button className="small" onClick={async e => {
                            const playerComponent: PlayerComponent = this.props.playerComponent;
                            const playMode: PlayModeDetailsStatus = playerComponent.getPlaymodeDetails() as PlayModeDetailsStatus;
                            if (!(playMode.status === 'selectedPlayModeSuccess')) return;
                            if (!(playMode.typeData.type === 'Xml')) return;
                            if (!this.props.onSaveSvgSyncData) return;

                            this.setState({ isSaving: true });

                            await this.props.onSaveLegacySyncData();
                            const verticalItems = playMode.typeData.gridItemsVertical!;
                            const horizontalItems = playMode.typeData.gridItemsHorizontal!;
                            await this.props.onSaveSvgSyncData(verticalItems, horizontalItems, playMode.playModeId);

                            this.setState({ isSaving: false });
                        }}>Save</button>

                        {/* <button
                            onClick={e => {
                                this.props.onPlaymodeDataMonitor();
                            }}
                        >Check</button> */}

                    </>
                    }
                </div>
            </div >

        </>
    }
}

