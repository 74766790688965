import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import { MetadataItem, MetadataItems } from '../../../api/ManagementApi';
import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { createArray, filterUnique } from '../../../models/metadataTree';
import Button from '../../../components/Button';
import Card from '../../../components/Card';

const MetadataSearch = () => {
  const data = useSelector((state: RootState) => state.metadataTree);
  const language = useSelector((state: RootState) => state.language);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [foundItems, setFoundItems] = useState<Array<MetadataItem>>([]);

  useEffect(() => {
    if (data.type !== 'loaded') return;
    if (debouncedSearch === '') {
      setFoundItems([]);
      return;
    }
    const foundItems = data.itemArray.filter((item) => {
      return language === 'swedish'
        ? item.labelSv.indexOf(search) > -1
        : language === 'german'
        ? item.labelDe.indexOf(search) > -1
        : item.labelEn.indexOf(search) > -1;
    });
    setFoundItems(foundItems);
  }, [debouncedSearch]);

  const foundItemsWithChildren = filterUnique(
    foundItems.flatMap((item) => {
      const items = createArray(item.children, []);
      items.unshift(item);
      return items;
    })
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
      <input
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={
          'search metadata items like ' +
          (language === 'swedish'
            ? '"jul", "natur", "kärlek"'
            : language === 'german'
            ? '"Weihnachten", "Natur", "Liebe"'
            : '"christmas", "nature", "love"')
        }
      />
      {debouncedSearch.length > 0 && foundItems.length === 0 && (
        <p>Nothing found</p>
      )}
      <div
        className="ItemList"
        style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
      >
        {foundItemsWithChildren.map((item) => {
          return (
            <>
              <SearchItem key={item.id} item={item} />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default MetadataSearch;

export const SearchItem = (props: { item: MetadataItem }) => {
  const dispatch = useDispatch<Dispatch>();
  const language = useSelector((state: RootState) => state.language);
  return (
    <Card yellow>
      <label>
        <p>
          <b>
            {language === 'swedish'
              ? props.item.labelSv
              : language === 'german'
              ? props.item.labelDe
              : props.item.labelEn}
          </b>
        </p>
        <small className="text secondary">
          {language === 'swedish'
            ? props.item.labelPathSv
            : language === 'german'
            ? props.item.labelPathDe
            : props.item.labelPathEn}
        </small>
      </label>
      <Button
        onClick={() => {
          dispatch.existingMetadata.addMetadata({ id: props.item.id });
        }}
      >
        Add
      </Button>
    </Card>
  );
};

export const MetadataTree = (props: { tree: MetadataItems }) => {
  const language = useSelector((state: RootState) => state.language);
  const dispatch = useDispatch<Dispatch>();
  const tItems = props.tree.map((item: MetadataItem) => {
    if (item.children.length === 0)
      return (
        <span key={`${item.path.join('/')}-single`} className="MetadataSingle">
          {language === 'swedish'
            ? item.labelSv
            : language === 'german'
            ? item.labelDe
            : item.labelEn}{' '}
          <a
            href="#"
            onClick={() => {
              dispatch.existingMetadata.addMetadata({ id: item.id });
            }}
          >
            Add
          </a>
        </span>
      );
    return (
      <details key={item.path.join('/')}>
        <summary>
          {language === 'swedish'
            ? item.labelSv
            : language === 'german'
            ? item.labelDe
            : item.labelEn}{' '}
          <a
            href="#"
            onClick={() => {
              dispatch.existingMetadata.addMetadata({ id: item.id });
            }}
          >
            Add
          </a>
        </summary>
        <div>
          <MetadataTree tree={item.children} />
        </div>
      </details>
    );
  });
  return <>{tItems}</>;
};
