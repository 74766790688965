import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import { PlaymodeUploadState } from '../../../models/playmodeUploads';
import Modal from '../../modal/Modal';
import useModal from '../../../hooks/useModal';
import XmlPreview from '../xmlpreview/XmlPreview';
import { useState } from 'react';


const UploadGraphicFiles = () => {
  const { isOpen, toggle, close } = useModal();
  const dispatch = useDispatch<Dispatch>();
  const arrangementData = useSelector((state: RootState) => state.arrangement);
  const arrVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const userData = useSelector((state: RootState) => state.user);
  const playmodeUploads = useSelector((state: RootState) => state.playmodeUploads);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': [],
      'application/xml': ['.xml', '.musicxml', '.mxl'],
    },
    onDropAccepted: async (files) => {
      const newItems = files.map(
        (file) => ({ type: 'file', file } as PlaymodeUploadState)
      );
      const items = [...newItems, ...playmodeUploads.items];
      dispatch.playmodeUploads.setState({
        items, msg: '',
      });

    },
    onDropRejected: (files) => {
      console.warn('Rejected', files);
    },
  });

  if (!arrVersion) return null;
  if (arrVersion.status === 'Published') return null;
  return (
    <>
      <h2>Add graphic files</h2>
      <p>
        Playmodes are created by adding music XML or PDF files to the
        arrangement.
      </p>
      <ul>
        <li>
          Music XML files for digital and dynamic sheet views that will be used
          for synchronization with the audio
        </li>
        <li>PDF files for static views</li>
      </ul>
      <div className="Flex FlexColumn">
        <div {...getRootProps({ className: 'Dropzone' })}>
          <input {...getInputProps()} />
          <p>
            Drag and drop some files here, or click to select files. Use music
            XML or PDF.
          </p>
        </div>
        <div className="Flex FlexColumn">
          {playmodeUploads.items.map((item, fileIdx) => {
            switch (item.type) {

              case 'uploading':
                return (
                  <Card key={fileIdx} yellow>
                    <h3>Uploading</h3>
                    <p>
                      {item.loaded} av {item.total}
                    </p>
                  </Card>
                );

              case 'file':
                return (
                  <Card key={item.file.name} yellow>
                    <label>
                      <p>{item.file.name}</p>
                    </label>
                    <div className="Flex FlexRow">

                      {(item.file.name.toLowerCase().endsWith('xml')) &&
                        <ButtonPreview item={item} />
                      }

                      <Button onClick={() => {
                        dispatch.playmodeUploads.removeItem(fileIdx);
                      }}>Remove</Button>

                      <Button
                        onClick={() => {
                          if (userData === null) return;
                          if (arrangementData.type !== 'arrangement') return;
                          dispatch.playmodeUploads.uploadGraphicFileData({
                            file: item.file,
                            index: fileIdx,
                          });
                          // dispatch.playmodeUploads.generateSvgModeData({ file: item.file, index: fileIdx });
                        }}
                      >
                        Upload
                      </Button>

                    </div>
                  </Card>
                );

              default:
                return <></>;
            }
          })}
        </div>
      </div >
    </>
  );
};

export default UploadGraphicFiles;


const ButtonPreview = (props: { item: PlaymodeUploadState }) => {
  const { isOpen, toggle, close } = useModal();
  const [xmlString, setXmlString] = useState('');

  return <>
    <Button onClick={async () => {
      if (props.item.type !== 'file') return null;
      const xmlString = await props.item.file.text();
      setXmlString(xmlString);
      toggle();
    }}>Preview MusicXml before upload</Button>
    {xmlString && <Modal isOpen={isOpen} toggle={toggle}><XmlPreview xml={xmlString as string} close={close} /></Modal>}

  </>
}