import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import ExistingMetadataItem from './ExistingMetadataItem';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import MetadataSearch, { MetadataTree } from './MetadataSearch';
import { LanguageState } from '../../../models/language';
import Button from '../../../components/Button';
import Card from '../../../components/Card';

const Metadata = () => {
  const dispatch = useDispatch<Dispatch>();
  const metaState = useSelector((state: RootState) => state.metadataTree);
  const existingState = useSelector(
    (state: RootState) => state.existingMetadata
  );
  const languageState = useSelector((state: RootState) => state.language);
  if (metaState.type === 'empty') return <p>Metadata empty</p>;
  if (metaState.type === 'loading') return <p>Metadata loading...</p>;
  return (
    <>
      <div className="Flex">
        <div className="Flex AlignCenter">
          <small className="text secondary">Language</small>
          <label className="Flex FlexRow">
            <input
              type="radio"
              value="swedish"
              name="language"
              checked={languageState === 'swedish'}
              onChange={(e) => {
                dispatch.language.setState(e.target.value as LanguageState);
              }}
            />
            Swedish
          </label>
          <label className="Flex FlexRow">
            <input
              type="radio"
              value="english"
              name="language"
              checked={languageState === 'english'}
              onChange={(e) => {
                dispatch.language.setState(e.target.value as LanguageState);
              }}
            />
            English
          </label>
          <label className="Flex FlexRow">
            <input
              type="radio"
              value="german"
              name="language"
              checked={languageState === 'german'}
              onChange={(e) => {
                dispatch.language.setState(e.target.value as LanguageState);
              }}
            />
            German
          </label>
        </div>
      </div>
      <div className="Columns">
        <div>
          <MandatoryMetadataItems />
          {!existingState.hasInstrumentation && <AddInstrumentation />}

          <h2>Add metadata item</h2>
          <p>
            Add further metadata about the song that will be searchable for the
            end user.
          </p>
          <Tabs>
            <TabList>
              <Tab>Browse tree</Tab>
              <Tab>Search</Tab>
            </TabList>
            <TabPanel>
              <MetadataTree tree={metaState.tree} />
              {/* <p>{metaState.itemArray.length}</p> */}
              {/* {metaState.itemArray.map(item => {
                        return <p key={item.id}>{item.labelSv}: {item.labelPath}</p>
                    })} */}
            </TabPanel>
            <TabPanel>
              <MetadataSearch />
            </TabPanel>
          </Tabs>
        </div>
        <div>
          <div className="Flex AlignCenter JustifyBetween">
            <h2>Metadata items</h2>
            <Button
              onClick={async () => {
                await dispatch.metadataTree.loadMetadataTree({});
                await dispatch.existingMetadata.load({});
              }}
            >
              Reload
            </Button>
          </div>
          <p>Metadata items already added to arrangements:</p>
          <ExistingMetadataItem />
        </div>
      </div>
    </>
  );
};

export default Metadata;

const AddInstrumentation = () => {
  const existingState = useSelector(
    (state: RootState) => state.existingMetadata
  );

  return (
    <>
      <h2>Mandatory instrumentation metadata</h2>
      <p style={{ color: 'red' }}>
        An arrangement must have instrumentation metadata - this arrangement
        doesn't.
      </p>
      <p>
        Please add one of the instrumentation alternatives in the
        instrumentation tree below:
      </p>
      <Card
        yellow
        style={{ flexDirection: 'column', margin: '.5rem 0', padding: '1rem' }}
      >
        <div
          style={{
            display: 'flex',
            gap: '.5rem',
            alignItems: 'flex-end',
            marginBottom: '.3rem',
          }}
        >
          <p>Instrumentation tree</p>
          <span>Click arrows to find sub alternatives</span>
        </div>
        <MetadataTree tree={existingState.instrumentationTree} />
      </Card>
      <p>
        You can later add further alternatives in the "Add metadata item"
        section below.
      </p>
    </>
  );
};

const MandatoryMetadataItems = () => {
  const dispatch = useDispatch<Dispatch>();
  const data = useSelector((state: RootState) => state.existingMetadata);
  const language = useSelector((state: RootState) => state.language);

  if (data.missing.length === 0) return null;
  return (
    <>
      <h2>Mandatory icon metadata</h2>
      <p>The following items should be added to all arrangements:</p>
      <div
        className="ItemList"
        style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
      >
        {data.missing.map((item) => {
          return (
            <Card key={item.id} red>
              <label>
                <p>
                  <b>
                    {language === 'swedish'
                      ? item.labelSv
                      : language === 'german'
                      ? item.labelDe
                      : item.labelEn}
                  </b>
                </p>
                <small className="text secondary">
                  {language === 'swedish'
                    ? item.labelPathSv
                    : language === 'german'
                    ? item.labelPathDe
                    : item.labelPathEn}
                </small>
              </label>
              <Button
                onClick={() => {
                  dispatch.existingMetadata.addMetadata({ id: item.id });
                }}
              >
                Add
              </Button>
            </Card>
          );
        })}
      </div>
    </>
  );
};
