import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import { RecordingUploadState, toSortOrder, VoiceType } from '../../../models/recordingUploads';
import { Dispatch, RootState } from '../../../store';

import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu';

const CreateRecordings = () => {
  const dispatch = useDispatch<Dispatch>();
  const recordingUploads = useSelector(
    (state: RootState) => state.recordingUploads
  );
  const options = Object.keys(VoiceType)
    .filter((key) => isNaN(Number(key)))
    .map((voiceType) => (
      <option key={voiceType} value={voiceType}>
        {voiceType}
      </option>
    ));
  return (
    <>
      <h2>Create recordings </h2>
      <p>items: </p>
      <ul>
        <li>
          Select the VoiceType that best represents the audio or video file content.
        </li>
        <li>The Label will be presented to users as track name in the player.</li>
        <li>
          Make sure to adjust the sort order in which the recordings will be
          presented to users in the player mixer.
        </li>
      </ul>
      {/* <li>Recommended sort order:</p>
      <ul style={{ marginBottom: '1rem' }}>
        <li>Lead vocal: 0-9</li>
        <li>Soprano: 10-19</li>
        <li>Alto: 20-29</li>
        <li>Tenor: 30-39</li>
        <li>Bass: 40-49</li>
        <li>Specific instruments: 50-89</li>
        <li>Music background: 90-99</li>
        <li>Master recording: 100</li>
        <li>Other: 101-</li>
      </ul> */}

      <h3 style={{ padding: '.3rem 0' }}>source files:</h3>
      <div className="RecordingsList">
        {recordingUploads.items.map((recording, fileIdx) => {
          switch (recording.type) {
            // case 'uploading':
            //   return (
            //     <div key={fileIdx} className="RecordingFileItem">
            //       <h3>Uploading</h3>
            //       <p>
            //         {recording.loaded} av {recording.total}
            //       </p>
            //     </div>
            //   );
            case 'parsed':
              return (
                <div key={fileIdx} className="card" style={{ display: 'block' }} >
                  <div className="Flex FlexColumn">

                    <div className="Flex FlexRow AlignEnd">
                      <span className="text secondary">Source file</span>
                      <p >{recording.sourceFile}</p>
                    </div>

                    <div className="Flex FlexRow JustifyBetween"  >
                      <div className="Flex FlexRow">
                        <label>
                          <span className="text secondary">VoiceType</span>
                          <select
                            value={VoiceType[recording.voiceType]}
                            onChange={(e) => {
                              const voiceType =
                                VoiceType[e.target.value as keyof typeof VoiceType];
                              const label = VoiceType[voiceType];
                              const items = [...recordingUploads.items];
                              items[fileIdx] = {
                                ...recording,
                                voiceType,
                                label,
                                sortOrder: toSortOrder(voiceType),
                              };
                              dispatch.recordingUploads.setState({
                                ...recordingUploads,
                                items,
                              });
                            }}
                          >
                            {options}
                          </select>
                        </label>
                        <label>
                          <span className="text secondary">Label</span>
                          <input
                            className="medium"
                            type="text"
                            value={recording.label}
                            onChange={(e) => {
                              const items = [...recordingUploads.items];
                              items[fileIdx] = {
                                ...recording,
                                label: e.target.value,
                              };
                              dispatch.recordingUploads.setState({
                                ...recordingUploads,
                                items,
                              });
                            }}
                          />
                        </label>
                        <label>
                          <span className="text secondary">Audio level</span>
                          <input
                            className="small"
                            type="number"
                            step=".1"
                            min="0"
                            max="1"
                            value={recording.audioLevel}
                            onChange={(e) => {
                              const items = [...recordingUploads.items];
                              items[fileIdx] = {
                                ...recording,
                                audioLevel: parseFloat(e.target.value),
                              };
                              dispatch.recordingUploads.setState({
                                ...recordingUploads,
                                items,
                              });
                            }}
                          />
                        </label>
                        <label>
                          <span className="text secondary">Sort order</span>
                          <input
                            className="small"
                            type="number"
                            step="1"
                            min="0"
                            max="1000"
                            value={recording.sortOrder}
                            onChange={(e) => {
                              const items = [...recordingUploads.items];
                              items[fileIdx] = {
                                ...recording,
                                sortOrder: parseInt(e.target.value),
                              };
                              dispatch.recordingUploads.setState({
                                ...recordingUploads,
                                items,
                              });
                            }}
                          />
                        </label>
                      </div>
                      <Menu menuButton={<MenuButton className="MenuBurger Flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 260" width="26"><g fill="none" stroke="#000" strokeWidth="49.336" strokeLinecap="round"><path d="M29.684 229.663H280.33M29.684 129.663H280.33M29.684 29.663H280.33" /></g></svg></MenuButton>}>
                        <MenuItem
                          onClick={() => {
                            dispatch.recordingUploads.createRecording({
                              recording,
                              index: fileIdx,
                            });
                          }}
                        >Create recording from source file</MenuItem>
                        <MenuDivider></MenuDivider>
                        <MenuItem
                          onClick={() => {
                            //                                              
                            let items = recordingUploads.items;
                            items = items.filter((item: RecordingUploadState) => {
                              return item.type === 'parsed' && (item.file.name !== recording.sourceFile);
                            });
                            dispatch.recordingUploads.setState({ items });
                          }}
                        >Remove from source files</MenuItem>
                      </Menu>


                    </div>

                    {/* <div className="Flex JustifyEnd">
                      <Button
                        onClick={() => {
                          dispatch.recordingUploads.createRecording({
                            recording,
                            index: fileIdx,
                          });
                        }}
                      >
                        Upload
                      </Button>
                    </div> */}
                  </div>
                </div>
              );

            default:
              return <></>;
          }
        })}
      </div>
    </>
  );
};

export default CreateRecordings;
