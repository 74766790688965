export default function Help() {
  return (
    <div className="FlexCenter">
      <div className="Flex FlexColumn">
        <h1>Video tutorials</h1>
        <h2>Synchronization: Barjumps</h2>
        <a
          className="Videolink"
          href="https://youtu.be/idO7eQWKty0"
          target="_blank"
        >
          Barjumps 1 - Basic concepts: Barjump regions and sync bars
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/5q_bIV0ZbMM"
          target="_blank"
        >
          Barjumps 2 - Two or more verses
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/Omapcpc0x8c"
          target="_blank"
        >
          Barjumps 3 - Different endings
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/Fmb1osUyY7c"
          target="_blank"
        >
          Barjumps 4 - Coda example
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/1i1b_ptrj3s"
          target="_blank"
        >
          Barjumps 5 - Function buttons
        </a>
        <h2>Synchronization: Timesync</h2>
        <a
          className="Videolink"
          href="https://youtu.be/lCPgOrDZg6Y"
          target="_blank"
        >
          Timesync 1 - Basic concepts
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/4s6Ce5svkvg"
          target="_blank"
        >
          Timesync 2 - Adding syncpoints
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/O7-Q6f5VD9A"
          target="_blank"
        >
          Timesync 3 - Adding multiple syncpoints
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/vbS5n2nyKxk"
          target="_blank"
        >
          Timesync 4 - Removing and adjusting syncpoints
        </a>
        <a
          className="Videolink"
          href="https://youtu.be/zlb9ykDWiN4"
          target="_blank"
        >
          Timesync 5 - Synchronizing overlapping barjump regions
        </a>
      </div>
    </div>
  );
}
