import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { delete_, get, put } from '../api/Api';
import { PlaymodeType_2_3 } from '../api/ManagementApi';
import { PlayModeTypeData } from '../player/wav/WavPublicApi';


export type Playmode = {
  id: string;
  name: string;
  playModeType: PlaymodeType_2_3;
  typeData: PlayModeTypeData | null;
};

export type Playmodes = {
  items: Playmode[];
  loading: boolean;
};

export const playmodes = createModel<RootModel>()({
  state: {
    loading: false,
    items: [],
  } as Playmodes,
  reducers: {
    setState(state, newState: Playmodes) {
      return newState;
    },
  },
  effects: (dispatch) => ({
    async load(payload, rootState): Promise<Playmode[] | null> {

      if (!rootState.user.token) return null;
      if (rootState.arrangement.type !== 'arrangement') return null;
      if (!rootState.arrangementVersion) return null;
      dispatch.playmodes.setState({
        items: rootState.playmodes.items,
        loading: true,
      });
      const arrangementId = rootState.arrangement.id;
      const url = `/arrangements/${arrangementId}/versions/${rootState.arrangementVersion.id}/playmodes`;
      const items = (await get(url, rootState.user.token, {})) as Playmode[];

      for (const item of items) {
        const typeData: PlayModeTypeData | null = await dispatch.playmodes.loadPlaymodeDetails({ item }) as PlayModeTypeData;
        item.typeData = typeData;
        if (item.typeData)
          item.typeData.type === item.playModeType;
      }

      dispatch.playmodes.setState({
        loading: false,
        items,
      });

      return items;
    },

    async delete(payload: { id: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      const url = `/arrangements/${rootState.arrangement.id}/versions/${rootState.arrangementVersion.id}/playmodes/${payload.id}`;
      await delete_(url, rootState.user.token, {});
      await dispatch.playmodes.load({});
    },

    async update(payload: { id: string; name: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      dispatch.playmodes.setState({
        items: rootState.playmodes.items,
        loading: true,
      });
      const url = `/arrangements/${rootState.arrangement.id}/versions/${rootState.arrangementVersion.id}/playmodes/${payload.id}`;
      const playmodeData = await get(url, rootState.user.token, {});
      const data = {
        ...playmodeData,
        name: payload.name,
      };
      await put(url, rootState.user.token, data);
      await dispatch.playmodes.load({});
    },

    async loadPlaymodeDetails(payload: { item: Playmode }, rootState): Promise<PlayModeTypeData | null> {
      if (!rootState.user.token) return null;
      if (rootState.arrangement.type !== 'arrangement') return null;
      if (!rootState.arrangementVersion) return null;
      const url = `arrangements/${rootState.arrangement.id}/versions/${rootState.arrangementVersion.id}/playmodes/${payload.item.id}`;
      const response = await get(url, rootState.user.token, {});

      let typeData: PlayModeTypeData;
      switch (payload.item.playModeType) {
        case 'Audio':
          typeData = { type: 'Audio' };
          break;
        case 'Video':
          typeData = { type: 'Video' };
          break;
        default:
          typeData = response.publicData;
          typeData.type = 'Xml';
      }
      return typeData;
    },

  }),




});
