import { Dispatch, RootState } from '../../../store';
import ExistingRecordings from './ExistingRecordings';
import CreateRecordings from './CreateRecordings';
import UploadRecordings from './UploadRecordings';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusBadge from '../../../components/StatusBadge';
import Button from '../../../components/Button';
import { RecordingUploadState, VoiceType } from '../../../models/recordingUploads';
import { round2 } from '../../../player/tools/NumberTools';

const Recordings = () => {
  const dispatch = useDispatch<Dispatch>();
  const arrangementVersion = useSelector(
    (state: RootState) => state.arrangementVersion
  );
  const arrangementVersions = useSelector(
    (state: RootState) => state.arrangementVersions
  );
  const recordingUploads = useSelector(
    (state: RootState) => state.recordingUploads
  );
  const recordings = useSelector((state: RootState) => state.recordings);
  const poll = useCallback(() => {
    if (recordings.items.some((x) => x.status || x.duration <= 0)) {
      console.log('Polling recordings (and encoding status)...');
      dispatch.recordings.load({}).catch((e) => console.error(e));
    }
  }, [recordings.items]);
  const pollIntervalHandle = useRef<number>();
  useEffect(() => {
    pollIntervalHandle.current = window.setInterval(() => poll(), 10000);
    return () => {
      if (pollIntervalHandle.current) {
        window.clearInterval(pollIntervalHandle.current);
      }
    };
  }, [pollIntervalHandle, poll]);



  const isLatest =
    arrangementVersions.versions[0]?.id === arrangementVersion?.id;

  if (!arrangementVersion) return null;

  return (
    <>
      {arrangementVersion.status !== 'Draft' && (
        <div className="Flex FlexColumn">
          <p>
            This arrangement version is{' '}
            <StatusBadge status={arrangementVersion?.status} />. You can not
            change recordings associated with an arrangement in this status.
            Create a Draft copy that can be safely edited.
          </p>
          {!isLatest && (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              This is NOT the latest version of this arrangement!
            </span>
          )}
        </div>
      )}
      {arrangementVersion?.status === 'Draft' && (
        <>
          <UploadRecordings />
          {recordingUploads.items.filter(item => item.type === 'parsed').length > 0 &&
            <CreateRecordings />
          }
        </>
      )}
      {recordingUploads.items.length > 0 &&
        <div className="Flex JustifyEnd Pad05">
          {/* <Button
            onClick={() => {
              // dispatch.recordings.
            }}
          >Cancel all uploads</Button> */}
          <Button onClick={async () => {
            console.log(recordingUploads.items);
            let abort = false;

            recordingUploads.items.forEach((recording, i) => {
              if (recording.type !== 'parsed') return;
              console.log(recording.voiceType);
              if (recording.voiceType === VoiceType.Unknown) {
                alert(`Voice type is "Unknown" for recording ${i + 1} - please set a voice type for all recordings before uploading.`);
                abort = true;
                return;
              }
            });

            if (abort) return;

            const promises: Promise<void>[] = [];

            for (let i = 0; i < recordingUploads.items.length; i++) {
              const recording = recordingUploads.items[i];
              const promise = new Promise<void>((res, rej) => {
                setTimeout(async () => {
                  dispatch.recordingUploads.createRecording({
                    recording,
                    index: i,
                  }).then(() => {
                    dispatch.recordings.load({});
                  });
                  res();
                }, i * 1000);
              });
            }

            Promise.all(promises).then(() => {
              console.log('All recordings created');
            });

          }}>Create recordings from {recordingUploads.items.length} source files above</Button>
        </div>}

      <UploadingRecordings />

      <ExistingRecordings />
    </>
  );
};

export default Recordings;


const UploadingRecordings = () => {
  const recordingUploads = useSelector(
    (state: RootState) => state.recordingUploads
  );

  if (recordingUploads.items.length === 0) return null;

  const items = recordingUploads.items.filter(x => x.type === 'uploading');
  if (items.length === 0) return null;

  return <>
    <h3>Ongoing uploads</h3>
    <ul>
      {items.map((item, fileIdx) => {
        if (item.type !== 'uploading') return null;
        return <li>{item.filename} ({round2(item.loaded / item.total)}%) </li>

      })}
    </ul>
  </>
}