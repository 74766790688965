import { createModel } from '@rematch/core';
import { RootModel } from '.';

export type TabType =
  | 'home'
  | 'help'
  | 'reports'
  | 'arrangement'
  | 'recordings'
  | 'playmodes'
  | 'interestedParties'
  | 'metadata'
  | 'sync'
  | 'publish'

export type Tab = {
  type: TabType;
  name: string;
  enabled: boolean;
};

export type TabState = {
  items: Tab[];
  selectedIndex: number;
};

export const tabs = createModel<RootModel>()({
  state: {
    items: [
      {
        type: 'home',
        name: 'Home',
        enabled: true,
      },
      {
        type: 'reports',
        name: 'Reports',
        enabled: false,
      },
      {
        type: 'arrangement',
        name: 'Arrangement',
        enabled: false,
      },
      {
        type: 'recordings',
        name: 'Recordings',
        enabled: false,
      },
      {
        type: 'playmodes',
        name: 'Playmodes',
        enabled: false,
      },
      {
        type: 'interestedParties',
        name: 'Interested parties',
        enabled: false,
      },
      {
        type: 'metadata',
        name: 'Metadata',
        enabled: false,
      },
      {
        type: 'sync',
        name: 'Synchronize',
        enabled: false,
      },
      {
        type: 'publish',
        name: 'Publish',
        enabled: false,
      },
      {
        type: 'help',
        name: 'Help',
        enabled: true,
      },
    ],
    selectedIndex: 0,
  } as TabState,
  reducers: {
    setTabEnabled(state, type: TabType, enabled: boolean) {
      const tabs = [...state.items];
      const index = tabs.findIndex((t) => t.type === type);
      if (index < 0 || index >= tabs.length) {
        return state;
      }
      tabs[index].enabled = enabled;
      return {
        ...state,
        items: tabs,
      };
    },
    setSelectedTab(state, type: TabType): TabState {
      const selectedIndex = state.items.findIndex((t) => t.type === type);
      if (selectedIndex < 0 || selectedIndex >= state.items.length) {
        return state;
      }
      return {
        ...state,
        selectedIndex,
      };
    },
    setSelectedIndex(state, selectedIndex: number) {
      if (selectedIndex < 0 || selectedIndex >= state.items.length) {
        return state;
      }
      return {
        ...state,
        selectedIndex,
      };
    },
  },
});
