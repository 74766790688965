import Signal from "../data/Signal";
import css from '../PlayerComponent.module.css'
import IdleTimer from "../tools/IdleTimer";
import { GridMouseActionType } from "../playerTypes";
export type PageCoordinates = { x: number, y: number, w: number, h: number };
export type FrameSize = { width: number, height: number };
export type PageDirection = 'first' | 'prev' | 'next' | 'last' | 'none';

export type PageInteraction = {
    type: 'tap',
} | {
    type: 'press',
} | {
    type: 'swipe',
    direction: 'right' | 'left',
} | {
    type: 'pinch',
    change: number,
} | {
    type: 'none'
}

const PINCH_FACTOR = .002;
const SWIPE_MIN_DISTANCE = 20;
const SWIPE_TIMEOUT_MS = 400;
const SCROLL_GRAPICHS_AREA_TIMEOUT_MS = 300;

export default class GraphicElementStore {

    public readonly graphicElement: HTMLElement = document.createElement('div');
    public readonly pagesElement: HTMLElement = document.createElement('div');
    public readonly pagesOverlayElement: HTMLElement = document.createElement('div');
    public readonly playheadOverlayElement: HTMLElement = document.createElement('div');
    public readonly playheadElement: HTMLElement = document.createElement('div');
    //----------------------------------------------------
    readonly graphicFrameSizeSignal = new Signal<FrameSize>({ width: 0, height: 0 }); //
    readonly graphicFrameScrollSignal = new Signal<{ top: number, left: number }>({ top: 0, left: 0 });
    readonly zoomChangeSignalSignal = new Signal(.0);
    readonly pageInteractionSignal = new Signal<PageInteraction>({ type: 'none' });

    public constructor() {
        //
    }

    private statusDiv: HTMLElement | null = null;
    private statusMessageDiv: HTMLElement | null = null;
    private SWIPE_THRESHOLD = 50;
    private swipeStartX: number | null = null;
    private swipeEndX: number | null = null;
    private btnTurnLeft: HTMLElement | null = null;
    private btnTurnRight: HTMLElement | null = null;

    private isSetup = false;
    private swiping = false;

    private graphicSrollIdleTimer: IdleTimer | null = null;

    // public blockOtherMouseActions = false;
    setup = (playerElement: HTMLElement, isMobile: boolean) => {
        if (this.isSetup) return;
        this.isSetup = true;

        this.graphicElement.classList.add(css.GraphicsElement);
        this.graphicElement.style.flex = '1';
        this.graphicSrollIdleTimer = new IdleTimer(SCROLL_GRAPICHS_AREA_TIMEOUT_MS, this.graphicElementOnScroll);
        this.graphicElement.addEventListener('scroll', this.graphicSrollIdleTimer.touch, true);

        this.pagesElement.classList.add(css.PagesElement);
        this.pagesElement.style.position = 'absolute';
        this.pagesElement.style.top = '0';
        this.pagesElement.style.left = '0';
        this.pagesElement.style.width = '3rem';
        this.pagesElement.style.height = '3rem';
        this.pagesElement.style.overflow = 'auto';
        this.graphicElement.appendChild(this.pagesElement);
        playerElement.appendChild(this.graphicElement);

        this.pagesOverlayElement.classList.add(css.PagesOverlayElement);
        this.pagesOverlayElement.style.pointerEvents = 'none';
        this.graphicElement.appendChild(this.pagesOverlayElement);

        this.playheadOverlayElement.classList.add(css.PlayheadOverlayElement);
        this.playheadOverlayElement.style.pointerEvents = 'none';
        this.graphicElement.appendChild(this.playheadOverlayElement);

        this.playheadElement.classList.add(css.PlayheadElement);
        this.playheadElement.style.pointerEvents = 'none';
        this.playheadOverlayElement.appendChild(this.playheadElement);
        //---------------------------------------------------------        

        //-------------------------------------------------------
        // listen for size changes
        const resizeObserver = new ResizeObserver(e => {
            const width = this.graphicElement ? this.graphicElement.clientWidth : 0;
            const height = this.graphicElement ? this.graphicElement.clientHeight : 0;
            this.graphicFrameSizeSignal.value = { width, height };

        });
        resizeObserver.observe(this.graphicElement);
        //-------------------------------------------------------------------
        // swipe and pinch
        const calcDistance = (event: TouchEvent) => {
            return Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY);
        }

        let distance = 0;
        let prevDistance = 0;
        let touchStartX = 0;
        let touchEndX = 0;
        let touchStartTime = 0;

        const swipeStart = (pageX: number) => {
            touchStartX = pageX;
            touchStartTime = Date.now();
            distance = 0;
            this.swiping = true;
        }

        const swipeMove = (pageX: number) => {
            const time: number = Date.now() - touchStartTime;
            if (time > SWIPE_TIMEOUT_MS) {
                this.swiping = false;
                return;
            }
            touchEndX = pageX;
            distance = (touchEndX - touchStartX);
        }

        const swipdEnd = () => {
            if (this.swiping) {
                if (Math.abs(distance) < SWIPE_MIN_DISTANCE) return;
                this.pageInteractionSignal.value = { type: 'swipe', direction: distance < 0 ? 'left' : 'right' };
                this.swiping = false;
            }
        }

        const pinchStart = (event: TouchEvent) => {
            event.preventDefault();
            prevDistance = calcDistance(event);
        }
        const pinchMove = (event: TouchEvent) => {
            event.preventDefault();
            distance = calcDistance(event);
            const change = -(prevDistance - distance) * PINCH_FACTOR;
            this.pageInteractionSignal.value = { type: 'pinch', change: change };
            prevDistance = distance;
        }

        const touchStart = (event: TouchEvent) => {
            // console.log('Graphic', 'touch start');
            switch (event.touches.length) {
                case 1: // swipe
                    swipeStart(event.touches[0].pageX)
                    break;
                case 2: // pinch
                    pinchStart(event);
                    break;
                default:
            }
        }

        const touchMove = (event: TouchEvent) => {
            switch (event.touches.length) {
                case 1: // swipe
                    swipeMove(event.touches[0].pageX)
                    break;
                case 2: // pinch
                    pinchMove(event);
                    break;
                default:
            }
        }

        const touchEnd = (event: TouchEvent) => {
            swipdEnd();
            // console.log('Graphic', 'touch end');
        }

        const mouseDown = (event: MouseEvent) => {
            // console.log('Graphic', 'mouse down');
            swipeStart(event.pageX);
        }

        const mouseMove = (event: MouseEvent) => {
            swipeMove(event.pageX);
        }

        const mouseUp = (event: MouseEvent) => {
            swipdEnd();
            // console.log('Graphic', 'mouse up');
        }

        this.graphicElement.addEventListener('touchstart', touchStart);
        this.graphicElement.addEventListener('touchmove', touchMove);
        this.graphicElement.addEventListener('touchend', touchEnd);
        this.graphicElement.addEventListener('mousedown', mouseDown);
        this.graphicElement.addEventListener('mousemove', mouseMove);
        this.graphicElement.addEventListener('mouseup', mouseUp);

        //---------------------------------------------------------------------

        this.graphicElement.addEventListener('wheel', event => {
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                const delta = event.deltaY;
                this.zoomChangeSignalSignal.value = delta;
            }
        });

        //--------------------------------------------------------------------
        if (isMobile) {
            this.graphicElement.addEventListener('click', e => {
                const children = Array.from(this.pagesOverlayElement.children);
                for (const child of children) {
                    const rect = child.getBoundingClientRect();
                    if (rect.x <= e.clientX && rect.y <= e.clientY && rect.right >= e.clientX && rect.bottom >= e.clientY) {
                        const xFraction = (e.clientX - rect.left) / rect.width;
                        this.onGridItemMouseEventAction(e, child.id, 'click', xFraction);
                        break;
                    }
                }
            })
        }
    }

    setVisible = (visible: boolean) => {
        this.graphicElement.style.display = visible ? 'inherit' : 'none';
    };

    public onGridItemMouseEventAction(e: MouseEvent, elementId: string, action: GridMouseActionType, xFraction: number) {
        // 
    }


    private graphicElementOnScroll = (e: Event) => {
        const viewportBounds = this.graphicElement.getClientRects();
        const scrollTop = this.graphicElement.scrollTop;
        const scrollLeft = this.graphicElement.scrollLeft;
        this.graphicFrameScrollSignal.value = { top: scrollTop, left: scrollLeft };
    }


}

// const TURN_SVG = '<svg viewBox="0 0 105 210" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fillRule:evenodd;clipRule:evenodd;strokeLinejoin:round;strokeMiterlimit:2;"><g transform="matrix(0.720588,0,0,0.720588,-7.71024,-267.931)" ><path d="M10.7,371.822C91.014,371.822 156.219,437.027 156.219,517.341C156.219,597.656 91.014,662.86 10.7,662.86L10.7,371.822ZM37.937,524.244C35.518,522.785 34.04,520.166 34.04,517.341C34.04,514.517 35.518,511.898 37.937,510.439C52.537,501.631 77.687,486.458 92.654,477.429C95.143,475.927 98.249,475.882 100.781,477.312C103.313,478.741 104.879,481.424 104.879,484.332C104.879,502.17 104.879,532.513 104.879,550.351C104.879,553.259 103.313,555.941 100.781,557.371C98.249,558.801 95.143,558.756 92.654,557.254C77.687,548.225 52.537,533.052 37.937,524.244Z"  /></g>< /svg>';