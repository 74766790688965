import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { get } from '../api/Api';
import {
  ArrangementVersion,
  ArrangementVersionsResponse,
} from '../api/ManagementApi';

export type ArrangementVersionsState = {
  versions: ArrangementVersion[];
  isLoading: boolean;
  error?: string;
};

export const arrangementVersions = createModel<RootModel>()({
  state: {
    isLoading: false,
    versions: [],
  } as ArrangementVersionsState,
  reducers: {
    setState(state, newState: ArrangementVersionsState) {
      return newState;
    },
  },
  effects: (dispatch) => ({
    async load(payload: { versionId?: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      const arrangementId = rootState.arrangement.id;
      dispatch.arrangementVersions.setState({
        versions: rootState.arrangementVersions.versions,
        isLoading: true,
      });
      try {
        const url = `/arrangements/${arrangementId}/versions`;
        const response = (await get(
          url,
          rootState.user.token,
          {}
        )) as ArrangementVersionsResponse;
        dispatch.arrangementVersions.setState({
          isLoading: false,
          versions: response.items,
        });
        dispatch.arrangementVersion.setState(
          response.items.find((v) => v.id === payload.versionId) ||
            response.items.find(
              (v) => v.id === rootState.arrangementVersion?.id
            ) ||
            response.items[0] ||
            null
        );
      } catch (e) {
        console.error(e);
        dispatch.arrangementVersions.setState({
          isLoading: false,
          versions: [],
          error: `Failed to load versions: ${e}`,
        });
      }
    },
  }),
});
