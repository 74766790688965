import Signal from '../data/Signal';
import { PlaymodeType_2_3, Playmode_2_3, PlayModeBaseData_2_3, PlayModeTypeData, ArrangementData_2_3, RecordingType } from '../wav/WavPublicApi';
import { TimeSyncData, BarJumpsData, XmlSyncInfo, GridExportItem } from '../playerTypes';
import { get } from '../wav/Api';
import { PlayerOptions } from '../playerTypes';


export type ArrangementPlayModes = {
    playModes: Array<Playmode_2_3>,
    selectedPlayMode: Playmode_2_3,
}

export type PlayModeDetailsStatus = {
    status: 'selectedPlayModeEmpty'
} | {
    status: 'selectedPlayModeLoading',
    arrangementData: ArrangementData_2_3,
    playModeId: string
} | {
    status: 'selectedPlayModeSuccess',
    arrangementData: ArrangementData_2_3,
    playModeId: string,
    baseData: PlayModeBaseData_2_3,
    typeData: PlayModeTypeData
}

export default class PlayModeStore {
    private arrangementId: string | null = null;
    private arrangementData: ArrangementData_2_3 | null = null;
    private playModes: Array<Playmode_2_3> = [];
    private selectedPlayMode: Playmode_2_3 | null = null;

    readonly arrangementPlayModesSignal = new Signal<ArrangementPlayModes>({
        playModes: [],
        selectedPlayMode: {
            id: 'x',
            name: 'x',
            type: PlaymodeType_2_3.Audio
        }
    });
    readonly playModeDetailsSignal = new Signal<PlayModeDetailsStatus>({ status: 'selectedPlayModeEmpty' });

    constructor(private readonly options: PlayerOptions, private readonly onError: (error?: unknown) => void) {
    }

    setPlayModes(arrangementId: string, arrangementData: ArrangementData_2_3, defaultPlayModeId: string | null) {
        this.arrangementId = arrangementId;
        this.arrangementData = arrangementData;
        this.playModes = this.arrangementData.playModes;

        const inferredPlayMode = this.playModes.find(i => i.id == defaultPlayModeId) ??
            this.playModes.find(i => i.type === PlaymodeType_2_3.Xml) ??
            this.playModes.find(i => i.type === PlaymodeType_2_3.ScorX) ??
            this.playModes.find(i => i.type === PlaymodeType_2_3.Video) ??
            this.playModes.find(i => i.type === PlaymodeType_2_3.Pdf) ??
            this.playModes.find(i => i.type === PlaymodeType_2_3.Audio) ??
            this.playModes[0];

        if (!inferredPlayMode) {
            this.onError('Can not find playMode to play!');
            return;
        }
        return this.loadPlayMode(inferredPlayMode);
    }

    private loadPlayMode = async (playMode: Playmode_2_3) => {
        if (!this.arrangementId) {
            this.onError('No arrangement ID!');
            return;
        }
        if (!this.arrangementData) {
            this.onError('No arrangement data!');
            return;
        }
        this.selectedPlayMode = playMode;
        this.arrangementPlayModesSignal.value = {
            playModes: this.playModes,
            selectedPlayMode: this.selectedPlayMode
        };
        this.playModeDetailsSignal.value = {
            status: 'selectedPlayModeLoading',
            arrangementData: this.arrangementData,
            playModeId: this.selectedPlayMode.id
        };

        // NOTE: When in preview, arrangementId is actually an arrangementVersionId
        const url = this.options.preview ? `${this.options.apiUrl}/arrangements/preview/${this.arrangementId}/play/${playMode.id}` : `${this.options.apiUrl}/arrangements/${this.arrangementId}/play/${playMode.id}`;
        // console.log('playmodeStore url', url);
        // console.log('preview:', this.options.preview);
        const baseData = await get<PlayModeBaseData_2_3>(url, this.options.apiToken, this.options.apiVersion);

        // console.log('basedata', baseData);

        let typeData: PlayModeTypeData = { type: PlaymodeType_2_3.Audio };
        switch (playMode.type) {
            case PlaymodeType_2_3.Xml:
                typeData = {
                    type: playMode.type,
                    ...baseData.playMode.data,
                };
                break;
            case PlaymodeType_2_3.ScorX:
                typeData = {
                    type: playMode.type,
                    ...baseData.playMode.data,
                };
                break;
            case PlaymodeType_2_3.Video:
                typeData = {
                    type: playMode.type,
                    videoUrl: baseData.recordings.find(r => r.recordingType == 'Video')?.url,
                };
                break;
            case PlaymodeType_2_3.Pdf:
                typeData = {
                    type: playMode.type,
                    ...baseData.playMode.data,
                };
                break;
            case PlaymodeType_2_3.Audio:
                typeData = {
                    type: playMode.type,
                };
                break;
            default:
                this.onError('Unsupported play mode: ${playMode.type}');
        }

        switch (playMode.type) {
            case PlaymodeType_2_3.Video:
                baseData.recordings = [];
                break;
            default:
                baseData.recordings = baseData.recordings.filter(r => r.recordingType == RecordingType.Audio);
        }

        // preload svgs and sync data
        switch (typeData.type) {
            case PlaymodeType_2_3.Xml:
                // console.log(typeData);
                if (!typeData.syncs) break;
                try {
                    const syncsVertical = typeData.syncs!.find(s => s.layout === 'Vertical');
                    if (syncsVertical) {
                        // console.log('loading syncs vertical...');
                        const response = await fetch(syncsVertical!.url);
                        typeData.gridItemsVertical = await response.json();
                    }
                    const syncsHorizontal = typeData.syncs!.find(s => s.layout === 'Horizontal');
                    if (syncsHorizontal) {
                        // console.log('loading syncs horizontal...');
                        const response = await fetch(syncsHorizontal!.url);
                        typeData.gridItemsHorizontal = await response.json();
                    }
                } catch (error) {
                    this.onError('Can not load Xml playmode vertical syncdata: ' + error);
                }
                break;
            default:
        }

        switch (typeData.type) {
            case PlaymodeType_2_3.Xml:
                if (!typeData.svgs) break;
                try {
                    typeData.svgsVertical = typeData.svgs!.filter(s => s.layout === 'Vertical');
                    typeData.svgsHorizontal = typeData.svgs!.filter(s => s.layout === 'Horizontal');
                } catch (error) {
                    this.onError('Can not load Xml playmode vertical syncdata: ' + error);
                }

                break;
            default:
        }

        // console.log(typeData);



        this.playModeDetailsSignal.value = {
            status: 'selectedPlayModeSuccess',
            arrangementData: this.arrangementData,
            playModeId: playMode.id,
            baseData,
            typeData
        } as PlayModeDetailsStatus;
    };

    setPlayMode = (playMode: Playmode_2_3): Promise<void> => {
        if (!playMode || !this.playModes.includes(playMode)) {
            this.onError(`selected playMode ${playMode.id} does not seem to be a part of arrangement playModes`);
            return Promise.reject();
        }
        return this.loadPlayMode(playMode);
    };

    setPlayModeById = (id: string): Promise<void> => {
        const playMode = this.playModes.find(pm => pm.id == id);
        if (!playMode) {
            this.onError(`PlayMode with id ${id} does not exist`);
            return Promise.reject();
        }
        return this.setPlayMode(playMode);
    };

    clear() {
        this.arrangementId = null;
        this.arrangementData = null;
        this.playModes = [];
        this.selectedPlayMode = null;
        this.arrangementPlayModesSignal.value = {
            playModes: [],
            selectedPlayMode: {
                id: 'x',
                name: 'x',
                type: PlaymodeType_2_3.Audio
            }
        };
        this.playModeDetailsSignal.value = { status: 'selectedPlayModeEmpty' };
    }

    getXmlPlayModeTimesyncAndBarjumps(): { timeSync?: TimeSyncData, barJumps?: BarJumpsData } | null {
        switch (this.playModeDetailsSignal.value.status) {
            case 'selectedPlayModeSuccess':
                switch (this.playModeDetailsSignal.value.typeData.type) {
                    case PlaymodeType_2_3.Xml:
                        return {
                            timeSync: this.playModeDetailsSignal.value.typeData.timeSync,
                            barJumps: this.playModeDetailsSignal.value.typeData.barJumps
                        };
                    default:
                }
                break;
            default:
        }
        return null;
    }
}
