
import GraphicElementStore, { PageCoordinates } from "./GraphicElementStore";
import { DisplayModeState, LandscapeSize } from "./DisplayStore";
import DisplayStore from "./DisplayStore";
import { FrameSize } from "./GraphicElementStore";
import Signal from "../data/Signal";
import { WAVExtendedTimeMapEntry } from "../playerTypes";
import css from '../PlayerComponent.module.css'
import deepEqual from 'deep-equal';
import { round3 } from "../tools/NumberTools";
import PlayModeStore from "./PlayModeStore";
import { PlaymodeType_2_3 } from "../wav/WavPublicApi";

export const DEFAULT_A4_WIDTH = 1000;
export const DEFAULT_A4_HEIGHT = 1410;
export const PAGE_WIDTH_MARGIN = 8;
export const PAGE_MARGIN = 4;

export type Point = { x: number, y: number };

export type PagesLayoutBase = {
    visibleNrOfPages: number,
    firstPageNr: number,
    columns: number,
    rows: number,
    totalNrOfPages: number,
    // areaSize: FrameSize,
    // pageSize: FrameSize,
    pagesRelCoords: Array<PageCoordinates>,
    pagesEl: HTMLElement,
    overlayEl: HTMLElement,
};

export type PagesLayoutDetailed = PagesLayoutBase & {
    areaSize: FrameSize,
    pageSize: FrameSize,
    pagesCoords: Array<Point>,
    graphicsEl: HTMLElement,
    playheadEl: HTMLElement,
}

export type SpreadLayoutStatus = { state: 'spreadStateActive', firstPageNr: number, visibleNrOfPages: number, totalNrOfPages: number, shouldPossiblyBeVisible: number } | { state: 'spreadStateNone' };

export type DocumentStatus = {
    status: 'documentEmpty'
} | {
    status: 'documentRendering',
    msg: string,
} | {
    status: 'documentSuccess',
    documentType: DocumentType,
}

export type DocumentType = {
    type: 'Xml',
    svgs: Array<string>,
    timemapData: Array<WAVExtendedTimeMapEntry>,
} | {
    type: 'Pdf'
} | {
    type: 'ScorX',
    // } | {
    //     type: 'Test',
}

export default class DocumentStore {

    private graphicElementStore: GraphicElementStore;
    private pageDisplayStore: DisplayStore;
    private playModeStore: PlayModeStore;

    public constructor(graphicElementStore: GraphicElementStore, pageDisplayStore: DisplayStore, playModeStore: PlayModeStore, private onError: (error?: unknown) => void) {
        this.graphicElementStore = graphicElementStore;
        this.pageDisplayStore = pageDisplayStore;
        this.playModeStore = playModeStore;
        this.documentContentsSignal.add(this.updatePageLayout);
        this.bottomMarginSignal.add(this.updatePageLayout);
    }

    //-----------------------------------------------------------

    readonly pagesElsArray: Array<HTMLElement> = [];

    private prevPagesLayoutBase: PagesLayoutBase | null = null;

    readonly documentContentsSignal = new Signal<Array<HTMLElement>>([]);
    readonly documentStatusSignal = new Signal<DocumentStatus>({ status: 'documentEmpty' });
    readonly bottomMarginSignal = new Signal(0);
    readonly pagesLayoutBaseSignal = new Signal<PagesLayoutBase | null>(null);
    readonly pagesLayoutDetailedSignal = new Signal<PagesLayoutDetailed | null>(null);
    readonly spreadLayoutStatusSignal = new Signal<SpreadLayoutStatus>({ state: 'spreadStateNone' });
    public pagesMap = new Map<number, number | null>();

    private counter = 0;
    public updatePageLayout = () => {
        // console.log('update counter', this.counter++);
        const pagesElement = this.graphicElementStore.pagesElement;
        const pagesOverlayElement = this.graphicElementStore.pagesOverlayElement;
        const playheadOverlayElement = this.graphicElementStore.playheadOverlayElement;
        const frameSize = this.graphicElementStore.graphicFrameSizeSignal.value;
        const bottomMargin = this.bottomMarginSignal.value;
        const displayMode: DisplayModeState = this.pageDisplayStore.displayModeSignal.value;
        const zoomScalePortrait: number = this.pageDisplayStore.zoomScalePortrait.value;
        const zoomScaleLandscape: number = this.pageDisplayStore.zoomScaleLandscape.value;

        let factor = 0;
        let pageSize = { width: -1, height: -1 };
        switch (displayMode.state) {
            case 'displayModePortrait':
                switch (displayMode.type) {
                    case 'height':
                        factor = frameSize.height / DEFAULT_A4_HEIGHT;
                        pageSize = { width: DEFAULT_A4_WIDTH * factor, height: DEFAULT_A4_HEIGHT * factor };
                        break;
                    case 'width':
                        factor = (frameSize.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH;
                        pageSize = { width: DEFAULT_A4_WIDTH * factor, height: DEFAULT_A4_HEIGHT * factor };

                        break;
                    case 'full':
                        factor = Math.min((frameSize.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH, frameSize.height / DEFAULT_A4_HEIGHT);
                        pageSize = { width: DEFAULT_A4_WIDTH * factor, height: DEFAULT_A4_HEIGHT * factor };
                        break;
                    case 'zoom':
                        pageSize = { width: DEFAULT_A4_WIDTH * zoomScalePortrait, height: DEFAULT_A4_HEIGHT * zoomScalePortrait };
                        break;
                }
                break;
            case 'displayModeLandscape':
                const size: LandscapeSize = this.pageDisplayStore.displayLandscapeSize.value;
                switch (displayMode.type) {
                    case 'height':
                        factor = frameSize.height / size.height;
                        pageSize = { width: size.width * factor, height: size.height * factor };
                        break;
                    case 'zoom':
                        pageSize = { width: size.width * zoomScaleLandscape, height: size.height * zoomScaleLandscape };
                        break;
                }


                break;
            case 'displayModeSpread':
                factor = Math.min((frameSize.width - PAGE_WIDTH_MARGIN) / DEFAULT_A4_WIDTH, frameSize.height / DEFAULT_A4_HEIGHT);
                pageSize = { width: DEFAULT_A4_WIDTH * factor, height: DEFAULT_A4_HEIGHT * factor };
                break;
        }

        const totalNrOfPages: number = this.documentContentsSignal.value.length;


        let visibleNrOfPages = totalNrOfPages;
        let shouldPossiblyBeVisible = visibleNrOfPages;
        switch (displayMode.state) {
            case 'displayModeSpread':
                const x = Math.max(1, this.documentContentsSignal.value.length - displayMode.pageNr);
                const c = Math.min(2, Math.floor((frameSize.width - PAGE_WIDTH_MARGIN) / pageSize.width));
                shouldPossiblyBeVisible = Math.min(c, totalNrOfPages);
                visibleNrOfPages = Math.min(Math.min(Math.max(1, x), c), totalNrOfPages);
                break;
            default:
        }

        let firstPageNr = 0;
        switch (displayMode.state) {
            case 'displayModeSpread': firstPageNr = Math.max(0, Math.min(displayMode.pageNr, this.documentContentsSignal.value.length - 1));
                break;
            default:
        }

        // update spread state for showing/hiding buttons
        switch (displayMode.state) {
            case 'displayModeSpread':
                let newState1: SpreadLayoutStatus = { state: 'spreadStateNone' };
                switch (this.playModeStore.arrangementPlayModesSignal.value.selectedPlayMode.type) {
                    case PlaymodeType_2_3.Pdf:
                    case PlaymodeType_2_3.ScorX:
                    case PlaymodeType_2_3.Xml:
                        newState1 = { state: 'spreadStateActive', totalNrOfPages, visibleNrOfPages, firstPageNr, shouldPossiblyBeVisible };
                        break;
                }

                // if (JSON.stringify(newState1) != JSON.stringify(this.spreadLayoutStatusSignal.value))
                if (!deepEqual(newState1, this.spreadLayoutStatusSignal.value))
                    this.spreadLayoutStatusSignal.value = newState1;
                break;
            case 'displayModePortrait':
            case 'displayModeLandscape':
                const newState2: SpreadLayoutStatus = { state: 'spreadStateNone' }
                // if (JSON.stringify(newState2) != JSON.stringify(this.spreadLayoutStatusSignal.value))
                if (!deepEqual(newState2, this.spreadLayoutStatusSignal.value))
                    this.spreadLayoutStatusSignal.value = newState2;
                break;
        }

        // remove unused elements 
        while (this.pagesElsArray.length > visibleNrOfPages) {
            const el: HTMLElement = this.pagesElsArray.pop() as HTMLElement;
            pagesElement.removeChild(el);
        }
        // const columns = Math.min(Math.max(1, (Math.floor((frameSize.width - PAGE_WIDTH_MARGIN) / pageSize.width)), visibleNrOfPages));
        const columns = Math.min(visibleNrOfPages, Math.max(1, Math.floor((frameSize.width - PAGE_WIDTH_MARGIN) / pageSize.width)));
        const rows = columns > 0 ? Math.max(1, Math.ceil(visibleNrOfPages / columns)) : 0;
        const areaSize: FrameSize = { width: pageSize.width * columns, height: pageSize.height * rows };
        const areaCoord: Point = {
            x: Math.max(((frameSize.width - areaSize.width) / 2), .0),
            y: Math.max((((frameSize.height - bottomMargin) - areaSize.height) / 2), .0)
        };
        //-------------------------------------
        pagesElement.style.left = areaCoord.x + 'px';
        pagesElement.style.top = areaCoord.y + 'px';
        const pagesWidth = areaSize.width;
        pagesElement.style.width = pagesWidth + 'px';
        const pagesHeight = (areaSize.height + bottomMargin);
        pagesElement.style.height = pagesHeight + 'px';
        const overlayElementsHeight = (areaSize.height) + 'px';

        // pagesOverlayElement.style.height = pagesHeight + 'px';
        // playheadOverlayElement.style.height = pagesHeight + 'px';
        //-------------------------------------
        pagesOverlayElement.style.left = pagesElement.style.left;
        pagesOverlayElement.style.top = pagesElement.style.top;
        pagesOverlayElement.style.width = pagesElement.style.width;
        // pagesOverlayElement.style.height = pagesElement.style.height;
        pagesOverlayElement.style.height = overlayElementsHeight;


        playheadOverlayElement.style.left = pagesOverlayElement.style.left;
        playheadOverlayElement.style.top = pagesOverlayElement.style.top;
        playheadOverlayElement.style.width = pagesOverlayElement.style.width;
        playheadOverlayElement.style.height = pagesOverlayElement.style.height;

        //---------------------------------------
        const pagesCoords = [];
        const pagesRelCoords = [];
        let count = 0;
        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < columns; col++) {
                // console.log('count', count, 'visibleNrOfPages', visibleNrOfPages);
                const x = col * pageSize.width;
                const y = row * pageSize.height;
                pagesCoords.push({ x, y });
                pagesRelCoords.push({ x: round3(x / areaSize.width), y: round3(y / areaSize.height), w: round3(pageSize.width / areaSize.width), h: round3(pageSize.height / areaSize.height) })
                if (count >= (visibleNrOfPages - 1)) break;
                count++;
            }
        }

        pagesCoords.map((coord, pageIdx) => {
            // console.log('PageEl', pageIdx, 'firstPageNr', firstPageNr);
            const displayContentIdx = pageIdx + firstPageNr;
            let pageEl: HTMLElement | null = null;
            if (this.pagesElsArray.length > pageIdx) {
                pageEl = this.pagesElsArray[pageIdx];
            } else {
                this.pagesMap.set(pageIdx, null);
                pageEl = document.createElement('div');
                pageEl.classList.add(css.DocumentPage);
                pageEl.style.position = 'absolute';
                pagesElement.appendChild(pageEl);
                this.pagesElsArray.push(pageEl);
            }
            // check pagesMap   
            if (this.documentContentsSignal.value[displayContentIdx]) {
                const shouldUpdate: boolean = this.pagesMap.get(pageIdx) != displayContentIdx;
                if (shouldUpdate) {
                    pageEl.innerHTML = '';
                    const contentEl = this.documentContentsSignal.value[displayContentIdx];
                    this.pagesMap.set(pageIdx, displayContentIdx);
                    pageEl.appendChild(contentEl);
                }
            } else {
                // console.log('documentsidan finns inte', pageIdx, displayContentIdx);
            }

            pageEl.style.left = (coord.x + PAGE_MARGIN) + 'px';
            pageEl.style.top = (coord.y + PAGE_MARGIN) + 'px';
            pageEl.style.width = (pageSize.width - 2 * PAGE_MARGIN) + 'px';
            pageEl.style.height = (pageSize.height - 2 * PAGE_MARGIN) + 'px';
            // this.pagesMap.set(pageIdx, displayContentIdx);
        });

        //-------------------------------------------------------------
        // populate signals

        const layoutBase: PagesLayoutBase = {
            totalNrOfPages,
            visibleNrOfPages,
            columns,
            rows,
            firstPageNr,
            pagesRelCoords,
            pagesEl: pagesElement,
            overlayEl: pagesOverlayElement,
        }

        if (this.prevPagesLayoutBase == null || !deepEqual(layoutBase, this.prevPagesLayoutBase)) {
            this.pagesLayoutBaseSignal.value = layoutBase;
            this.prevPagesLayoutBase = this.pagesLayoutBaseSignal.value;
        }

        //------------------------------------------------------------------------

        this.pagesLayoutDetailedSignal.value = {
            totalNrOfPages,
            visibleNrOfPages,
            columns,
            rows,
            firstPageNr,
            areaSize,
            pageSize,
            pagesCoords,
            pagesRelCoords,
            pagesEl: pagesElement,
            overlayEl: pagesOverlayElement,
            graphicsEl: this.graphicElementStore.graphicElement,
            playheadEl: this.graphicElementStore.playheadElement,
        };
    }

    public replaceDocumentContentPage(pageIdx: number, pageContent: HTMLElement) {
        if (!this.documentContentsSignal.value[pageIdx]) {
            // this.onError(`Page ${pageIdx} does not exist - can not replace it.`);
            console.log(`Page ${pageIdx} does not exist - can not replace it.`);
            return;
        }

        // replace in documentsContentsSignal
        const contents: Array<HTMLElement> = this.documentContentsSignal.value;
        contents[pageIdx] = pageContent;
        this.documentContentsSignal.setSilently(contents);

        // replace in visible screen page
        const pagesArea = this.graphicElementStore.pagesElement;
        const documentPage: HTMLElement = pagesArea.children.item(pageIdx) as HTMLElement;
        if (documentPage) {
            documentPage.innerHTML = '';
            documentPage.appendChild(pageContent);
        }
    }

    public getBlankPage = (): HTMLElement => {
        const page = document.createElement('div');
        // page.innerHTML = PAGE_SVG;
        return page;
    }

    public clearPageElements = (): void => {
        this.pagesElsArray.forEach(el => el.innerHTML = '');
    }


}

// const PAGE_SVG = '<svg width="100%" height="100%" viewBox="0 0 317 120" version="1.1"  xml:space="preserve" style="fillRule:evenodd;clipRule:evenodd;strokeLinejoin:round;strokeMiterlimit:2;"><g transform="matrix(1,0,0,1,-321,-501)">    <g transform="matrix(1,0,0,0.784314,0,108.059)">        <rect x="321" y="501" width="317" height="153" style="fill:white;"/>    </g>    <g transform="matrix(1.025,0,0,1,-9.025,5)">        <rect x="361" y="540" width="120" height="16" style="fill:rgb(240,240,240);"/>    </g>    <g transform="matrix(1.49167,0,0,1,-177.492,35)">        <rect x="361" y="540" width="120" height="16" style="fill:rgb(240,240,240);"/>    </g>    <g transform="matrix(0.683333,0,0,1,114.317,65)">        <rect x="361" y="540" width="120" height="16" style="fill:rgb(240,240,240);"/>    </g></g></svg>';