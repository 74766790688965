import appsettings from '../appsettings.json';

export type ManArrangementData_2_3 = {
  title: string;
  subtitle: string;
  description: string;
  id: string;
};

export const get = (
  endpoint: string,
  token: string,
  headers: any
): Promise<any | boolean> =>
  callApi(endpoint, token, {
    method: 'GET',
    headers,
  });

export const post = (
  endpoint: string,
  token: string,
  body: any = {},
  headers?: any,
  rawBody?: any,
  options?: any
): Promise<any | boolean> =>
  callApi(endpoint, token, {
    method: 'POST',
    headers: rawBody ? {} : { 'Content-Type': 'application/json', ...headers },
    body: rawBody || JSON.stringify(body),
    ...options,
  });

export const put = (
  endpoint: string,
  token: string,
  body: any = {},
  headers?: any,
  rawBody?: any,
  options?: any
): Promise<any | boolean> =>
  callApi(endpoint, token, {
    method: 'PUT',
    headers: rawBody ? {} : { 'Content-Type': 'application/json', ...headers },
    body: rawBody || JSON.stringify(body),
    ...options,
  });

export const delete_ = (
  endpoint: string,
  token: string,
  body: any = {},
  headers?: any,
  rawBody?: any,
  options?: any
): Promise<any | boolean> =>
  callApi(endpoint, token, {
    method: 'DELETE',
    headers: rawBody ? {} : { 'Content-Type': 'application/json', ...headers },
    body: rawBody || JSON.stringify(body),
    ...options,
  });

const callApi = async (
  endpoint: string,
  token: string,
  options: any
): Promise<any | boolean> => {
  const url = endpoint.startsWith('http')
    ? endpoint
    : appsettings.API_URL + endpoint;
  const response = await fetch(url, {
    ...options,
    headers: {
      ...(options.headers || {}),
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });

  // Throw response as error, to allow client to check status, messages etc.
  if (!response.ok) {
    console.error('callApi error', 'status', response.status);
    const text = await response.text();
    console.error('callApi error', 'text', text);
    throw response;
  }

  try {
    return await response.json();
  } catch (_) {
    // If there was an error parsing JSON assume it was an empty 200 OK response and return boolean
    return true;
  }
};
