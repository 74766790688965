import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

const OrganizationSelect = () => {
  const userOrganizations = useSelector(
    (state: RootState) => state.userOrganizations
  );
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );
  const dispatch = useDispatch<Dispatch>();
  return (
    <>
      {userOrganizations.isLoading && <p>Loading user organizations...</p>}
      {userOrganizations.error && <p>Failed to get user organizations!</p>}

      {!userOrganizations.isLoading && !userOrganizations.items.length && (
        <p>
          You are not representing a content provider organization.
          <br />
          Please contact <a href="mailto:support@streamvoice.com">support@streamvoice.com</a> and specify your user account email address in order to setup your user account and connect it to a content provider organization.
        </p>
      )}

      {userOrganization && userOrganizations.items.length === 1 && (
        <p>You are representing {userOrganization.name} organization.</p>
      )}

      {userOrganizations.items.length > 1 && (
        <>
          <h3 style={{ marginTop: '1rem' }}>
            Select the organization to represent
          </h3>
          <select
            onChange={(e) => {
              dispatch.arrangement.unloadArrangement();
              dispatch.userOrganization.setState(
                userOrganizations.items.find(
                  (uo) => uo.id === e.target.value
                ) || null
              );
            }}
            value={userOrganization?.id}
          >
            {userOrganizations.items.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </>
      )}
    </>
  );
};

export default OrganizationSelect;
