import { createModel } from '@rematch/core';
import { RootModel } from '.';
import appsettings from '../appsettings.json';
import { Playmode } from './playmodes';
import { delay } from '../player/tools/TimerTools';
import { VerovioRenderData } from '../player/playerTypes';
import { createVeriovioRenderDataFromXmlUrl } from '../player/render/RenderXmlExportSVG';
import { uploadSvgFile, uploadSyncData } from '../player/grid/uploadSvgModeData';
import { createGridExportItemsFromVerovioData } from '../player/grid/gridUtils';
import { User } from './user';
import { ArrangementStatus } from './arrangement';
import { ArrangementVersion } from '../api/ManagementApi';
import VerovioStore from '../player/store/VerovioStore';

export type PlaymodeUploadState =
  | {
    type: 'file';
    file: File;
  }
  | {
    type: 'uploading';
    loaded: number;
    total: number;
    msg: string;
  }

export type PlaymodeUploadsState = {
  items: PlaymodeUploadState[];
  msg: string,
};

export const playmodeUploads = createModel<RootModel>()({
  state: { items: [], msg: '' } as PlaymodeUploadsState,
  reducers: {
    setState(state, newState: PlaymodeUploadsState) {
      return newState;
    },
  },
  effects: (dispatch) => ({

    async uploadGraphicFileData(data: { file: File; index: number; }, rootState) {

      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      if (!rootState.user.token) return;

      console.log('uploadGraphicFileData');
      const arrId = rootState.arrangement.id;
      const versionId = rootState.arrangementVersion.id;
      const ext = data.file.name.substring(data.file.name.length - 3);
      const url =
        appsettings.API_URL +
        `/arrangements/${arrId}/versions/${versionId}/upload/${ext}`;

      const formData = new FormData();
      formData.append('file', data.file);

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Bearer ${rootState.user.token}` }
      });

      if (response.ok) {
        const items = [...rootState.playmodeUploads.items];
        items.splice(data.index, 1);
        dispatch.playmodeUploads.setState({ items, msg: '' });
        dispatch.playmodes.load({});
      } else {
        alert('Could not upload musicxml file');
      }

      // console.log('before delay');
      // await delay(1000)
      // console.log('after delay');
      dispatch.playmodeUploads.setState({ items: [], msg: '' })

      const items: Playmode[] | null = await dispatch.playmodes.load({});
      console.log('items', items);
      if (!items) return null;



      for (const item of items) {
        switch (item.playModeType) {
          case 'Xml':
            console.log('Xml playModeType');
            if (!item.typeData) {
              console.log('item has no typedata');
              return;
            }
            // console.log('item has typeData', item.typeData);
            switch (item.typeData.type) {
              case 'Xml':
                dispatch.playmodeUploads.setState({ items: [], msg: 'Generating svgs and syncs' });
                await delay(100);
                try {
                  await generateAndUploadSvgsAndSyncs(rootState.user!, rootState.arrangement, rootState.arrangementVersion, item, null);
                } catch (e) {
                  alert('Error generating svgs and syncs: ' + e);
                }
                await delay(100);
                dispatch.playmodeUploads.setState({ items: [], msg: '' });
                break;
              default:
                console.log('Not XML');
            }

            break;
          default:
          // console.log('Othre playModeType');
        }
      }

      await delay(2000);
      dispatch.playmodes.load({});

    },

    // async generateSvgModeData(data: { file: File, index: number }) {
    //   const xmlString = await data.file.text()
    //   // console.log(xmlString);
    // },

    async removeItem(fileIdx: number, rootState) {
      //
      const item = rootState.playmodeUploads.items[fileIdx];

      if (item.type === 'uploading') return;


      const items = rootState.playmodeUploads.items;
      items.splice(fileIdx, 1);
      dispatch.playmodeUploads.setState({ items, msg: 'one item removed' });
    }

  }),
});

const verovioStore = new VerovioStore((error) => console.error(error));

export const generateAndUploadSvgsAndSyncs = async (userState: User, arrState: ArrangementStatus, arrVersion: ArrangementVersion, item: Playmode, statusSetter: any | null) => {
  if (!item.typeData) return null;
  if (item.typeData.type !== 'Xml') return null;
  if (arrState.type !== 'arrangement') return null;

  const log = (msg: string) => {
    if (statusSetter) statusSetter(msg);
    console.log(msg);
  }
  log('Generating Svg Data...');

  const xmlUrl = item.typeData.musicXml ?? item.typeData.musicXmlUrl;

  if (!xmlUrl) {
    alert('xmlUrl is undefined');
    return;
  }

  const verticalData: VerovioRenderData =
    await createVeriovioRenderDataFromXmlUrl(
      xmlUrl,
      verovioStore,
      'Vertical'
    );

  console.log(verticalData);

  let pageIdx = 0;
  for (const svg of verticalData.svgs) {
    console.log('Upload portrait svg, page ' + pageIdx);
    log('Creating vertical page nr ' + pageIdx);
    const response = await uploadSvgFile(
      appsettings.API_URL,
      arrState.id,
      arrVersion.id,
      item.id,
      svg,
      pageIdx,
      'Vertical',
      userState.token!
    );
    if (!response.ok) {
      alert(response.statusText);
      return;
    }
    pageIdx++;
  }

  log('Creating vertical syncdata...');
  const exportItemsPortrait =
    await createGridExportItemsFromVerovioData(
      verticalData,
      [],
      [],
      (e: any) => alert(e)
    );

  const responsePortraitSync = await uploadSyncData(
    appsettings.API_URL,
    arrState.id,
    arrVersion.id,
    item.id,
    exportItemsPortrait!,
    'Vertical',
    userState.token!
  );

  if (!responsePortraitSync.ok) {
    alert(responsePortraitSync.statusText);
    return;
  }

  //=================================================================
  // Export and save horizontal data
  log('Creating horizontal page...');
  const horizontalData: VerovioRenderData =
    await createVeriovioRenderDataFromXmlUrl(
      xmlUrl,
      verovioStore,
      'Horizontal'
    );

  console.log(horizontalData);
  pageIdx = 0;
  for (const svg of horizontalData.svgs) {
    console.log('Upload portrait svg, page ' + pageIdx);
    const response = await uploadSvgFile(
      appsettings.API_URL,
      arrState.id,
      arrVersion.id,
      item.id,
      svg,
      pageIdx,
      'Horizontal',
      userState.token!
    );
    console.log('page', pageIdx, 'Horizontal', response);
    if (!response.ok) {
      alert(response.statusText);
      return;
    }
    pageIdx++;
  }

  log('Creating horizontal syncdata...');
  const exportItemsLandscape =
    await createGridExportItemsFromVerovioData(
      horizontalData,
      [],
      [],
      (e: any) => alert(e)
    );

  const responseLandscapeSync = await uploadSyncData(
    appsettings.API_URL,
    arrState.id,
    arrVersion.id,
    item.id,
    exportItemsLandscape!,
    'Horizontal',
    userState.token!
  );
  if (!responseLandscapeSync.ok) {
    alert(responseLandscapeSync.statusText);
    return;
  }

  log('');
}