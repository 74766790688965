
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf'
// import * as PDFJS from 'pdfjs-dist/build/pdf'

import { PDFPageProxy } from '../../../node_modules/pdfjs-dist/types/src/display/api';
import { PDFDocumentProxy } from '../../../node_modules/pdfjs-dist/types/web/pdf_find_controller';
import DocumentStore from '../store/DocumentStore';
import { numberIterator } from '../tools/NumberTools';
import GraphicElementStore from '../store/GraphicElementStore';
import { PlaymodeType_2_3 } from '../wav/WavPublicApi';
// PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

export default class RenderPdf {
    public static async renderPdf(url: string, documentStore: DocumentStore) {
        documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading pages...' };
        const pdf: PDFDocumentProxy = await PDFJS.getDocument(url).promise;
        documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading page 1/' + pdf.numPages };
        documentStore.documentContentsSignal.value = numberIterator(0, pdf.numPages - 1).map(idx => documentStore.getBlankPage());

        for (let pageIdx = 0; pageIdx < pdf.numPages; pageIdx++) {
            documentStore.documentStatusSignal.value = { status: 'documentRendering', msg: 'Loading page ' + (pageIdx + 1) + '/' + pdf.numPages };
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            const page: PDFPageProxy = await pdf.getPage(pageIdx + 1);
            const viewport = page.getViewport({ scale: 2.5 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.style.width = canvas.style.height = '100%';
            const context = canvas.getContext("2d") as CanvasRenderingContext2D;
            const renderCondext = { canvasContext: context, viewport: viewport }
            await page.render(renderCondext);
            const tempDiv = document.createElement('div');
            tempDiv.appendChild(canvas);
            tempDiv.style.height = tempDiv.style.width = '100%';
            documentStore.replaceDocumentContentPage(pageIdx, tempDiv);
        }

        documentStore.documentStatusSignal.value = { status: 'documentSuccess', documentType: { type: PlaymodeType_2_3.Pdf } };
    }

}