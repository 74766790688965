import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { delete_, get, put } from '../api/Api';
import { RecordingDB } from '../api/ManagementApi';

export type RecordingState = RecordingDB & {
  status?: string;
  progress?: number;
};

export type RecordingsState = {
  items: RecordingState[];
  loading: boolean;
};

type Job = {
  id: string;
  createdUtc: string;
  updatedUtc: string;
  name: string;
  type: string;
  status: string;
  message: string;
  progress: number;
  metadata: {
    audioTrackId: string;
    arrangementId: string;
    arrangementVersionId: string;
  };
  createdBy: {
    id: string;
    displayName: string;
  };
  dismissed: string;
};

export const recordings = createModel<RootModel>()({
  state: {
    loading: false,
    items: [],
  } as RecordingsState,
  reducers: {
    setState(state, newState: RecordingsState) {
      return newState;
    },
  },
  effects: (dispatch) => ({
    async load(payload, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      dispatch.recordings.setState({
        ...rootState.recordings,
        loading: true,
      });
      const arrangementId = rootState.arrangement.id;
      const versionId = rootState.arrangementVersion.id;
      const url = `/arrangements/${arrangementId}/versions/${versionId}/audiotracks`;
      const items = (await get(url, rootState.user.token, {})) as RecordingDB[];
      dispatch.recordings.setState({
        ...rootState.recordings,
        loading: false,
        items: items.map((i) => ({
          ...rootState.recordings.items.find((oldItem) => oldItem.id === i.id),
          ...i,
        })),
      });
      await dispatch.recordings.updateEncodingStatus({});
    },
    async delete(id: string, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      dispatch.recordings.setState({
        ...rootState.recordings,
        loading: true,
      });
      const arrangementId = rootState.arrangement.id;
      const versionId = rootState.arrangementVersion.id;
      const url = `/arrangements/${arrangementId}/versions/${versionId}/audiotracks/${id}`;
      await delete_(url, rootState.user.token, {});
      await dispatch.recordings.load({});
      await dispatch.playmodes.load({});
    },
    async update(payload: { id: string; name: string }, rootState) {
      if (!rootState.user.token) return;
      if (rootState.arrangement.type !== 'arrangement') return;
      if (!rootState.arrangementVersion) return;
      dispatch.recordings.setState({
        ...rootState.recordings,
        loading: true,
      });
      const url = `/arrangements/${rootState.arrangement.id}/versions/${rootState.arrangementVersion.id}/audiotracks/${payload.id}`;
      const recordingData = await get(url, rootState.user.token, {});
      const data = {
        ...recordingData,
        name: payload.name,
      };
      await put(url, rootState.user.token, data);
      await dispatch.recordings.load({});
    },
    async updateEncodingStatus(payload, rootState) {
      if (!rootState.user.token) return;
      const jobs = (await get('/jobs', rootState.user.token, {})) as Job[];
      const items = rootState.recordings.items.map(
        (i) =>
          ({
            ...i,
            status: undefined,
            progress: undefined,
          } as RecordingState)
      );
      for (const j of jobs) {
        const jobItems = items.filter((i) => i.id === j.metadata.audioTrackId);
        for (const item of jobItems) {
          item.status = j.status;
          item.progress = j.progress;
        }
      }
      dispatch.recordings.setState({
        ...rootState.recordings,
        items,
      });
    },
  }),
});
