import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import { Dispatch, RootState } from '../../../store';

const ArrangementLoad = () => {
  const dispatch = useDispatch<Dispatch>();
  const defaultArr = '';
  // const defaultArr = '894d3855-f206-483e-b208-91f2cd9f08ad';
  const [id, setId] = useState(defaultArr);
  const userOrganization = useSelector(
    (state: RootState) => state.userOrganization
  );
  if (!userOrganization) return null;
  return (
    <div>
      <h3 style={{ marginTop: '1rem' }}>Load arrangement</h3>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto min-content',
          gap: '0.5rem',
        }}
      >
        <label>
          <input
            type="text"
            placeholder="Arrangement ID"
            value={id || ''}
            onChange={(e) => setId(e.target.value)}
          />
        </label>
        <Button
          onClick={() => {
            dispatch.arrangement.loadArrangement({ id: id });
          }}
        >
          Load
        </Button>
      </div>
    </div>
  );
};

export default ArrangementLoad;
