export const get = async <TOut>(apiUrl: string, token: string, apiVersion?: string): Promise<TOut> => {
    const authorization = `Bearer ${token}`;

    const response = await fetch(apiUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': authorization,
            'api-version': apiVersion ?? '3.0',
        }
    });

    return response.json() as TOut;
};

export const post = async <TIn, TOut>(apiUrl: string, token: string, data: TIn, apiVersion?: string, keepAlive?: boolean): Promise<TOut | undefined> => {
    const authorization = `Bearer ${token}`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization,
            'api-version': apiVersion ?? '3.0'
        },
        body: JSON.stringify(data),
        keepalive: keepAlive
    });
    if (response.status < 200 || response.status >= 300) {
        throw new Error(`Failed POST request: ${response.status} ${response.statusText}`);
    }
    if (response.status === 204) {
        return undefined;
    }
    return await response.json() as TOut;
};

